<template>
  <Layout :bgData="currentCard.cont" :name="currentCard.name" @click="$emit('click')">
    <template v-slot:fixedInfo>
      <div class="p-hiring">
        <div class="p-hiring__info-wrap">
          <div class="p-hiring__info">
            <div v-if="!data.isLangKr" class="p-hiring__info-tit">
              <span class="t-cb--13"><span class="t--fak">Category</span></span>
            </div>
            <div v-else class="p-hiring__info-tit">
              <span class="t-cb--13"><span class="t--kr">카테고리</span></span>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__info-txt">
              <div class="t-cb--13"><span class="t--fak">Branding</span></div>
            </div>
            <div v-else class="p-hiring__info-txt">
              <div class="t-cb--13"><span class="t--kr">브랜딩</span></div>
            </div>
          </div>
          <div class="p-hiring__info">
            <div v-if="!data.isLangKr" class="p-hiring__info-tit">
              <span class="t-cb--13"><span class="t--fak">Job Type</span></span>
            </div>
            <div v-else class="p-hiring__info-tit">
              <span class="t-cb--13"><span class="t--kr">유형</span></span>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__info-txt">
              <div class="t-cb--13"><span class="t--fak">Full Time Contractor</span></div>
            </div>
            <div v-else class="p-hiring__info-txt">
              <div class="t-cb--13"><span class="t--kr">정규직</span></div>
            </div>
          </div>
        </div>
        <div v-if="!data.isLangKr" class="p-hiring__desc">
          <div class="t-cb--16"><span class="t--fak">디파이에서는 브랜드 경험을 기획하고 디자인하는 Brand Experience Designer 분야의 인재를 찾습니다.</span></div>
          <div class="t-cb--16"><span class="t-cb__item t--fak">현재 디파이에서 시작한 브랜드인 D FLAT을 시작으로 여러 내부 브랜딩을 시작하면서 새로운 경험에 도전하는 분들을 기다리고 있습니다.</span></div>
          <div class="t-cb--16"><span class="t-cb__item t--fak">디파이에서 새롭게 시작하는 브랜드와 함께 BX Designer로 시작해 Director로 성장하실 분을 찾습니다.</span></div>
        </div>
        <div v-else class="p-hiring__desc">
          <div class="t-cb--16"><span class="t--kr">디파이에서는 브랜드 경험을 기획하고 디자인하는 Brand Experience Designer 분야의 인재를 찾습니다.</span></div>
          <div class="t-cb--16"><span class="t-cb__item t--kr">현재 디파이에서 시작한 브랜드인 D FLAT을 시작으로 여러 내부 브랜딩을 시작하면서 새로운 경험에 도전하는 분들을 기다리고 있습니다.</span></div>          
          <div class="t-cb--16"><span class="t-cb__item t--kr">디파이에서 새롭게 시작하는 브랜드와 함께 BX Designer로 시작해 Director로 성장하실 분을 찾습니다.</span></div>          
        </div>
        <ul class="p-hiring__required-wrap">
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➊</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">관련 경력 1 - 5년 이하 경력을 갖추신 분</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">관련 경력 1 - 5년 이하 경력을 갖추신 분</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➋</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">브랜드 초기 구축에서 운영까지의 경험이 있으신 분</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">브랜드 초기 구축에서 운영까지의 경험이 있으신 분</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➌</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">브랜드 정체성에 맞는 감각적인 비주얼 디자인이 가능하신 분</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">브랜드 정체성에 맞는 감각적인 비주얼 디자인이 가능하신 분</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➍</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">높은 수준의 그래픽디자인과 일러스트레이션 능력을 갖추신 분</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">높은 수준의 그래픽디자인과 일러스트레이션 능력을 갖추신 분</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➎​</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">인쇄 제작 프로세스를 이해하고 있으신 분</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">인쇄 제작 프로세스를 이해하고 있으신 분</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➏</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">목적에 맞는 논리적인 기획력과 소통 능력을 갖추신 분</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">목적에 맞는 논리적인 기획력과 소통 능력을 갖추신 분</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➐</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">타 직군 및 이해관계자 간의 유연한 커뮤니케이션이 원활하신 분</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">타 직군 및 이해관계자 간의 유연한 커뮤니케이션이 원활하신 분</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">❤️</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">모션그래픽, 또는 3D 애니메이션 스킬이 있으신 분</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">모션그래픽, 또는 3D 애니메이션 스킬이 있으신 분</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">❤️</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">정확하고 간결한 글쓰기 능력을 갖추신 분</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">정확하고 간결한 글쓰기 능력을 갖추신 분</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">❤️</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">플랫폼 비즈니스에 대한 높은 관심이 있으신 분</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">플랫폼 비즈니스에 대한 높은 관심이 있으신 분</span></div>
            </div>
          </li>
        </ul>
      </div>
    </template>
  </Layout>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { ref, reactive, computed, onMounted, onUpdated,watchEffect, onBeforeUnmount, onUnmounted, useStore } from "@/helper/vue.js";
import { addEvent, removeEvent } from "@/helper/event.js";

import Layout from "../../_ChatPageLayout.vue";

export default {
  props: [ 'currentCard' ],
  emits: [ 'click' ],
  components: {
    Layout,
  },
  setup(props, {emit}) {
    const { state, get, commit, dispatch } = useStore();
    const data = reactive({
      isLangKr: computed(() => state.isLangKr),
    });

    const methods = {
    };

    onMounted(() => {
      // console.log("props.bgData", props.bgData);
    });

    return {
      data,
      methods
    }
  }
}
</script>

<style lang="scss" src="@/css/chatbot.scss"></style>
