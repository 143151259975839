import * as _ from "fxjs";
import * as $ from "fxdom";
import { gsap } from "gsap/all";
import { clamp, normalize } from "@/helper/gadget.js";
import { Pointer } from "@/js/cursorActions.js";

const isMobile = true;

// el로 지정한 영역 드래그 이벤트 수신 활성화
const Gesture = (function() {
	function Gesture(settings) {
		// type, el, validator
		Object.assign(this, settings);
		this.dragEl = null;
		this.items = [];
		this.startPos = {
			x: 0, y: 0
		};
		this.threshold = {
			x: 0, y: 0
		};
		this.dragStartTarget = null;

		switch(this.type) {
			case "HORIZONTAL_DRAG":
				this.threshold.x = 30;
				this.threshold.y = 20;
				break;
			case "VERTICAL_DRAG":
				this.threshold.x = 20;
				this.threshold.y = 30;
				break;
			case "DRAG":
				this.threshold.x = 30;
				this.threshold.y = 30;
				break;
		}

		this.isPointDown = false;
		this.isDragging = false;

		// validator
		if(this.validator == undefined) {
			this.validator = () => true;
		}
		this.isValidate = this.validator();

		if(this.isValidate) {
			// console.log("beforeInit");
			this.init();
			// console.log("afterInit");
		}

	};

	Gesture.prototype.add = function(item) {
		this.items.push(item);
	}

	Gesture.prototype.thresholdTest = function () {
		if(this.isPointDown == false) return;
		const deltaX = Pointer.getRealX() - this.startPos.x;
		const absX = Math.abs(deltaX);
		const deltaY = Pointer.getRealY() - this.startPos.y;
		const absY = Math.abs(deltaY);

		switch(this.type) {
			case "HORIZONTAL_DRAG" :
				if(absX > this.threshold.x) {
					if(deltaX < 0) {
						return "DRAG_LEFT";
					}
					else {
						return "DRAG_RIGHT";
					}
				}
				break;
			case "DRAG" :
				if(absX > this.threshold.x) {
					if(deltaX < 0) {
						return "DRAG_LEFT";
					}
					else {
						return "DRAG_RIGHT";
					}
				}
				else if(absY > this.threshold.y) {
					if(deltaY < 0) {
						return "DRAG_DOWN";
					}
					else {
						return "DRAG_UP";
					}
				}
				break;
		}

		return false;
	};

	// 마우스 다운, 터치 스타트
	Gesture.prototype.pointDown = function(e) {
		const dragEl = $.closest(".c-drag__point", e.target);
		if(!dragEl) return;
		this.dragEl = dragEl;
		this.startPos.x = Pointer.getRealX();
		this.startPos.y = Pointer.getRealY();
		// $.setText(`pointDown [${this.startPos.x}, ${this.startPos.y}]`, $.qs(".notice"));
		// console.log("pointDown");
		this.isPointDown = true;
	};

	// 마우스 업, 터치 엔드
	Gesture.prototype.pointUp = function() {
		// $.setText("pointUp", $.qs(".notice"));
		// console.log("pointUp");
		if(this.isDragging) {
			this.dragEnd();
		}
		this.isPointDown = false;
		this.isDragging = false;
	};

	// Pan 스타트(pointDown 후에 treshold만큼 이동했을 경우)
	Gesture.prototype.dragStart = function() {
		this.onDragStart(this);
		$.addClass("c-drag--dragging", this.el);
		this.isDragging = true;
	};

	// dragStart 후에 일어나는 모든 이동
	// dragEnd 이전까지 유지
	Gesture.prototype.onDragMove = function() {};

	// Pan 스타트가 일어난 후에 pointUp이 발생하면 추가적으로 발생
	Gesture.prototype.dragEnd = function() {
		this.onDragEnd();
		$.removeClass("c-drag--dragging", this.el);
	};

	Gesture.prototype.init = function() {

		// pointDown
		this.el.addEventListener("touchstart", e => {
			this.dragStartTarget = e.target;
			// if(!isMobile) return;
			this.pointDown(e);
		});
		this.el.addEventListener("mousedown", e => {
			this.dragStartTarget = e.target;
			// if(isMobile) return;
			this.pointDown(e);
		});

		// pointUp
		document.addEventListener("touchend", () => {
			this.dragStartTarget = null;
			if(!this.isPointDown) return;
			// if(!isMobile || !this.isPointDown) return;
			this.pointUp();
		});
		document.addEventListener("mouseup", () => {
			this.dragStartTarget = null;
			if(!this.isPointDown) return;
			// if(isMobile || !this.isPointDown) return;
			this.pointUp();
		});
		document.addEventListener("mouseleave", () => {
			this.pointUp();
		});

		// move
		const moveEventProc = () => {
			if(!this.isDragging) {
				if(this.thresholdTest() != false) {
					this.dragStart();
				}
				// $.setText(test, $.qs(".notice"));
			}
			else {
				this.onDragMove(this);
			}
		}
		this.el.addEventListener("touchmove", () => {
			if(!this.isPointDown) return;
			// if(!isMobile || !this.isPointDown) return;
			moveEventProc();
		});
		this.el.addEventListener("mousemove", () => {
			if(!this.isPointDown) return;
			// if(isMobile || !this.isPointDown) return;
			// console.log("mousemove");
			moveEventProc();
		});
	};

	Gesture.prototype.reset = function() {};

	Gesture.prototype.testEnabled = function() {};

	Gesture.prototype.stop = function() {
	};

	// 미디어쿼리 변경될 때 실행
	Gesture.prototype.update = function() {

		this.isValidate = this.validator();

		// 활성
		if(this.isValidate) {
			!!this.ham && this.init();
		}
		// 비활성
		else {}
	};

	Gesture.prototype.add = function() {

	};

	return Gesture;
}());

const DragSlider = (function() {

	function DragSlider(settings) {
		const noop = () => {};
		this.onInit = noop;
		this.onRefresh = noop;
		this.onEarlySnapComplete = noop;
		this.onSnapComplete = noop;
		this.onUpdate = noop;

		// el, onDragStart, onDragMove
		Object.assign(this, settings);
		this.gesture = new Gesture({
			type: "HORIZONTAL_DRAG",
			el: this.el,
			validator: () => {
				if(1) {
					return true;
				}
			},
			onDragStart: this.onDragStart.bind(this),
			onDragMove: this.onDragMove.bind(this),
			onDragEnd: this.onDragEnd.bind(this)
		});

		// 프로토타입 저장은 한번만
		this.alignPrototype = this.align;

		this.tickerCallback = function() {
			const pointerTotalDeltaX = ((this.gesture.isDragging ? Pointer.getRealX() : this.dropPos.x) - this.startPointerPos.x) * 2;
			// const drawTotalDeltaX = pointerTotalDeltaX;
			// this.currentItemPos.x += (pointerTotalDeltaX + this.startItemPos.x - this.currentItemPos.x) * 0.07;

			let newDest = pointerTotalDeltaX + this.startItemPos.x;
			newDest = clamp(newDest, this.endPos.x, 0);

			if(this.gesture.isDragging == false) {
				newDest = this.snapDest;
			}

			this.drawPos.x += (newDest - this.drawPos.x) * 0.07;

			let newProgress = ((this.containerRect.width - this.drawPos.x) / this.dragItemWidth * 100);
			newProgress = Math.round(this.drawPos.x) == this.endPos.x ? 100 : newProgress;
			this.updateDragItemX(this.drawPos.x);

			// release
			if(this.gesture.isDragging == false) {
				const roundNewDest = Math.round(newDest);
				const roundDrawPos = Math.round(this.drawPos.x);
				const gap = Math.abs(roundNewDest - roundDrawPos);

				// Early snap
				if(gap < 65 && !this.isEarlySnapDone) {
					this.onEarlySnapComplete(this.el, this.previousItemIndex, this.currentItemIndex);
					this.isEarlySnapDone = true;
				}

				// stop
				if(roundNewDest == roundDrawPos) {
					if(this.snapDest == this.endPos.x) {
						newProgress = 100;
					}
					this.onSnapComplete(this.previousItemIndex, this.currentItemIndex);
					gsap.ticker.remove(this.ticker);
				}
			}

			this.updateProgbar(newProgress);
			this.onUpdate(newProgress);
		};

		this.a = null;

		this.recalculate();

		this.init();
	};

	DragSlider.prototype.init = function() {
		this.RESIZE_CANVAS_X = function(){
			this.recalculate();
		};
		this.RESIZE_CANVAS_X = this.RESIZE_CANVAS_X.bind(this);
		window.addEventListener("resize.canvas.x", this.RESIZE_CANVAS_X);
		this.onInit(this);
	};

	DragSlider.prototype.recalculate = function() {

		gsap.ticker.remove(this.ticker);
		this.dragItem && this.updateDragItemX(0);

		if(typeof this.alignPrototype == "function") {
			this.align = this.alignPrototype();
		}

		this.containerRect = $.qs(".c-drag__container", this.el).getBoundingClientRect();
		this.dragItem = $.qs(".c-drag__unit", this.el);
		const dragItems = $.qsa(".c-drag__item", this.el);
		const firstDragItemRect = dragItems[0].getBoundingClientRect();
		const lastDragItemRect = dragItems[dragItems.length - 1].getBoundingClientRect();
		this.dragItemWidth = (lastDragItemRect.x + lastDragItemRect.width) - firstDragItemRect.x;
		this.dragItem.style.width = this.dragItemWidth + "px";
		this.previousItemIndex = this.currentItemIndex = 0;
		this.progbar = $.qs(".c-drag__progress", this.el);

		this.startPointerPos = { x: 0, y: 0 };
		this.startItemPos = { x: 0, y: 0 };
		this.currentItemPos = { x: 0, y: 0 };
		this.drawPos = { x: 0, y: 0 };
		this.dropPos = { x: 0, y: 0 };
		this.endPos = { x: 0, y: 0 };
		this.updateDragItemX = gsap.quickSetter(this.dragItem, "x", "px");
		this.updateProgbar = gsap.quickSetter(this.progbar, "width", "%");
		const progress = ((this.containerRect.width - this.drawPos.x) / this.dragItemWidth * 100);
		this.updateProgbar(progress);
		this.ticker = null;
		this.endPos.x = this.containerRect.width - this.dragItemWidth;

		this.onUpdate(progress);
		this.onEarlySnapComplete(this.el, 0, 0);
		this.onSnapComplete(0, 0);

		this.onRefresh(this.el);
	};

	DragSlider.prototype.destroy = function() {
		// console.log("destroy");
		window.removeEventListener("resize.canvas.x", this.RESIZE_CANVAS_X);
		gsap.ticker.remove(this.ticker);
		this.el = null;
		// this.gesture.destroy();
		this.gesture = null;
	};

	DragSlider.prototype.onDragStart = function() {
		this.isEarlySnapDone = false;
		this.gesture.isDragging = true;
		this.startPointerPos.x = Pointer.getRealX();
		this.startItemPos.x = gsap.getProperty($.qs(".c-drag__unit", this.el), "x");
		this.drawPos.x = this.startItemPos.x;
		this.dropPos.x = this.startItemPos.x;
		gsap.ticker.remove(this.ticker);
		this.ticker = this.tickerCallback.bind(this);
		gsap.ticker.add(this.ticker);
	};

	DragSlider.prototype.onDragMove = function() {};

	DragSlider.prototype.onDragEnd = function() {

		this.previousItemIndex = this.currentItemIndex;

		// this.dropPos.x = Pointer.getRealX() - Pointer.getDeltaX() * 3;
		this.dropPos.x = Pointer.getRealX() - ((Pointer.getCurrentX() - Pointer.getRealX()) * 1.3);
		// this.dropPos.x = Pointer.getRealX() - ((Pointer.getCurrentX() - Pointer.getRealX()) * 0.5);

		// alert(Pointer.getCurrentX() - Pointer.getRealX());

		const pointerTotalDeltaX = (this.dropPos.x - this.startPointerPos.x) * 2;
		let dest = pointerTotalDeltaX + this.startItemPos.x;
		dest = clamp(dest, this.endPos.x, 0);

		if(dest == this.endPos.x) {
			this.snapDest =	this.endPos.x;
			this.currentItemIndex = $.qsa(".c-drag__item", this.el).length - 1;
		}
		else if(dest == 0) {
			this.snapDest =	0;
			this.currentItemIndex = 0;
		}
		else {
			// defalut: left
			let saveDiff = 0;
			let saveGap = 99999;
			let itemIndex = 0;
			let closestItem = _.go(
				$.qsa(".c-drag__item", this.el),
				_.minBy(item => {
					const itemRect = item.getBoundingClientRect();
					// this.containerRect.width / 2
					let diff;
					if(this.align == "center") {
						diff = (this.containerRect.width / 2 + this.containerRect.x - dest + this.drawPos.x) - (itemRect.x + (itemRect.width / 2));
					}
					else {
						diff = (this.containerRect.x - dest + this.drawPos.x) - (itemRect.x + (itemRect.width / 2));
					}
					const gap = Math.abs(diff);

					if(saveGap > gap) {
						saveGap = gap;
						saveDiff = diff;
						this.currentItemIndex = itemIndex++;
					}
					return gap;
				})
			);

			if(this.align != "center" && saveDiff > 0) {
				if(closestItem.nextElementSibling) {
					closestItem = closestItem.nextElementSibling;
					this.currentItemIndex += 1;
				}
			}

			if(this.align == "center") {
				this.snapDest = this.drawPos.x - closestItem.getBoundingClientRect().x + ((-closestItem.getBoundingClientRect().width / 2) + this.containerRect.x + this.containerRect.width / 2);
			}
			else {
				this.snapDest = this.drawPos.x - closestItem.getBoundingClientRect().x + (this.containerRect.x);
			}

			if(this.snapDest < this.endPos.x) this.snapDest = this.endPos.x;

		}
	};

	return DragSlider;
})();

// window.addEventListener("landed", () => {
// 	// const slider = new DragSlider({
// 	// 	el: $.qs(".c-slider"),
// 	// 	align() {
// 	// 		return "left";
// 	// 		// return "center";
// 	// 	},
// 	// 	onSnapComplete(prevIndex, currIndex) {
// 	// 		console.log(`prev: ${prevIndex} / curr: ${currIndex}`);
// 	// 	},
// 	// 	onUpdate(p) {
// 	// 		// console.log(this.containerRect.width);
// 	// 		// const total = Math.abs(this.endPos.x);
// 	// 		// const pushWidth = 320;
// 	// 		// const pushStartProgress = 100 - (pushWidth / total) * 100;
// 	// 		// const pushProgress = normalize(p, pushStartProgress, 100);
// 	// 		// $.setCss({
// 	// 		// 	width: `calc(80% + ${320 * pushProgress}px)`
// 	// 		// }, $.qs(".c-drag__progress-wrap", this.el));
// 	// 		// console.log("asddfsfdsfadsdsf - ", pushProgress);
// 	// 	}
// 	// });
// });

export { Gesture, DragSlider };