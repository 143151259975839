<template>
  <div class="l-mdl--64 c-last__contents normal-desc">
    <div class="g-row s--h-end">
      <div class="g-col--6 g-col--3s g-col--4l g-mr--2l">
        <div class="t-h--18 t-h--16s">
          <div class="t-block t--fak" v-html="methods.descFilter(normalDescEn)"></div>
          <div class="t-block t--kr normal-desc__kr" v-html="methods.descFilter(normalDescKr)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "@/helper/vue.js";
export default {
  name: 'Footer normal desc',
  props: {
    normalDescEn: {
      type: String,
      default: null
    },
    normalDescKr: {
      type: String,
      default: null
    },
  },
  setup() {
    const methods = {
      escapeSequenceDfy: (desc) => {
        return desc.split('DFY®').join('<span class="t-dfy">DFY</span>')
      },
      textBreak: desc => {
        return desc.split('\n').join('<br />')
      },
      descFilter:(desc) =>{
       let paragraph = methods.escapeSequenceDfy(desc)
        return methods.textBreak(paragraph)
      }
    }

    onMounted(() => {
      
    })
    return {
      methods
    }
  }
}
</script>

<style lang="scss" scoped>
  // @import "@/css/_settings.scss";
  .normal-desc {
    &__kr {
      margin-top: 0.52em;
    }
  }
  .t-dfy {
    color: inherit;
    &:after {
      color: inherit;
    }
  }
</style>