<template>
  <div class="copy">
    <h1 class="copy-tit">
      <span class="copy-tit__unit">Defy</span><span class="copy-tit__unit the"> the</span><span class="copy-tit__unit copy-tit__unit--last"> Current</span><span class="copy-tit__unit tm">tm</span>
    </h1>
    <div class="l-row slogan">
      <h2 class="t-h--18 t-h--16s dfy-logo-box"><span class="t--fak"><span class="t-dfy">DFY</span></span></h2>
      <p class="t-h--18 t-h--16s slogan__keyword">
        <span class="slogan__keyword-wrap"><span class="t--fak"> TODAY’S CREATIVE</span><span class="t--rec slogan__keyword-unit"> for </span> <span class="t--fak">TOMORROW</span></span>
        <NormalButton class="slogan__ripple slogan__ripple--1 s--mobile-precise"
          @click="methods.openPopup('credit')"
          pc-size="xs"
          mo-size="xs"
          :is-black="true"
          :is-ripple="true"
          text="credits"
        />
        <NormalButton class="slogan__ripple slogan__ripple--2 s--mobile-precise"
          @click="methods.openPopup('privacyPolicy')"
          pc-size="xs"
          mo-size="xs"
          :is-black="true"
          :is-ripple="true"
          text="cookie &amp; privacy policy"
        />
      </p>
    </div>
  </div>
</template>

<script>
  import { onMounted, useStore } from "@/helper/vue.js";
  import NormalButton from "@/parts/button/button.vue";

  export default {
    name: 'FooterCopyright',
    components: {
      NormalButton
    },
    setup() {
      const { commit } = useStore()
      const data = {
        isOpen: false
      }
      const methods = {
        // 현재 년도 메서드
        filtedYear: () => {
          const date = new Date
          const myYear = date.getFullYear()
          return myYear - 2000
        },

        // 푸터 Credits, Privacy 팝업 오픈 메서드
        openPopup: (value) => {
          commit('setLayerPopup', {compName: value});
          // credit, privacyPolicy, introYt, workYt

          document.documentElement.classList.add('with-modal-popup')
          document.querySelector('body').style.overflow = 'hidden'
          // commit('set', { sel: `${value}`, to: true })
        }
      }
      onMounted(() => {
        methods.filtedYear()
      })
      return {
        data,
        methods
      }
    }
  }
</script>

<style scoped lang="scss">
.copy {
  padding-bottom: 32em;;
  &-tit {
    position: relative;
    .__w600 & {
      display: flex;
      flex-wrap: wrap;
    }
    &__unit {
      font-family: "Editorial";
      font-weight: 300;
      font-size: 189em;
      line-height: 0.94;
      margin-left: 0.02em;
      letter-spacing: -0.06em;
      
      &:first-child {
        margin-left: 0.07em;
      }
      &.the {
        font-family: "Editorial Italic";
        letter-spacing: -0.068em;
        margin-left: -0.037em;

      }
      &.tm {
        position: absolute;
        top: 1.1em;
        right:1.6em;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 9.6em;
        letter-spacing: -0.02em;

      }
      .__w600 & {
        font-size: 144em;
        &:first-child {
          letter-spacing: -0.08em;
        }
        &.tm {
          font-size: 9.6em;
          top: 11.1em;
        }  
        &.the {
          margin-top: 0.18em;
          letter-spacing: -0.09em;
        }
        &--last {
          margin-top: -0.33em;
          margin-left: 0.08em;
        }
      }
      .w1600__ & {
        font-size: 299em;
        &:first-child {
          margin-left: 0.035em;
        }
        &.tm {
          font-size: 9.6em;
          top: 2.1em;
        }
      }
    }
  }
}
  .slogan {
    margin-top: -18em;
    &__keyword {
      display: flex;
      position: absolute;
      top: 0;
      left: 299em;
      align-items: center;
      &-wrap {
        white-space: nowrap;
        .__w600 & {
          display: block;
        }
      }
      &-unit {
        text-transform: lowercase;
      }
      .__w600 &{
        display: block;
        text-align: center;
        position: relative;
        left: 0;
        transform: translateX(0%);
      }
      .w1600__ &{
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &__ripple {
      padding: 0;
      &--1 {
        margin-left: 19em;
        .__w600 & {
          margin-left: 0;
        }
      }
      &--2 {
        margin-left: 8em;
      }
      .__w600 & {
        margin-top: 8em;
      }
    }
    .__w600 &{
      margin-top: -14em;
    }
    .w1600__ & {
      margin-top: -22em;
    }
  }
  .dfy-logo-box {
    display: block;
    align-self: flex-end;
    .__w600 & {
      position: absolute;
    }
  }
</style>