const MinimeType = {
  0:{ length: 6, x: 73, y: 40, w: 19, h: 23 },
  1:{ length: 8, x: 58, y: 51, w: 28, h: 24 },
  2:{ length: 9, x: 82, y: 144, w: 26, h: 26 },
  3:{ length: 5, x: 42, y: 125, w: 26, h: 26 },
  4:{ length: 8, x: 16, y: 140, w: 26, h: 24 },
  5:{ length: 6, x: 76, y: 91, w: 20, h: 20 },
  6:{ length: 6, x: 72, y: 86, w: 20, h: 23 },
  7:{ length: 5, x: 78, y: 49, w: 22, h: 24 },
  8:{ length: 7, x: 65, y: 42, w: 20, h: 27 },
  9:{ length: 8, x: 68, y: 38, w: 20, h: 25 },
  10:{ length: 6, x: 66, y: 44, w: 20, h: 25 },
  11:{ length: 6, x: 50, y: 39, w: 52, h: 25 },
  12:{ length: 14,x: 69, y: 41, w: 21, h: 23 },
  13:{ length: 9, x: 55, y: 43, w: 21, h: 21 },
  14:{ length: 8, x: 74, y: 45, w: 21, h: 21 },
  15:{ length: 6, x: 73, y: 43, w: 21, h: 25 },
  16:{ length: 7, x: 71, y: 46, w: 23, h: 25 },
  17:{ length: 5, x: 64, y: 38, w: 20, h: 27 },
  18:{ length: 5, x: 66, y: 46, w: 25, h: 25 },
  19:{ length: 5, x: 68, y: 42, w: 20, h: 23 },
  20:{ length: 4, x: 70, y: 43, w: 20, h: 25 },
  21:{ length: 6, x: 80, y: 121, w: 20, h: 22 },
  22:{ length: 6, x: 62, y: 45, w: 20, h: 22 },
  23:{ length: 6, x: 68, y: 47, w: 20, h: 24 },
  24:{ length: 7, x: 70, y: 41, w: 18, h: 24 },
  25:{ length: 6, x: 70, y: 43, w: 21, h: 20 },
  26:{ length: 7, x: 74, y: 45, w: 21, h: 22 },
  27:{ length: 4, x: 73, y: 44, w: 23, h: 22 },
  28:{ length: 7, x: 71, y: 44, w: 20, h: 22 },
  29:{ length: 7, x: 82, y: 44, w: 20, h: 20 },
  30:{ length: 7, x: 64, y: 46, w: 20, h: 22 },
  31:{ length: 7, x: 68, y: 48, w: 24, h: 25 },
  32:{ length: 8, x: 37, y: 43, w: 91, h: 23 },
  33:{ length: 8, x: 51, y: 45, w: 57, h: 25 },
  34:{ length: 7, x: 48, y: 43, w: 63, h: 25 },
  35:{ length: 7, x: 41, y: 40, w: 79, h: 30 },
  36:{ length: 5, x: 42, y: 46, w: 82, h: 30 },
  37:{ length: 6, x: 72, y: 40, w: 16, h: 21 },
  38:{ length: 6, x: 71, y: 40, w: 20, h: 21 },
  39:{ length: 6, x: 49, y: 42, w: 40, h: 21 },
  40:{ length: 16, x: 63, y: 33, w: 24, h: 28 },
  41:{ length: 5, x: 67, y: 36, w: 24, h: 28 },
  42:{ length: 14, x: 60, y: 32, w: 44, h: 28 },
  43:{ length: 24, x: 57, y: 76, w: 49, h: 28 },
}
// 160 X 200 기준

const TextType = [
  '오늘 일은 내일의 \n나에게 부탁해야지',
  '올드하지만 참신하잖아',
  '완벽하지는 않지만 \n퍼팩트해!!',
  '화려하면서 심플하게',
  '클래식하면서 모던하게',
  '왜 그런 느낌 있다니까',
  '이렇게 그냥 하면 \n될 것만 같은데?',
  '노력하면 \n다 되는 것 같던데?',
  '덤벼라 세상아 \n난 퇴근한다!',
  '뜨헉~!',
  '월급은 작고 귀엽다니까',
  '내가 지금 무슨 \n소리를 들은 거지?',
  '영혼아 먼저 \n집에 가 있으렴~',
  '아삽이요',
  '월요일 오전에?!',
  '피드백_약간_(36페이지).pptx',
  '컴펌났어요!!!',
  '다시 보니 \nA-1안이 제일 좋다',
  '일단 이해하겠어',
  '¥€$',
  '가장 중요한 건 \n눈에 보이지 않아',
  '세상에 당연한 건 없다니까',
  '걱정하지 말자. \n손은 눈보다 빠르니까',
  '느낌적인 느낌이랄까?',
  '가볍게 느낌만 봐야지',
  '휴일 전날이 \n휴일 당일보다 \n더 행복함',
  '아끼는 옷은 회사에 \n입고 오지 않아요',
  '1인가구라 내가 \n집에 가지 않으면 \n가정이 위태로워',
  '인수인계라는 건 \n상상 속의 동물',
  '슬픈 표정 하지 마, \n다음 연휴가 있으니까',
  '더 바쁠 예정이에요',
  '조금만 더 하면 \n나올 것 같은데',
  '딥하니까 디파이',
  '네넵넹',
  // wow가 추가한 텍스트 👇
  "Shoot for the moon",
  "오늘은 꼭 고백해서 \n혼내주겠어!",
  "우린 깐부잖아~",
  "커피는 마실 때 보다 \n쏟는게 더 효과적",
  "영혼아 먼저 \n집에 가있으렴",
  "아, 퇴근마렵다...",
  "우리 회의해요?"
];

const MinimeTotal = (+Object.keys(MinimeType).pop()) + 1
const TextTotal = TextType.length
export { MinimeType, MinimeTotal, TextType, TextTotal }
