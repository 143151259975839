import axios from 'axios';
import * as _ from 'fxjs';
import * as $ from 'fxdom';
import { getToday } from "@/helper/gadget.js";
import { API_SERVER } from "@/store/appSettings.js";

const INIT_DATA = 'INIT_DATA';
const DEFAULT_DATA = 'DEFAULT_DATA';
const CLIENT_DATA = 'CLIENT_DATA';
const CLIENT_GROUP_DATA = 'CLIENT_GROUP_DATA';
const DELETE_CLIENT = 'DELETE_CLIENT';
const MEMBER_DATA = 'MEMBER_DATA';
const TEAM_MEMBER_DATA = 'TEAM_MEMBER_DATA';
const UPDATE_ID = 'UPDATE_ID';
const UPDATE_TYPOCOLOR = 'UPDATE_TYPOCOLOR';
const UPDATE_BGCOLOR = 'UPDATE_BGCOLOR';
const UPDATE_SERVICE = 'UPDATE_SERVICE';
const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
const UPDATE_THEME = 'UPDATE_THEME';

export default {
  namespaced: true,
  state: {
    assetRoot: API_SERVER,
    isAddPopupActive: false,
    isPopupActive: {
      addModulePopup: {
        isOpen: false,
        dataObj: {}
      },
      videoEmbedPopup: {
        isOpen: false,
        dataObj: {}
      }
    },
    addModuleIndex: 0,
    client: null,
    clientGroup: null,
    member: null,
    teams: null,
    test: "test",
    openModal: () => {},
    uploadInfo: {},
    targetRail: null,
    tagetRails: null,
    targetRailIndex: null,
    project2: {
      modules: [
        {
          type: "111",
          brand: "111",
          layout: 0,
        },
        {
          type: "222",
          brand: "222",
          layout: 0,
        }
      ]
    },
    // project state default 
    project: {
      state: "draft",
      id: "new",
      pid: "00000",
      url: "",
      type: "",
      start: getToday(),
      launching: getToday(),
      thumbnail: "",
      thumbnailRatio: "50",
      bgImg: "",
      headline: "",
      headlineKo: "",
      story: "",
      storyKo: "",
      category: [],
      service: [],
      theme: [],
      options: {
        credit: 1,
        latest: 1,
        recommend: 0,
        trailer: 0,
        private: 0
      },
      brand: "",
      client: {
        id: 1,
        name: "",
        logo: "",
        link: ""
      },
      liveLink: [["Visit Website",""]],
      websiteImg: "",
      color: [
        "171010",
        "F9F5EF"
      ],
      memberSet: {
        agency: [],
        plan: [],
        design: [],
        contents: [],
        develop: [],
        resigned: [],
        partner: []
      },
      meta: {
        image: "",
        title: "",
        desc: "",
        keywords: "",
        tags: ""
      },
      modules: [
        {
          "type": "Kv",
          "visualCaption": "",
          "layout": 0,
          "src": ""
        },
        {
          "type": "Credit",
          "items": [
            {
              "src": ""
            }
          ]
        }
      ]
    }
  },
  mutations: {
    [INIT_DATA](state, project) {
      state.project = project
    },
    [DEFAULT_DATA](state, payload) {
      state.project = payload
    },
    [MEMBER_DATA](state, payload) {
      state.member = payload
    },
    [CLIENT_DATA](state, payload) {
      state.client = payload
    },
    [CLIENT_GROUP_DATA](state) {
      let sortData = state.client;
      state.clientGroup = sortData.sort((a, b) => {
        return a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0;
      });
    },
    [DELETE_CLIENT](state, payload) {
      state.client = state.client.filter((i) => i[1] !== payload);
    },
    [TEAM_MEMBER_DATA](state) {
      state.teams = _.go(
				state.member,
				_.entries,
				_.map(item => {
					const obj = {};
					obj.title = item[0];
					obj.members = item[1].sort((a, b)=> {
						return a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0;
					});
					return obj;
				})
			)
    },
    [UPDATE_ID](state, payload) {
      state.project.id = payload
    },
    [UPDATE_TYPOCOLOR](state, payload) {
      state.project.color[0] = payload
    },
    [UPDATE_BGCOLOR](state, payload) {
      state.project.color[1] = payload
    },
    [UPDATE_SERVICE](state, payload) {
      state.project.service = payload
    },
    [UPDATE_CATEGORY](state, payload) {
      state.project.category = payload
    },
    [UPDATE_THEME](state, payload) {
      state.project.theme = payload
    }
  },
  actions: {
    async onNewProject({ commit, state }) { 
      const data = {...state.project};
      try {
        const res = await axios.post(
          `${state.assetRoot}/api/upload`,
          data
        );
        // await commit('set', { sel: 'edit.project.id', to: res.data.project.id }, { root: true });
        await commit(UPDATE_ID, res.data.project.id);
      } catch (e) {
        console.log(e);
      }
    },
    async setDefault({ commit, state }) {
      try { 
        const data = {
          state: "draft",
          id: "new",
          pid: "00000",
          url: "",
          type: "",
          start: getToday(),
          launching: getToday(),
          thumbnail: "",
          bgImg: "",
          headline: "",
          headlineKo: "",
          story: "",
          storyKo: "",
          category: [],
          service: [],
          theme: [],
          options: {
            credit: 1,
            latest: 1,
            recommend: 0,
            trailer: 0,
            private: 0
          },
          brand: "",
          client: {
            name: "",
            logo: "",
            link: ""
          },
          liveLink: [["Visit Website",""]],
          websiteImg: "",
          color: [
            "171010",
            "F9F5EF"
          ],
          memberSet: {
            agency: [],
            plan: [],
            design: [],
            contents: [],
            develop: [],        
            resigned: [],
            partner: []
          },
          meta: {
            image: "",
            title: "",
            desc: "",
            keywords: "",
            tags: ""
          },
          modules: [
            {
              "type": "Kv",
              "visualCaption": "",
              "layout": 0,
              "src": ""
            },
            {
              "type": "Credit",
              "items": [
                {
                  "src": ""
                }
              ]
            }
          ]
        }
        await commit(DEFAULT_DATA, data);
      } catch (e) {
        console.log(e)
      }
    },
    async init({ commit, state }, id ) {
      try { 
        const res = await axios.get(
          `${state.assetRoot}/api/projects/${id}`
        );
        await commit(INIT_DATA, res.data);
      } catch (e) {
        console.log(e)
      }
    },
    async getData({ commit, state }, { type } ) {
      try { 
        if (!type) {
          return;
        }
        const res = await axios.get(
          `${state.assetRoot}/api/data/${type}`
        );
        if (type == 'member') {
          commit(MEMBER_DATA, res.data);
        } else if (type == 'client') {
          commit(CLIENT_DATA, res.data);
          commit(CLIENT_GROUP_DATA);
        }
      } catch (e) {
        console.log(e)
      }
    },
    async onUpload({ commit, state },{ id }) { 
      commit('set', { sel: 'edit.project.id', to: id }, { root: true });
      const data = {...state.project};
      try {
        await axios.post(
          `${state.assetRoot}/api/upload`,
          data
        );
        $.qs(".toast-message").dispatchEvent(new CustomEvent("toastMessage:Show", {
          detail: {
            text: "업로드가 완료되었습니다."
          },
        }))
      } catch (e) {
        console.log(e);
      }
    },
    async onProjectsUpload({ state }, { urlState }) {
      const data = {
        id: state.project.id,
        state: state.project.state,
        url: state.project.url,
        pid: state.project.pid,
        thumbnail: state.project.thumbnail,
        thumbnailRatio: state.project.thumbnailRatio,
        bgImg: state.project.bgImg,
        thumbDesc: state.project.visualCaption,
        brand: state.project.brand,
        type: state.project.type,
        client: state.project.client.name,
        color: state.project.color,
        category: state.project.category,
        service: state.project.service,
        year: Number(state.project.launching.toString().slice(0,4)),
        launching: state.project.launching,
        options: state.project.options,
        headline: state.project.headline,
        headlineKo: state.project.headlineKo,
        theme: state.project.theme,
        kvLayout: state.project.modules[0].layout,
        meta: state.project.meta,
        urlState
      }
      try {
        await axios.post(
          `${state.assetRoot}/api/projects/upload`,
          data
        );
        $.qs(".toast-message").dispatchEvent(new CustomEvent("toastMessage:Show", {
          detail: {
            text: "워크 리스트 업로드가 완료되었습니다."
          },
        }))
      } catch (e) {
        console.log(e);
      }
    },
    async onUpdate({ commit, state}, { id }) {
      commit('set', { sel: 'edit.project.id', to: id }, { root: true });
      const data = {...state.project};
      try {
        await axios.post(
          `${state.assetRoot}/api/upload`,
          data
        );
        $.qs(".toast-message").dispatchEvent(new CustomEvent("toastMessage:Show", {
          detail: {
            text: "업데이트가 완료되었습니다."
          },
        }))
      } catch (e) {
        console.log(e);
      }
    },
    async onMediaUpload({ commit, state }, { formData } ) {
      const data = formData
      try {
        const res = await axios.post(
          `${state.assetRoot}/api/upload/media`,
          data
        );
        commit('set', { sel: `edit.project.${state.uploadInfo.urlSel}`, to: res.data.mediaLocation }, { root: true });
        $.qs(".toast-message").dispatchEvent(new CustomEvent("toastMessage:Show", {
          detail: {
            text: "미디어 업로드가 완료되었습니다."
          },
        }))
      } catch (e) {
        console.log(e);
      }
    },
    async onModuleMediaUpload({ commit, state }, { formData } ) {
      const data = formData
      try {
        const res = await axios.post(
          `${state.assetRoot}/api/upload/media`,
          data
        );
        commit('set', { sel: `edit.project.${state.uploadInfo.urlSel}`, to: res.data.mediaLocation }, { root: true });
        $.qs(".toast-message").dispatchEvent(new CustomEvent("toastMessage:Show", {
          detail: {
            text: "모듈 미디어 업로드가 완료되었습니다."
          },
        }))
      } catch (e) {
        console.log(e);
      }
    },
    async onMediaDelete({ state }, { formData, msg }) {
      const data = formData
      try {
        await axios.put(
          `${state.assetRoot}/api/delete/modules/media`,
          data
        );
        if (msg) {
          $.qs(".toast-message").dispatchEvent(new CustomEvent("toastMessage:Show", {
            detail: {
              text: "미디어 삭제가 완료되었습니다."
            },
          }))
        } 
      } catch (e) {
        console.log(e);
      }
    },
    async onDataUpload({ state, commit }, { type }) {
      try {
        let data = null;
        if (type == 'member') {
          const memberData = {...state.member};
          data = { memberData, type };
        } else if (type == 'client') {
          const clientData = [...state.client];
          data = { clientData, type };
        }
        await axios.post(
          `${state.assetRoot}/api/data/upload`,
          data
        );
        if (type == 'member') {
          commit(TEAM_MEMBER_DATA);
        } else if (type == 'client') {
          commit(CLIENT_GROUP_DATA);
        }
        $.qs(".toast-message").dispatchEvent(new CustomEvent("toastMessage:Show", {
          detail: {
            text: "데이터 업데이트가 완료되었습니다."
          },
        }))
      } catch (e) {
        console.log(e);
      }
    },
    openModal({ state }) {
      state.openModal();
    },
    onFileupload({ state, commit }, info) {
      commit('set', { sel: 'edit.uploadInfo', to: info }, { root: true });
      $.qs('.ui-input__file').click();
    },
    onModalFileupload({ state, commit }, info) {
      commit('set', { sel: 'edit.uploadInfo', to: info }, { root: true });
    }
  },   
  getters: {
    module: state => idx => state.project.modules[idx],
    getModule: state => eid => {
      return state.project.modules[eid];
    },
    getProject: state => {
      return state.project;
    }
  },
  computed:{

  }
};