<template>
  <div ref="self">
    <!-- 여러개 select -->
    <template v-if="item.contents.selectType == 'multi'">
      <div class="SelectText__info">
        <span class="t-cb--13"><span :class="!data.isLangKr ? 't--fak' : 't--kr'">{{ item.contents.selectInfo }}</span></span>
      </div>
      <ul class="SelectText__item-wrap">
        <li v-for="(sItem, index) in item.contents.selectItems" :key="sItem.id" class="b-balloon SelectText__item">
          <input type="checkbox" :id="sItem.shown" :value="sItem.hidden ? sItem.hidden : sItem.shown" v-model="data.selectedItem" class="b-balloon__check" />
          <button class="b-balloon__box" @click="methods.getSelectedItemIndex(index); methods.removeComplete();">
            <label :for="sItem.shown" class="b-balloon__box-inr">
              <div class="t-cb--16"><div :class="!data.isLangKr ? 't--fak' : 't--kr'">{{ sItem.shown }}</div></div>
            </label>
          </button>
        </li>
      </ul>
      

      <!-- select confirm -->
      <div class="SelectText__btn chat-right">
        <button @click="methods.hideBallon(); methods.addComplete(); methods.executeNextEvent(item.nextId, item.contents, data.selectedItemIndex);" class="b-confirm SelectText__confirm">
          <div class="balloon__txt">
            <span class="t-cb--16"><span :class="!data.isLangKr ? 't--fak' : 't--kr'">{{ item.contents.confirm }}</span></span>
          </div>
          <span class="b-arrow b-confirm__arrow">
            <span class="b-arrow__img ico-chatbot--navi-arrow--forward"></span>
          </span>
        </button>
      </div>
    </template>

    <!-- 한개 select -->
    <template v-else>
      <ul class="SelectText__item-wrap">
        <li v-for="(sItem, index) in item.contents.selectItems" :key="sItem.id" :class="sItem.type == 'text' ? 'SelectText__team' : 'b-balloon SelectText__item'">
          <div v-if="sItem.type == 'text'">
            <span class="t-cb--13"><span :class="!data.isLangKr ? 't--fak' : 't--kr'">{{ sItem.shown }}</span></span>
          </div>
          <div v-else>
            <input type="radio" :id="sItem.shown" :value="sItem.hidden ? sItem.hidden : sItem.shown" :sub-value="sItem.subValue ? sItem.subValue : ''" v-model="data.selectedItem" @click="methods.removeComplete(); methods.addComplete(); methods.executeNextEvent(item.nextId, item.contents, index);" class="b-balloon__check" />
            <button class="b-balloon__box" :disabled="data.disabled">
              <label :for="sItem.shown" class="b-balloon__box-inr">
                <div class="t-cb--16"><div :class="!data.isLangKr ? 't--fak' : 't--kr'">{{ sItem.shown }}</div></div>
              </label>
            </button>
          </div>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { getCurrentTime } from "@/helper/gadget.js";
import { gsap } from "gsap/all";
import { ref, reactive, computed, onBeforeUpdate, onUpdated, onMounted, watchEffect, useStore } from "@/helper/vue.js";

export default {
  props: ['item', 'index', 'dataName'],
  emits: [ 'click' ],

  setup(props) {
    const { state, get, dispatch, commit } = useStore();

    const self = ref();

    const data = reactive({
      isLangKr: computed(() => state.isLangKr),
      getAllItems: computed(() => get["chatbot/getAllItems"]),
      selectedItem: [],
      selectedItemIndex: [],
      disabled: false,
    });

    const methods = {
      hideBallon: () => {
        const confirm = $.find(".SelectText__confirm", self.value);
        const hasHide = $.hasClass("SelectText__confirm--hide", confirm);
        if (!hasHide) {
          $.addClass("SelectText__confirm--hide", confirm)
        }
      },
      executeNextEvent: (nextId, contents, index) => {
        dispatch('chatbot/addTimer', {delay: 100, callback: () => {
          if (props.item.contents.selectInfo) {
            $.setCss( { display: 'none' }, $.find(".SelectText__confirm", self.value));
          }

          // console.log("executeNextEvent", contents.selectItems[index].query);
          // if (contents.selectItems[index].query) {
          //   console.log("query");
          //   dispatch('chatbot/executeNextEvent', {nextId: nextId, dataName: props.dataName});
          // } else {
          //         console.log("no query", state.chatbot.items[props.dataName]);
          //   _.go(_.values(state.chatbot.items[props.dataName]),
          //     _.each(item => {
          //       if (item.id == nextId) {
          //         console.log("item", item);
          //         dispatch('chatbot/executeNextEvent', {nextId: item.nextId, dataName: props.dataName});
          //       }
          //     })
          //   )
          // }
          dispatch('chatbot/executeNextEvent', {nextId: nextId, dataName: props.dataName});
          dispatch('chatbot/getSelectedItem', {dataName: props.dataName, currId: props.item.id, contents: contents, selectedItemIndex: index });
        }})
        // commit('chatbot/getSelectedItem', {contents: contents, selectedItem: selectedItem, selectedItemIndex: index });
      },
      addComplete: () => {
        const selectWrap = $.find(".SelectText__item-wrap", self.value);
        const hasComplete = $.hasClass("SelectText__item-wrap--complete", selectWrap);
        if (!hasComplete) {
          $.addClass("SelectText__item-wrap--complete", selectWrap);
          if (props.item.contents.selectOnce) {
            data.disabled = true;
          }
        }
      },
      removeComplete: () => {
        const selectWrap = $.find(".SelectText__item-wrap", self.value);
        const hasComplete = $.hasClass("SelectText__item-wrap--complete", selectWrap);
        if (hasComplete) {
          if (!props.item.contents.selectOnce) {
            $.removeClass("SelectText__item-wrap--complete", selectWrap);
            commit('chatbot/removeHistoryItem', {index: props.index + 1, dataName: props.dataName});
            commit('chatbot/endState', {dataName: props.dataName, end: false});
            commit('chatbot/clearTimer');
            if (props.item.contents.selectInfo) {
              const confirm = $.find(".SelectText__confirm", self.value);
              const hasHide = $.hasClass("SelectText__confirm--hide", confirm)
              if (hasHide) {
                $.setCss( { display: 'flex' }, $.find(".SelectText__confirm", self.value));
                $.removeClass("SelectText__confirm--hide", confirm)
              }
            }
          }
        }
      },
      getSelectedItemIndex: (index) => {
        const isInclude = _.includes(index, data.selectedItemIndex);
        if (isInclude) {
          data.selectedItemIndex = _.filter(el => el !== index, data.selectedItemIndex)
        } else {
          data.selectedItemIndex.push(index);
        }
        
        const length = data.selectedItemIndex.length;
        const confirm = $.find(".SelectText__btn", self.value);
        if (length > 0) {
          $.addClass("SelectText__btn--show", confirm)
        } else {
          $.removeClass("SelectText__btn--show", confirm)
        }
        methods.setConfirmScroll();
      },

      setConfirmScroll: () => {
        const hasClass = $.hasClass("SelectText__btn--show", $.find(".SelectText__btn", self.value))
        if (hasClass) {
          const subpage = $.qs(".subpage");

          const scrollPosition = { scrollDistance: subpage.scrollTop };
          gsap.to(scrollPosition, 1.5, { scrollDistance: subpage.scrollHeight - 44, ease: "none",
            onUpdate: () => {
              subpage.scrollTop = scrollPosition.scrollDistance;
            }
          });
        }
      },
    
    }

    const getTime = () => {
      const clock = $.find(".balloon__date", self.value);
      clock.innerText = `${getCurrentTime().hours < 12? `${getCurrentTime().hours == 0 ? 12 : getCurrentTime().hours}`:`${getCurrentTime().hours - 12}`}:${getCurrentTime().minutes < 10 ? `0${getCurrentTime().minutes}` : getCurrentTime().minutes} ${getCurrentTime().hours < 12? 'AM ' : 'PM '}`;
    }

    onMounted(() => {
      // if (props.item.contents.selectInfo) {
      //   getTime();
      // }
    });


    return {
      self,
      data,
      methods
    }
  }
}
</script>

<style>

</style>