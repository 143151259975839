import * as _ from "fxjs";
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    items: {
    }
  },
  getters: {
    getItems: (state) => {
      return state.items;
    },
  },
  actions: {
    fetchGiphy(context, query) {
      return axios.get(`https://api.giphy.com/v1/gifs/search?q=${query}&api_key=9I2w1yetELumjpBXn2nKp4fbKLyIkETU&limit=50`)
      .then(response => {
        var randomIndex = Math.floor(Math.random() * 50);
        // console.log("response", response.data.data[randomIndex].source);
        // console.log("response", response.data.data[randomIndex]);
        const obj = {
          title: response.data.data[randomIndex].title,
          source: response.data.data[randomIndex].source,
          pageUrl: response.data.data[randomIndex].url,
          url: response.data.data[randomIndex].images.original.url,
          size: response.data.data[randomIndex].images.original.size,
          ratio: response.data.data[randomIndex].images.original.height/response.data.data[randomIndex].images.original.width
        }
        return obj;
      }).catch(error => console.log(error))
    },
  },
}