<template>
  <Layout :bgData="currentCard.cont" :name="currentCard.name" @click="$emit('click')">
    <template v-slot:fixedInfo>
      <div class="p-channel">
        <div v-if="!data.isLangKr" class="p-channel__tit">
          <span class="t-cb--16"><span class="t--fak">Digital Agency</span></span>    
        </div>
        <div v-else class="p-channel__tit">
          <span class="t-cb--16"><span class="t--fak">디지털 에이전시</span></span>    
        </div>
        <div v-if="!data.isLangKr" class="p-channel__info">
          <span class="t-cb--16"><span class="t--fak">Located in the heart of hip central, <span class="t-dfy">DFY</span> Seongsu is designed for open conversations and creative connections.</span></span>    
        </div>
        <div v-else class="p-channel__info p-channel__info--kr">
          <span class="t-cb--16"><span class="t--fak">힙스터의 도시, 성수에 위치한 디파이. 우리는 이곳에서 자유롭게 변화하고 소통하며 새로움을 만듭니다.</span></span>    
        </div>
        <div v-if="!data.isLangKr" class="p-channel__location">
          <div class="t-cb--16"><span class="t--fak">3F, 90, Yeonmujang-gil, Seongdong-gu, Seoul, Korea</span></div>
          <div class="t-cb--16"><span class="t--fak"><span class="t--ft">(</span>04785<span class="t--ft">)</span></span></div>
        </div>
        <div v-else class="p-channel__location">
          <div class="t-cb--16"><span class="t--fak">서울 성동구 연무장길 90 3층</span></div>
          <div class="t-cb--16"><span class="t--fak"><span class="t--ft">(</span>04785<span class="t--ft">)</span></span></div>
        </div>
        <div class="p-channel__cont">
          <News v-for="(item, index) in currentCard.news" :key="index" :item="item" :person="currentCard.cont"></News>
        </div>
      </div>
      <div class="power">
        <span class="t-cb--11 power__txt"><span class="t--fak">Powered by </span></span>
        <span class="t-cb--11"><span class="t--fak"><span class="t-dfy">DFY</span> 3F</span></span>
      </div>
    </template>
  </Layout>
  
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { ref, reactive, computed, onMounted, onUpdated,watchEffect, onBeforeUnmount, onUnmounted, useStore } from "@/helper/vue.js";
import { addEvent, removeEvent } from "@/helper/event.js";

import Layout from "../_ChatPageLayout.vue";
import News from '../type/_MainNews.vue';

export default {
  props: [ 'currentCard' ],
  emits: [ 'click' ],
  components: {
    Layout,
    News
  },
  setup(props, {emit}) {
    const { state, get, commit, dispatch } = useStore();
    const data = reactive({
      isLangKr: computed(() => state.isLangKr),
    });

    const methods = {
    };

    onMounted(() => {
    });

    onBeforeUnmount(() => {
    })

    return {
      data,
      methods
    }
  }
}
</script>

<style lang="scss" src="@/css/chatbot.scss"></style>
