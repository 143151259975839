<template>
  <Layout :bgData="currentCard.cont" :name="currentCard.name" @click="$emit('click')">
    <template v-slot:fixedInfo>
      <div class="p-hiring">
        <div class="p-hiring__info-wrap">
          <div class="p-hiring__info">
            <div v-if="!data.isLangKr" class="p-hiring__info-tit">
              <span class="t-cb--13"><span class="t--fak">Category</span></span>
            </div>
            <div v-else class="p-hiring__info-tit">
              <span class="t-cb--13"><span class="t--kr">카테고리</span></span>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__info-txt">
              <div class="t-cb--13"><span class="t--fak">Web/Mobile UI Designer, Graphic Designer</span></div>
            </div>
            <div v-else class="p-hiring__info-txt">
              <div class="t-cb--13"><span class="t--kr">Web/Mobile UI 디자이너, 그래픽 디자이너</span></div>
            </div>
          </div>
          <div class="p-hiring__info">
            <div v-if="!data.isLangKr" class="p-hiring__info-tit">
              <span class="t-cb--13"><span class="t--fak">Job Type</span></span>
            </div>
            <div v-else class="p-hiring__info-tit">
              <span class="t-cb--13"><span class="t--kr">유형</span></span>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__info-txt">
              <div class="t-cb--13"><span class="t--fak">Full Time Contractor</span></div>
            </div>
            <div v-else class="p-hiring__info-txt">
              <div class="t-cb--13"><span class="t--kr">정규직</span></div>
            </div>
          </div>
        </div>
        <div v-if="!data.isLangKr" class="p-hiring__desc">
          <div class="t-cb--16"><span class="t--fak">여러 분야의 팀원과 협력하여 프로젝트 수행 및 품질을 담당할 프로덕트 디자이너를 찾고 있습니다.</span></div>
          <div class="t-cb--16"><span class="t-cb__item t--fak">서비스 출시부터 개선/운영 프로젝트 경험과 디자인에 관한 다양한 인사이트를 바탕으로 새로운 아이디어를 제공할 수 있어야 합니다.</span></div>
        </div>
        <div v-else class="p-hiring__desc">
          <div class="t-cb--16"><span class="t--kr">여러 분야의 팀원과 협력하여 프로젝트 수행 및 품질을 담당할 프로덕트 디자이너를 찾고 있습니다.</span></div>
          <div class="t-cb--16"><span class="t-cb__item t--kr">서비스 출시부터 개선/운영 프로젝트 경험과 디자인에 관한 다양한 인사이트를 바탕으로 새로운 아이디어를 제공할 수 있어야 합니다.</span></div>          
        </div>
        <ul class="p-hiring__required-wrap">
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➊</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">UX/UI 디자인 경험과 디자인 시스템 구축 및 운영 경험</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">UX/UI 디자인 경험과 디자인 시스템 구축 및 운영 경험</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➋</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">사용자 경험을 이해하고 깊이 고민해서 시각화하실 수 있는 분</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">사용자 경험을 이해하고 깊이 고민해서 시각화하실 수 있는 분</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➌</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">심미성 뛰어난 이미지 연출과 시각적 능력 / UI 디자인 및 인사이트, 트렌드에 민감한 분</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">심미성 뛰어난 이미지 연출과 시각적 능력 / UI 디자인 및 인사이트, 트렌드에 민감한 분</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➍</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">서비스 개발 과정 전반에서 유연하고 적극적인 커뮤니케이션</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">서비스 개발 과정 전반에서 유연하고 적극적인 커뮤니케이션</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➎​</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">독립적으로 워크 로드를 관리</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">독립적으로 워크 로드를 관리</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➏</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">Figma, Adobe, Sketch, Zeplin 등 디자인 툴 활용에 대한 경험과 이해</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">Figma, Adobe, Sketch, Zeplin 등 디자인 툴 활용에 대한 경험과 이해</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➐</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">인터랙티브 디자인, 프로토 타이핑 역량</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">인터랙티브 디자인, 프로토 타이핑 역량</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➑</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">(우대사항) 커머스 플랫폼 비즈니스 관련 서비스 제작 경험 및 운영 경험</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">(우대사항) 커머스 플랫폼 비즈니스 관련 서비스 제작 경험 및 운영 경험</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➒</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">(우대사항) 영어 커뮤니케이션</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">(우대사항) 영어 커뮤니케이션</span></div>
            </div>
          </li>
        </ul>
      </div>
    </template>
  </Layout>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { ref, reactive, computed, onMounted, onUpdated,watchEffect, onBeforeUnmount, onUnmounted, useStore } from "@/helper/vue.js";
import { addEvent, removeEvent } from "@/helper/event.js";

import Layout from "../../_ChatPageLayout.vue";
import ChatHoc from "../../_ChatHoc.vue";

export default {
  props: [ 'currentCard' ],
  emits: [ 'click' ],
  components: {
    Layout,
  },
  setup(props, {emit}) {
    const { state, get, commit, dispatch } = useStore();
    const data = reactive({
      isLangKr: computed(() => state.isLangKr),
    });

    const methods = {
    };

    onMounted(() => {
      // console.log("props.bgData", props.bgData);
    });

    return {
      data,
      methods
    }
  }
}
</script>

<style lang="scss" src="@/css/chatbot.scss"></style>
