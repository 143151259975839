// ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️
// 배열/객체에서 맨앞에 등장하는 숫자는 모두 primary key(유일한 값)이다.
// 데이터를 수정할 때 현재 들어가 있는 키를 임의 변경하면 안된다.
// 아이템을 추가해야될 상황에서는 중복되지 않게 오름차순으로 추가해서 사용한다.
// 아이템 삭제를 해야될 경우에도(예: 퇴사자 발생) 해당 아이템을 삭제하는 것은 가능하나 키를 당기거나 조정하면 안된다.
// 아이템 삭제로 인해 발생한 빈 키값이 있어도 사용하면 안된다(⭐)
// ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️   ⚠️

import * as _ from "fxjs";

const commonData = {
	work: {
		category: {
			0: "Digital Products & Platforms",
			1: "Branded e-Commerce",
			2: "Branding Strategy & Design",
			3: "Branded Experience"
		},
		categoryWithColor: [
			[0, "Digital Products & Platforms", "#F6E8CA"],
			[1, "Branded e-Commerce", "#C0E8F4"],
			[2, "Branding Strategy & Design", "#D1DAFA"],
			[3, "Branded Experience", "#D4E9E1"],
		],
		service: {
			strategy:[
				["브랜드 경험", "Brand Experience"],
				["브랜드 가이드라인", "Brand Guidelines"],
				["브랜드 아이덴티티", "Brand Identity"],
				["브랜드 메세지", "Brand Messaging"],
				["브랜드 전략", "Brand Strategy"],
				["브랜드 경험 컨설팅", "BX Consulting"],
				["캠페인 전략", "Campaign Strategy"],
				["컨설팅", "Consulting"],
				["콘텐츠 가이드라인", "Content Guidelines"],
				["콘텐츠 전략", "Content Strategy"],
				["고객 경험 컨설팅", "Customer Experience Consulting"],
				["고객 경험 전략", "Customer Experience Strategy"],
				["디지털 경험 컨설팅", "Digital Experience Consulting"],
				["디지털 경험 전략", "Digital Experience Strategy"],
				["디지털 전략", "Digital Strategy"],
				["디지털 기술 컨설팅", "Digital Technical Consulting"],
				["이커머스 전략", "E-commerce Strategy"],
				["마케팅 컨설팅", "Marketing Consulting"],
				["O2O 경험 컨설팅", "O2O Experience Consulting"],
				["O2O 경험 전략", "O2O Experience Strategy"],
				["제품 패키징 디자인", "Packaging Design"],
				["제품 브랜딩", "Product Branding"],
				["굿즈 디자인", "Promotional Material Design"],
				["프로토타이핑", "Prototyping"],
				["서비스/프로덕트 전략", "Service/Product Strategy"],
				["소셜네트워크 컨설팅", "Social Media Consulting"],
				["UX 컨설팅", "UX Consulting"],
				["UX 전략", "UX Strategy"]
			],
			ux:[
				["아트디렉션", "Art Direction"],
				["크리에이티브 디렉션", "Creative Direction"],
				["GUI 디자인", "GUI Design"],
				["인터렉티브 스타일 가이드", "Interactive Style Guide"],
				["모바일 앱 디자인", "Mobile App Design"],
				["모바일 사이트 디자인", "Mobile Site Design"],
				["스타일 가이드", "Style Guide"],
				["UI/UX 디자인", "UI/UX Design"],
				["시각언어", "Visual Language"],
				["웹사이트 디자인", "Website Design"]
			],
			development: [
				["아트디렉션", "Art Direction"],
				["크리에이티브 디렉션", "Creative Direction"],
				["GUI 디자인", "GUI Design"],
				["인터렉티브 스타일 가이드", "Interactive Style Guide"],
				["모바일 앱 디자인", "Mobile App Design"],
				["모바일 사이트 디자인", "Mobile Site Design"],
				["스타일 가이드", "Style Guide"],
				["UI/UX 디자인", "UI/UX Design"],
				["시각언어", "Visual Language"],
				["웹사이트 디자인", "Website Design"],
				["웹 접근성", "Web Accessibility"],
			],
			content:[
				["2D 모션 디자인", "2D Motion Design"],
				["2D 모션 그래픽", "2D Motion Graphics"],
				["2D 영상", "2D Video"],
				["3D 모델링", "3D Modeling"],
				["3D 영상", "3D Video"],
				["3D 프로덕션", "3D Video Production"],
				["3D 모션 디자인", "3D Motion Design"],
				["3D 모션 그래픽", "3D Motion Graphics"],
				["증강현실", "AR"],
				["아트웍", "Artwork"],
				["콘텐츠 디렉션", "Content Direction"],
				["콘텐츠 제작", "Content Production"],
				["영문 에디팅", "English Content Editing"],
				["영문 카피라이팅", "English Copywriting"],
				["그래픽 디자인", "Graphic Design"],
				["일러스트레이션", "Illustration"],
				["국문 에디팅", "Korean Content Editing"],
				["국문 카피라이팅", "Korean Copywriting"],
				["음악", "Music/Sound"],
				["O2O 경험 디자인", "O2O Experience Design"],
				["사진", "Photography"],
				["서체디자인", "Typeface Design"],
				["영상 편집", "Video Editing"],
				["영상 촬영", "Video Production"],
				["가상현실", "VR"]
			],
			marketing:[
				["CRM 콘텐츠", "CRM Content"],
				["디지털 캠페인", "Digital Campaign"],
				["이메일/CRM 콘텐츠", "Email/CRM"],
				["오프라인 이벤트", "Experiential Marketing"],
				["마케팅 캠페인", "Marketing Campaign"],
				["O2O 마케팅", "O2O Marketing"],
				["온라인 이벤트", "Online Event"],
				["매체랩", "Paid Media"],
				["매체 운영", "Paid Media & Analysis"],
				["퍼포먼스 마케팅", "Performance Marketing"],
				["SEM/SEO", "SEM/SEO"],
				["SNS 운영", "Social Media Management"],
				["SNS 캠페인", "Social Campaign"],
				["Integrated Marketing Campaign", "IMC"]
			]
		},
		tag: {
			0: "Human Technology",
			1: "New Ways To Retail",
			2: "Ready To Launch",
			3: "Being Social",
			4: "Purposeful Spaces",
			5: "Tomorrow Today",
			6: "Stories that Inspire",
			7: "Samsung Galaxy"
		},
		type: {
			0: "2D Motion Design",
			1: "2D Video",
			2: "3D Modeling",
			3: "3D Video",
			4: "App Design",
			5: "AR",
			6: "Artwork",
			7: "Back-End Development",
			8: "Brand Contents",
			9: "Brand eXperience",
			10: "Brand Guidelines",
			11: "Branding",
			12: "BX Consulting",
			13: "Campaign",
			14: "Consulting",
			15: "Content Creation",
			16: "Copywriting",
			17: "CRM Content",
			18: "Customer Experience Consulting",
			19: "Customer Experience Strategy",
			20: "Design Guidelines",
			21: "Design System",
			22: "Digital Art",
			23: "Digital Branding",
			24: "Digital Campaign",
			25: "Digital Experience Consulting",
			26: "Digital Experience Strategy",
			27: "E-commerce",
			28: "E-commerce Strategy",
			29: "English Content Editing",
			30: "English Copywriting",
			31: "Experiential Marketing",
			32: "Front-end Development",
			33: "Graphic Design",
			34: "GUI Design",
			35: "Illustration",
			36: "Integrated Marketing Campaign",
			37: "Interactive Contents",
			38: "Interactive Design",
			39: "Interactive Development",
			40: "Interactive Video",
			41: "Interactive Website",
			42: "Korean Content Editing",
			43: "Korean Copywriting",
			44: "Marketing Campaign",
			45: "Marketing Consulting",
			46: "Mobile App",
			47: "Mobile Site",
			48: "O2O Experience",
			49: "O2O Experience Consulting",
			50: "O2O Experience Strategy",
			51: "Online Event",
			52: "Packaging Design",
			53: "Paid Media & Analysis",
			54: "Performance Marketing",
			55: "Platform",
			56: "Product Branding",
			57: "Product Design",
			58: "Promotion",
			59: "Promotional Material Design",
			60: "Promotional Video",
			61: "SEM/SEO",
			62: "Service Prototype",
			63: "Social Campaign",
			64: "Social Contents",
			65: "Social Media",
			66: "Social Media Consulting",
			67: "Social Media Management",
			68: "Strategy",
			69: "UI Design",
			70: "UI/UX Design",
			71: "UX Consulting",
			72: "UX Design",
			73: "UX Strategy",
			74: "Video",
			75: "Video Production",
			76: "VR",
			77: "Web Accessibility",
			78: "Web design & development",
			79: "Web Publishing",
			80: "Web+Mobile Site",
			81: "Website",
			82: "Website Management",
			83: "Mobile Game",
			84: "Web Game",
			85: "Digital Experience",
			86: "Online Experience",
			87: "Pictogram Design",
			88: "PIP Renewal",
			89: "Product Detail Page",
			90: "Product Information Page",
		}
	}
};

const getData = sel => _.sel(sel, commonData);
const getDataList = sel => _.entries(_.sel(sel, commonData));

export { commonData, getData, getDataList }