<template>
	<div class="policy" :class="{ 'policy--open' : data.isOpen === true }">
		<div class="l-row policy__inner">
			<div class="policy__tit">
				<div class="t-h--54">
					<span class="t--fak">privacy policy</span>
				</div>
				<div class="t-b--11">
					<span class="t--fak">Last Update: 9 April, 2021</span>
				</div>
			</div>
			<div class="g-row policy__cont l-mdl--64 l-mdl--32s">
				<div class="g-col--6 g-col--5l g-col--5s policy__txt-wrap">
					<span class="t-b--11"><span class="t--fak policy__lang-type">(ENG)</span></span>
					<div class="t-b--18 t-b--16s">
						<p class="t-block t--fak"><span class="t-dfy">DFY</span> respect your privacy regarding any information that may be collected from you across <a class="c-link" href="http://www.dfy.co.kr.">www.dfy.co.kr.</a> </p>
						<p class="t-block t--fak">When we ask for personal information, it is because we need it to provide a service to you. It is collected by lawful means, and with your knowledge and consent. We also let you know why we’re collecting it and how it will be used. We only retain collected information for as long as necessary to provide you with relevant services.</p>
						<p class="t-block t--fak">We do not share any personally identifying information publicly or with third-parties, except when we are required to do so by law. We protect the data we store within commercially acceptable means to prevent loss and theft, as well as unauthorized access, copying, disclosure, use or modification. Our website may be linked to external sites that we do not own and are not operated by us. Please be aware that we have no control over the content and practices of these external sites, and thus unable to accept responsibility or liability for their respective privacy policies.</p>
						<p class="t-block t--fak">You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services. Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
					</div>
				</div>
				<div class="g-col--6 g-col--5l g-col--5s policy__txt-wrap policy__txt-wrap--kr">
					<span class="t-b--11"><span class="t--fak policy__lang-type">(KOR)</span></span>
					<div class="t-b--18 t-b--16s">
						<p class="t-block t--kr"><span class="t-dfy">DFY</span> 는 웹사이트(<a class="c-link" href="http://www.dfy.co.kr">www.dfy.co.kr</a>) 이용자로부터 수집한 개인정보를 보호합니다.</p>
						<p class="t-block t--kr">우리는 서비스를 제공하기 위하여 웹사이트 이용자에게 개인정보를 요청합니다. 우리는 개인정보 보호법을 준수하고 이용자의 동의를 얻어 개인정보를 수집합니다. 개인정보를 수집할 때 우리는 이유와 목적을 이용자에게 알리며 서비스를 제공하는 데 필요한 기간 동안 이용자의 개인정보를 보관합니다.</p>
						<p class="t-block t--kr">우리는 법에 따라 요구되는 경우를 제외하고 개인식별정보를 공개하거나 제3자와 공유하지 않습니다. 우리는 이용자 데이터의 무단 액세스, 복사, 공개, 사용 및 수정, 손실 및 도난을 방지하기 위해 상업적으로 허용된 수단으로써 저장된 데이터를 보호합니다. 이 웹사이트는 우리 소유가 아닌 외부 사이트에 링크될 수 있습니다. 우리는 이러한 외부 사이트의 내용 및 관행을 제어할 수 없음으로 각 개인정보 보호정책에 대한 책임을 지지 않습니다.</p>
						<p class="t-block t--kr">이용자는 개인정보에 대한 당사의 요청을 거절할 수 있으며 거절 시 일부 서비스를 이용할 수 없습니다. 이 웹사이트를 계속 사용하는 것은 이용자가 우리의 개인정보 및 개인정보에 대한 관행을 수락하는 것으로 간주합니다. 우리의 개인정보 처리방침에 대해 더 궁금한 점이 있는 경우 언제든지 문의해 주십시오.</p>
					</div>
				</div>
			</div>

			<div class="policy__foot s--center">
				<Minime class="s--none-mo"
					:is-block-content="true"
				/>
				<div class="l-mdl--16 l-mdl--32s">
					<div class="t-b--18 t-b--16s">
						<span class="t--fak">	This policy is effective as of 9 April 2021.</span>
					</div>
					<div class="t-b--13">
						<p class="t--kr">이 정책은 2021년 4월 9일부터 유효합니다.</p>
					</div>
				</div>
				<Minime class="s--none-pc l-mdl--32 policy__minime--mo"
					:is-block-content="true"
				/>
			</div>
		</div>
	</div>
</template>

<script>
// import * as $ from "fxdom";
// import * as _ from "fxjs";
import { ref, reactive, onMounted, onUnmounted, onActivated, onDeactivated } from "@/helper/vue.js";
import Minime from "@/parts/minime/Minime.vue";
import { nextTick } from '@vue/runtime-core';

export default {
	components: {
    Minime,
  },
	setup(props) {
		// 현재 템플릿 ROOT를 DOM으로 접근
		const self = ref();
		// 템플릿에서 사용되는 데이터
		const data = reactive({
			isOpen: false,
		});
		// 템플릿에서 사용되는 메소드
		const methods = {
			contentSet: () => {
				const windowH = window.innerHeight;
				const policyInner = document.querySelector('.policy__inner');
				const policyInnerH = policyInner.clientHeight;
				const policyCont = document.querySelector('.policy__cont');

					if(windowH < policyInnerH){
						policyInner.classList.remove('policy__inner--center');
					}else{
						policyCont.style.height = 'auto';
						policyInner.classList.add('policy__inner--center');
					}
			}
		};

		// DOM 접근 가능 시점
		onMounted(() => {
			// nextTick(() => {
			// 	data.isOpen = true
			// 	methods.contentSet();
			// 	window.addEventListener('resize', methods.contentSet)
			// })
		});
		onActivated(() => {
			nextTick(() => {
				data.isOpen = true
				methods.contentSet();
				window.addEventListener('resize', methods.contentSet)
			})
		})
		onDeactivated(() => {
			window.removeEventListener('resize', methods.contentSet)
			data.isOpen = false
		})

		// window, document 등 Unmount되지 않는 이벤트 해제를 위해 사용
		onUnmounted(() => {
			// window.removeEventListener('resize', methods.contentSet)
			// data.isOpen = false
		});

		return {
			self,
			data,
			methods
		};
	}
}
</script>
<style scoped lang="scss" src="@/css/layerPop.scss"></style>
<style lang="scss" scoped>
@import "@/css/_settings.scss";
.policy{    
  $self: &;
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	color: $f-black;
	position: relative;
	// background: #f9f5ef;
	&__inner {
		opacity: 0;
    -webkit-transition: opacity .35s .3s ease-in;
    transition: opacity .35s .3s ease-in;
	}
	&__inner--center{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		transform: translate(-50%, -50%);
	}
	&__tit{
		margin-top: 96em;
		text-align: center;
		.__w600 &{
			margin-top: 48em;
			text-align: left;
		}
	}
	&__cont{
		overflow-y: scroll;
	}
	&__txt-wrap{
		position: relative;
		&--kr {
			margin-left: calc(var(--cw1) + 2 * var(--gutter));
			.__w600 & {
				margin-left: 0;
				margin-top: 32em;
			}
		}
		.policy__lang-type{
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			font-size: 11em;
			line-height: 2.5em;
			color: #171010;
			.__w600 &{
				font-size: 9em;
			}
		}
		.t-b--12 .t--fak{
			font-size: 12em;
		}
	}
	&__foot{
		margin-top: -52em;
		padding-bottom: 101em;
		position: relative;
		text-align: center;
		.__w600 &{
			margin-top: 0;
			padding-bottom: 74em;
			text-align: left;
		}
	}
	&--open {
		#{$self}__inner {
			opacity: 1;
		}
	}
	&__minime--mo {
		:deep(.g-row) {
			justify-content: flex-end;
		}
	}
}
</style>