<template>
  <div ref="selfHeadOut" class="head-out c-drag">
    <div class="head-out__traf-btn-wrap">
      <button class="head-out__traf-btn head-out__traf-btn--close" @click="methods.closeChatbot">
        <img class="head-out__close" src="@/assets/messenger/btn-traffic-close.svg" alt="">
      </button>
      <div class="head-out__traf-btn"></div>
      <div class="head-out__traf-btn"></div>
    </div>
    <div class="head-out__tit-wrap">
      <div class="head-out__tit">
        <span class="t-cb--13 t-cb--16-s"><span class="t--fak">
          <i class="emoji" style="background-image: url(https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/279/speech-balloon_1f4ac.png);"></i>Let's Talk
        </span></span>
      </div>
    </div>
    <div class="c-drag__point c-chatbot__drag-point"></div>
  </div>
  <div ref="mainCont" class="main-cont">
    <div ref="selfHead" class="main-head">
      <div class="main-head__inr">
        <div class="main-head__tit">
          <span class="t-cb--13"><span class="t--fak main-tit__time">{{ data.clock }}</span></span>
        </div>
          <button class="main-head__btn b-lang" @click="methods.changeLang">
            <div class="t-cb--13"><span class="t--fak">{{data.isLangKr ? 'EN' : 'KR'}}</span></div>
          </button>
      </div>
    </div>
    <div class="main-wrap">
      <div ref="self" class="main">
        <div class="main-tit">
          <div class="main-tit--soft">
            <span class="t-cb--16"><span class="t--fak main-tit__time">{{ data.clock }}</span></span>
          </div>
          <div class="main-tit__tit-wrap">
            <div class="main-tit__tit">
              <div class="main-tit__inr">
                <div class="main-tit__txt">
                  <span v-if="!data.isLangKr" class="t-cb--30"><span class="t--fak">Oh, hello!</span></span>
                  <span v-else class="t-cb--30"><span class="t--kr">반가워요</span></span>
                  <div class="main-tit__hand">
                    <img src="@/assets/messenger/hand.svg" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!data.isLangKr" class="main-subtit">
            <div class="t-cb--30">
              <RandomTxt :sayText="data.say" :type="'autoPlay'" :replaceTime="[2000, 75]" :randomType="'ordered'"></RandomTxt>
            </div>
          </div>
          <div v-else class="main-subtit">
            <div class="t-cb--30">
              <RandomTxt :sayText="data.sayKr" :type="'autoPlay'" :replaceTime="[2000, 75]" :randomType="'ordered'"></RandomTxt>
            </div>
          </div>
          <button class="main__btn" @click="methods.changeLang">
            <div class="t-cb--13"><span class="t--fak">{{data.isLangKr ? 'EN' : 'KR'}}</span></div>
          </button>
        </div>

        <div class="main-card">
          <Card @click="methods.setComponentName" :cardList="data.mainCardList" :pad="true"></Card>
        </div>
        <div class="main-job">
          <div class="main-job__tit sec-tit">
            <div v-if="!data.isLangKr" class="sec-tit__tit">
              <span class="t-cb--30"><span class="t--fak">🦸‍♂️ Job Openings</span></span>
            </div>
            <div v-else class="sec-tit__tit">
              <span class="t-cb--30"><span class="t--fak">🦸‍♂️</span><span class="t--kr"> 채용</span></span>
            </div>
            <div class="sec-tit__num">
              <span class="t-cb--16"><span class="t--fak">{{ data.jobOpeningsNum }}</span></span>
            </div>
          </div>
          <div v-if="data.jobOpenings.length < 1" class="main-job-close no-jobs">
            <div class="no-jobs__cont">
              <div class="no-jobs__face">
                <Avatar :avatarName="'david'" :type="'mid'"/>
              </div>

              <!-- <img class="no-jobs__face" src="@/assets/messenger/face-1.jpg" alt=""> -->
              <div v-if="!data.isLangKr" class="no-jobs__txt">
                <div class="no-jobs__tit">
                  <span class="t-cb--16"><span class="t--fak">We don't have any open positions right now.</span></span>
                </div>
                <div class="no-jobs__desc">
                  <span class="t-cb--16"><span class="t--fak">Check back later or send us your resume and portfolio. We'll keep them on file and reach out for an interview when there's an opening!</span></span>
                </div>
              </div>
              <div v-else class="no-jobs__txt">
                <div class="no-jobs__tit">
                  <span class="t-cb--16"><span class="t--kr">현재 진행중인 채용 공고가 없습니다</span></span>
                </div>
                <div class="no-jobs__desc">
                  <span class="t-cb--16"><span class="t--kr">채용 진행 시 디파이 홈페이지를 통해 공고를 냅니다. 상시채용도 진행하니, 디파이 인재풀에 이력서와 포트폴리오를 등록해주세요. <br>채용 시 인재풀에 등록된 이력서와 포트폴리오를 최우선으로 검토합니다.</span></span>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="main-job__card">
            <Card @click="methods.setComponentName" :cardList="data.jobOpenings"></Card>
          </div>
        </div>

        <div class="main-channel">
          <div class="main-channel__tit sec-tit">
            <div v-if="!data.isLangKr" class="sec-tit__tit">
              <span class="t-cb--30"><span class="t--fak">📻 Channel</span></span>
            </div>
            <div v-else class="sec-tit__tit">
              <span class="t-cb--30"><span class="t--fak">📻</span><span class="t--kr"> 채널</span></span>
            </div>
          </div>

          <div ref="channelDrag">
            <div class="main-channel__card-wrap c-drag slider">
              <div class="c-drag__container">
                <div class="c-drag__unit c-drag__point slider__unit">
                  <button
                    class="main-channel__card channel c-drag__item slider__item"
                    v-for="(item, index) in data.channel"
                    :key="index"
                    @click="methods.setComponentName(data.channel[index])"
                  >
                    <div class="channel__bg-wrap">
                      <img class="channel__bg" :src="item.cont.bgPath" alt="">
                    </div>
                    <div class="overlay overlay-1"></div>
                    <div class="overlay overlay-2"></div>
                    <div class="channel__tit">
                      <span class="t-cb--24"><span class="t--fak" v-html="item.cont.headTitle"></span></span>
                    </div>
                    <div class="channel__img-wrap">
                      <div class="channel__img-inr">
                        <div class="channel__img">
                          <img
                            :src="item.cont.facePath"
                            alt=""
                          >
                        </div>
                        <div class="open-state">

                          <template v-if="item.cont.openTime == 'coming'">
                            <div class="open-state__coming">
                              <div class="channel__state channel__state--coming">
                                <span class="t-cb--11"><span class="t--fak">COMING SOON</span></span>
                              </div>
                            </div>
                          </template>


                          <template v-else>

                            <div :class=" `open-state__${item.cont.isOpenNow}`" >
                              <div v-if="item.cont.isOpenNow === 'open'" class="channel__border"></div>
                              <div
                                class="channel__state"
                                :class="`channel__state--${item.cont.isOpenNow}`">
                                <span class="t-cb--11"><span class="t--fak">{{ item.cont.isOpenNow.toUpperCase() }}</span></span>
                              </div>
                            </div>

                            <!-- <div class="open-state__open">
                              <div class="channel__border"></div>
                              <div class="channel__state channel__state--open">
                                <span class="t-cb--11"><span class="t--fak">OPEN</span></span>
                              </div>
                            </div>
                            <div class="open-state__close">
                              <div class="channel__state channel__state--closed">
                                <span class="t-cb--11"><span class="t--fak">CLOSED</span></span>
                              </div>
                            </div> -->
                          </template>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main-news">
          <News :item="data.newsList.seongsuFlat[2]" :person="data.channel[3].cont"></News>
          <News :item="data.newsList.seongsuFlat[3]" :person="data.channel[3].cont"></News>
          <News :item="data.newsList.seongsuFlat[5]" :person="data.channel[3].cont"></News>
          <News :item="data.newsList.sinsa[0]" :person="data.channel[0].cont"></News>
          <News :item="data.newsList.paju[0]" :person="data.channel[1].cont"></News>
          <News :item="data.newsList.sinsa[1]" :person="data.channel[0].cont"></News>
          <News :item="data.newsList.seongsu[0]" :person="data.channel[2].cont"></News>
        </div>
        <div class="main-topic topic">
          <div class="topic__tit sec-subtit">
            <div v-if="!data.isLangKr" class="sec-subtit__tit">
              <span class="t-cb--16"><span class="t--fak">View by Topic</span></span>
            </div>
            <div v-else class="sec-subtit__tit">
              <span class="t-cb--16"><span class="t--kr">주제별 프로젝트</span></span>
            </div>
          </div>
          <ul class="topic__item-wrap">
            <li class="topic__item">
              <a
                href="/interests/new-way-to-retail"
                router-to="/interests/new-way-to-retail"
                @click="methods.closeChatbot"
              >
                <span class="topic__bg"></span>
                <span class="topic__emoji">
                  <span class="t-cb--28"><span class="t--fak">🎳</span></span>
                </span>
                <span class="topic__itm-tit-wrap">
                  <span class="topic__itm-tit">
                    <span class="t-cb--16"><span class="t--fak">New ways <br>to Retail</span></span>
                  </span>
                  <span class="topic__num">
                    <span class="t-cb--13"><span class="t--fak">{{ data.getCount(1) }} Projects</span></span>
                  </span>
                </span>
                <span class="topic__btn-wrap">
                  <span class="b-arrow topic__btn">
                    <span class="b-arrow__img b-arrow__img--black topic__arrow ico-chatbot--navi-arrow--se"></span>
                    <span class="b-arrow__img b-arrow__img--white topic__arrow ico-chatbot--navi-arrow--se"></span>
                  </span>
                </span>
              </a>
            </li>
            <li class="topic__item">
              <a href="/interests/ready-to-launch" router-to="/interests/ready-to-launch" @click="methods.closeChatbot">
                <span class="topic__bg"></span>
                <span class="topic__emoji">
                  <span class="t-cb--28"><span class="t--fak">🚀</span></span>
                </span>
                <span class="topic__itm-tit-wrap">
                  <span class="topic__itm-tit">
                    <span class="t-cb--16"><span class="t--fak">Ready to Launch</span></span>
                  </span>
                  <span class="topic__num">
                    <span class="t-cb--13"><span class="t--fak">{{ data.getCount(2) }} Projects</span></span>
                  </span>
                </span>
                <span class="topic__btn-wrap">
                  <span class="b-arrow topic__btn">
                    <span class="b-arrow__img b-arrow__img--black topic__arrow ico-chatbot--navi-arrow--se"></span>
                    <span class="b-arrow__img b-arrow__img--white topic__arrow ico-chatbot--navi-arrow--se"></span>
                  </span>
                </span>
              </a>
            </li>
            <li class="topic__item">
              <a href="/interests/purposeful-spaces" router-to="/interests/purposeful-spaces" @click="methods.closeChatbot">
                <span class="topic__bg"></span>
                <span class="topic__emoji">
                  <span class="t-cb--28"><span class="t--fak">🔮</span></span>
                </span>
                <span class="topic__itm-tit-wrap">
                  <span class="topic__itm-tit">
                    <span class="t-cb--16"><span class="t--fak">Purposeful <br>Spaces</span></span>
                  </span>
                  <span class="topic__num">
                    <span class="t-cb--13"><span class="t--fak">{{ data.getCount(4) }} Projects</span></span>
                  </span>
                </span>
                <span class="topic__btn-wrap">
                  <span class="b-arrow topic__btn">
                    <span class="b-arrow__img b-arrow__img--black topic__arrow ico-chatbot--navi-arrow--se"></span>
                    <span class="b-arrow__img b-arrow__img--white topic__arrow ico-chatbot--navi-arrow--se"></span>
                  </span>
                </span>
              </a>
            </li>
            <li class="topic__item">
              <a href="/interests/tomorrow-today" router-to="/interests/tomorrow-today" @click="methods.closeChatbot">
                <span class="topic__bg"></span>
                <span class="topic__emoji">
                  <span class="t-cb--28"><span class="t--fak">🎭</span></span>
                </span>
                <span class="topic__itm-tit-wrap">
                  <span class="topic__itm-tit">
                    <span class="t-cb--16"><span class="t--fak">Tomorrow <br>Today</span></span>
                  </span>
                  <span class="topic__num">
                    <span class="t-cb--13"><span class="t--fak">{{ data.getCount(5) }} Projects</span></span>
                  </span>
                </span>
                <span class="topic__btn-wrap">
                  <span class="b-arrow topic__btn">
                    <span class="b-arrow__img b-arrow__img--black topic__arrow ico-chatbot--navi-arrow--se"></span>
                    <span class="b-arrow__img b-arrow__img--white topic__arrow ico-chatbot--navi-arrow--se"></span>
                  </span>
                </span>
              </a>
            </li>
            <li class="topic__item">
              <a href="/interests/being-social" router-to="/interests/being-social" @click="methods.closeChatbot">
                <span class="topic__bg"></span>
                <span class="topic__emoji">
                  <span class="t-cb--28"><span class="t--fak">💎</span></span>
                </span>
                <span class="topic__itm-tit-wrap">
                  <span class="topic__itm-tit">
                    <span class="t-cb--16"><span class="t--fak">Being Social</span></span>
                  </span>
                  <span class="topic__num">
                    <span class="t-cb--13"><span class="t--fak">{{ data.getCount(3) }} Projects</span></span>
                  </span>
                </span>
                <span class="topic__btn-wrap">
                  <span class="b-arrow topic__btn">
                    <span class="b-arrow__img b-arrow__img--black topic__arrow ico-chatbot--navi-arrow--se"></span>
                    <span class="b-arrow__img b-arrow__img--white topic__arrow ico-chatbot--navi-arrow--se"></span>
                  </span>
                </span>
              </a>
            </li>
            <li class="topic__item">
              <a href="/interests/human-technology" router-to="/interests/human-technology" @click="methods.closeChatbot">
                <span class="topic__bg"></span>
                <span class="topic__emoji">
                  <span class="t-cb--28"><span class="t--fak">💖</span></span>
                </span>
                <span class="topic__itm-tit-wrap">
                  <span class="topic__itm-tit">
                    <span class="t-cb--16"><span class="t--fak">Human <br>Technology</span></span>
                  </span>
                  <span class="topic__num">
                    <span class="t-cb--13"><span class="t--fak">{{ data.getCount(0) }} Projects</span></span>
                  </span>
                </span>
                <span class="topic__btn-wrap">
                  <span class="b-arrow topic__btn">
                    <span class="b-arrow__img b-arrow__img--black topic__arrow ico-chatbot--navi-arrow--se"></span>
                    <span class="b-arrow__img b-arrow__img--white topic__arrow ico-chatbot--navi-arrow--se"></span>
                  </span>
                </span>
              </a>
            </li>
            <li class="topic__item">
              <a href="/interests/stories-that-inspire" router-to="/interests/stories-that-inspire" @click="methods.closeChatbot">
                <span class="topic__bg"></span>
                <span class="topic__emoji">
                  <span class="t-cb--28"><span class="t--fak">👻</span></span>
                </span>
                <span class="topic__itm-tit-wrap">
                  <span class="topic__itm-tit">
                    <span class="t-cb--16"><span class="t--fak">Stories that Inspire</span></span>
                  </span>
                  <span class="topic__num">
                    <span class="t-cb--13"><span class="t--fak">{{ data.getCount(6) }} Projects</span></span>
                  </span>
                </span>
                <span class="topic__btn-wrap">
                  <span class="b-arrow topic__btn">
                    <span class="b-arrow__img b-arrow__img--black topic__arrow ico-chatbot--navi-arrow--se"></span>
                    <span class="b-arrow__img b-arrow__img--white topic__arrow ico-chatbot--navi-arrow--se"></span>
                  </span>
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div class="main-explore explore">
          <div class="explore__tit sec-subtit">
            <div v-if="!data.isLangKr" class="sec-subtit__tit">
              <span class="t-cb--16"><span class="t--fak">More to Explore</span></span>
            </div>
            <div v-else class="sec-subtit__tit">
              <span class="t-cb--16"><span class="t--kr">바로가기</span></span>
            </div>
          </div>

          <div class="explore__link-wrap">
            <a class="explore__link" href="/work" router-to="/work" @click="methods.closeChatbot">
              <span v-if="!data.isLangKr" class="explore__name">
                <span class="t-cb--16"><span class="t--fak">All Projects</span></span>
              </span>
              <span v-else class="explore__name">
                <span class="t-cb--16"><span class="t--kr">프로젝트 보러가기</span></span>
              </span>
              <span class="explore__btn">
                <button class="b-arrow">
                  <span class="b-arrow__img b-arrow__img--black topic__arrow ico-chatbot--navi-arrow--se"></span>
                  <span class="b-arrow__img b-arrow__img--white topic__arrow ico-chatbot--navi-arrow--se"></span>
                </button>
              </span>
            </a>
            <a class="explore__link" href="/services" router-to="/services" @click="methods.closeChatbot">
              <span v-if="!data.isLangKr" class="explore__name">
                <span class="t-cb--16"><span class="t--fak">Our Services</span></span>
              </span>
              <span v-else class="explore__name">
                <span class="t-cb--16"><span class="t--kr">서비스 소개</span></span>
              </span>
              <span class="explore__btn">
                <button class="b-arrow">
                  <span class="b-arrow__img b-arrow__img--black topic__arrow ico-chatbot--navi-arrow--se"></span>
                  <span class="b-arrow__img b-arrow__img--white topic__arrow ico-chatbot--navi-arrow--se"></span>
                </button>
              </span>
            </a>
            <a class="explore__link" href="/about" router-to="/about" @click="methods.closeChatbot">
              <span v-if="!data.isLangKr" class="explore__name">
                <span class="t-cb--16"><span class="t--fak">About <span class="t-dfy">DFY</span></span></span>
              </span>
              <span v-else class="explore__name">
                <span class="t-cb--16"><span class="t--kr">디파이 소개</span></span>
              </span>
              <span class="explore__btn">
                <button class="b-arrow">
                  <span class="b-arrow__img b-arrow__img--black topic__arrow ico-chatbot--navi-arrow--se"></span>
                  <span class="b-arrow__img b-arrow__img--white topic__arrow ico-chatbot--navi-arrow--se"></span>
                </button>
              </span>
            </a>
            <a class="explore__link" href="/contact" router-to="/contact" @click="methods.closeChatbot">
              <span v-if="!data.isLangKr" class="explore__name">
                <span class="t-cb--16"><span class="t--fak">Contact Us</span></span>
              </span>
              <span v-else class="explore__name">
                <span class="t-cb--16"><span class="t--kr">문의하기</span></span>
              </span>
              <span class="explore__btn">
                <button class="b-arrow">
                  <span class="b-arrow__img b-arrow__img--black topic__arrow ico-chatbot--navi-arrow--se"></span>
                  <span class="b-arrow__img b-arrow__img--white topic__arrow ico-chatbot--navi-arrow--se"></span>
                </button>
              </span>
            </a>
            <a class="explore__link" href="https://instagram.com/dfy.seoul" target="_blank">
              <span v-if="!data.isLangKr" class="explore__name">
                <span class="t-cb--16"><span class="t--fak">IG (@dfy.seoul)</span></span>
              </span>
              <span v-else class="explore__name">
                <span class="t-cb--16"><span class="t--kr">인스타그램 </span><span class="t--fak">(@dfy.seoul)</span></span>
              </span>
              <span class="explore__btn">
                <button class="b-arrow">
                  <span class="b-arrow__img b-arrow__img--black topic__arrow ico-chatbot--navi-arrow--ne"></span>
                  <span class="b-arrow__img b-arrow__img--white topic__arrow ico-chatbot--navi-arrow--ne"></span>
                </button>
              </span>
            </a>
            <a class="explore__link" href="https://behance.net/defythecurrent" target="_blank">
              <span v-if="!data.isLangKr" class="explore__name">
                <span class="t-cb--16"><span class="t--fak">BE (@defythecurrent)</span></span>
              </span>
              <span v-else class="explore__name">
                <span class="t-cb--16"><span class="t--kr">비핸스 </span><span class="t--fak">(@defythecurrent)</span></span>
              </span>
              <span class="explore__btn">
                <button class="b-arrow">
                  <span class="b-arrow__img b-arrow__img--black topic__arrow ico-chatbot--navi-arrow--ne"></span>
                  <span class="b-arrow__img b-arrow__img--white topic__arrow ico-chatbot--navi-arrow--ne"></span>
                </button>
              </span>
            </a>
          </div>
        </div>
        <div class="power">
          <span class="t-cb--11 power__txt"><span class="t--fak">Powered by </span></span>
          <span class="t-cb--11"><span class="t--fak"><span class="t-dfy">DFY</span> 3F</span></span>
        </div>
      </div>
    </div>
    <div class="subpage-wrap">
      <div class="subpage">
        <keep-alive>
          <component v-bind:is="data.currentSubpage" @click="methods.setSubpageClass" :currentCard="data.currentCard" :subpage="data.subpage"></component>
        </keep-alive>
      </div>
    </div>
    
    <div class="sub-subpage-wrap">
      <keep-alive>
        <component v-bind:is="data.subSubpage.type" :bgData="data.subSubpage.bgData"></component>
      </keep-alive>
      <!-- <Video :bgData="data.bgData"></Video>
      <Map :bgData="data.bgData"></Map> -->
      <!-- <Video :videoState="data.videoState" :bgData="data.bgData" @setVideoState="methods.setVideoState"></Video>
      <Map
        :mapState="data.mapState"
        :bgData="data.bgData"
        @setMapState="methods.setMapState"
      /> -->
    </div>

    <div class="sub-policy-wrap">
      <Policy :policyState="data.policyState" @setPolicyState="methods.setPolicyState"></Policy>
    </div>
    <div class="sub-policy-bg" @click="methods.setPolicyState()"></div>
  </div>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { ref, reactive, computed, onMounted, watchEffect, watch, onUnmounted, onActivated, onDeactivated, useStore } from "@/helper/vue.js";
import { Pointer } from "@/js/cursorActions.js";
import { Gesture } from '@/js/slider.js';
import { getCurrentTime } from "@/helper/gadget.js";
import Card from './type/_MainCard.vue';
import RandomTxt from "@/parts/text/randomTxt.vue";
import News from './type/_MainNews.vue';
import Policy from "./subpages/_ChatPolicy.vue";
import ChatVideo from "./subpages/_ChatVideo.vue";
import ChatMap from "./subpages/_ChatMap.vue";
import { count } from "@/store/data-workList.js";

import { gsap } from "gsap/all";
import { DragSlider } from "@/js/slider.js";
import Project from './subpages/_ChatProject.vue';
import JoinTeam from './subpages/_ChatJoinTeam.vue';
// import @@@hiringTemplate from './subpages/hiring/_Chat@@@HiringTemplate.vue';
import BxDesigner from './subpages/hiring/_ChatBxDesigner';
import SeniorProductDesigner from './subpages/hiring/_ChatSeniorProductDesigner.vue';
import UiuxStrategist from './subpages/hiring/_ChatUiuxStrategist.vue';
import WebPublisher from './subpages/hiring/_ChatWebPublisher.vue';
import Sinsa from './subpages/_ChatSinsa.vue';
import Paju from './subpages/_ChatPaju.vue';
import Seongsu from './subpages/_ChatSeongsu.vue';
import SeongsuFlat from './subpages/_ChatSeongsuFlat.vue';

import Avatar from "./type/_Avatar.vue";

export default {
  components: {
    Avatar,
    Card,
    RandomTxt,
    News,
    Policy,
    'chatmap': ChatMap,
    'chatvideo': ChatVideo,
    'project': Project,
    'joinTeam': JoinTeam,
    // '@@@hiringTemplate': @@@HiringTemplate,
    'bxDesigner': BxDesigner,
    'seniorProductDesigner': SeniorProductDesigner,
    'uiuxStrategist': UiuxStrategist,
    'webPublisher': WebPublisher,
    'sinsa': Sinsa,
    'paju': Paju,
    'seongsu': Seongsu,
    'seongsuFlat': SeongsuFlat,
  },
  setup() {
    const { state, get, commit, dispatch } = useStore();
    const data = reactive({
      getCount: index => count.tag[index],
      clock: null,
			componentName: null,
      isLangKr: computed(() => state.isLangKr),
      // openS!tate: computed(() => state.chatbot.openS!tate),
      newsList: computed(() => state.chatbot.newsList),
      policyState: computed(() => state.chatbot.policyState),
      currentSubpage: computed(() => state.chatbot.currentSubpage),
      jobOpeningsNum: '',
      subpage: false,
      say: [{text: 'How can we help?'}, {text: 'Got a project for us?'}, {text: 'Like our approach?'}, {text: 'Let’s make it happen'}],
      sayKr: [{text: '어떻게 도와드릴까요?', lang: 'kr'}, {text: '프로젝트를 의뢰하시겠어요?', lang: 'kr'}, {text: '무언가 특별한 게 필요한가요?', lang: 'kr'}, {text: '지금 바로 시작해요!', lang: 'kr'}],
      currentCard: {},
      subSubpage: {
        type: null,
        bgData: {},
      },
      mainCardList: [
        {
          name: 'project',
          cont: {
            type: 'card',
            name: 'project',
            bgPath: require('@/assets/messenger/card-bg-1.jpg'),
            shapePath1: require('@/assets/messenger/shape-1-1.svg'),
            shapePath2: require('@/assets/messenger/shape-1-2.svg'),
            shapePath3: require('@/assets/messenger/shape-1-3.svg'),
            headFacePath: require('@/assets/messenger/face-1.jpg'),
            faceName: 'demi',
            headFaceName: 'demi',
            borderColor: '#C0EAAC',
            float: true,
          },
          contENG: {
            leftLabel: 'Start a Project',
            title: 'Want to work with us?',
            desc: 'We’re happy to discuss any potential projects. If you have a couple of minutes, we’d love to hear more.',
            headTitle: 'Start a Project',
            widthClass: 'card__tit--w200',
          },
          contKR: {
            leftLabel: '프로젝트 의뢰하기',
            title: '디파이의 도움이 필요하신가요?',
            desc: '의뢰하실 프로젝트에 대해 설명해주시면<br class="p-layout__none"> 담당자 연결 후 빠른 시일 내 연락<br class="p-layout__none"> 드리겠습니다.',
            headTitle: '프로젝트 의뢰하기',
            widthClass: 'card__tit--w200',
          },
        },
        {
          name: 'joinTeam',
          cont: {
            type: 'card',
            name: 'joinTeam',
            bgPath: require('@/assets/messenger/card-bg-2.jpg'),
            shapePath1: require('@/assets/messenger/shape-2-1.svg'),
            shapePath2: require('@/assets/messenger/shape-2-2.svg'),
            shapePath3: require('@/assets/messenger/shape-2-3.svg'),
            headFacePath: require('@/assets/messenger/face-2.jpg'),
            faceName: 'david',
            headFaceName: 'david',
            borderColor: '#BBD9E9',
            float: true,
          },
          contENG: {
            leftLabel: 'Send Us Your Resume',
            title: "We're always open to applications.",
            descTop: "You never know when we might have an open position that's just right for you, so send us ",
            desc: "your resume and portfolio and we'll keep it on file for future consideration.",
            headTitle: 'Join The Team', 
            widthClass: 'card__tit--w250',
          },
          contKR: {
            leftLabel: '디파이 인재풀에 등록하기',
            // rightLabel: '디파이에 합류하는 가장 빠른 방법',
            title: '디파이에 합류하는 가장 빠른 방법',
            descTop: '디파이는 상시 채용을 진행합니다. 새로운 자리가 날 경우 이력서가 사전 등록된 인재풀에서 가장 먼저 적임자를 찾습니다. 현재 진행 중인 채용 공고가 없거나 채용 기간이',
            desc: '아니더라도, 인재풀에 이력서를 미리 등록해 주시면 채용 시 우선적으로 이력서를 검토하겠습니다.',
            headTitle: '디파이 인재풀에 등록하기',
            widthClass: 'card__tit--w200',
          },
        },
      ],
      // jobOpenings: [],
      jobOpenings: [
        // {
        //   name: 'hiringTemplate',
        //   cont: {
        //     type: 'hiring',
        //     bgPath: require('@/assets/chatbot/hiring_@@@.jpg'),
        //     // facePath: require('@/assets/messenger/face-1.jpg'),
        //     // headFacePath: require('@/assets/messenger/face-1.jpg'),
        //     faceName: '@@@dana, demi, david',
        //     headFaceName: '@@@',
        //     borderColor: '#@@@',
        //     colorWhite: true, 
        //   },
        //   contENG: {
        //     leftLabel: 'Now Hiring',
        //     rightLabel: '@@@Full Time',
        //     title: '@@@Hiring Template',
        //     location: '@@@Sinsa, Seoul',
        //     desc: '@@@',
        //     headTitle: '@@@',
        //   },
        //   contKR: {
        //     leftLabel: '채용 중',
        //     rightLabel: '@@@정규직',
        //     title: '@@@',
        //     location: '@@@신사, 서울',
        //     desc: '@@@',
        //     headTitle: '@@@',
        //   },
        // },
        {
          name: 'seniorProductDesigner',
          cont: {
            type: 'hiring',
            bgPath: require('@/assets/chatbot/hiring_seniorProductDesigner.jpg'),
            // facePath: require('@/assets/messenger/face-1.jpg'),
            // headFacePath: require('@/assets/messenger/face-1.jpg'),
            faceName: 'dana',
            headFaceName: 'dana',
            borderColor: '#294143',
            colorWhite: true, 
          },
          contENG: {
            leftLabel: 'Now Hiring',
            rightLabel: 'Full Time',
            title: 'Product Designer',
            location: 'Sinsa, Seoul',
            descTop: '여러 분야의 팀원과 협력하여 프로젝트 수행 및 품질을 담당할 프로덕트 디자이너를 찾고 있습니다.',
            desc: '서비스 출시부터 개선/운영 프로젝트 경험과 디자인에 관한 다양한 인사이트를 바탕으로 새로운 아이디어를 제공할 수 있어야 합니다.',
            headTitle: 'Product Designer',
          },
          contKR: {
            leftLabel: '채용 중',
            rightLabel: '정규직',
            title: '프로덕트 디자이너',
            location: '신사, 서울',
            descTop: '여러 분야의 팀원과 협력하여 프로젝트 수행 및 품질을 담당할 프로덕트 디자이너를 찾고 있습니다.',
            desc: '서비스 출시부터 개선/운영 프로젝트 경험과 디자인에 관한 다양한 인사이트를 바탕으로 새로운 아이디어를 제공할 수 있어야 합니다.',
            headTitle: '프로덕트 디자이너',
          },
        },
        {
          name: 'bxDesigner',
          cont: {
            type: 'hiring',
            bgPath: require('@/assets/chatbot/hiring_bxDesigner.jpg'),
            // facePath: require('@/assets/messenger/face-1.jpg'),
            // headFacePath: require('@/assets/messenger/face-1.jpg'),
            faceName: 'demi',
            headFaceName: 'demi',
            borderColor: '#294143',
            colorWhite: true, 
          },
          contENG: {
            leftLabel: 'Now Hiring',
            rightLabel: 'Full Time',
            title: 'BX Designer',
            location: 'Sinsa, Seoul',
            desc: '디파이에서는 브랜드 경험을 기획하고 디자인하는 Brand Experience Designer 분야의 인재를 찾습니다.',
            headTitle: 'BX Designer',
          },
          contKR: {
            leftLabel: '채용 중',
            rightLabel: '정규직',
            title: 'BX 디자이너',
            location: '신사, 서울',
            desc: '디파이에서는 브랜드 경험을 기획하고 디자인하는 Brand Experience Designer 분야의 인재를 찾습니다.',
            headTitle: 'BX 디자이너',
          },
        },
        {
          name: 'uiuxStrategist',
          cont: {
            type: 'hiring',
            bgPath: require('@/assets/chatbot/hiring_uiuxStrategist.jpg'),
            // facePath: require('@/assets/messenger/face-1.jpg'),
            // headFacePath: require('@/assets/messenger/face-1.jpg'),
            faceName: 'david',
            headFaceName: 'david',
            borderColor: '#294143',
            colorWhite: true, 
          },
          contENG: {
            leftLabel: 'Now Hiring',
            rightLabel: 'Full Time',
            title: 'UI/UX Strategist',
            location: 'Sinsa, Seoul',
            desc: '디파이의 기획자는 웹사이트, E-커머스, 브랜딩, 미디어 비즈니스의 고객과의 접점에서 전략적 접근과 아이디어로 커뮤니케이션합니다. 비즈니스 환경과 흐름을 빠르게 파악하고, 디파이의 크리에이티브안에서 함께 성장해 갈 분들을 기다립니다.',
            headTitle: 'UI/UX Strategist',
          },
          contKR: {
            leftLabel: '채용 중',
            rightLabel: '정규직',
            title: 'UI/UX 기획자',
            location: '신사, 서울',
            desc: '디파이의 기획자는 웹사이트, E-커머스, 브랜딩, 미디어 비즈니스의 고객과의 접점에서 전략적 접근과 아이디어로 커뮤니케이션합니다. 비즈니스 환경과 흐름을 빠르게 파악하고, 디파이의 크리에이티브안에서 함께 성장해 갈 분들을 기다립니다.',
            headTitle: 'UI/UX 기획자',
          },
        },
        {
          name: 'webPublisher',
          cont: {
            type: 'hiring',
            bgPath: require('@/assets/chatbot/hiring_webPublisher.jpg'),
            // facePath: require('@/assets/messenger/face-1.jpg'),
            // headFacePath: require('@/assets/messenger/face-1.jpg'),
            faceName: 'demi',
            headFaceName: 'demi',
            borderColor: '#294143',
            colorWhite: true, 
          },
          contENG: {
            leftLabel: 'Now Hiring',
            rightLabel: 'Full Time',
            title: 'Web Publisher,<br>Front-End Developer',
            location: 'Sinsa, Seoul',
            desc: '디파이의 통찰력과 수준 높은 디자인을 웹으로 구현합니다. 웹 사이트에 생명력을 불어넣기 위해 마이크로 인터랙션과 모션을 적극 활용합니다. 이를 위해서는 견고한 마크업이 바탕이 되어야 하며, 자바스크립트에 대한 이해와 경험이 중요합니다. 풀스택 개발자와 같은 전설 속 영웅을 기대하는 것은 아닙니다. 조금 부족한 부분이 있더라도 이 일을 사랑하고 즐길 준비가 되어 있다면 우리 여정의 동반자로 충분합니다. 우리는 해결사가 아닌, 함께 성장할 수 있는 동료를 구합니다.',
            headTitle: 'Web Publisher, Front-End Developer',
          },
          contKR: {
            leftLabel: '채용 중',
            rightLabel: '정규직',
            title: '웹 퍼블리셔, 프론트엔드 개발자',
            location: '신사, 서울',
            desc: '디파이의 통찰력과 수준 높은 디자인을 웹으로 구현합니다. 웹 사이트에 생명력을 불어넣기 위해 마이크로 인터랙션과 모션을 적극 활용합니다. 이를 위해서는 견고한 마크업이 바탕이 되어야 하며, 자바스크립트에 대한 이해와 경험이 중요합니다. 풀스택 개발자와 같은 전설 속 영웅을 기대하는 것은 아닙니다. 조금 부족한 부분이 있더라도 이 일을 사랑하고 즐길 준비가 되어 있다면 우리 여정의 동반자로 충분합니다. 우리는 해결사가 아닌, 함께 성장할 수 있는 동료를 구합니다.',
            headTitle: '웹 퍼블리셔, 프론트엔드 개발자',
          },
        },
      ],
      channel: [
        {
          name: 'sinsa',
          cont: {
            type: 'channel',
            name: 'sinsa',
            bgPath: require('@/assets/chatbot/photo_sinsa_01.jpg'),
            facePath: require('@/assets/chatbot/avatar_sinsa.jpg'),
            headFacePath: require('@/assets/chatbot/avatar_sinsa.jpg'),
            headTitle: '<span class="t-dfy">DFY</span> Sinsa',
            info: '<span class="t-dfy">DFY</span> Sinsa',
            location: 'sinsa',
            isOpenNow: state.chatbot.openStateDFY,
            openTime: state.chatbot.openingHoursDFY,
            colorWhite: true, 
            latLng: { lat: 37.51395881977477, lng: 127.02216673005664 },
            googleMapUrl: 'https://www.google.com/maps/place/DFY+(%EC%A3%BC)%EB%94%94%ED%8C%8C%EC%9D%B4/@37.5142589,127.0213639,18.76z/data=!4m9!1m2!2m1!1z65SU7YyM7J20!3m5!1s0x0:0x7ce1560037196110!8m2!3d37.5139461!4d127.0221661!15sCgnrlJTtjIzsnbSSARBtYXJrZXRpbmdfYWdlbmN5'
          },
          contENG: {
            address: '20, Gangnam-daero 140-gil, \n Gangnam-gu, Seoul, Korea (06043)'
          },
          contKR: {
            address: '서울특별시 강남구 강남대로140길 20\n (06043)'
            // info: '안녕DFY Sinsa',
            // location: '안녕location',
          },
        },
        {
          name: 'seongsu',
          cont: {
            type: 'channel',
            name: 'seongsu',
            bgPath: require('@/assets/chatbot/photo_seongsu_01.jpg'),
            facePath: require('@/assets/chatbot/avatar_seongsu.jpg'),
            headFacePath: require('@/assets/chatbot/avatar_seongsu.jpg'),
            headTitle: '<span class="t-dfy">DFY</span> Seongsu',
            info: '<span class="t-dfy">DFY</span> Seongsu',
            location: 'seongsu',
            isOpenNow: state.chatbot.openStateDFY,
            openTime: state.chatbot.openingHoursDFY,
            colorWhite: true,
            latLng: { lat: 37.541172, lng: 127.059023 },
            googleMapUrl: 'https://www.google.com/maps/place/%EB%94%94%ED%94%8C%EB%9E%AB%EC%84%B1%EC%88%98/data=!3m1!4b1!4m5!3m4!1s0x357ca5dfe457a1f5:0x203e8d4dc818b3be!8m2!3d37.5411108!4d127.058987'
          },
          contENG: {
          },
          contKR: {
            // info: '안녕DFY Paju',
            // location: '안녕location',
          },
        },
        {
          name: 'seongsuFlat',
          cont: {
            type: 'channel',
            name: 'seongsuFlat',
            bgPath: require('@/assets/chatbot/photo_seongsu_flat_01.jpg'),
            facePath: require('@/assets/chatbot/avatar_seongsu_flat.jpg'),
            headFacePath: require('@/assets/chatbot/avatar_seongsu_flat.jpg'),
            headTitle: '<span class="t-dfy">D FLAT</span> Seongsu',
            info: '<span class="t-dfy">D FLAT</span> Seongsu',
            location: 'seongsuFlat',
            isOpenNow: state.chatbot.openStateDFlatSeongsu,
            openTime: state.chatbot.openingHoursDFlatSeongsu,
            colorWhite: true,
            latLng: { lat: 37.541172, lng: 127.059023 },
            googleMapUrl: 'https://www.google.com/maps/place/%EB%94%94%ED%94%8C%EB%9E%AB%EC%84%B1%EC%88%98/data=!3m1!4b1!4m5!3m4!1s0x357ca5dfe457a1f5:0x203e8d4dc818b3be!8m2!3d37.5411108!4d127.058987'
          },
          contENG: {
          },
          contKR: {
            // info: '안녕DFY Paju',
            // location: '안녕location',
          },
        },
        {
          name: 'paju',
          cont: {
            type: 'channel',
            name: 'paju',
            bgPath: require('@/assets/chatbot/photo_paju_01.jpg'),
            facePath: require('@/assets/chatbot/avatar_paju.jpg'),
            headFacePath: require('@/assets/chatbot/avatar_paju.jpg'),
            headTitle: '<span class="t-dfy">D FLAT</span> Paju',
            info: '<span class="t-dfy">D FLAT</span> Paju',
            isOpenNow: state.chatbot.openStateDFlat,
            openTime: state.chatbot.openingHoursDFlat,
            colorWhite: true,
            latLng: { lat: 37.72011282973321, lng: 126.69452366000141 },
            googleMapUrl: 'https://www.google.com/maps/place/%EB%94%94%ED%94%8C%EB%9E%AB/@37.7200296,126.69233,17z/data=!3m1!4b1!4m5!3m4!1s0x357c893a83a832b7:0x12ebc0066feda07b!8m2!3d37.7200296!4d126.6945187'
          },
          contENG: {
          },
          contKR: {
            // info: '안녕DFY Paju',
            // location: '안녕location',
          },
        },
      ],
    });

    const methods = {
			closeChatbot: () => {
        let chatbotEl = $.qs('.c-chatbot__cont');
        let chatbotBtn = $.qs('.c-chatbot__btn-wrap');
        gsap.to(chatbotEl, 0.3, { x: 0 , y: 0, ease: 'power1' });
        gsap.to(chatbotBtn, 0.3, { x: 0 , y: 0, ease: 'power1' });
        $.removeClass("chat-body-lock", $.qs("body"));
				$.removeClass("scroll-lock", $.qs(".app"));

        _.go(
          $.qs(".c-chatbot"),
          $.removeClass("c-chatbot--open")
        )
			},
      setComponentName: (item, contactName) => {
        commit('chatbot/clearTimer');
        if (contactName) {
          commit('chatbot/setCurrentSubpage', contactName);
        } else if (item.name) {
          commit('chatbot/setCurrentSubpage', item.name);
        }
        methods.setSubpageClass();
        data.currentCard = item;
      },
      setSubpageClass: () => {
        _.go(
          $.qs(".subpage-wrap"),
          $.toggleClass("subpage-wrap--open")
        )
        data.subpage = !data.subpage;
      },
      setPolicyState: () => {
        // data.policyState = !data.policyState;
        if (data.policyState) {
          dispatch('chatbot/addTimer', {delay: 400, callback: () => {
            commit('chatbot/setPolicyState');
          }})
        } else {
          commit('chatbot/setPolicyState');
        }
      
        _.go(
          $.qs(".sub-policy-wrap"),
          $.toggleClass("sub-policy-wrap--scroll"),
          $.toggleClass("sub-policy-wrap--open")
        )
      },
      setPolicyName: () => {
        data.componentName = 'policy';
        methods.openPolicy();
      },
      changeLang: () => {
        dispatch("chatbot/changeLang");
      },
      checkHeadScrollClass: () => {
        const main = $.qs(".main-wrap");
        const mainScrollTop = main.scrollTop;

        if (mainScrollTop > 60) {
          $.addClass("main-head--scroll", selfHead.value);
        } else {
          $.removeClass("main-head--scroll",  selfHead.value);
        }
      },
    };
    const self = ref();
    const selfHead = ref();
    const mainCont = ref();
    const selfHeadOut = ref();
    const channelDrag = ref();

    let setClockInt = null;
    const setClock = () => {
      const getH = getCurrentTime().hours;
      const getM = getCurrentTime().minutes;
      const hours = getH < 12 ? `${getH == 0 ? 12 : getH}`:`${getH - 12 == 0 ? 12 : getH - 12}`;
      const minutes = getM < 10 ? `0${getM}` : getM;
      
      data.clock = `Today ${hours}:${minutes} ${getH < 12? 'AM' : 'PM'}`
    }

    const getJobOpeningsNum = () => {
      const num = data.jobOpenings.length
      data.jobOpeningsNum = num;
    }
    const goEach = (lists, callback) => {
      _.go(lists, _.each(callback))
    }

    const setDataByLang = () => {
      const lang = data.lang;
      let lists = [data.mainCardList, data.jobOpenings, data.channel];

      // main data
      goEach(lists, list => {
        goEach(list, data => {
          const keys = _.keys(data.contENG)
          goEach(keys, key => {
            if (!state.isLangKr) { 
              data.cont[key] = data.contENG[key]
            } else {
              if (data.contKR[key] == undefined) {
                data.cont[key] = data.contENG[key]
              } else {
                data.cont[key] = data.contKR[key]
              }
            }
          })
        })
      })
      
      // newsList data
      commit('chatbot/setNewsListByLang', {lang: state.isLangKr});
      goEach(data.channel, channel => {
        goEach(_.keys(data.newsList), key => {
          if (key == channel.name) {
            channel.news = data.newsList[key]
          }
        })
      })
    }

    const setSubSubpageByMediaType = (type) => {
      if (state.chatbot[`${type}State`].state && state.chatbot[`${type}State`].name !== null) {
        _.go(data.channel,
          _.each(list => {
            if (list.name == state.chatbot[`${type}State`].name) {
              data.subSubpage.bgData = list.cont;
              data.subSubpage.type = `chat${type}`;
            }
          })
        )
      }
    }
    const scrollCallback = () => {
      methods.checkHeadScrollClass();
    }  

    const dragDrop = () => {
      const dragItem = $.qs('body');
      // const dragItem = $.qs('.c-drag__point', selfHeadOut.value);
      let startPos = { x: 0, y: 0 }
      let dropPos = { x: 0, y: 0 }
      let startItemPos = { x: 0, y: 0 }
      let startBtnPos = { x: 0, y: 0 }

      let chatbotEl = $.qs('.c-chatbot__cont');
      let chatbotBtn = $.qs('.c-chatbot__btn-wrap');
      let updateDragItemX = gsap.quickSetter(chatbotEl, "x", "px");
      let updateDragItemY = gsap.quickSetter(chatbotEl, "y", "px");
      let updateDragBtnX = gsap.quickSetter(chatbotBtn, "x", "px");
      let updateDragBtnY = gsap.quickSetter(chatbotBtn, "y", "px");

      const gesture = new Gesture({
        type: 'DRAG',
        el: dragItem,
        onDragStart: (_this) => {
          if(_this.dragStartTarget != $.qs(".c-chatbot__drag-point", selfHeadOut.value)) return;
          // 마우스 포인터 좌표값 시작점
          startPos.x = Pointer.getRealX()
          startPos.y = Pointer.getRealY()
          // 챗봇 엘리먼트 시작점
          startItemPos.x = gsap.getProperty(chatbotEl, "x");
          startItemPos.y = gsap.getProperty(chatbotEl, "y");
          startBtnPos.x = gsap.getProperty(chatbotBtn, "x");
          startBtnPos.y = gsap.getProperty(chatbotBtn, "y");
        },
        onDragMove: (_this) => {
          if(_this.dragStartTarget != $.qs(".c-chatbot__drag-point", selfHeadOut.value)) return;
          const endPosXLeft = 390 - window.innerWidth + 32;
          const endPosYTop = 707 - window.innerHeight + 32;

          dropPos.x = Pointer.getRealX()
          dropPos.y = Pointer.getRealY()

          const DrawX = (pos) => {
            if (endPosXLeft > pos) {
              return endPosXLeft
            }
            if (pos > 0) {
              return 0
            } 
            return pos
          }

          const DrawY = (pos) => {
            if (endPosYTop > pos) {
              return endPosYTop
            }
            if (pos > 0) {
              return 0
            } 
            return pos
          }

          updateDragItemX(DrawX(startItemPos.x + (dropPos.x - startPos.x)));
          updateDragItemY(DrawY(startItemPos.y + (dropPos.y - startPos.y)));
          updateDragBtnX(DrawX(startBtnPos.x + (dropPos.x - startPos.x)));
          updateDragBtnY(DrawY(startBtnPos.y + (dropPos.y - startPos.y)));
        },
        onDragEnd: () => {
        },
      });
    }

    // slider.js

    let CB_RESIZE_COMMON;
    onMounted(() => {
      commit("set", { sel: "chatbot.isVisited", to: true });
      setClock();
      setClockInt = setInterval(() => {
        setClock();
      }, 60000);
      getJobOpeningsNum();
      setDataByLang();
      $.removeClass("main-head--scroll",  selfHead.value);
      watch(
        () => state.chatbot.fromContact,
        (curr, prev) => {
          // console.log("fromContact", state.chatbot.fromContact);
          if (state.chatbot.fromContact[0] !== null && state.chatbot.fromContact[0] !== undefined) {
            let item;
            const findItemByContact = (listData) => {
              _.go(listData,
                _.each(list => {
                  if (list.name == state.chatbot.fromContact[0]) {
                    item = list;
                  }
                })
              )
            }
            if (state.chatbot.fromContact[1] == 'main') {
              findItemByContact(data.mainCardList);
            } else if (state.chatbot.fromContact[1] == 'job') {
              findItemByContact(data.jobOpenings);
            } else if (state.chatbot.fromContact[1] == 'channel') {
              findItemByContact(data.channel);
            }

            // chatbot 카드가 이미 열려있을 때 contact에서 또 누르는 경우
            // if ($.hasClass("subpage-wrap--open", $.qs(".subpage-wrap"))) {
            //   // 기존 subpage 닫고 다시 열기, 기존 subpage 스크롤 위치 기억
            //   methods.setSubpageClass();
            // }
            methods.setComponentName(item, state.chatbot.fromContact[0]);
          }
        }
      );
      watch(
        () => state.chatbot.scrollLockState,
        (curr, prev) => {
					if (state.chatbot.scrollLockState) {
						$.addClass("scroll-lock", mainCont.value)
            document.querySelector('.c-chatbot').ontouchmove = e => e.preventDefault()
					} else {
						$.removeClass("scroll-lock", mainCont.value)
            document.querySelector('.c-chatbot').ontouchmove = () => true
					}
        }
      );
      watch(
        () => state.chatbot.mapState.name,
        (curr, prev) => {
          setSubSubpageByMediaType('map');
        }
      )
      watch(
        () => state.chatbot.videoState.name,
        (curr, prev) => {
          setSubSubpageByMediaType('video');
        }
      )

      setTimeout(() => dragDrop(), 500);

      CB_RESIZE_COMMON = (e) => {
        if ($.hasClass("c-chatbot--open", $.qs(".c-chatbot"))) {
          if (e.type === "resize" && !state.isMobileAgent) {
            methods.closeChatbot();
          } else if (e.type === "resize.canvas.x" && state.isMobileAgent) {
            methods.closeChatbot();
          }
        }
      }


      // swiper.js
      const CB_LANDED = () => {
				// console.log("111", channelDrag.value);
        // const target = document.querySelector('.main-channel__card-wrap')
				const slider = new DragSlider({
					el: channelDrag.value,
					align() {
						return "left";
					},
					onInit(instance) {
					},
					onRefresh(el) {
						// UpdateProgbar(0);
					},
					onEarlySnapComplete(el, prevIndex, currIndex) {
					},
					onUpdate(p) {
            // data.mouseOnChannel = target.classList.contains('c-drag--dragging') ? true : false
            // console.log('on update', target.classList.contains('c-drag--dragging'))
						// UpdateProgbar(p);
					}
				});
				// console.log("222");
			};

      window.addEventListener('resize', (e) => CB_RESIZE_COMMON(e))
      window.addEventListener("resize.canvas.x", (e) => CB_RESIZE_COMMON(e));
      if(state.pageState != "landed") {
				// window.addEventListener("changed.mq", CB_CHANGED_MQ);
				window.addEventListener("landed", CB_LANDED, { once: true });
			} else {
				// CB_CHANGED_MQ();
				CB_LANDED();
			}
		});

    onActivated(() => {
      const main = $.qs(".main-wrap");
      main.addEventListener('scroll', scrollCallback);
    });
    onDeactivated(() => {
      const main = $.qs(".main-wrap");
      main.addEventListener('scroll', scrollCallback);
    })

    onUnmounted(() => {
      clearInterval(setClockInt)
    })

    watchEffect(() => {
      setDataByLang();
		},{ flush: 'post' });

    return {
      data,
      methods,
      self,
      mainCont,
      selfHead,
      selfHeadOut,
      channelDrag
    }
  }

}
</script>

<style lang="scss" src="@/css/chatbot.scss"></style>