<template>
  <div class="giphy" :class="`giphy--${data.idWithHyphen}`">
    <img :src="data.src"  alt="" class="giphy__img" @load="$emit('transmit', data.responseData)">
  </div>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { reactive, computed, watchEffect, onBeforeUpdate, onUpdated, onMounted, useStore } from "@/helper/vue.js";
import { addEvent, removeEvent } from "@/helper/event.js";

export default {
  props: {
    id: String,
  },
  emits: ['endLoad', 'transmit'],
  setup(props, { emit }) {
    const { state, get, commit, dispatch } = useStore();

    const data = reactive({
      responseData: {},
      pageUrl: '',
      src: '#',
      ratio: '',
      idWithHyphen: props.id.replace(/ /g, '-'),
      idWithPlus: props.id.replace(/ /g, '+')
    });

    onMounted(() => {
      const request = dispatch('giphy/fetchGiphy', data.idWithPlus).then(response => {
        data.responseData = response;
        data.pageUrl = response.pageUrl;
        data.src = response.url;
        data.ratio = response.ratio;
      });
      commit("loadManager/addQueue", request);
    });

    const setPaddingTop = () => {
      _.go(
        $.qsa(`.giphy--${data.idWithHyphen}`),
        _.each((giphy) => {
          $.setCss(
            {
              "padding-top": `${data.ratio * 100}%`
            }, giphy
          )
        })
      )
    }
    watchEffect(() => {
      setPaddingTop();
    },{ flush: 'post' })

    return {
      data,
    }
  }
}
</script>

<style>
.giphy {
  position: relative;
  width: 100%;
}
.giphy__img {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
}
</style>