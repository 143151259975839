<template>
    <div id="app-drawing-line" ref="self">
      <svg ref="svg">
        <circle ref="ref_circle" id="pointer-circle" cx="500" cy="500" r="8"></circle>
        <polyline ref="ref_polyline" id="drawing-polyline" points=""></polyline>
        <svg ref="ref_marker" class="marker-circs">
          <circle class="bg-circs__fill" cx="48" cy="48" r="48" />
        </svg>
      </svg>
      <div class="c-cursor-txt">
        <span class="c-cursor-txt__video">PLAY<br>VIDEO</span>
        <span class="c-cursor-txt__drag">DRAG<br>AND CLICK</span>
      </div>
    </div>
</template>

<script>
import * as _ from "fxjs";
import { ref, watch, onMounted, onUnmounted, useStore } from "@/helper/vue.js";


export default {
  setup() {
    const { state } = useStore();

    const self = ref();
    const svg = ref();
    const ref_polyline = ref();
    const ref_circle = ref();
    const ref_marker = ref();

    const method = {
      isTouchDevice() {
        return (('ontouchstart' in window) ||
          (navigator.maxTouchPoints > 0) ||
          (navigator.msMaxTouchPoints > 0));
      },
      loadingEventListener(e){
        markerButtons = document.querySelectorAll('a, button, .video, .drag, .btn-ripple');
        switch(e.type){
          case 'loader.complete':
            console.log(`%c Add mouseEvent :: ${markerButtons.length} buttons in page`, `background: #222; color: #00ffff`);
            markerButtons.forEach(button => {
              button.setAttribute("data-draw-line", true);
              button.addEventListener('mouseover', markerMouseEventHandler);
              button.addEventListener('mouseout', markerMouseEventHandler);
            })
            document.querySelectorAll('.video a, .drag a').forEach(button => {
              button.removeEventListener('mouseover', markerMouseEventHandler);
              button.removeEventListener('mouseout', markerMouseEventHandler);
            });
            markerMouseEventHandler(new MouseEvent('mouseout'));
            break;
          case 'ready.curtain':
            console.log(`%c Remove mouseEvent :: ${markerButtons.length} buttons in page`, `background: #222; color: #00ffff`);
            markerButtons.forEach(button => {
              button.removeAttribute("data-draw-line");
              button.removeEventListener('mouseover', markerMouseEventHandler);
              button.removeEventListener('mouseout', markerMouseEventHandler);
            })
            break;
        }
      },
      onChangeDom(){
        markerButtons = document.querySelectorAll('a:not([data-draw-line]), button:not([data-draw-line]), .video:not([data-draw-line]), .drag:not([data-draw-line]), .btn-ripple:not([data-draw-line])');
        markerButtons.forEach(button => {
          button.setAttribute("data-draw-line", true);
          button.addEventListener('mouseover', markerMouseEventHandler);
          button.addEventListener('mouseout', markerMouseEventHandler);
        })
        document.querySelectorAll('.video a, .drag a').forEach(button => {
          button.removeAttribute("data-draw-line");
          button.removeEventListener('mouseover', markerMouseEventHandler);
          button.removeEventListener('mouseout', markerMouseEventHandler);
        });
        markerMouseEventHandler(new MouseEvent('mouseout'));
        console.log(`%c Add new mouseEvent :: ${markerButtons.length} buttons in page`, `background: #222; color: #00ffff`);
      }
    }

    let markerButtons;
    let mousemoveEventHandler;
    let mouseupEventHandler;
    let mousedownEventHandler;
    let markerMouseEventHandler;

    window.addEventListener('loader.complete', method.loadingEventListener);
    window.addEventListener('ready.curtain', method.loadingEventListener);

    onMounted(() => {
      var polyline = ref_polyline.value;
      var polyPoints = polyline.getAttribute('points');

      var circle = ref_circle.value;
      var circleX = circle.getAttribute('cx');
      var circleY = circle.getAttribute('cy');
      var circleR = circle.getAttribute('r');

      var marker = ref_marker.value;
      var markerX = marker.getAttribute('x');
      var markerY = marker.getAttribute('y');

      var total = 12;
      var gap = 30;
      var ease = 0.5;
      var debounce_removeLine;
      var debounce_counter = 0;

      var pointer = {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
        tx: 0,
        ty: 0,
        dist: 0,
        scale: 1,
        speed: 2,
        circRadius: 8,
        updateCrds: function() {
          if (this.x != 0) {
            //this.dist = Math.sqrt(Math.pow(this.x - this.tx, 2) + Math.pow(this.y - this.ty, 2));
            this.dist = Math.abs((this.x - this.tx) + (this.y - this.ty));
            this.scale = Math.max(this.scale + ((100 - this.dist * 8)*0.01 - this.scale) * 0.1, 0.25); // gt 0.25 = 4px
            this.tx += (this.x - this.tx) / this.speed;
            this.ty += (this.y - this.ty) / this.speed;
          }
        }
      };

      var points = [];

      mousemoveEventHandler = (e) => {
        pointer.x = e.clientX;
        pointer.y = e.clientY;
        debounce_counter = 0;
        drawLine();

        // debounce
        clearTimeout(debounce_removeLine);
        debounce_removeLine = setTimeout(() => {
            //console.log('debounce_removeLine', new Date().getTime());
            debounce_counter = 12;
            drawLine();
        }, 80);
      }

      mousedownEventHandler = (e) => {
        marker.classList.add('press');
        pointer.circRadius = 6;
        drawLine();
      }

      mouseupEventHandler = (e) => {
        marker.classList.remove('press');
        pointer.circRadius = 8;
        drawLine();
      }

      markerMouseEventHandler = (e) => {
        const t = e.currentTarget;
        switch(e.type){
          case 'mouseover':
            if(t.classList.contains('video')){
              marker.classList.add('video');
              self.value.classList.add('video');
              marker.classList.remove('drag');
              self.value.classList.remove('drag');
              //self.value.classList.add('link'); // temp class: 확정 후 재 조정
            }else if(t.classList.contains('drag')){
              marker.classList.add('drag');
              self.value.classList.add('drag');
              marker.classList.remove('video');
              self.value.classList.remove('video');
              //self.value.classList.add('link'); // temp class: 확정 후 재 조정
            }else if(t.tagName == "A" || t.tagName == "BUTTON" /*&& t.classList.contains('__no-ripple')*/) {
              self.value.classList.add('link');
            }else if(t.classList.contains('btn-ripple') && !t.classList.contains('__no-ripple')){
              self.value.classList.add('ripple');
              pointer.circRadius = 0;
            }
            total = 0;
            drawLine();
            break;
          case 'mouseout':
            marker.classList.remove('video');
            marker.classList.remove('press');
            marker.classList.remove('drag');
            self.value.classList.remove('link');
            self.value.classList.remove('drag');
            self.value.classList.remove('video');
            if(t && t.classList.contains('btn-ripple')){
              self.value.classList.remove('ripple');
              pointer.circRadius = 8;
            }
            total = 12;
            drawLine();
            break;
        }
      }

      watch(
        () => state.changeDomCount,
        (cur, prev) => {
            method.onChangeDom();
        }
      );

      window.addEventListener("mousemove", mousemoveEventHandler);
      window.addEventListener("mousedown", mousedownEventHandler);
      window.addEventListener("mouseup", mouseupEventHandler);




      function drawLine(){
        pointer.updateCrds();

        points.push({x:pointer.tx, y:pointer.ty});
        while (points.length > total) {
          points.shift();
          if (points.length > gap) {
            for (var i = 0; i < 5; i++) {
              points.shift();
            }
          }
        }
        var pointsArr = points.map(point => `${point.x},${point.y}`);
        polyPoints = pointsArr.join(' ');
        polyline.setAttribute('points', polyPoints);

        // circle
        circleX = pointer.x;
        circleY = pointer.y;
        circleR = pointer.scale * pointer.circRadius;

        circle.setAttribute('cx', circleX);
        circle.setAttribute('cy', circleY);
        circle.setAttribute('r', circleR);

        if(debounce_counter > 0){
          debounce_counter --;
          requestAnimationFrame(drawLine);
        }

        // marker
        markerX = pointer.x - 48;
        markerY = pointer.y - 48;
        marker.setAttribute('x', markerX);
        marker.setAttribute('y', markerY);

        // self.value.querySelector(".c-cursor-txt").setAttribute('x', markerX);
        // self.value.querySelector(".c-cursor-txt").setAttribute('y', markerY);
        self.value.querySelector(".c-cursor-txt").style.transform = `translate(calc(-50% + ${circleX}px), calc(-50% + ${circleY}px))`;
      }
      drawLine();
    });


    onUnmounted(() => {
      window.removeEventListener("mousemove", mousemoveEventHandler);
      window.removeEventListener("mousedown", mousedownEventHandler);
      window.removeEventListener("mouseup", mouseupEventHandler);
      window.removeEventListener('loader.complete', method.loadingEventListener);
      window.removeEventListener('ready.curtain', method.loadingEventListener);
    });


    return {
      self,
      svg,
      ref_polyline,
      ref_circle,
      ref_marker,
      method
    };
  }
}

</script>

<style>
#app-drawing-line {
  position: relative;
  z-index: 1500;
  height: 0;
}
#app-drawing-line > svg {
  position: fixed;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}
#app-drawing-line > svg:nth-child(2) {
  z-index: -1;
}

#app-drawing-line > svg #drawing-polyline {
  fill: none;
  stroke: #D2330F;
  stroke-width: 2;
}
#app-drawing-line > svg #pointer-circle {
  fill: #D2330F;
}
#app-drawing-line > svg .marker-circs .txt-video,
#app-drawing-line > svg .marker-circs .txt-drag {display: none;}
#app-drawing-line > svg .marker-circs.video .txt-video {display: block;}
#app-drawing-line > svg .marker-circs.video .txt-drag {display: none;}
#app-drawing-line > svg .marker-circs.drag .txt-video {display: none;}
#app-drawing-line > svg .marker-circs.drag .txt-drag {display: block;}
#app-drawing-line > svg .marker-circs .bg-circs__fill {r:0px; fill: #D2330F; transition: r 200ms cubic-bezier(0.22, 0.61, 0.36, 1);}
#app-drawing-line > svg .marker-circs.video .bg-circs__fill,
#app-drawing-line > svg .marker-circs.drag .bg-circs__fill {r: 48px;}
#app-drawing-line > svg .marker-circs.video.press .bg-circs__fill,
#app-drawing-line > svg .marker-circs.drag.press .bg-circs__fill {r: 32px;}

#app-drawing-line.link,
#app-drawing-line.ripple {mix-blend-mode: difference;}

#app-drawing-line.link .marker-circs text,
#app-drawing-line.ripple .marker-circs text {display: none;}
#app-drawing-line.link .marker-circs .bg-circs__fill {r: 24px;}
#app-drawing-line.ripple .marker-circs .bg-circs__fill {r: 0px;}

.c-cursor-txt {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  font-size: 13px;
  color: #fff;
  z-index: 1;
  text-align: center;
  font-family: "Faktum";
  font-weight: 400;
  line-height: 1.18;
  letter-spacing: -0.04em;
}
.c-cursor-txt__video, .c-cursor-txt__drag {
  display: none;
}
.video .c-cursor-txt__video {
  display: inline;
}
.drag .c-cursor-txt__drag {
  display: inline;
}

@supports (-webkit-hyphens:none) {
  #app-drawing-line.link,
  #app-drawing-line.ripple {mix-blend-mode: unset;}
  #app-drawing-line.link .marker-circs .bg-circs__fill {opacity:0.8}
  #app-drawing-line.link > svg #pointer-circle {display:none;}
}

.s--custom-cursor,
.s--custom-cursor a,
.s--custom-cursor button {
  cursor: none;
}

</style>