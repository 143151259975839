<template>
  <div ref="self" class="loader">
		<div class="loader__layer" :class="[
			{ 'loader__layer--front': data.frontLayer == 1 },
			{ 'loader__layer--back': data.frontLayer == -1 }
		]">
			<div class="loader__title">
				<component :is="data.layerTitle1"></component>
			</div>
		</div>
		<div class="loader__layer loader__layer--bg">
			<div class="loader__bg-slider"></div>
		</div>
		<div class="loader__layer loader__layer--mobile-header">
			<HeaderMobile v-show="data.isMobileSize"/>
		</div>
		<div class="loader__layer" :class="[
			{ 'loader__layer--front': data.frontLayer == -1 },
			{ 'loader__layer--back': data.frontLayer == 1 }
		]">
			<div class="loader__title">
				<component :is="data.layerTitle2"></component>
			</div>
		</div>
		<div class="loader__minime">
			<Minime
				v-if="data.isLoading && !data.splash"
				:position="['center', 'auto', 'auto', 'center']"
				:is-always-fast="true"
			/>
		</div>

		<div v-if="data.splash" class="splash">
			<div class="splash__title">
				<MarqueeText class="l-mdl--16" timeScale="1">
					<template v-slot:default>
						<span class="t-h--128 t-h--54s t-h--200l splash__title-item">
							<span class="t--fak splash__title-txt splash__title-txt-dfy">DEFY THE CURRENT<span class="t--fak splash__tm">TM</span></span>
							<span class="t--fak splash__title-txt"><i class="c-gear"><svg class="c-gear__svg" width="90" height="91" viewBox="0 0 90 91" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M47.7 0.5H42.3V18.5L43.74 42.62L27.72 24.44L15.12 11.84L11.34 15.62L24.12 28.4L42.3 44.42L18 42.8H0V48.2H18L42.12 46.76L23.94 62.78L11.16 75.56L14.94 79.34L27.72 66.56L43.74 48.38L42.3 72.5V90.5H47.7V72.5L46.26 48.38L62.28 66.56L75.06 79.34L78.84 75.56L66.06 62.78L47.88 46.76L72 48.2H90V42.8H72L47.88 44.24L66.06 28.22L78.84 15.44L75.06 11.66L62.28 24.44L46.26 42.62L47.7 18.5V0.5Z" fill="#fff"/></svg></i></span>
						</span>
					</template>
				</MarqueeText>
			</div>
			<div class="splash__bot">
				<div class="splash__bar"></div>
				<div class="splash__year">
					<span class="t-h--54 t-h--28s"><span class="t--fak splash__year-txt">2011</span></span>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { ref, reactive, computed, defineAsyncComponent, watch, onMounted, onUnmounted, onBeforeUnmount, useStore } from "@/helper/vue.js";
import router from "@/router/index.js";
import { ResizeObserver } from '@juggle/resize-observer';
import { locoScroll } from "@/js/common.js";
import { gsap } from "gsap/all";
import { getPinnedScroll, normalize, stPos, easing, setScroll } from "@/helper/gadget.js";
import { isSplashOn } from "@/store/appSettings.js";
import BlankTitle from "@/parts/title/BlankTitle.vue";
import MarqueeText from "@/parts/MarqueeText.vue";
import HeaderMobile from "@/parts/header/HeaderMobile.vue";
import { getWorkWithUrl } from '@/store/data-workList.js';
import Minime from "@/parts/minime/Minime.vue";

export default {
  components: {
		BlankTitle,
		MarqueeText,
		HeaderMobile,
		Minime,
		HomeTitle: defineAsyncComponent(() => import("@/parts/title/HomeTitle.vue")),
		WorkMainTitle: defineAsyncComponent(() => import("@/parts/title/WorkMainTitle.vue")),
		ProjectTitle: defineAsyncComponent(() => import("@/parts/title/ProjectTitle.vue")),
		AboutTitle: defineAsyncComponent(() => import("@/parts/title/AboutTitle.vue")),
		ContactTitle: defineAsyncComponent(() => import("@/parts/title/ContactTitle.vue")),
		WorkDetailTitle: defineAsyncComponent(() => import("@/pages/work/_SubLandingTitle.vue")),
		ServicesTitle: defineAsyncComponent(() => import("@/parts/title/ServicesTitle.vue")),
  },
	setup() {
    const { state, commit } = useStore();
		const self = ref();
		const data = reactive({
			layerTitle1: "BlankTitle",
			layerTitle2: "BlankTitle",
			frontLayer: 1,
			splash: isSplashOn,
			isLoading: false,
			isMobileSize: computed(() => state.isMobileSize)
		});

		let bgSlider;
		let targetContentColor = ["171010", "F9F5EF", "F9F5EF"];
		const CB_BG_SLIDE = () => {
			// 다음페이지 받을 준비 됐다고 알림
			window.scrollTo(0, 0);
			$.addClass("s--body-lock", $.qs("html"));

			// 막이 완전히 올라온 후에 타겟 페이지의 색상 정보를 변경해준다.
			if((data.frontLayer == 1 && data.layerTitle1 == "ProjectTitle") || data.frontLayer == -1 && data.layerTitle2 == "ProjectTitle") {

				const { color } = state.project.currentMeta;

				document.documentElement.style.setProperty("--foreground", "#" + color[0]);
				document.documentElement.style.setProperty("--background", "#" + color[1]);
			}
			else {
				commit("set", { sel: "project.currentMeta.contentColor", to: targetContentColor});
				document.documentElement.style.setProperty("--foreground", "#" + targetContentColor[0]);
				document.documentElement.style.setProperty("--background", "#" + targetContentColor[1]);
				document.documentElement.style.setProperty("--canvasColor", "#" + targetContentColor[2]);
				targetContentColor = ["171010", "F9F5EF", "F9F5EF"];
			}
			window.dispatchEvent(new CustomEvent("ready.curtain"));
		};

		const CB_BG_OPACITY = () => {
			$.addClass("s--title-ready", $.qs("html"));
			$.removeClass("s--curtain-fade s--show-curtain s--loading s--body-lock", $.qs("html"));
			window.scrollTo(0, 0);
			data.frontLayer *= -1;
			data.isLoading = false;
			_.go(
				$.qsa(".loader__title--show", self.value),
				_.each(item => {
					$.removeClass("loader__title--show", item);
					item.offsetHeight;
				})
			);
			window.dispatchEvent(new CustomEvent("loader.complete"));
			window.scrollTo(0, 0);
			locoScroll && locoScroll.start();
		};

		const CB_LANDED = () => {

			// 스플래시 ------------
			if(data.splash) {
				document.documentElement.style.setProperty('--titleHeight', $.qs(".loader__layer--back .loader__title", self.value).clientHeight + "px");
				return;
			}
			// 스플래시 ------------

			$.addClass("s--curtain-fade", $.qs("html"));
			if(data.frontLayer == 1) {
				data.layerTitle2 = "BlankTitle";
			}
			else {
				data.layerTitle1 = "BlankTitle";
			}
			document.documentElement.style.setProperty('--titleHeight', $.qs(".loader__layer--front .loader__title", self.value).clientHeight + "px");
			setScroll(0);

			// 로딩 스테이트 동기화
			commit("set", { sel: "loadingState", to: "startFadeout" });
			// 페이드아웃 끝나면 layer swap, html class 제거
			bgSlider.addEventListener("transitionend", CB_BG_OPACITY, { once: true });
		};

		const CB_PREPARE_ENTER = async({ detail }) => {

			await state.earlyLoadPromise;
			// 완전히 실행되기 전에 라우터 이동을 하면 타이틀 전면/후면을 스왑해준다.
			if(data.isLoading) {
				data.frontLayer *= -1;
				data.isLoading = false;
			}
			else {
				data.isLoading = true;
			}

			let { loadingColor, contentColor, titleComponent } = detail.meta;
			const currentTitle = $.qs(".loader__layer--front .loader__title", self.value);

			// 서비스 / 인터레스트 랜딩페이지
			if(titleComponent == 'WorkDetailTitle') {
				let category = -1;
				let tag = -1;
				let background = "F9F5EF";
				switch(detail.params.id) {
					case "digital-products-platforms": category = 0; background = "F6E8CA"; break;
					case "branded-ecommerce": category = 1; background = "C0E8F4"; break;
					case "branding-strategy-design": category = 2; background = "D1DAFA"; break;
					case "branded-experience": category = 3; background = "D4E9E1"; break;
					case "human-technology": tag = 0; break;
					case "new-way-to-retail": tag = 1; break;
					case "ready-to-launch": tag = 2; break;
					case "being-social": tag = 3; break;
					case "purposeful-spaces": tag = 4; break;
					case "tomorrow-today": tag = 5; break;
					case "stories-that-inspire": tag = 6; break;
					case "samsung-galaxy": tag = 7; break;
				}
				if(category != -1) {
					commit("workList/setFilter", { type: "category", id: category });
      	}
				if(tag != -1) {
        commit("workList/setFilter", { type: "tag", id: tag });
	      }

				loadingColor = ["171010", background];
				contentColor = ["171010", background, background];
			}

			// 워크 상세 페이지일 경우 로딩 컬러 설정
			if(titleComponent == "ProjectTitle") {
				let work = null;
				if(detail.query["preview"] == 1) {
					work = getWorkWithUrl(detail.params.id, "uuid");
				}
				else {
					work = getWorkWithUrl(detail.params.id);
				}

				if(work == null) {
					router.push({ path: "/404" });
					return;
				}

				commit("set", { sel: "project.currentMeta", to: { client: work.client, brand: work.brand, color: work.color, type: work.type, kvLayout: work.kvLayout } });

				$.setCss({ color: work.color[0] ? "#" + work.color[0] : "" }, currentTitle);
				$.setCss({ backgroundColor: work.color[1] ? "#" + work.color[1] : "" }, bgSlider);
			}
			// 일반 로딩 컬러 설정
			else {
				console.log("loadingColor loadingColor - ", titleComponent);
				if(loadingColor) {
					$.setCss({ color: loadingColor[0] ? "#" + loadingColor[0] : "" }, currentTitle);
					$.setCss({ backgroundColor: loadingColor[1] ? "#" + loadingColor[1] : "" }, bgSlider);
				}
				else {
					$.setCss({ color: "" }, currentTitle);
					$.setCss({ backgroundColor: "" }, bgSlider);
				}
				targetContentColor = contentColor ? contentColor : targetContentColor;
			}

			// 스플래시 ------------
			if(data.splash) {
				data.layerTitle1 = titleComponent;
				data.frontLayer *= -1;
				data.isLoading = false;

				locoScroll && locoScroll.stop();

				$.qs(".splash__bot", self.value).addEventListener("transitionend", () => {
					// 막이 완전히 올라온 후에 타겟 페이지의 색상 정보를 변경해준다.
					if(titleComponent == "ProjectTitle") {

						const { color } = state.project.currentMeta;

						document.documentElement.style.setProperty("--foreground", "#" + color[0]);
						document.documentElement.style.setProperty("--background", "#" + color[1]);
					}
					else {
						commit("set", { sel: "project.currentMeta.contentColor", to: targetContentColor});
						document.documentElement.style.setProperty("--foreground", "#" + targetContentColor[0]);
						document.documentElement.style.setProperty("--background", "#" + targetContentColor[1]);
						document.documentElement.style.setProperty("--canvasColor", "#" + targetContentColor[2]);
						targetContentColor = ["171010", "F9F5EF", "F9F5EF"];
					}
					window.dispatchEvent(new CustomEvent("ready.curtain"));
				}, { once: true });

				setTimeout(() => {
					$.addClass("s--splash-start s--title-ready", $.qs("html"));
				});

				window.addEventListener("landed", CB_LANDED, { once: true });
				return;
			}
			// 스플래시 ------------

			// 1. 반복호출 영향없는 작업들 먼저 처리
			// 타이틀 비동기 로드
			if(data.frontLayer == 1) {
				data.layerTitle1 = titleComponent;
			}
			else {
				data.layerTitle2 = titleComponent;
			}
			// 스크롤 멈추고
			locoScroll && locoScroll.stop();

			// 2. 반복호출에서 초기화 되어야 하는 것들 처리
			// 안전하게 이벤트 먼저 제거해주고, 클래스를 제거해준다.
			bgSlider.removeEventListener("transitionend", CB_BG_SLIDE);
			bgSlider.removeEventListener("transitionend", CB_BG_OPACITY);
			window.removeEventListener("landed", CB_LANDED);
			$.removeClass("s--show-curtain s--title-ready s--curtain-fade", $.qs("html"));
			bgSlider.offsetHeight;
			_.go(
				$.qsa(".loader__title--show", self.value),
				_.each(item => {
					$.removeClass("loader__title--show", item);
					item.offsetHeight;
				})
			);

			// 로딩 클래스 넣어주고, 레이어 슬라이드 시키고
			$.addClass("s--show-curtain s--loading", $.qs("html"));
			// 타이틀 등장 모션을 위한 클래스 추가
			$.addClass("loader__title--show", $.qs(".loader__layer--front .loader__title", self.value));

			// 레이어 슬라이드 끝나면
			bgSlider.addEventListener("transitionend", CB_BG_SLIDE, { once: true });
			// 다음페이지 준비 완료되면(landed) 레이어 슬라이드 페이드아웃, 뒷면 제거
			window.addEventListener("landed", CB_LANDED, { once: true });
			// window.addEventListener("landed", () =>  {
			// 	$.addClass("s--curtain-fade", $.qs("html"));
			// 	if(data.frontLayer == 1) {
			// 		data.layerTitle2 = "BlankTitle";
			// 	}
			// 	else {
			// 		data.layerTitle1 = "BlankTitle";
			// 	}
			// 	// 페이드 아웃 시작되기 전에 스무스스크롤 상단으로 초기화
			// 	locoScroll.scrollTo("top", { duration: 0, disableLerp: true });
			// 	// 페이드아웃 끝나면 layer swap, html class 제거
			// 	bgSlider.addEventListener("transitionend", CB_BG_OPACITY, { once: true });
			// }, { once: true });
		};

		let progressTw;
		let progress = 0;
		onMounted(() => {
			$.appendTo($.qs("body"), $.qs(".loader__minime", self.value));
			bgSlider = $.qs(".loader__bg-slider", self.value);

			window.addEventListener("landed", () => {
				gsap.timeline({
					scrollTrigger: {
						group: 'titleSync',
						trigger: ".i-canvas",
						start: e => stPos(e, 0, 0),
						end: 50000,
						scrub: 0,
						onUpdate({ progress, start, isActive }) {
							!state.isMobileAgent && gsap.set($.qsa(".loader__layer", self.value), { y: getPinnedScroll(start) });
						},
					}
				});
			}, { once: true });

			// 타이틀의 크기에 따라서 본문 컨텐츠 상단 마진을 줌
			const RO = new ResizeObserver(targets => {
				// 로딩중 타이틀 로딩이 완료된 시점에 적용
				if($.hasClass("loader__layer--front", targets[0].target)) {
					window.addEventListener("ready.curtain", () => {
						document.documentElement.style.setProperty('--titleHeight', targets[0].target.clientHeight + "px");
					}, { once: true });
				}
				if($.qs("html.s--title-ready")) {
					if(targets.length > 1) {
						setTimeout(() => {
							document.documentElement.style.setProperty('--titleHeight', Math.max(targets[0].target.clientHeight, targets[1].target.clientHeight) + "px");
						});
					}
				}
			});
			RO.observe($.qs(".loader__layer--front"));
			RO.observe($.qs(".loader__layer--back"));

			window.addEventListener("prepare.enter", CB_PREPARE_ENTER);

			// 스플래시 설정
			if(state.isFirstLanding) {
			}

			watch(
			() => state.loadingProgress,
				dest => {
					if(!data.splash) {
						$.removeClass("s--before-splash s--hide-content s--splash-end", $.qs("html"));
						return;
					}
					const isPcL = $.hasClass("w1600__", $.qs('html'))
					const loadingW = isPcL ? 90.55 : state.isMobileSize ? 77.25 : 85.05;
					progressTw && progressTw.kill();
					const start = progress;
					const end = dest;
					progressTw = gsap.to({}, {
						onUpdate(){
							progress = start + (end - start) * easing(this.progress(), "easeOutCubic");
							gsap.set($.qs(".splash__bar"), { width: `${progress * loadingW}%` });
							let year = 2011 + Math.floor(normalize(progress, 0, 0.9) * (new Date().getFullYear() - 2011));
							$.qs(".splash__year-txt", self.value).innerText = year;
						},
						onComplete(){
							if(progress == 1) {
								if(state.pageState == "landed") {
									$.removeClass("s--hide-content", $.qs("html"));
									// 완료 화면 잠시 유지(180ms)
									setTimeout(() => {
										// return;
										$.addClass("s--splash-end", $.qs("html"));
										$.qs(".splash", self.value).addEventListener("transitionend", () => {
											data.splash = false;
											$.removeClass("s--splash-start s--before-splash s--splash-end", $.qs("html"));
											window.dispatchEvent(new CustomEvent("loader.complete"));
											locoScroll && locoScroll.start();
										}, { once: true });
									}, 180);
								}
							}
						},
					duration: 1.3 });
				}
			);
		});

		onBeforeUnmount(() => {
			window.removeEventListener("prepare.enter", CB_PREPARE_ENTER);
		});

		return {
			self,
			data
		}
	}
}
</script>

<style scoped lang="scss">
.loader {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 1200;
	&__minime {
		position: fixed;
		left: 50%;
		top: 65vh;
		transform: translate(-50%, -50%);
		z-index: 1210;
		opacity: 0;
		@media screen and (max-width: 599px) {
			top: 55vh;
		}
		.s--show-curtain & {
			transition: opacity 0.5s 0s ease;
			opacity: 1;
		}
		.s--curtain-fade & {
			transition: opacity 0.25s ease;
			opacity: 0;
		}
	}
	&__layer {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		&--front {
			z-index: 50;
			.loader__title {
				opacity: 0;
				transform: translate3d(0, 25em, 0);
			}
			.loader__title--show {
				opacity: 1;
				transform: translate3d(0, 0, 0);
				transition: transform 0.5s 0.35s ease, opacity 0.5s 0.35s ease;
			}
		}
		&--bg {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 40;
		}
		&--mobile-header {
			z-index: 30;
		}
		&--back {
			transform: translate3d(0, 0, 0) !important;
			z-index: 20;
			.loader__title {
				opacity: 1;
				transform: translate3d(0, 0, 0);
			}
		}
	}
	&__bg-slider {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100vh;
		// height: var(--vh);
		background-color: #171010;
		transform: translateY(100%);
		visibility: hidden;
		.s--show-curtain & {
			transform: translateY(0);
			visibility: visible;
			transition: transform 0.6s cubic-bezier(0.4, 0, 0,1);
		}
		.s--curtain-fade & {
			opacity: 0;
			transition: opacity 0.5s;
		}
	}
	.s--no-smooth-scroll & {
		position: relative;
		.loader__layer--front {
			position: fixed;
		}
		.loader__layer--bg {
			position: fixed;
		}
		.loader__layer--mobile-header {
			position: fixed;
		}
	}
	.__w600 & {
		z-index: 1200;
	}
}

.splash {
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: #171010;
	color: #EBE3D7;
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	transition: clip-path 0.5s cubic-bezier(0.4, 0, 0,1);
	pointer-events: none;
	overflow: hidden;
	z-index: 99999;
	font-size: 0.09765vw;
	@media screen and (min-width: 1600px) {
		font-size: 0.0625vw;
	}
	@media screen and (max-width: 599px) {
		font-size: 0.25641vw;
	}
	.s--no-smooth-scroll & {
		position: fixed;
		height: 100%;
	}
	.c-gear__svg path { fill: #EBE3D7; }
	&__title {
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		// font-size: 200em;
		transform: translate3d(0, -50%, 0);
		opacity: 0;
		transition: opacity 0.45s 0s cubic-bezier(0.4, 0, 0,1);
		.mqt {
			margin-top: 0;
		}
	}
	// &__title-item {
	// 	// padding-left: var(--outerGutter);
	// }
	&__title-txt {
		position: relative;
		font-size: 128em !important;
		@media screen and (min-width: 1600px) {
			font-size: 200em !important;
		}
		@media screen and (max-width: 599px) {
			font-size: 54em !important;
		}
	}
	&__title-txt-dfy {
		margin-right: -0.09em;
	}
	&__tm {
		position: absolute;
    left: 100%;
    transform: scale(0.075) translateX(-39.3%);
    display: inline-block;
    transform-origin: left bottom;
    bottom: 22.5%;
	}
	&__bot {
		position: absolute;
		left: var(--gutter);
		right: var(--gutter);
		bottom: var(--gutter);
		opacity: 0;
		transition: opacity 0.45s 0s cubic-bezier(0.4, 0, 0,1);
		white-space: nowrap;
		@media screen and (max-width: 599px) {
			left: 16em;
			right: 16em;
			bottom: 16em;
		}
	}
	&__bar {
		display: inline-block;
		width: 0;
		height: 1px;
		margin-right: 4em;
		background-color: #EBE3D7;
	}
	&__year {
		display: inline-block;
	}
	&__year-txt {
		font-size: 54em;
		&:before {
			content: "©";
		}
		@media screen and (max-width: 599px) {
			font-size: 28em !important;
		}
	}
	@at-root .s--splash-start {
		.splash__title {
			opacity: 1;
		}
		.splash__bot {
			opacity: 1;
		}
	}
	@at-root .s--splash-end {
		.splash {
			clip-path: polygon(0 0, 100% 0%, 100% 0%, 0 0);
		}
	}
}

// 추가 이유: mysdcstack 390 이하에서 타이틀 넘침
[data-page-name="project"] .loader__layer--back .loader__title {
	overflow: hidden;
}

</style>