<template>
  <div ref="self" class="person">
    <div class="person__face-wrap">
      <div class="person__face">
        <img v-if="item.contents.facePath" :src="item.contents.facePath" alt="">
        <Avatar v-else :avatarName="data.avatarName" :type="'mid'"/>
      </div>
      <div :class="`person__state open-state-${methods.getLocation(item.contents.location)}__ball`"></div>
    </div>
    <div class="person__txt">
      <div class="person__name">
        <span class="t-cb--13"><span class="t--fak" v-html="item.contents.name"></span></span>        
      </div>
      <div class="person__info">
        <span class="t-cb--13"><span class="t--fak person__date">00:00 AM</span></span>
        <span class="person__location-img ico-chatbot--content-place"></span>
        <span class="t-cb--13"><span class="t--fak person__location" v-html="data.location"></span></span>
      </div>
    </div>
  </div>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { reactive, ref, computed, onMounted, onUnmounted, useStore } from "@/helper/vue.js";
import { getCurrentTime } from "@/helper/gadget.js";
import Avatar from "./_Avatar.vue";

export default {
  components: {
    Avatar
  },
  props: {
    item: Object,
  },
  setup(props) {
    const { state, get, commit, dispatch } = useStore();

    const data = reactive({
      avatarName: computed(() => props.item.contents.name.toLowerCase()),
      location: props.item.contents.location == 'sinsa' ? 'Sinsa' : props.item.contents.location == 'paju' ? 'Paju' : props.item.contents.location == 'seongsu' ? 'Seongsu' : props.item.contents.location == 'seongsuFlat' ? 'Seongsu': props.item.contents.location
    });

    const methods = {
      getLocation(location) {
        let place = '';
        switch (location) {
          case 'sinsa' :
            place = 'dfy'
            break;

          case 'paju' :
            place = 'dflat'
            break;

          case 'seongsu' :
            place = 'dfy'
            break;

          case 'seongsuFlat' :
            place = 'dflatSeongsu'
            break;

          default :
          place = 'dfy'
          break;
        }

        return place
      }
    };
    const self = ref();

    const getTime = () => {
      const clock = $.find(".person__date", self.value);
      clock.innerText = `${getCurrentTime().hours < 12? `${getCurrentTime().hours == 0 ? 12 : getCurrentTime().hours}`:`${getCurrentTime().hours - 12}`}:${getCurrentTime().minutes < 10 ? `0${getCurrentTime().minutes}` : getCurrentTime().minutes} ${getCurrentTime().hours < 12? 'AM ' : 'PM '}`;
    }

    onMounted(() => {
      getTime();
    });

    return {
      data,
      methods,
      self
    };
  },


}
</script>

<style>

</style>