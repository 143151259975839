<template>
  <Layout :bgData="currentCard.cont" :name="currentCard.name" @click="$emit('click')">
    <template v-slot:fixedInfo>
      <div class="p-hiring">
        <div class="p-hiring__info-wrap">
          <div class="p-hiring__info">
            <div v-if="!data.isLangKr" class="p-hiring__info-tit">
              <span class="t-cb--13"><span class="t--fak">Category</span></span>
            </div>
            <div v-else class="p-hiring__info-tit">
              <span class="t-cb--13"><span class="t--kr">카테고리</span></span>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__info-txt">
              <div class="t-cb--13"><span class="t--fak">Develop</span></div>
            </div>
            <div v-else class="p-hiring__info-txt">
              <div class="t-cb--13"><span class="t--kr">개발</span></div>
            </div>
          </div>
          <div class="p-hiring__info">
            <div v-if="!data.isLangKr" class="p-hiring__info-tit">
              <span class="t-cb--13"><span class="t--fak">Job Type</span></span>
            </div>
            <div v-else class="p-hiring__info-tit">
              <span class="t-cb--13"><span class="t--kr">유형</span></span>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__info-txt">
              <div class="t-cb--13"><span class="t--fak">Full Time Contractor</span></div>
            </div>
            <div v-else class="p-hiring__info-txt">
              <div class="t-cb--13"><span class="t--kr">정규직</span></div>
            </div>
          </div>
        </div>
        <div v-if="!data.isLangKr" class="p-hiring__desc">
          <div class="t-cb--16"><span class="t--fak">디파이의 통찰력과 수준 높은 디자인을 웹으로 구현합니다. 웹 사이트에 생명력을 불어넣기 위해 마이크로 인터랙션과 모션을 적극 활용합니다. 이를 위해서는 견고한 마크업이 바탕이 되어야 하며, 자바스크립트에 대한 이해와 경험이 중요합니다.</span></div>
          <div class="t-cb--16"><span class="t-cb__item t--fak">풀스택 개발자와 같은 전설 속 영웅을 기대하는 것은 아닙니다. 조금 부족한 부분이 있더라도 이 일을 사랑하고 즐길 준비가 되어 있다면 우리 여정의 동반자로 충분합니다. 우리는 해결사가 아닌, 함께 성장할 수 있는 동료를 구합니다.</span></div>
        </div>
        <div v-else class="p-hiring__desc">
          <div class="t-cb--16"><span class="t--kr">디파이의 통찰력과 수준 높은 디자인을 웹으로 구현합니다. 웹 사이트에 생명력을 불어넣기 위해 마이크로 인터랙션과 모션을 적극 활용합니다. 이를 위해서는 견고한 마크업이 바탕이 되어야 하며, 자바스크립트에 대한 이해와 경험이 중요합니다.</span></div>
          <div class="t-cb--16"><span class="t-cb__item t--kr">풀스택 개발자와 같은 전설 속 영웅을 기대하는 것은 아닙니다. 조금 부족한 부분이 있더라도 이 일을 사랑하고 즐길 준비가 되어 있다면 우리 여정의 동반자로 충분합니다. 우리는 해결사가 아닌, 함께 성장할 수 있는 동료를 구합니다.</span></div>          
        </div>
        <ul class="p-hiring__required-wrap">
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➊</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">3년 이상의 실무 경험</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">3년 이상의 실무 경험</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➋</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">자바스크립트(Vanilla JS)에 대한 기본적인 이해</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">자바스크립트(Vanilla JS)에 대한 기본적인 이해</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➌</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">Node.js 기반의 개발 환경에 대한 경험</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">Node.js 기반의 개발 환경에 대한 경험</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➍</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">프로젝트 리딩 경험(우대)</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">프로젝트 리딩 경험(우대)</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➎​</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">React, Vue와 같은 프레임워크 및 라이브러리에 대한 경험(우대)</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">React, Vue와 같은 프레임워크 및 라이브러리에 대한 경험(우대)</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➏</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">TIL이나 블로그 작성과 같이 경험을 정리하고 정보를 공유하는 일을 즐김(우대)</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">TIL이나 블로그 작성과 같이 경험을 정리하고 정보를 공유하는 일을 즐김(우대)</span></div>
            </div>
          </li>
        </ul>
      </div>
    </template>
  </Layout>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { ref, reactive, computed, onMounted, onUpdated,watchEffect, onBeforeUnmount, onUnmounted, useStore } from "@/helper/vue.js";
import { addEvent, removeEvent } from "@/helper/event.js";

import Layout from "../../_ChatPageLayout.vue";
import ChatHoc from "../../_ChatHoc.vue";

export default {
  props: [ 'currentCard' ],
  emits: [ 'click' ],
  components: {
    Layout,
  },
  setup(props, {emit}) {
    const { state, get, commit, dispatch } = useStore();
    const data = reactive({
      isLangKr: computed(() => state.isLangKr),
    });

    const methods = {
    };

    onMounted(() => {
      // console.log("props.bgData", props.bgData);
    });

    return {
      data,
      methods
    }
  }
}
</script>

<style lang="scss" src="@/css/chatbot.scss"></style>
