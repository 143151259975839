<template>
  <div class="footer-object-desc">
    <!-- top Description -->
    <div class="g-row s--h-end l-mdl--32 c-last__desc">
      <div class="g-col--6 g-col--3s">
        <div class="t-h--18">
          <div class="t--fak c-last__desc-item">{{ objectDesc }}</div>
        </div>
      </div>
    </div>

    <!-- background object -->
    <div
      v-if="objectUrl"
      class="c-last__object"
    >
      <img :src="require(`@/assets${objectUrl}`)" alt="">
    </div>
  </div>
</template>

<script>
import Picture from "@/parts/picture/PictureView.vue";

export default {
  name: 'Footer Object Desc',
  components: {
    Picture,
  },
  props: {
    /**
		 * [오브젝트] 상단 디스크립션
		 * */
		objectDesc: {
			type: String,
			default: null
		},

    /**
		 * [오브젝트] 백그라운드 오브젝트
		 * */
		objectUrl: {
			type: String,
			default: null
		}
  }
}
</script>

<style lang="scss" scoped>
@import "@/css/_settings.scss";
  .footer-object-desc {
    position: relative;
  }
  .c-last {
    &__desc {
      &-item {
        text-indent: 3.8em;
        .__w600 & {
          text-indent: 5.8em;
        }
        .w1600__ & {
          text-indent: 6.5em;
        }
      }
    }
    &__object {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      z-index: -1;
    }
  }

  // work 간격
  .trademark {
    & + .footer-object-desc {
      margin-top: $em128;
    }
  }
</style>