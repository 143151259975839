<template>
  <Layout :bgData="currentCard.cont" :name="currentCard.name" @click="$emit('click')">
    <template v-slot:fixedInfo>
      <div class="p-channel">
        <div v-if="!data.isLangKr" class="p-channel__tit">
          <span class="t-cb--16"><span class="t--fak">Cafe</span></span>    
        </div>
        <div v-else class="p-channel__tit">
          <span class="t-cb--16"><span class="t--fak">카페</span></span>    
        </div>
        <div v-if="!data.isLangKr" class="p-channel__info">
          <span class="t-cb--16"><span class="t--fak"><span class="t-dfy">D FLAT</span> Seongsu stands out from the neighboring cafes and restaurants with a vibe that can only be described as DFY.<br><br>Everyday 11 AM - 9 PM</span></span>    
        </div>
        <div v-else class="p-channel__info p-channel__info--kr">
          <span class="t-cb--16"><span class="t--fak">성수에 자리한 디플랫은 생동감 넘치는 도시 속에서 한적하고 여유로운 공간을 선사합니다. 디플랫에서 텐션을 반음 낮춰보세요.<br><br>매일 오전 11시 - 오후 9시</span></span>    
        </div>
        <div v-if="!data.isLangKr" class="p-channel__location">
          <div class="t-cb--16"><span class="t--fak">90, Yeonmujang-gil, Seongdong-gu, Seoul, Korea</span></div>
          <div class="t-cb--16"><span class="t--fak"><span class="t--ft">(</span>04785<span class="t--ft">)</span></span></div>
        </div>
        <div v-else class="p-channel__location">
          <div class="t-cb--16"><span class="t--fak">서울 성동구 연무장길 90 1층</span></div>
          <div class="t-cb--16"><span class="t--fak"><span class="t--ft">(</span>04785<span class="t--ft">)</span></span></div>
        </div>
        <div class="p-channel__btn-wrap">
          <a class="b-border b-border--square p-channel__btn" href="https://www.instagram.com/dflat_cafe/" target="_blank">
            <span class="b-border__img ico-chatbot--social-instagram"></span>
            <span class="b-border__txt">
              <span class="t-cb--13"><span class="t--fak">DFLAT Cafe</span></span>
            </span>
          </a>
        </div>
        <div class="p-channel__cont">
          <News v-for="(item, index) in currentCard.news" :key="index" :item="item" :person="currentCard.cont"></News>
        </div>
      </div>
      <div class="power">
        <span class="t-cb--11 power__txt"><span class="t--fak">Powered by </span></span>
        <span class="t-cb--11"><span class="t--fak"><span class="t-dfy">DFY</span> 3F</span></span>
      </div>
    </template>
  </Layout>
  
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { ref, reactive, computed, onMounted, onUpdated,watchEffect, onBeforeUnmount, onUnmounted, useStore } from "@/helper/vue.js";
import { addEvent, removeEvent } from "@/helper/event.js";

import Layout from "../_ChatPageLayout.vue";
import News from '../type/_MainNews.vue';

export default {
  props: [ 'currentCard' ],
  emits: [ 'click' ],
  components: {
    Layout,
    News
  },
  setup(props, {emit}) {
    const { state, get, commit, dispatch } = useStore();
    const data = reactive({
      isLangKr: computed(() => state.isLangKr),
    });

    const methods = {
    };

    onMounted(() => {
    });

    onBeforeUnmount(() => {
    })

    return {
      data,
      methods
    }
  }
}
</script>

<style lang="scss" src="@/css/chatbot.scss"></style>
