import axios from 'axios';
import * as _ from 'fxjs';
import * as $ from "fxdom";
import { formatTime } from "@/helper/gadget.js";
import { API_SERVER } from "@/store/appSettings.js";

export default {
  namespaced: true,
  state: {
    isVisited: false,
    isInitialized: false,
    // openState: '',
    openStateDFY: '', // Dfy Open State
    openStateDFlat: '', // D Flat Open State
    openStateDFlatSeongsu: '', // D Flat Seongsu Open State
    openingHoursDFY: '', // Opening Hours
    openingHoursDFlat: '',
    openingHoursDFlatSeongsu: '',
    newsList: {},
    scrollLockState: false,

    items: {
      // project: {},
    },
    history: {
      // project: [],
    },
    subpageScroll: {
      // project: '',
    },
    selectedInfo: {
      // project: {},
    },
    input: {
      // project: {},
    },
    inputState: {
      // project: {
      //   checkValidation: false,
      //   passValidation: false
      // },
    },
    endState: {
      // project: false,
    },

    policyState: false,

    openJobs: 5,

    delayTime: 500,

    // loadingInfo: {
    //   toggleLoading: false,
    //   default: 1000,
    //   noise: 0,
    //   totalTime: 0,
    // },
    mainComponent: false,
    currentSubpage: null,
    fromContact: [
      //subpageName, type
    ],
    mapState: {
      state: false,
      name: null,
    },
    videoState: {
      state: false,
      name: null,
    },
    openCloseDelay: 800,

    // 폭죽
    applyCompleted: false,

    timerList: [],
    mailSent: {
      // project: false,
    }
  },
  getters: {
    getAllItems: state => state.history,
    getItems: state => name => {
      return _.map(id => state.items[name][id], state.history[name]);
    },
    getScrollTop: state => name => state.subpageScroll[name],
    getInput: state => state.input,
    getInputState: state => name => state.inputState[name],
    // getLoadingTime: state => currentItem => {
    //   return currentItem.loading
    //     ? state.loadingInfo.totalTime
    //     : state.delayTime;
    // },
  },
  mutations: {
    ['init'](state, payload) {
      // data-chatbot.js 에서 비동기로 받아온 Raw(가공되지않은, 날것의) 데이터를
      // 활용하기 좋은 형태로 가공해서 store에 넣어둔다.
      // 로우 데이터는 초기에 한번 불러오는데만 쓰인다.
      // 모든 데이터 처리는 위와 같이 진행된다.

      // id로 해당 아이템을 바로 꺼낼 수 있도록 indexBy시켜서 저장한다.
      // 별도의 가공이 더 필요하면 파이프라인에 추가한다.
      // (tap 활용방법 안내)

      // const dataName = _.keys(data);
      // _.go(dataName,
      //   _.each(name => {
      //     state.items[name] = _.indexBy(item => item.id, data[name].items);
      //     state.history[name] = [];
      //   })
      // )

      // // state.history.push(_.keys(state.items)[0]);
      // state.isInitialized = true;

      /**
       * Opening Hours
       * --------------------------
       * * DFY
       * weekday [1,2,3,4,5]
       * 10 - 18
       *
       * weekend [0,6]
       * closed
       * --------------------------
       * * D FLAT
       * weekday [1,2,3,4,5]
       * 10 - 17
       *
       * weekend [0,6]
       * 11 - 20
       */

      const newDate = new Date();
      const day = newDate.getDay();
      const hours = newDate.getHours();
      const isWeekday = 0 < day && day < 6
      let dfyTime = null
      let flatTime = null
      let flatSeongsuTime = null

      const checkOpeningHours = (dfy, flat, flatSeongsu) => {
        if (dfy === 'closed') {
          state.openStateDFY = 'close'
          state.openingHoursDFY = '10 AM ~ 6 PM'
        } else {
          state.openStateDFY = dfy[0] <= hours && hours < dfy[1] ? 'open' : 'close'
          state.openingHoursDFY = `${dfy[0]} AM ~ ${dfy[1] - 12} PM`
        }
        state.openStateDFlat = 'close down' // flat[0] <= hours && hours < flat[1] ? 'open' : 'close'
        state.openingHoursDFlat = 'Close down' // `${flat[0]} AM ~ ${flat[1] - 12} PM`

        state.openStateDFlatSeongsu = flatSeongsu[0] <= hours && hours < flatSeongsu[1] ? 'open' : 'close'
        state.openingHoursDFlatSeongsu = `${flatSeongsu[0]} AM ~ ${flatSeongsu[1] - 12} PM`
      }

      if (isWeekday) {
        dfyTime = [10, 19]
        flatTime = [10, 17]
        flatSeongsuTime = [11, 21]
      } else {
        dfyTime = 'closed'
        flatTime = [11, 19]
        flatSeongsuTime = [11, 21]
      }

      checkOpeningHours(dfyTime, flatTime, flatSeongsuTime)


      // if (day == 0 || day == 6) {
      //   state.openState = 'close';
      // } else if (hours < 8 || hours > 17) {
      //   state.openState = 'close';
      // } else {
      //   state.openState = 'open';
      // }

      state.newsList = payload.news.newsList;
    },
    ['importData'](state, payload) {
      state.items[payload.name] = _.indexBy(item => item.id, payload.data.items);
      state.history[payload.name] = [];
    },
    ['setContentsByLang'](state, payload) {
      _.go(_.keys(state.items),
        _.each(dataName => {
          state.items[dataName] = _.go(
            _.values(state.items[dataName]),
            _.each(item => {
              if (item.type == 'NormalText') {
                item.contents.text = item.contents[`text${payload.lang}`];
              } else if (item.type == 'SelectText') {
                item.contents.selectInfo = item.contents[`selectInfo${payload.lang}`];
                item.contents.confirm = item.contents[`confirm${payload.lang}`];
                item.contents.selectItems = item.contents[`selectItems${payload.lang}`];
                item.contents.selectedItem = item.contents[`selectedItem${payload.lang}`];
              } else if (item.type == 'TextInput') {
                _.go(item.contents.items,
                  _.each(item => {
                    item.leftLabel = item[`leftLabel${payload.lang}`];
                    item.placeholder = item[`placeholder${payload.lang}`];
                    item.option = item[`option${payload.lang}`];
                    item.text = item[`text${payload.lang}`];
                    item.subText = item[`subText${payload.lang}`];
                  })
                )
              }
            }),
            _.indexBy(item => item.id)
          )
          if (state.selectedInfo[dataName]) {
            state.selectedInfo[dataName].info = state.selectedInfo[dataName][payload.lang]
          }
        })
      )
    },
    ['setNewsListByLang'](state, payload) {
      const goEach = (lists, callback) => {
        _.go(lists, _.each(callback))
      }

      goEach(_.keys(state.newsList), key => {
        goEach(state.newsList[key], data => {
          const keys = _.keys(data.contENG)
          goEach(keys, key => {
            if (!payload.lang) {
              data.cont[key] = data.contENG[key]
            } else {
              if (data.contKR[key] == undefined) {
                data.cont[key] = data.contENG[key]
              } else {
                data.cont[key] = data.contKR[key]
              }
            }
          })
        })
      })
    },
    ['setScrollLock'](state, scrollState) {
      state.scrollLockState = scrollState;
    },
    ['executeFirstItem'](state, name) {
      state.history[name].push(_.keys(state.items[name])[0]);
      state.history[name].push(_.keys(state.items[name])[1]);
    },
    ['addNextHistory'](state, payload) {
      // state.loadingInfo.toggleLoading = false;
      state.history[payload.dataName].push(payload.id);
    },
    ['removeHistoryItem'](state, payload) {
      state.history[payload.dataName].splice(payload.index);
      if (state.inputState[payload.dataName]) {
        state.inputState[payload.dataName].checkValidation = false;
        state.inputState[payload.dataName].passValidation = false;
      }
      // state.loadingInfo.toggleLoading = false;
    },
    ['clearTimer'](state) {
      _.go(state.timerList,
        _.each(item => {
          clearTimeout(item);
        })
      )
      // state.loadingInfo.toggleLoading = false;
    },
    ['getSelectedItem'](state, payload) {
      payload.contents.selectedItemENG = [];
      payload.contents.selectedItemKR = [];

      const setSelectedItems = (index) => {
        const selectedDataENG = payload.contents.selectItemsENG[index];
        payload.contents.selectedItemENG.push(selectedDataENG)

        const selectedDataKR = payload.contents.selectItemsKR[index];
        payload.contents.selectedItemKR.push(selectedDataKR)
      }

      payload.contents.selectedItem = payload.lang == 'ENG' ? payload.contents.selectedItemENG : payload.contents.selectedItemKR;
      
      // selectText에서 선택된 것
      const id = payload.currId.split('-')[0];

      if (!state.selectedInfo[payload.dataName]) {
        state.selectedInfo[payload.dataName] = {}
      }
      
      let selectedInfo = state.selectedInfo[payload.dataName]
      if (!selectedInfo.info) {
        selectedInfo.info = {}
        selectedInfo.ENG = {}
        selectedInfo.KR = {}
      }
      if (payload.contents.selectType == 'multi') {
        _.go(payload.selectedItemIndex,
          _.each(index => {
            setSelectedItems(index);
            }
          )
        )
        const setInfoByLang = (item, lang) => {
          selectedInfo[lang][id] = []
          _.go(item,
            _.each(el => {
                selectedInfo[lang][id].push(el.shown);
              }
            )
          )
        }
        setInfoByLang(payload.contents.selectedItem, 'info');
        setInfoByLang(payload.contents.selectedItemENG, 'ENG');
        setInfoByLang(payload.contents.selectedItemKR, 'KR');
        state.selectedInfo[payload.dataName] = selectedInfo;
      } else {
        setSelectedItems(payload.selectedItemIndex);
        selectedInfo.info[id] = (payload.contents.selectedItem[0].shown);
        selectedInfo.ENG[id] = (payload.contents.selectedItemENG[0].shown);
        selectedInfo.KR[id] = (payload.contents.selectedItemKR[0].shown);
        
        if (payload.contents.selectedItem[0].subValue) {
          selectedInfo.info = {...selectedInfo.info, ...payload.contents.selectedItem[0].subValue}
          selectedInfo.ENG = {...selectedInfo.ENG, ...payload.contents.selectedItemENG[0].subValue}
          selectedInfo.KR = {...selectedInfo.KR, ...payload.contents.selectedItemKR[0].subValue}
        }
        state.selectedInfo[payload.dataName] = selectedInfo;
      }
    },
    ['setInput'](state, payload) {
      state.input[payload.dataName] = payload.input;
      // state.input = '';
    },
    ['setPolicyState'](state) {
      state.policyState = !state.policyState;
    },
    ['setInputState'](state, payload) {
      if (!state.inputState[payload.dataName]) {
        state.inputState[payload.dataName] = {};
        state.inputState[payload.dataName].checkValidation = false;
        state.inputState[payload.dataName].passValidation = false;
      }

      if (payload.change == 'checkValidation') {
        state.inputState[payload.dataName].checkValidation = !state.inputState[payload.dataName].checkValidation;
      } else if (payload.change == 'passValidation') {
        state.inputState[payload.dataName].passValidation = !state.inputState[payload.dataName].passValidation;
      }

    },
    // ['activeLoading'](state, item) {
    //   state.loadingInfo.toggleLoading = !state.loadingInfo.toggleLoading;
    //   state.loadingInfo.noise = Math.floor(Math.random() * 1000);
    //   var randomTime = state.loadingInfo.default + state.loadingInfo.noise;

    //   state.loadingInfo.totalTime = item.loadingTime ? item.loadingTime : randomTime;
    // },
    ['setScrollTop'](state, payload) {
      state.subpageScroll[payload.dataName] = payload.scrollTop;
    },
    ['endState'](state, payload) {
      if (payload.end) {
        state.endState[payload.dataName] = true;
      } else {
        state.endState[payload.dataName] = false;
      }
    },
    ['renderMainComponent'](state) {
      state.mainComponent = true;
    },
    ['setCurrentSubpage'](state, name) {
      state.currentSubpage = name;
      // console.log("name", name);
    },
    ['setSubpageFromContact'](state, payload) {
      state.fromContact = [payload.subpageName, payload.type];
      state.currentSubpage = payload.subpageName;
    },
    ['closeSubSubpage'](state, payload) {
      state.mapState.name = null;
      state.mapState.state = false;
      state.videoState.name = null;
      state.videoState.state = false;
    },
    ['openSubSubpage'](state, payload) {
      state[`${payload.type}State`].name = payload.name;
      state[`${payload.type}State`].state = true;
    },
    ['changeApplyCompleted'](state, flag) {
      state.applyCompleted = flag;
    },
  },
  actions: {
    async init({ commit }, payload) {
      const news = await import('./data-chat-news.js');
      commit('init', {'news': news.default});
    },
    async importData({ commit, rootState }, name) {
      const dataName = await import(`./data-chat-${name}.js`);
      setTimeout(() => {
        commit('importData', {'name': name, 'data': dataName.default});
        commit('setContentsByLang', {'name': name, 'lang': rootState.isLangKr ? 'KR' : 'ENG' });
        commit('setScrollLock', true);
        commit('executeFirstItem', name);
      }, 100);
    },
    getSelectedItem({ commit, rootState }, payload) {
      commit('getSelectedItem', {'dataName': payload.dataName, 'currId': payload.currId, 'contents': payload.contents, 'selectedItemIndex': payload.selectedItemIndex, 'lang': rootState.isLangKr ? 'KR' : 'ENG' });
    },
    openChatbot({ state, commit, dispatch }, payload) {
      // subpageName: chatMain, project, joinTeam, hiring, sinsa, paju
      // type: main, join, channel
      // media: video, map
      console.log("payload", payload);
      dispatch('resetPageClass', payload); // 열려있는 페이지 모두 닫기
      if (state.currentSubpage !== payload.subpageName) {
        if (payload.subpageName !== 'chatMain') {
          // '서브' 열기
          setTimeout(() => {
            commit('setSubpageFromContact', payload);
            // open class는 chatMain에서 card item 다 정해지면 붙음
          }, state.openCloseDelay);

          // '서브 - 서브' 열기
          if (payload.media) {
            setTimeout(() => {
              dispatch('openSubSubpage', {type: payload.media, name: payload.subpageName});
            }, state.openCloseDelay);
          }
        } else {
          setTimeout(() => {
            commit('setSubpageFromContact', {subpageName : null, type: null});
            // open class는 chatMain에서 card item 다 정해지면 붙음
          }, state.openCloseDelay);
        }
      } else {
        if (payload.media) {
          dispatch('openSubSubpage', {type: payload.media, name: payload.subpageName});
        }
      }
    },
    resetPageClass({ state, commit, dispatch }, payload) {
      if (state.mainComponent) {
        if (state.currentSubpage !== payload.subpageName) {
          // sub - subpage
          if ($.hasClass("sub-subpage-wrap--open", $.qs(".sub-subpage-wrap"))) {
            dispatch('closeSubSubpage', {type: payload.media, name: payload.subpageName});
          }
          // subpage
          if ($.hasClass("subpage-wrap--open", $.qs(".subpage-wrap"))) {
            $.removeClass("subpage-wrap--open", $.qs(".subpage-wrap"));
          }
        }
      }
      if (!$.hasClass("c-chatbot--open", $.qs(".c-chatbot"))) {
        $.addClass("c-chatbot--open", $.qs(".c-chatbot"));
        if (!state.mainComponent) {
          commit('renderMainComponent');
        }
      }
    },
    openSubSubpage({ state, commit }, payload) {
      $.addClass("sub-subpage-wrap--open", $.qs(".sub-subpage-wrap"));
      commit('openSubSubpage', payload);
    },
    closeSubSubpage({ state, commit }, payload) {
      $.removeClass("sub-subpage-wrap--open", $.qs(".sub-subpage-wrap"));
      setTimeout(() => {
        commit('closeSubSubpage', payload);
      }, state.openCloseDelay);
    },

    executeNextEvent({ getters, commit, state, dispatch }, payload) {
      commit('setScrollLock', true);
      // let currentItem = state.items[payload.dataName][payload.nextId];

      // if (currentItem.loading) {
      //   commit('activeLoading', currentItem);
      //   dispatch('addTimer', { delay: getters.getLoadingTime(currentItem), callback: () => {
      //     dispatch('addNextHistory', payload);
      //   }})
      // } else {
        dispatch('addNextHistory', payload);
      // }
    },
    addNextHistory({ commit, state }, payload) {
      let currentItem = state.items[payload.dataName][payload.nextId];
      if (currentItem.person) {
        commit('addNextHistory', {'id': 'person', 'dataName': payload.dataName});
      }
      commit('addNextHistory', {'id': payload.nextId, 'dataName': payload.dataName});
    },
    addTimer({ commit, state }, payload) {
      const timer = setTimeout(() => {
        payload.callback();
        const index = state.timerList.indexOf(timer);
        commit('splice', { sel: 'chatbot.timerList', start: index }, { root: true });
      }, payload.delay);
      commit('set', { sel: 'chatbot.timerList', to: [...state.timerList, timer] }, { root: true });
    },
    changeLang({ commit, rootState }) {
      commit('changeLang', null, { root: true });
      commit('setContentsByLang', {'lang': rootState.isLangKr ? 'KR' : 'ENG'});
      // commit('setContentsByLang', {'lang': rootState.isLangKr ? 'ENG' : 'KR'});
    },
    async onMailSubmit({ commit, state }, { type }) {
      try {
        let formData = new FormData();
        let userFormData = new FormData();
        const getDepartment = () => {
          const position = state.input[type].position;
          // let dept = undefined

          // return state.input[type].position

          // switch(position) {
          //   case 'UX / UI' :
          //   case '크리에이티브 디렉터' :
          //   case '아트 디렉터' :
          //   case 'UI 디자이너' :
          //   case 'BX 디자이너' :
          //   case 'UX Strategist' :
          //   case 'Creative Director' :
          //   case 'Art Director' :
          //   case 'UI Designer' :
          //   case 'BX Designer':
          //     return 'UX / UI'

          //   case '그래픽 디자이너' :
          //   case '2D 모션 ' :
          //   case '3D 디자이너' :
          //   case '국문 카피라이터' :
          //   case '영문 카피라이터' :
          //   case 'AE' :
          //   case '퍼포먼스 마케터' :
          //   case 'Graphic Designer' :
          //   case '2D Motion' :
          //   case '3D Designer' :
          //   case 'Korean Copywriter' :
          //   case 'English Copywriter' :
          //   case 'Performance Marketer':
          //     return 'Digital Marketing'

          //   case '프론트엔드 개발자' :
          //   case '백엔드 개발자' :
          //   case 'Front end Developer' :
          //   case 'Back end Developer':
          //     return 'Develop'
          // }

          // return dept
          if (position == 'Develop') {
            return 'Develop'
          } else if (position == 'UX / UI') {
            return 'UX/UI'
          } else if (position == 'Digital Marketing') {
            return 'Digital Marketing'
          }
          // if (position == '프론트엔드 개발자' || position == 'Front End Developer') {
          //   console.log('!!!!!!!!!!!!!', position, '!!!!!!!!!!!!!')
          //   return 'Develop'
          // } else if (position == '크리에이티브 디렉터' || position == 'Creative Director') {
          //   console.log('!!!!!!!!!!!!!', position, '!!!!!!!!!!!!!')
          //   return 'UX/UI'
          // } else if (position == '프로젝트 매니저' || position == 'Project Manager') {
          //   console.log('!!!!!!!!!!!!!', position, '!!!!!!!!!!!!!')
          //   return 'Digital Marketing'
          // }
        }

        const getFormData = (form) => {
          // type: project, joinTeam, creativeDirector ...
          if (form == userFormData) {
            form.append('type', `${type}-user`);
          }
          if (form == formData) {
            form.append('type', type);
          }

          if (type == 'project') {
            form.append('company', state.input[type].company);
            form.append('position', state.input[type].position);
            if (typeof(state.selectedInfo[type].info.service) == 'Array') {
              form.append('service', state.selectedInfo[type].info.service.join(', '));
            } else {
              form.append('service', state.selectedInfo[type].info.service);
            }
            form.append('budget', state.selectedInfo[type].info.budget);
            form.append('timeline', state.selectedInfo[type].info.timeline);
          } else if (type == 'joinTeam') {
            form.append('department', state.selectedInfo[type].info.department);
            form.append('position', state.selectedInfo[type].info.position);
          } else {
            form.append('position', state.input[type].position);
            form.append('department', state.input[type].department);
          }

          form.append('time', formatTime());
          form.append('name', state.input[type].name);
          form.append('email', state.input[type].email);
          // form.append('position', state.input[type].position);
          form.append('url', state.input[type].url);
          form.append('tel', state.input[type].tel);
          form.append('award', state.input[type].award);
          form.append('desc', state.input[type].desc);
          form.append('file', state.input[type].file);

          return form
        }

        formData = getFormData(formData);
        userFormData = getFormData(userFormData);

        const res = await axios.post(
          `${API_SERVER}/api/mail/send`,
          formData
        );

        if (res.data.status === 'Success') {
          commit('set', { sel: `chatbot.mailSent.${type}`, to: true }, { root: true });
          await axios.post(
            `${API_SERVER}/api/mail/send`,
            userFormData
          );
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
