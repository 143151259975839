
<template>
  <div class="c-button"
  :class="[
    { 'c-button--footer': isFooter},
    { 'c-button--black': isBlack },
    { 'c-button--tp': isTransparent },
    { 'btn-ripple': isRipple },
    data.btnSizePc,
    data.btnSizeMo,
  ]">
    <component ref="self" :is="isLink ? 'a' : 'button'" v-bind="isLink ? data.isAttr : data.isButton"
    :class="[
    isFooter ? 'footer-btn btn-ripple':'normal-btn btn-ripple',
    isRipple ? '' :'__no-ripple'
    ]"
    >
      <template v-if="!isFooter">
        <span class="c-button__inner">
          <span
          :class="[
            'c-button__txt',
            data.fontSizePc,
            data.fontSizeMo
          ]"
          >
            <span class="t--fak">
              {{ text }}
            </span>
          </span>
          <span class="ripple" ref="ref_ripple"></span>
        </span>
      </template>
      <template v-else>
        <span class="footer-btn__inner">
          <span class=" t-h--54 t-h--28s footer-btn__txt">
            <RandomTxt :sayText="footerText" :type="'hover'"></RandomTxt>
          </span>
          <Minime
            :position="[93, '0', 'auto', 'center']"
            :position-mo="[93, '0', 'auto', 'center']"
          />
        </span>
      </template>
    </component>
  </div>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { ref, reactive, computed, onMounted, onUnmounted, onBeforeUnmount, useStore } from "@/helper/vue.js";
import Minime from "@/parts/minime/Minime.vue";
import RandomTxt from "@/parts/text/randomTxt.vue";
// import Picture from "@/parts/picture/PictureView.vue";

export default {
  components: {
    Minime,
    RandomTxt
    // Picture,
  },
  props: {
    /**
		 * pc size: xs , s , m, l , xl
		 * */
		pcSize: {
			type: String,
			default: 'm'
    },
    /**
		 * mo size: xs , s , m, l , xl
		 * */
    moSize: {
      type: String,
      default: 'xs'
    },
    /**
		 * false: button tag
     *  true: a tag
		 * */
    isLink: {
      type: Boolean,
      default: false
    },
    /**
		 *  false:router 링ㅇ크 x
     *  true: router 링크 연결 (기본값)
		 * */
    isRouter: {
      type: Boolean,
      default: true
    },
    /**
     *  외부링크 열기  타입 
     * ex "_blank"
		 * */
    linkTarget: {
      type: String,
      default: null
    },
    /**
     * 주소 
		 * */
    url: {
      type:String,
      default: null
    },
    /**
     * 푸터용 큰 버튼 
		 * */
    isFooter: {
      type: Boolean,
      default: false
    },
    /**
     * 푸터용 작은버튼
		 * */
    isBlack: {
      type:Boolean,
      default: false
    },
    /**
     * 텍스트
		 * */
    text: {
      type:String,
      default:'button'
    },
    footerText: {
      type: Array,
      default:['button', 'button']
    },
    /**
     * 푸터 미니미 유알엘
		 * */
    minimeImage: {
      type: Number,
      default: 0
    },
    /**
     * 버튼 보더 투명버전 
		 * */
    isTransparent:{
      type: Boolean,
      default: false
    },
    isRipple: {
      type: Boolean,
      default: false
    },
		openChatbot: {
      type: Array,
      default: ['chatMain']
		}
  },
  setup(props) {
    const { state, dispatch } = useStore();
    const self = ref();
    const ref_ripple = ref();
    const data = reactive({
      isLink: true ? 'a' : 'button',
      isAttr: {
        href:props.url,
        'router-to': props.isRouter ? props.url : null,
        target:props.linkTarget
      },
      isButton: {
        type:'button'
      },
      btnSizePc : computed(()=>{
        return methods.pcSizeFilter(props.pcSize)
      }),
      btnSizeMo : computed(()=>{
        return methods.moSizeFilter(props.moSize)
      }),
      fontSizePc: computed(()=>{
        return methods.pcFontFilter(props.pcSize)
      }),
      fontSizeMo: computed(()=>{
        return methods.moFontFilter(props.moSize)
      }),

        
    })
    const methods = {
      pcSizeFilter: size => {
        switch(size) {
          case 'xs' :
            return 'c-button--xs'
          case 's' :
            return 'c-button--s'
          case 'm' :
            return 'c-button--m'  
          case 'l' :
            return 'c-button--l'  
          case 'xl' :
            return 'c-button--xl'
        }
      },
      moSizeFilter: size => {
        switch(size) {
          case 'xs' :
            return 'c-button--xs-s'
          case 's' :
            return 'c-button--s-s'
          case 'm' :
            return 'c-button--m-s'  
          case 'l' :
            return 'c-button--l-s'  
          case 'xl' :
            return 'c-button--xl-s'
        }
      },
      pcFontFilter: size=> {
        switch(size) {
          case 'xs' :
            return 't-h--13'
          case 's' :
            return 't-h--16'
          case 'm' :
            return 't-h--18'  
          case 'l' :
            return 't-h--28'  
          case 'xl' :
            return 't-h--54'
        }
      },
      moFontFilter: size=> {
        switch(size) {
          case 'xs' :
            return 't-h--13s'
          case 's' :
            return 't-h--16s'
          case 'm' :
            return 't-h--18s'  
          case 'l' :
            return 't-h--28s'  
          case 'xl' :
            return 't-h--54s'
        }
      },
    }
    let button;
    let ripple;
    let mousemoveEventHandler;
    const CB_CLICK = () => {
      if (props.isFooter) {
        if (state.chatbot.mainComponent) {
          const main = $.find('.main-wrap', $.qs(".c-chatbot"));
          main.scrollTop = 0;
        }
        dispatch('chatbot/openChatbot', {subpageName: props.openChatbot[0], type: props.openChatbot[1], media: props.openChatbot[2]});
      }
    };

    onMounted(() => {
      button = self.value;
      ripple = ref_ripple.value;
      mousemoveEventHandler = (e) => {
        const t = e.currentTarget;
        switch(e.type){
          case 'mousemove':
            const o = e.clientX
            , i = e.clientY
            , rect = t.getBoundingClientRect()
            , n = rect.left
            , r = rect.top;
            ripple.style.top = i - r + 'px';
            ripple.style.left = o - n + 'px';
            t.classList.add('active');
          break;
          case 'mouseleave':
              t.classList.remove('active');
            break;
        }
      };

      self.value.addEventListener("click", CB_CLICK);

      if(button.classList.value.indexOf('__no-ripple') < 0){
        button.addEventListener("mousemove", mousemoveEventHandler);
        button.addEventListener("mouseleave", mousemoveEventHandler);
      }
    });

    onBeforeUnmount(() => {
      self.value.removeEventListener("click", CB_CLICK);
      if(button.classList.value.indexOf('__no-ripple') < 0){
        button.removeEventListener("mousemove", mousemoveEventHandler);
        button.removeEventListener("mouseleave", mousemoveEventHandler);
      }
    });
    return {
      data,
      methods,
      self,
      ref_ripple
    }
  }
}
</script>

<style lang="scss" scoped>
$f-black: #171010; //onyx
$f-white: #F9F5EF; //spring wood
.btn-ripple {
  .ripple {
    position:absolute;
    z-index: 0;
    transform: scale(0);
    transition: transform .3s linear;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $f-black;
  }
  &.active {

    .c-button__inner  {
      color: $f-white;
    }
    .ripple {
      transform: scale(100);
    }
  }
  .c-button--black & {
    .ripple{
      background: $f-white;
    }
    &.active {
      .c-button__inner  {
        color: $f-black;
      }
    }
  }
}
.c-button {
  display: inline-block;
  cursor: pointer;
  padding-bottom: 2em;
  &__inner {
    display: inline-block;
    position: relative;
    overflow: hidden;
    border: 1px solid #171010;
    border-radius: 14em;
    padding: 4em 8em;
    transition: 0.3s ease all;
    transform: translateZ(0);
  }
  &__txt {
    white-space: nowrap;
    position: relative;
    z-index: 1;
  }
  
  &--xs {
    &, .__w600 &-s   {
      .c-button__inner {
        border-radius: 14em;
        padding: 3em 6em;
      }

    }
  }
  &--s {
    &, .__w600 &-s   {
      .c-button__inner {
        border-radius: 12em ;
        padding: 2em 8em;
      }
    }
  }
  &--l {
    &, .__w600 &-s   {
      .c-button__inner {
        border-radius: 20em ;
        padding: 3em 13em;
      }
    }
  }
  &--xl {
    &, .__w600 &-s   {
      .c-button__inner {
        border-radius: 28em ;
        padding: 0em 18em;
      }
    }
  }
  &--black {
    color:#EBE3D7;
    .c-button__inner {
      border: 1px solid rgba(235, 227, 215, 0.4);
    }
    .normal-btn {
      &:hover {
        .c-button__inner {
          border: 1px solid rgba(235, 227, 215, 1);
        }
      }
     
    }
    &.c-button--xs {
      .c-button__inner {
        border-radius: 10em;
        padding: 0 4em;
        .t-h--13s .t--fak{
          font-size: 11em;
          letter-spacing: -0.02em;
          line-height: 1.3;
        }
      }
    }
  }
  &--tp {
    .c-button__inner {
      border: 1px solid rgba(23, 16, 16, 0.4);
    }
  }
  &--footer {
    width: 100%;
    .footer-btn {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      height: 238em;
      border-radius: 96em;
      border: 1px solid #EBE3D7;
      transition: 0.3s ease all;
      &:hover {
        border-radius: 30em;
      }
      &__inner {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 192em);
        height: 100%;
        

      }
      .__w600 & {
        height: 220em;;
        &__inner {
          // width: calc(100% - 120em);
          // width: calc(100% - 92em);
          width: calc(100% - 60em);

        }
      }
      
    }
  }
  &--active{
    // background: #171010;
    .c-button__inner {
      background: #171010;
      color: #F9F5EF;
    }
  }
}
.c-button--xs,
.c-button--s,
.c-button--m,
.c-button--l {
  .normal-btn {
    &:hover {
      .c-button__inner {
        border-radius: 6em;
      }
    }
  }
}
.c-button--xl {
  .normal-btn {
    &:hover {
      .c-button__inner {
        border-radius: 12em;
      }
    }
  }
}
.__w600 {
  .c-button--l-s {
    .normal-btn {
      &:hover {
        .c-button__inner {
          border-radius: 6em;
        }
      }
    }
  }
}

.c-button--tp {
  .normal-btn {
    &:hover {
      .c-button__inner {
         border: 1px solid rgba(23, 16, 16, 1);
      }
    }
  }
}
.__w600 .footer--work {
  .footer-btn__inner {
    width: calc(100% - 120em);
  }
}

.__w600 .s--mobile-precise .c-button__inner { padding-top: 1em; padding-bottom: 1em; }
.__w600 .s--mobile-precise .t--fak { line-height: 1 !important; }

// .c-button--black.c-button--xs .c-button__inner { padding-top: 1em !important; padding-bottom: 1em !important; }
// .c-button--black.c-button--xs .c-button__inner .t-h--13s .t--fak { line-height: 1 !important; }
</style>