import * as _ from 'fxjs';
import axios from 'axios';
import { getData } from "./data-global.js";
import { API_SERVER } from "@/store/appSettings.js";

let realProjects = []
let allArr = []
let allObj ={}
let allObjWithUrl ={}
let categoryObj = {};
let themeObj= {};
let featuredArr = []
let latestArr =[]
let archiveArr = []
let addArr = ((arr,keys)=>{
	// keys.
	// for(let a = 0; a < total; a++) {
	// 	arr.push([])
	// }
	keys.forEach(key => {
		arr[key] = [];
	});
});
const count = {
	all: 0,
	category: {},
	tag: {}
};

const fetchData = async () => {
	try {

		addArr(categoryObj, Object.keys(getData('work.category')));
		addArr(themeObj, Object.keys(getData('work.tag')));

		let response = await axios.get(API_SERVER + "/api/projects");
		realProjects = response.data;
		realProjects = realProjects ? realProjects : [];
		realProjects.forEach((item,index)=>{
			if(item.state === 'publish') {
				let newPid = parseInt(item.pid)
				allArr.push(item)
				allObj[newPid] = item
				allObjWithUrl[item.url] = item
				// category
				item.category.forEach(id => {
					categoryObj[id] && categoryObj[id].push(newPid)
				})
				// tag
				if(item.theme !== undefined) {
					item.theme.forEach(id => {
						themeObj[id] && themeObj[id].push(newPid)
					})
				}
				if(item.options['recommend'] == 1) {
					featuredArr.push(newPid)
				}
				if(item.options['latest'] == 1){
					latestArr.push(newPid)
				}
				if(item.year < 2020) {
					archiveArr.push(newPid)
				}
			}
		});

		// 각 필터별 갯수 정보
		count.all = allArr.length;
		_.go(
			Object.keys(categoryObj),
			_.each(id => {
				count.category[id] = categoryObj[id].length;
			})
		);
		_.go(
			Object.keys(themeObj),
			_.each(id => {
				count.tag[id] = themeObj[id].length;
			})
		);

		// allArr = _.go(
		// 	allArr,
		// 	_.filter(item => getExtension(item.thumbnail) != "mp4")
		// );

	} catch (e) {
		console.log(e);
	}
};

const getWork = targetArr => {
	let resultArr =[]


	if(typeof targetArr === 'number') {
		resultArr.push(allObj[targetArr])
	}else {
  	targetArr.forEach(id=>{
    	resultArr.push(allObj[id])
  	})
	}
	return resultArr

}

const getWorkWithUrl = (url, type="url") => {
	if(type == "url") {
		return allObjWithUrl[url];
	}
	else if(type == "uuid") {
		return _.go(
			realProjects,
			_.filter(item => item.id == url),
		)[0];
	}
};

export {
	allArr,
	categoryObj as categoryArr,
	themeObj as themeArr,
	featuredArr,
	latestArr,
	archiveArr,
	allObj,
	getWork,
	getWorkWithUrl,
	fetchData,
	count
}
