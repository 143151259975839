<template>
  <Layout :bgData="currentCard.cont" :name="currentCard.name" @click="$emit('click')">
    <template v-slot:fixedInfo>
      <div class="p-hiring">
        <div class="p-hiring__info-wrap">
          <div class="p-hiring__info">
            <div v-if="!data.isLangKr" class="p-hiring__info-tit">
              <span class="t-cb--13"><span class="t--fak">Category</span></span>
            </div>
            <div v-else class="p-hiring__info-tit">
              <span class="t-cb--13"><span class="t--kr">카테고리</span></span>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__info-txt">
              <div class="t-cb--13"><span class="t--fak">UX / UI</span></div>
            </div>
            <div v-else class="p-hiring__info-txt">
              <div class="t-cb--13"><span class="t--kr">기획 / 디자인</span></div>
            </div>
          </div>
          <div class="p-hiring__info">
            <div v-if="!data.isLangKr" class="p-hiring__info-tit">
              <span class="t-cb--13"><span class="t--fak">Job Type</span></span>
            </div>
            <div v-else class="p-hiring__info-tit">
              <span class="t-cb--13"><span class="t--kr">유형</span></span>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__info-txt">
              <div class="t-cb--13"><span class="t--fak">Full Time Contractor</span></div>
            </div>
            <div v-else class="p-hiring__info-txt">
              <div class="t-cb--13"><span class="t--kr">정규직</span></div>
            </div>
          </div>
        </div>
        <div v-if="!data.isLangKr" class="p-hiring__desc">
          <div class="t-cb--16"><span class="t--fak">디파이의 기획자는 웹사이트, E-커머스, 브랜딩, 미디어 비즈니스의 고객과의 접점에서 전략적 접근과 아이디어로 커뮤니케이션합니다.</span></div>
          <div class="t-cb--16"><span class="t-cb__item t--fak">비즈니스 환경과 흐름을 빠르게 파악하고, 디파이의 크리에이티브안에서 함께 성장해 갈 분들을 기다립니다.</span></div>
        </div>
        <div v-else class="p-hiring__desc">
          <div class="t-cb--16"><span class="t--kr">디파이의 기획자는 웹사이트, E-커머스, 브랜딩, 미디어 비즈니스의 고객과의 접점에서 전략적 접근과 아이디어로 커뮤니케이션합니다.</span></div>
          <div class="t-cb--16"><span class="t-cb__item t--kr">비즈니스 환경과 흐름을 빠르게 파악하고, 디파이의 크리에이티브안에서 함께 성장해 갈 분들을 기다립니다.</span></div>          
        </div>
        <ul class="p-hiring__required-wrap">
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➊</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">리서치, 분석을 통한 전략 기획 경험</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">리서치, 분석을 통한 전략 기획 경험</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➋</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">서비스 프로세스 및 화면설계 경험</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">서비스 프로세스 및 화면설계 경험</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➌</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">타 부서와 원활한 커뮤니케이션을 위한 기타 사회 활동 경험</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">타 부서와 원활한 커뮤니케이션을 위한 기타 사회 활동 경험</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➍</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">2년 이상의 동종업계 경력자 우대</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">2년 이상의 동종업계 경력자 우대</span></div>
            </div>
          </li>
          <li class="p-hiring__required">
            <div class="p-hiring__req-num">
              <div class="t-cb--16"><span class="t--fak">➎​</span></div>
            </div>
            <div v-if="!data.isLangKr" class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--fak">외국어 능통자 우대 (영어, 일어)</span></div>
            </div>
            <div v-else class="p-hiring__req-desc">
              <div class="t-cb--16"><span class="t--kr">외국어 능통자 우대 (영어, 일어)</span></div>
            </div>
          </li>
        </ul>
      </div>
    </template>
  </Layout>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { ref, reactive, computed, onMounted, onUpdated,watchEffect, onBeforeUnmount, onUnmounted, useStore } from "@/helper/vue.js";
import { addEvent, removeEvent } from "@/helper/event.js";

import Layout from "../../_ChatPageLayout.vue";
import ChatHoc from "../../_ChatHoc.vue";

export default {
  props: [ 'currentCard' ],
  emits: [ 'click' ],
  components: {
    Layout,
  },
  setup(props, {emit}) {
    const { state, get, commit, dispatch } = useStore();
    const data = reactive({
      isLangKr: computed(() => state.isLangKr),
    });

    const methods = {
    };

    onMounted(() => {
      // console.log("props.bgData", props.bgData);
    });

    return {
      data,
      methods
    }
  }
}
</script>

<style lang="scss" src="@/css/chatbot.scss"></style>
