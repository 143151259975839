<template>
	<div class="yt-inner">
		<div id="player" class="yt-player"></div>
	</div>
</template>

<script>
// import * as $ from "fxdom";
// import * as _ from "fxjs";
import { ref, reactive, onMounted, onUnmounted, onActivated, onDeactivated } from "@/helper/vue.js";
import { nextTick } from '@vue/runtime-core';

export default {
	setup(props) {
		// 현재 템플릿 ROOT를 DOM으로 접근
		const self = ref();
		// 템플릿에서 사용되는 데이터
		const data = reactive({});
		// 템플릿에서 사용되는 메소드
		const methods = {

		};

		// DOM 접근 가능 시점
		onMounted(() => {});
		onActivated(() => {
			nextTick(() => {
				loadPlayer();

				function loadPlayer() {
					console.log('load player')
					if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
						var tag = document.createElement('script');
						tag.src = "https://www.youtube.com/iframe_api";
						var firstScriptTag = document.getElementsByTagName('script')[0];
						firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

						window.onYouTubePlayerAPIReady = function() {
							onYouTubePlayer();
						};
					} else {
						onYouTubePlayer();
					}
				}

				var player;
				function onYouTubePlayer() {
					console.log('onYouTubePlayer')
					player = new YT.Player('player', {
						height: '100%',
						width: '100%',
						videoId: 'errEJpnW7nE',
						host: 'https://www.youtube.com',
						playerVars: {'controls':1, 'loop' :1, 'autoplay':1, 'rel': 0}
					});
				}
			})
		})

		// window, document 등 Unmount되지 않는 이벤트 해제를 위해 사용
		onUnmounted(() => {
		});

		return {
			self,
			data,
			methods
		};
	}
}
</script>
<style scoped lang="scss" src="@/css/layerPop.scss"></style>
<style lang="scss" scoped>
// .main-intro__yt{
// 	background: rgba(0,0,0,0.8);
// }
.yt-inner{
	// position: absolute;
	// top: 60em;
	// left: 0;
	// bottom: 60em;
	// width: 100%;
	
	width: 100%;
	height: 100%;
}
.yt-player{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>