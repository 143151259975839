<template>
	<button ref="self" class="music-control" :class="{'music-control--stop': data.isMuted}">
		<div class="music-control__bar-wrap">
			<span class="music-control__bar music-control__bar--0"></span>
			<span class="music-control__bar music-control__bar--1"></span>
			<span class="music-control__bar music-control__bar--2"></span>
			<span class="music-control__bar music-control__bar--3"></span>
			<span class="music-control__bar music-control__bar--4"></span>
		</div>

		<audio class="click-beep" autostart="0">
			<source src="@/assets/audio/ui-click.mp3"/>
		</audio>
		<audio class="hover-beep" autostart="0">
			<source src="@/assets/audio/ui-hover.mp3"/>
		</audio>
		<audio class="done-beep" autostart="0">
			<source src="@/assets/audio/ui-done.mp3"/>
		</audio>
		<audio class="bgm" autostart="0" loop>
			<source src="@/assets/audio/bg.mp3"/>
		</audio>
	</button>
</template>

<script>
import * as _ from 'fxjs';
import * as $ from 'fxdom';
import { ref, reactive, onMounted, useStore, computed, onBeforeUnmount } from "@/helper/vue.js";

export default {
	components: {
	},
	setup() {
		const { state, commit } = useStore();
		const self = ref();
		const data = reactive({
			// isMuted: true,
			isMuted: computed(() => state.isMuted),
		});

		const CB_CHANGED_MQ = () => {
			// return;
			if(!self.value) return;
			if(state.isMobileSize) {
				$.prependTo($.qs('.mheader__right'), self.value)
			} else {
				$.prependTo($.qs('.header__nav'), self.value)
			}
		}

		// 컨트롤러 액션
		const bounceDelay = 200;
		const pulses = [
			[0, 4, 10],
			[1, 6, 13],
			[2, 4, 7],
			[3, 4, 10],
			[4, 5, 13],
		];
		let intervalID = null;

		const getRandomHeight = (min, max) => {
			return min + Math.floor(Math.random() * (max - min + 1));
		};

		const toggleMusic = () => {
			// data.isMuted = !data.isMuted;
			commit('setMutedState');
			clearInterval(intervalID);
			if(data.isMuted == true) {
				$.qs(".bgm", self.value).pause();
				return;
			}
			intervalID = setInterval(() => {
				_.go(
					pulses,
					_.each(([index, min, max]) => {
						$.setCss({ transform: `scaleY(${getRandomHeight(min, max)})`}, $.qs(`.music-control__bar--${index}`, self.value));
					})
				);
			}, bounceDelay);
			$.qs(".bgm", self.value).play();
		};
		// 컨트롤러 액션

		onMounted(() => {
			window.addEventListener('landed', CB_CHANGED_MQ, {once: true})
			window.addEventListener('changed.mq', CB_CHANGED_MQ)
			const clickBeep = new Audio()
			const hoverBeep = new Audio()

			// control button click
			self.value.addEventListener("click", () => {
				toggleMusic();
			});

			// clcik beep
			$.qs("body").addEventListener("click", () => {
				if(data.isMuted) return;
				clickBeep.src = require('@/assets/audio/ui-click.mp3')
				clickBeep.play()
			});

			// hover beep
			let mouseEnter = false;
			let mouseEnteredTarget = null;
			$.qs("body").addEventListener("mouseover", (e) => {
				if(data.isMuted) return;
				if(mouseEnter) return;

				mouseEnteredTarget = e.target.closest("a") || e.target.closest("button");

				if(mouseEnteredTarget) {
					hoverBeep.src = require('@/assets/audio/ui-hover.mp3')
					hoverBeep.play()
					mouseEnter = true;
					mouseEnteredTarget.addEventListener("mouseleave", () => {
						mouseEnteredTarget = null;
						mouseEnter = false;
					}, { once: true });
				}
				e.stopPropagation();
			});
		});

		onBeforeUnmount(() => {
			window.removeEventListener('changed.mq', CB_CHANGED_MQ)
		})

		return {
			self,
			data
		}
	}
}
</script>

<style scoped lang="scss">
audio {
	display: none;
}

.music-control {
	display: inline-block;
	padding: 3px 4px 4px 4px;
	transform: scale(1);
	margin-right: 16px;
	position: relative;
	border-radius: 4px;
	transition: background-color 0.4s ease;
	font-size: 0;
	.s--desktop &:hover {
		background-color: var(--foreground);
		.music-control__bar {
			background-color: var(--background);
		}
	}
	.s--mobile-size & {
		position: absolute;
    top: 1px;
    left: -45px;
    transform: scaleY(0.8);
	}
	&__bar-wrap {
		position: relative;
		display: inline-block;
		width: 22px;
		height: 13px;
	}
	&__bar {
		position: absolute;
		display: inline-block;
		bottom: 0;
		width: 2px;
		height: 1px;
		transform-origin: center bottom;
		will-change: transform;
		background-color: var(--foreground);
		transition: background-color 0.4s ease, transform 0.2s linear;
		&--0 {
			left: 0;
		}
		&--1 {
			left: 5px;
		}
		&--2 {
			left: 10px;
		}
		&--3 {
			left: 15px;
		}
		&--4 {
			left: 20px;
		}
	}
	&--stop {
		.music-control__bar--0 { transform: scaleY(8) !important; }
		.music-control__bar--1 { transform: scaleY(13) !important; }
		.music-control__bar--2 { transform: scaleY(5) !important; }
		.music-control__bar--3 { transform: scaleY(8) !important; }
		.music-control__bar--4 { transform: scaleY(11) !important; }
	}
}
</style>