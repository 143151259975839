import * as $ from "fxdom";
import * as _ from "fxjs";
import * as C from "fxjs/Concurrency";
import { ref, reactive, computed, onMounted, onUnmounted, watch, useStore } from "@/helper/vue.js";

const Editable = (function(){

	function Editable(el, idx, items) {

		this.el = el;
		this.idx = idx;
		this.items = items;
		this.watchCallbacks = [];
		const { state, commit } = useStore();
		this.state = state;
		this.commit = commit;

		this.items.forEach(item => {
			this.makeEditable(item);
		});

		// watch(
		// 	() => this.state.edit.project.modules[this.idx],
		// 	() => {
		// 		this.watchCallbacks.forEach(item => {
		// 			item();
		// 		});
		// 	},
		// 	{ immediate: true, deep: true }
		// );

	}

	return Editable;

})();

Editable.prototype.set = function({ val, unit, editUnit, map }) {

	const { sel, displayTarget, placeholder, beforeSet } = map || {};

	if(val) {
		// val = beforeSet ? beforeSet(val) : val;
		this.commit("set", { sel: `edit.project.${sel}`, to: val });
		// $.addClass("editable--completed", editUnit);
	}
	else {
		this.commit("set", { sel: `edit.project.${sel}`, to: "" });
		// setTimeout(() => {
		// 	$.setHTML(placeholder, (unit == displayTarget) ? editUnit : $.qs(displayTarget, editUnit));
		// });
		// $.removeClass("editable--completed", editUnit);
	}
	// if(val) {
	// 	val = beforeSet ? beforeSet(val) : val;
	// 	this.commit("set", { sel: `edit.project.${sel}`, to: val });
	// }
};

Editable.prototype.addItem = function(...newItems) {
	newItems.forEach(item => {
		this.items.push(item);
		this.makeEditable(item);
	});
};

Editable.prototype.makeEditable = function({ type, unit, onClick, wrap, map, tip }) {
	const { sel, displayTarget, useTextarea, inputTarget, placeholder, beforeSet, beforeGet } = map || {};
	let editUnit = $.qs(unit, this.el);

	if(type) {
		if(type == "src") {
			editUnit.addEventListener("click", () => {
				$.qs(".ui-btns__btn--upload-file", editUnit).click();
			})
		}
	}

	// wrap
	if(wrap) {
		const wrapDOM = $.el(wrap);
		_.go(
			wrapDOM,
			$.insertBefore(editUnit),
			$.append(editUnit),
		);
		editUnit = wrapDOM;
	}
	$.addClass("editable", editUnit);

	// click
	if(onClick) {
		editUnit.addEventListener("click", e => {
			if(!$.closest(".ui-btns", e.target)) {
				onClick();
			}
		});
	}

	// tool tip
	if(tip) {
		_.go(
			$.el(`<span class="editable__tip">${tip}</span>`),
			$.appendTo(editUnit),
		)
	}

	// map
	if(map) {
		// let timerTrue, timerFalse = -1;
		watch(
			() => _.sel(sel, this.state.edit.project),
			(curr) => {
				if(curr && !(_.isArray(curr) && curr.length == 0)) {
					setTimeout(() => {
						$.addClass("editable--completed", editUnit);
					});
				}
				else {
					setTimeout(() => {
						if(placeholder) {
							$.setHTML(placeholder, (unit == displayTarget) ? editUnit : $.qs(displayTarget, editUnit));
						}
						$.removeClass("editable--completed", editUnit);
						// console.log("no", this.state.edit.project);
					});
					
				}
			},
			{ immediate: true }
			// { immediate: true, deep: true }
		);

		// this.watchCallbacks.push(() => {
			// const val = this.state.edit.project.modules[this.idx][sel] || placeholder;
		// 값이 없을 경우
		this.set({ val: _.sel(sel, this.state.edit.project), unit, editUnit, map });
		// if(!this.state.edit.project.modules[this.idx][sel]) {
		// 	$.setHTML(placeholder, (unit == displayTarget) ? editUnit : $.qs(displayTarget, editUnit));
		// 	// $.setHTML(placeholder, $.find(displayTarget, editUnit));
		// }
			// const val = this.state.edit.project.modules[this.idx][sel] || placeholder;
			// const editArea = $.qs(".c-pic__desc--info", editUnit);
			// $.setHTML(val, editArea);
		// });
		if(inputTarget) {
			editUnit.addEventListener("click", () => {

				if($.hasClass("editable--editing", editUnit)) return;
				
				// 에디트 시작
				// const val = this.state.edit.project.modules[this.idx][sel];
				const val = beforeGet ? beforeGet(_.sel(sel, this.state.edit.project)) : _.sel(sel, this.state.edit.project);
				let input;
				if(useTextarea) {
					input = $.el(`<textarea row="7" class="editable__textarea">${ val ? val : ""}</textarea>`);
				}
				else {
					input = $.el(`<input type="text" class="editable__input" value="${val}">`);
				}
				
				_.go(
					editUnit,
					$.addClass("editable--editing"),
					() => {
						return (unit == inputTarget) ? editUnit : $.qs(inputTarget, editUnit);
					},
					$.addClass("editable__input--editing"),
					$.append(input)
				);
				input.focus();
				input.select();

				// 에디트 종료
				input.addEventListener("focusout", () => {
					_.go(
						editUnit,
						$.removeClass("editable--editing"),
						() => {
							return (unit == inputTarget) ? editUnit : $.qs(inputTarget, editUnit);
						},
						$.removeClass("editable__input--editing"),
						() => {
							this.set({ val: beforeSet ? beforeSet(input.value) : input.value, unit, editUnit, map });
							$.remove(input);
						}
					);
				});
			});
		}
	}
};

const moduleDefault = {
	Kv: {
		type: "Kv",
		visualCaption: "",
		layout: 0,
		src: ""
	},
	SoloPicture: {
	  type: "SoloPicture",
		size: 3,
		align: 1,
	  caption: "",
		src: "",
	  // src: "/test/test-0-1.jpg",
	  // src: "https://amazon-clone-minji-v1.s3.amazonaws.com/dev/works/123/1622084352387.jpeg",
	  // src: "/@sample-video.mp4",
		// src: "M7lc1UVf-VE",
		from: "",
		// from: "asset",
		ratio: "",
		// ratio: "1280*720",
		embedType: "",
		// embedType: "youtube",
	},
	DoublePicture: {
	  type: "DoublePicture",
		layout: 0,
	  items: [
			{
				src: "",
				caption: "",
			},
			{
				src: "",
				caption: "",
			}
		]
	},
	TriplePicture: {
	  type: "TriplePicture",
	  items: [
			{
				src: "",
				caption: "",
			},
			{
				src: "",
				caption: "",
			},
			{
				src: "",
				caption: "",
			}
		]
	},
	TripleDevice: {
	  type: "TripleDevice",
		notch: 1,
	  items: [
			{
				src: "",
				caption: "",
			},
			{
				src: "",
				caption: "",
			},
			{
				src: "",
				caption: "",
			}
		]
	},
	Moodboard1: {
	  type: "Moodboard1",
		body: "",
	  items: [
			{
				src: "",
				caption: "",
			},
			{
				src: "",
				caption: "",
			},
			{
				src: "",
				caption: "",
			}
		]
	},
	Moodboard2: {
	  type: "Moodboard2",
		body: "",
	  items: [
			{
				src: "",
				caption: "",
			},
			{
				src: "",
				caption: "",
			},
			{
				src: "",
				caption: "",
			}
		]
	},
	// 레일(이미지, 모바일1~2)
	Rail: {
		picture: {
			type: "Rail",
			itemType: "picture",
			body: "",
			swap: 0,
			notch: 1,
			items: [
				{
					src: "",
					caption: "",
				},
			]
		},
		device: {
			type: "Rail",
			itemType: "device",
			body: "",
			swap: 0,
			notch: 1,
			items: [
			  {
			    src: "",
			    caption: "",
			  },
			],
		},
		device2: {
			type: "Rail",
			itemType: "device2",
			body: "",
			bodyKo: "",
			swap: 0,
			notch: 1,
			items: [
				{
					device: [
						{
							src: "",
							caption: "",
						},
						{
							src: "",
							caption: "",
						},
					]
				},
			]
		}
	},
	// 디바이스 1칼럼(아이폰, 태블릿 가로 세로)
	SoloDevice: {
		iphone: {
			type: "SoloDevice",
			itemType: "iphone",
			align: 1,
			notch: 1,
			src: "",
			caption: ""
		},
		"tablet-h": {
			type: "SoloDevice",
			itemType: "tablet-h",
			align: 1,
			src: "",
			caption: ""
		},
		"tablet-v": {
			type: "SoloDevice",
			itemType: "tablet-v",
			align: 1,
			notch: 1,
			src: "",
			caption: ""
		},
	},
	Separator: {
		type: "Separator",
		label: ""
	},
	LargeTitle: {
		type: "LargeTitle",
		title: "",
		body: ""
	},
	MediumTitle: {
		type: "MediumTitle",
		title: "",
		body: ""
	},
	Description: {
		type: "Description",
		align: 0,
		body: ""
	},
	HeroTitle: {
		type: "HeroTitle",
		label: "",
		txt1: "",
		txt2: "",
		txt3: "",
	},
	Credit: {
		type: "Credit"
	}
};

const scrollToModule = (idx) => {
	setTimeout(() => {
		// console.log($.qs(`.container > .edit-module:nth-child(${idx + 1})`).getBoundingClientRect());
		const moduleY = $.qs(`.container > .edit-module:nth-child(${idx + 1})`).getBoundingClientRect().y;
		window.scrollTo(0, window.pageYOffset + moduleY - 200);
	}, 0);
}

const getModuleDefault = (type, itemType) => {
	if(itemType) {
		return JSON.parse(JSON.stringify(moduleDefault[type][itemType]));
	}
	else {
		return JSON.parse(JSON.stringify(moduleDefault[type]));
	}
};

const moduleOrderButton = ({ id, idx, commit, dispatch, mdLeng }) => {
	return {
		text: "모듈 순서 변경",
		icon: "btn-arrow-right",
		items: [
			{
				text: "위로",
				onClick({ target }) {
					if(idx < 2) return;
					commit("move", { sel: `edit.project.modules`, index: idx, to: idx - 1 });
					scrollToModule(idx - 1);
					dispatch('edit/onUpdate', { id });
				}
			},
			{
				text: "아래로",
				onClick() {
					if(idx + 2 === mdLeng) return;
					commit("move", { sel: `edit.project.modules`, index: idx, to: idx + 1 });
					scrollToModule(idx + 1);
					dispatch('edit/onUpdate', { id });
				}
			}
		]
	};
};

const moduleAlignButtonDefault = () => {
	return {
		title: "정렬",
		state: "align",
		items: [
			{ icon: 'btn-align-left', value: 0 },
			{ icon: 'btn-align-center', value: 1 },
			{ icon: 'btn-align-right', value: 2 },
		]
	};
};

const moduleDelBtnDefault = ({ id, idx, commit, dispatch }) => {
	return {
		text: "삭제",
		icon: "btn-delete",
		async onClick() {
			if(confirm('모듈을 삭제하시겠습니까?')) {
				commit("splice", { sel: "edit.project.modules", start: idx });
				await dispatch('edit/onUpdate', { id });
				scrollToModule(idx);
			}
		}
	};
}

const moduleDeleteButton = ({ id, commit, dispatch, idx, removeSrc, removeCvSrc, itemType }) => {
	return {
		text: "삭제",
		icon: "btn-delete",
		onClick() {
			if(confirm('모듈을 삭제하시겠습니까?')) {
				_.go(
					removeSrc(),
					items => {
						// 삭제할 모듈이 device2인 경우 
						if(itemType == "device2") {
							return _.go(
								items,
								_.map(item => item.device),
								_.flat,
							)
						} else {
							return items;
						}
					},
					_.map(item => item.src),
					_.map(async (src) => {
						const formData = new FormData();
						formData.append('id', id);
						formData.append('sel', src); 
						// src가 있고 src가 url인 경우 (임베드 src가 아닌 경우에만 실행)
						if (src && src.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/) !== null) {
							console.log('💩💩💩 dispatch 실행')
							await dispatch('edit/onMediaDelete', { formData, msg: 0});
						}
					}),
					C.takeAll,
					_.tap(() => {
						_.go(
							// src 삭제가 완료 된 후 coverSrc도 삭제
							removeCvSrc(),
							items => {
								if(itemType == "device2") {
									return _.go(
										items,
										_.map(item => item.device),
										_.flat,
									)
								} else {
									return items;
								}
							},
							_.map(item => item.coverSrc),
							_.map(async (coverSrc) => {
								const formData = new FormData();
								formData.append('id', id);
								formData.append('sel', coverSrc);
								if (coverSrc) {
									return await dispatch('edit/onMediaDelete', { formData, msg: 0});
								}
							}),
							C.takeAll,
							_.tap(async () => {
								commit("splice", { sel: "edit.project.modules", start: idx });
								await dispatch('edit/onUpdate', { id });
								scrollToModule(idx);
							})
						)
					})
				)
			}
		}
	}
}

const moduleImageUploadButton = (props) => {
	return [
		{
			text: "업로드 파일",
			async onClick() {
				await moduleImageUploadButtonAction(props)
			}
		},
		{
			text: "패럴렉스 효과",
			icon: "btn-arrow-right",
			items: [
				{
					text: "사용",
					map: `edit.project.${props.urlSel}.parallax`,
					onClick() {
						props.commit("set", {
							sel: `edit.project.${props.urlSel}.parallax`,
							to: 1
						});
					}
				},
				{
					text: "사용 안함",
					map: `edit.project.${props.urlSel}.parallax`,
					onClick() {
						props.commit("set", {
							sel: `edit.project.${props.urlSel}.parallax`,
							to: 0
						});
					}
				}
			]
		}
	]
}

const moduleImageUploadButtonAction = ({ id, sel, urlSel, get, commit, dispatch }) => {
	dispatch('edit/onFileupload', {
		sel: `${sel}.src`,
		urlSel: `${urlSel}.src`,
		moduleSel: `${urlSel}`,
	})

	// embedType이 있는 경우 store uploadInfo 업데이트, _InputFile에서 처리
	if(get["from"](`edit.project.${urlSel}.embedType`)) {
		commit("set", { sel: `edit.uploadInfo.embedType`, to: 1 });
	}

	// coverSrc가 있는 경우 store uploadInfo 업데이트, _InputFile에서 처리
	if(get["from"](`edit.project.${urlSel}.coverSrc`)) {
		commit("set", { sel: `edit.uploadInfo.coverSrc`, to: 1 });
	}
}

const moduleVideoEmbedButton = (props) => {
	return {
		text: "임베드",
		async onClick() {
			await moduleVideoEmbedButtonAction(props)
		}
	}
}

const moduleVideoEmbedButtonAction = ({ id, sel, urlSel, commit }) => {
	commit("set", {
		sel: "edit.isPopupActive.videoEmbedPopup",
		to: {
			isOpen: true,
			dataObj: {
				sel,
				urlSel
			}
		}
	});
}

const stripHTMLlink = (val) => {
	if(val == undefined) return "";

	const wrappedLink = val.match(/<a.+?\/a>/gi);
	const urlArr = val.match(/https?:[^"\s]+/gi);
	const linkName = val.match(/[^">]*.(?=<\/a>)/gi);

	if (!wrappedLink || !urlArr || !linkName) {
		return val;
	}

	let stripped = [];

	urlArr.map((i, idx) => {
		const format = `@(${i}, ${linkName[idx]})`;
		stripped.push(format);
	})

	wrappedLink.map((i, idx) => {
		val = val.replace(i, stripped[idx]);
	})

	return val;
}

const wrapLink = (val) => {
	if(val == undefined) return "";
	const wrappedArr = _.go(
		val,
		(val) => {
			return val.match(/@\((.*?)\)/gi);
		},
		_.map((i) => {
			const newStr = i.replace("@(", "")
			let replaced = newStr.replace(")", "")
			return `<a href="${replaced.split(',')[0]}" target="_blank">${(replaced.split(',')[1]).trim()}</a>`
		}),
	)
	const unWrapped = val.match(/@\(.+?\)/gi);
	wrappedArr.map((i, idx) => {
		val = val.replace(unWrapped[idx], i)
	})

	return val;
}

export { Editable, getModuleDefault, moduleOrderButton, moduleDelBtnDefault, moduleDeleteButton, moduleImageUploadButton, moduleImageUploadButtonAction, moduleVideoEmbedButton, moduleVideoEmbedButtonAction, moduleAlignButtonDefault, scrollToModule, stripHTMLlink, wrapLink };