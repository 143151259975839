<template>
  <Layout :bgData="currentCard.cont" :name="currentCard.name" @click="$emit('click')">
    <template v-slot:fixedInfo>
      <div class="p-channel">
        <div v-if="!data.isLangKr" class="p-channel__tit">
          <span class="t-cb--16"><span class="t--fak">Cafe</span></span>    
        </div>
        <div v-else class="p-channel__tit">
          <span class="t-cb--16"><span class="t--fak">카페</span></span>    
        </div>
        <div v-if="!data.isLangKr" class="p-channel__info">
          <span class="t-cb--16"><span class="t--fak">D FLAT® Paju branch is closing its business.</span></span>    
          <div class="t-cb--16 p-channel__info-line"><span class="t--fak">We are grateful for all the love we received during this time, and we will repay it with better service at D FLAT® Seongsu branch.</span></div>    
        </div>
        <div v-else class="p-channel__info p-channel__info--kr">
          <span class="t-cb--16"><span class="t--fak">디플랫 파주점이 영업을 종료합니다.</span></span>    
          <div class="t-cb--16 p-channel__info-line"><span class="t--fak">그 동안의 많은 사랑에 감사드리며, 디플랫 성 수점에서 더 좋은 서비스로 보답하겠습니다.</span></div>    
        </div>
        <div v-if="!data.isLangKr" class="p-channel__location">
          <div class="t-cb--16"><span class="t--fak">April 22, 2020 - January 31, 2023</span></div>
          <!-- <div class="t-cb--16"><span class="t--fak"><span class="t--ft">(</span>10881<span class="t--ft">)</span></span></div> -->
        </div>
        <div v-else class="p-channel__location">
          <div class="t-cb--16"><span class="t--fak">2020년 4월 22일 - 2023년 1월 31일</span></div>
          <!-- <div class="t-cb--16"><span class="t--fak"><span class="t--ft">(</span>10881<span class="t--ft">)</span></span></div> -->
        </div>
        <div class="p-channel__btn-wrap">
          <a class="b-border b-border--square p-channel__btn" href="https://www.instagram.com/dflat_cafe/" target="_blank">
            <span class="b-border__img ico-chatbot--social-instagram"></span>
            <span class="b-border__txt">
              <span class="t-cb--13"><span class="t--fak">DFLAT Cafe</span></span>
            </span>
          </a>
        </div>
        <div class="p-channel__cont">
          <News v-for="(item, index) in currentCard.news" :key="index" :item="item" :person="currentCard.cont"></News>
        </div>
      </div>
      <div class="power">
        <span class="t-cb--11 power__txt"><span class="t--fak">Powered by </span></span>
        <span class="t-cb--11"><span class="t--fak"><span class="t-dfy">DFY</span> 3F</span></span>
      </div>
    </template>
  </Layout>
  
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { ref, reactive, computed, onMounted, onUpdated,watchEffect, onBeforeUnmount, onUnmounted, useStore } from "@/helper/vue.js";
import { addEvent, removeEvent } from "@/helper/event.js";

import Layout from "../_ChatPageLayout.vue";
import News from '../type/_MainNews.vue';

export default {
  props: [ 'currentCard' ],
  emits: [ 'click' ],
  components: {
    Layout,
    News
  },
  setup(props, {emit}) {
    const { state, get, commit, dispatch } = useStore();
    const data = reactive({
      isLangKr: computed(() => state.isLangKr),
    });

    const methods = {
    };

    onMounted(() => {
    });

    onBeforeUnmount(() => {
    })

    return {
      data,
      methods
    }
  }
}
</script>

<style lang="scss" src="@/css/chatbot.scss"></style>
