<template>
  <a :href="data.responseData.pageUrl" class="gip" target="_blank">
    <Giphy class="gip__img" @transmit="methods.transmit" :id="data.selectedId"></Giphy>
    <div class="gip__info-wrap">
      <div class="gip__txt-wrap">
        <div class="gip__txt">
          <span class="t-cb--13"><span class="t--fak">GIPHY</span></span>
        </div>
        <div class="gip__txt gip__txt--soft">
          <span class="t-cb--13"><span class="t--fak">GIF Image, {{ data.size }}</span></span>
        </div>
      </div>
      <div class="gip__txt-wrap">
        <div class="gip__txt gip__txt--soft">
          <span class="t-cb--13"><span class="t--fak">{{ data.source }}</span></span>
        </div>
      </div>
    </div>
  </a>
  
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { reactive, computed, onBeforeUpdate, watchEffect, onUpdated, onMounted, useStore } from "@/helper/vue.js";
import Giphy from "@/parts/giphy/Giphy.vue";

export default {
  props: ['item', 'dataName'],
  components: {
    Giphy,
  },
  setup(props, { emit }) {
    const { state, get, commit, dispatch } = useStore();
    const data = reactive({
      responseData: {}, 
      size: computed(() => {
        let result;
        const size = parseInt((data.responseData.size)/1000);
        if (size/1000 > 1) {
          result = `${parseInt(size/1000)}MB`
        } else {
          result = `${size}KB`
        }
        return result;
      }),
      source: computed(() => {
        let result;
        if (data.responseData.source == "") {
          result = 'https://media.giphy.com'
        } else {
          const sourceArray = _.go(data.responseData.source,
            _.split("/"),
          )
          result = `${sourceArray[0]}//${sourceArray[2]}`;
        }
        return result;
      }),
      pageUrl: '',
      getItems: computed(() => get["chatbot/getItems"](props.dataName)),
      selectedId: computed(() => {
        let selectedId = '';
        if (!props.item.contents.query) {
          _.go(data.getItems,
            _.each(item => {
              const queryData = _.map(item => item.query, item.contents.selectedItem)
              if (props.item.contents.selectId == item.id) {
                selectedId = queryData[0];
              }
            })
          )
        } else {
          selectedId = props.item.contents.query;
        }
        return selectedId;
      })
    });

    const methods = {
      transmit: (response) => {
        data.responseData = response;
      }
    }

    onMounted(() => {
    });

    return {
      data,
      methods,
    }
  }

}
</script>

<style>

</style>