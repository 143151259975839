import axios from 'axios';
import * as _ from 'fxjs';
import * as $ from 'fxdom';
import { getToday } from "@/helper/gadget.js";
import { API_SERVER } from "@/store/appSettings.js";

const INIT_DATA = 'INIT_DATA';

export default {
  namespaced: true,
  state: {
    currentMeta: {
      title: ""
    },
    assetRoot: API_SERVER,
    isAddPopupActive: false,
    isPopupActive: {
      addModulePopup: {
        isOpen: false,
        dataObj: {}
      },
      videoEmbedPopup: {
        isOpen: false,
        dataObj: {}
      }
    },
    addModuleIndex: 0,
    client: null,
    clientGroup: null,
    member: null,
    teams: null,
    test: "test",
    openModal: () => {},
    uploadInfo: {},
    targetRail: null,
    tagetRails: null,
    targetRailIndex: null,
    project2: {
      modules: [
        {
          type: "111",
          brand: "111",
          layout: 0,
        },
        {
          type: "222",
          brand: "222",
          layout: 0,
        }
      ]
    },
    // project state default 
    project: {
      state: "draft",
      id: "new",
      pid: "00000",
      url: "",
      type: "",
      start: getToday(),
      launching: getToday(),
      thumbnail: "",
      bgImg: "",
      headline: "",
      headlineKo: "",
      story: "",
      storyKo: "",
      category: [],
      service: [],
      theme: [],
      options: {
        credit: 1,
        latest: 1,
        recommend: 0,
        trailer: 0,
        private: 0
      },
      brand: "",
      client: {
        id: 1,
        name: "",
        logo: "",
        link: ""
      },
      liveLink: [["Visit Website",""]],
      websiteImg: "",
      color: [
        "171010",
        "F9F5EF"
      ],
      memberSet: {
        agency: [],
        plan: [],
        design: [],
        contents: [],
        develop: [],
        resigned: [],
        partner: []
      },
      meta: {
        image: "",
        title: "",
        desc: "",
        keywords: "",
        tags: ""
      },
      modules: [
        {
          "type": "Kv",
          "visualCaption": "",
          "layout": 0,
          "src": ""
        },
        {
          "type": "Credit",
          "items": [
            {
              "src": ""
            }
          ]
        }
      ]
    }
  },
  mutations: {
    [INIT_DATA](state, project) {
      state.project = project
    }
  },
  actions: {
    async init({ commit, state }, id) {
      try { 
        const res = await axios.get(
          `${state.assetRoot}/api/projects/${id}`
        );
        await commit(INIT_DATA, res.data);
      } catch (e) {
        console.log(e)
      }
    },
  },   
  getters: {
    getProject: state => {
      return state.project;
    }
  },
  computed:{
  }
};