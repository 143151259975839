<template>
  <Layout :bgData="currentCard.cont" :name="currentCard.name" @click="$emit('click')">
    <template v-slot:fixedInfo>
      <div class="p-channel">
        <div v-if="!data.isLangKr" class="p-channel__tit">
          <span class="t-cb--16"><span class="t--fak">Digital Agency</span></span>    
        </div>
        <div v-else class="p-channel__tit">
          <span class="t-cb--16"><span class="t--kr">디지털 에이전시</span></span>    
        </div>
        <div v-if="!data.isLangKr" class="p-channel__info">
          <span class="t-cb--16"><span class="t--fak"><span class="t-dfy">DFY</span> combines digital strategy with emotional design to produce creative solutions that make a difference.︎</span></span>    
        </div>
        <div v-else class="p-channel__info p-channel__info--kr">
          <span class="t-cb--16"><span class="t--kr">디파이의 크리에이티브 DNA에는 강남의 힙하고 강북의 딥한 감각이 담겨 있습니다. 강남과 강북을 잇는 비즈니스의 중심지 신사에서 디파이를 만나보세요.</span></span>    
        </div>
        <div v-if="!data.isLangKr" class="p-channel__location">
          <div class="t-cb--16"><span class="t--fak">20, Gangnam-daero 140-gil,</span></div>
          <div class="t-cb--16"><span class="t--fak">Gangnam-gu, Seoul, Korea <span class="t--ft">(</span>06043<span class="t--ft">)</span></span></div>
        </div>
        <div v-else class="p-channel__location">
          <div class="t-cb--16"><span class="t--kr">서울특별시 강남구 강남대로 140길 20</span></div>
          <div class="t-cb--16"><span class="t--fak"><span class="t--ft">(</span>06043<span class="t--ft">)</span></span></div>
        </div>
        <div class="p-channel__btn-wrap">
          <a class="b-border b-border--square p-channel__btn" href="/" router-to="/">
            <span class="b-border__img ico-chatbot--content-language"></span>
            <span class="b-border__txt">
              <span class="t-cb--13"><span class="t--fak">DFY.CO.KR</span></span>
            </span>
          </a>
          <a class="b-border b-border--square p-channel__btn" href="mailto:hello@dfy.co.kr " target="_blank">
            <span class="b-border__img ico-chatbot--content-mail"></span>
            <span class="b-border__txt">
              <span class="t-cb--13"><span class="t--fak">MAIL</span></span>
            </span>
          </a>
          <a class="b-border b-border--square p-channel__btn" href="https://www.instagram.com/dfy.seoul/" target="_blank">
            <span class="b-border__img ico-chatbot--social-instagram"></span>
          </a>
          <!-- <a class="b-border b-border--square p-channel__btn" href="https://v3.vuejs.org/" target="_blank">
            <span class="b-border__img ico-chatbot--social-facebook"></span>
          </a> -->
          <a class="b-border b-border--square p-channel__btn" href="https://www.behance.net/DEFYTHECURRENT" target="_blank">
            <span class="b-border__img ico-chatbot--social-behance"></span>
          </a>
        </div>
        <div class="p-channel__cont">
          <News v-for="(item, index) in currentCard.news" :key="index" :item="item" :person="currentCard.cont"></News>
        </div>
      </div>
      <div class="power">
        <span class="t-cb--11 power__txt"><span class="t--fak">Powered by </span></span>
        <span class="t-cb--11"><span class="t--fak"><span class="t-dfy">DFY</span> 3F</span></span>
      </div>
    </template>
  </Layout>
  
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { ref, reactive, computed, onMounted, onUpdated,watchEffect, onBeforeUnmount, onUnmounted, useStore } from "@/helper/vue.js";
import { addEvent, removeEvent } from "@/helper/event.js";

import Layout from "../_ChatPageLayout.vue";
import News from '../type/_MainNews.vue';

export default {
  props: [ 'currentCard', 'subpage' ],
  emits: [ 'click' ],
  components: {
    Layout,
    News
  },
  setup(props, {emit}) {
    const { state, get, commit, dispatch } = useStore();
    const data = reactive({
      isLangKr: computed(() => state.isLangKr),
    });

    const methods = {
    };

    onMounted(() => {
    });

    onBeforeUnmount(() => {
    })

    return {
      data,
      methods
    }
  }
}
</script>

<style lang="scss" src="@/css/chatbot.scss"></style>
