<template>
	<div class="credits" :class="{ 'credits--open' : data.isOpen == true }">
		<div class="credits__video">
			<div class="credits__video-pad">
				<video id="creditVideo" loop playsinline muted>
					<source src="@/assets/common/credit-movie.mp4" type="video/mp4">
				</video>
			</div>
		</div>

		<div class="credits__cont s--center">
			<div class="l-row">
				<div class="c-pic c-logo">
					<span class="c-logo--dfy">
						<svg viewBox="0 0 214 64" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M156.392 0L177.158 31.1349L198.277 0H213.333L183.608 42.3473V64H170.709V42.4889L140.912 0H156.392ZM33.6287 0C54.9258 0 67.5896 9.26195 67.5896 31.9996C67.5896 54.5098 55.1778 63.8125 34.265 63.9963L33.6287 63.9991H0V0H33.6287ZM134.824 0V10.9507H90.1331V27.4282H132.32V37.8894H90.1331V63.9991H77.2382V0H134.824ZM33.0939 10.7562H12.9188V53.243H33.0939C47.0929 53.243 55.5256 47.077 55.5256 31.9996C55.5256 16.9221 47.0929 10.7562 33.0939 10.7562Z"/>
						</svg>
					</span>
					<span class="c-logo--r">
						<svg viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1.20821 0.876712C0.620512 1.46575 0.333496 2.19178 0.333496 3.0274C0.333496 3.86301 0.620512 4.57534 1.20821 5.13699C1.79591 5.71233 2.50662 6 3.34033 6C4.17404 6 4.88475 5.71233 5.47245 5.13699C6.04648 4.57534 6.3335 3.86301 6.3335 3.0137C6.3335 2.17808 6.04648 1.46575 5.47245 0.876712C4.88475 0.287671 4.17404 0 3.34033 0C2.50662 0 1.79591 0.287671 1.20821 0.876712ZM1.5499 4.83562C1.11254 4.38356 0.893861 3.78082 0.893861 3.0274C0.893861 1.47945 1.80958 0.493151 3.34033 0.493151C4.87108 0.493151 5.7868 1.47945 5.7868 3.0137C5.7868 3.76712 5.56812 4.38356 5.13076 4.83562C4.6934 5.28767 4.09204 5.52055 3.34033 5.52055C2.58862 5.52055 1.98725 5.28767 1.5499 4.83562ZM3.91436 3.34247C4.42006 3.23288 4.73441 2.84931 4.73441 2.34247C4.73441 1.75342 4.25605 1.38356 3.54534 1.38356H2.20593V4.46575H2.83464V3.38356H3.42233L4.0237 4.46575H4.83008L4.25605 3.65753L3.91436 3.34247ZM3.65468 1.94521C3.9417 1.94521 4.09204 2.08219 4.09204 2.34247C4.09204 2.64384 3.95536 2.79452 3.68202 2.79452H2.83464V1.94521H3.65468Z"/>
						</svg>
					</span>
				</div>
				<div class="l-mdl--32 c-logo-dsc">
					<div class="t-h--18 t-h--16s">
						<span class="t-block t--fak">
							<span class="t-dfy">DFY</span>
							WEBSITE
							<img class="round-txt" src="@/assets/credits-round-txt.svg" alt="4th">
							RENEWAL © 2K22
						</span>
					</div>
				</div>
			</div>

			<div class="l-row--t64 row-blt">
				<ul class="credits__list credits__cont-inner">
					<li class="credits__member">
						<div class="t-h--13 t-h--11s">
							<span class="t-block t--fak">
								<span class="t--ft">(</span>CREATIVE AGENCY<span class="t--ft">)</span>
							</span>
						</div>
						<div class="t-h--18 t-h--16s l-mdl--8">
							<span class="t-block t--fak">
								<span class="t-dfy">DFY</span>
							</span>
						</div>
					</li>
					<li class="credits__member" v-for="(member, index) in data.member" :key="index">
						<div class="t-h--13 t-h--11s">
							<span class="t-block t--fak">
								<span class="t--ft">(</span>{{member.position}}<span class="t--ft">)</span>
							</span>
						</div>
						<div class="t-h--18 t-h--16s l-mdl--8">
							<span class="t-block t--fak" v-html="member.name"></span>
						</div>
					</li>
				</ul>
					<!-- :is-block-content="true" -->
        	<!-- :position-mo="[80, 'auto', 'auto', 0]" -->
				
			</div>

			<div class="l-row--t64 row-blt">
				<div class="credits__cont-inner">
					<div class="t-h--13 t-h--11s">
						<span class="t-block t--fak">
							<span class="t--ft">(</span>PERFORMERS<span class="t--ft">)</span>
						</span>
					</div>
					<div class="g-row l-mdl--16 l-box credits__guests">
						<ul class="g-col--5 g-col--3l l-box__l" data-swap=".credits__guests-c-wrap|prepend|.credits__guests|prepend">
							<li class="t-h--18 t-h--16s l-mdl--4" v-for="(guestList01, index) in data.guestList01" :key="index">
								<span class="t-block t--fak">{{guestList01}}</span>
							</li>
						</ul>
						<div class="g-col--5  g-col--3l l-box__m credits__guests-c-wrap">
							<ul class="l-box__m-t" data-swap=".credits__guests|prepend|.credits__guests-c-wrap|prepend">
								<li class="t-h--18 t-h--16s l-mdl--4" v-for="(guestList02, index) in data.guestList02" :key="index">
									<span class="t-block t--fak">{{guestList02}}</span>
								</li>
							</ul>
							<ul class="l-box__m-b" data-swap=".credits__guests|append|.credits__guests-c-wrap|append">
								<li class="t-h--18 t-h--16s l-mdl--4" v-for="(guestList03, index) in data.guestList03" :key="index">
									<span class="t-block t--fak">{{guestList03}}</span>
								</li>
							</ul>
						</div>
						<ul class="g-col--5 g-col--3l l-box__r" data-swap=".credits__guests-c-wrap|append|.credits__guests|append">
							<li class="t-h--18 t-h--16s l-mdl--4" v-for="(guestList04, index) in data.guestList04" :key="index">
								<span class="t-block t--fak">{{guestList04}}</span>
							</li>
						</ul>
					</div>
					<div class="credits__member l-mdl--64">
						<div class="t-h--13 t-h--11s">
							<span class="t-block t--fak">
								<span class="t--ft">(</span>SPECIAL GUEST<span class="t--ft">)</span>
							</span>
						</div>
						<div class="t-h--18 t-h--16s l-mdl--8">
							<span class="t-block t--fak">S.M.S</span>
						</div>
					</div>
				</div>
			</div>

			<div class="l-row--t64 row-blt">
				<div class="credits__cont-inner">
					<div class="t-h--13 t-h--11s">
						<span class="t-block t--fak">
							<span class="t--ft">(</span>Special Thanks<span class="t--ft">)</span>
						</span>
					</div>
					<div class="g-row l-mdl--8">
						<div class="g-col--3 g-col--3s">
							<img src="@/assets/common/3.jpg" alt="">
						</div>
					</div>
					<div class="t-h--18 t-h--16s l-mdl--8">
						<span class="t-block t--fak">3</span>
					</div>
				</div>
			</div>

			<div class="l-row--t64 row-blt">
				<div class="credits__cont-inner">
					<div class="credits__member">
						<div class="t-h--13 t-h--11s">
							<span class="t-block t--fak">
								<span class="t--ft">(</span>BGM<span class="t--ft">)</span>
							</span>
						</div>
						<div class="t-h--18 t-h--16s l-mdl--8">
							<span class="t-block t--fak">DEEP CHILL 420 <span class="s--lowercase">by</span> MATTIJS MULLER</span>
						</div>
					</div>
					<div class="credits__member">
						<div class="t-h--13 t-h--11s">
							<span class="t-block t--fak">
								<span class="t--ft">(</span>UI SOUND<span class="t--ft">)</span>
							</span>
						</div>
						<div class="t-h--18 t-h--16s l-mdl--8">
							<span class="t-block t--fak">Roman Zimarev (Great Simple Studio)</span>
						</div>
					</div>
					<div class="credits__member">
						<div class="t-h--13 t-h--11s">
							<span class="t-block t--fak">
								<span class="t--ft">(</span>TYPEFACES<span class="t--ft">)</span>
							</span>
						</div>
						<div class="t-h--18 t-h--16s l-mdl--8">
							<span class="t-block t--fak credits__fonts">Faktum <span class="s--lowercase">by</span> Studio René Bieder <br>Editorial New <span class="s--lowercase">by</span> Pangram Pangram <br>YoonGothic700 <span class="s--lowercase">by</span> YoonDesign</span>
						</div>
					</div>
				</div>
			</div>

			<div class="l-row--t64 row-blt">
				<div class="credits__cont-inner">
					<div class="happy-svg">
						<img src="@/assets/credits-happy.svg" alt="">
					</div>
					<div class="g-row l-mdl--64">
						<div class="g-col--15 t-h--18 t-h--16s">
							<span class="t--fak">
								<span class="t-dfy">DFY</span>,
								INC © 2K11 - {{new Date().getFullYear().toString().substring(2)}}
							</span>
						</div>
						<div class="g-col--13 g-col--9l t-h--11 l-mdl--16">
							<p class="t--fak">
							DFY® combines digital strategy with emotional design to produce creative solutions that make a difference, and merges branding and advertising with digital platforms for authentic brand experiences that connect with the consumer.
							</p>
						</div>
						<div class="g-col--15 t-h--18 t-h--16s l-mdl--16">
							<span class="t--fak">TODAY’S CREATIVE for TOMORROW™</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as $ from "fxdom";
import { ref, reactive, onMounted, onUnmounted, onActivated, onDeactivated } from "@/helper/vue.js";
import { moveElement } from "@/helper/gadget.js";
import { addEvent } from "@/helper/event.js";
import { gsap } from "gsap/all";
import { nextTick } from '@vue/runtime-core';

export default {
	setup(props) {
		// 현재 템플릿 ROOT를 DOM으로 접근
		// 템플릿에서 사용되는 데이터
		const data = reactive({
			isOpen: false,
			member: [
				{
					position: "Creative Direction + Design + Planning",
					name: "Gwon soongyu"
				},
				{
					position: "Digital Product Strategy",
					name: "Erin Bai"
				},
				{
					position: "Development Strategy + Direction",
					name: "Yu Choongyeol"
				},
				{
					position: "UI Interaction",
					name: "Hwang Geommyeong, Jeon Hogeun, <br>Kwon Hyunji, Oh Saem, Yoo Dasol"
				},
				{
					position: "Publishing",
					name: "Choi Hyeji, Gu Yujung, Song Eunji"
				},
				{
					position: "Back-End Development",
					name: "Kim Chuljoong, Lee Minji"
				},
				{
					position: "API Integration",
					name: "Hwang Geommyeong"
				},
				{
					position: "Copywriting and Editing",
					name: "Dana, Erin Bai, Lee Myeongyeon"
				},
				{
					position: "Photo Shoot",
					name: "Gwon Soongyu"
				},
				{
					position: "2D Motion Design",
					name: "Jang Semin"
				},
				{
					position: "3D Motion Deign",
					name: "Kim Wonkyu"
				}
			],
			guestList01: [ "Oh Saemee", "Kim Eunbit", "Lee Suji", "Yeon Ayoung", "Hyun Yeji", "Kim Minwoo", "Jeon Hogeun", "Hwang Ilmin" ],
			guestList02: [],
			guestList03: [ "CHOI JIHOON", "KIM WHEEKYOUNG", "JEONG YOUNGSUK", "YU CHOONGYEOL", "KIM JUNYOUNG", "HONG HEEJUNG", "JUN HONGRAN", "KIM JUNGHOON", "TABBY" ],
			guestList04: [ "JUNG YEONJU", "OH SAEM", "JO YOONSIK", "GWON SOONGYU", "JUNG HAECHAN", "KIM HYUNMO", "JOUNG JIEUN", "NERO" ],
		});
		// 템플릿에서 사용되는 메소드
		const method = {
			videoControl(openValue) {
				// $.setCss({ display: "none" }, document.getElementById("creditVideo"));
				// return;
				const credit_vid = document.getElementById("creditVideo");
				if(openValue){
					credit_vid.play();
				}else{
					setTimeout(() => {
						credit_vid.pause();
						credit_vid.currentTime = 0;
					}, 1100);
				}
			},
		};

		// DOM 접근 가능 시점
		onMounted(() => {
		});

		let tl = null;
		onActivated(() => {
			const creditCont = document.querySelector('.credits__cont');
			let creditContH;
			let creditDuration;
			const creditMotion = () => {
				//credit translate Y 값
				creditContH = creditCont.clientHeight;
				//credit 속도
				creditDuration = Math.floor(creditContH / 80);

				//credit timeline
				tl =  gsap.timeline({
					onComplete:function() {
						this.restart();
					},
				});
				tl.fromTo(creditCont, {y: 0, top : "100vh"}, {y: "-100%", top : 0, duration: creditDuration, delay:1, ease: "linear"});
				// tl.set(creditCont, { y: "-251vh" });
				if(data.isOpen){
					tl.resume();
				}else{
					tl.pause();
				}
			}
			nextTick(() => {
				data.isOpen = true
				creditMotion();
				method.videoControl(data.isOpen);
			})
			moveElement();
			window.addEventListener('changed.mq', () => {
				moveElement();
			});
		});
		onDeactivated(() => {
			data.isOpen = false
			tl.kill();
			window.removeEventListener('changed.mq', () => {
				moveElement();
			});
		})

		// window, document 등 Unmount되지 않는 이벤트 해제를 위해 사용
		onUnmounted(() => {
			// data.isOpen = false
			// window.removeEventListener('changed.mq', () => {
			// 	moveElement();
			// });
		});

		return {
			// self,
			data,
			method
		};
	}
}
</script>
<style scoped lang="scss" src="@/css/layerPop.scss"></style>

<style lang="scss">
.credits{
  $self: &;
	height: 100vh;
	overflow: hidden;
  background: #171010;
	color:#EBE3D7;
	text-transform: uppercase;
  .t-dfy{
    color:#EBE3D7;
    &::after{
      color:#EBE3D7;
    }
  }
  &__video{
		// opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(var(--baseWidth));
    height: 100vh;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    -webkit-transition: all .8s .5s ease-in;
    transition: all .8s .5s ease-in;
    video{
			position: absolute;
			left: 0;
			top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      .w1600__ &{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
	&__video-pad {
		position: absolute;
		left: 50%;
		top: 50%;
		padding-top: 75%;
		width: 100%;
		transform: translate(-50%, -50%);
		opacity: 0;
		transition: opacity 0.8s 0.5s ease-in;
		.__w600 & {
			padding-top: 100%;
		}
	}
  &__cont{
    position: absolute;
    top: 100vh;
    left: 0;
    width: 100%;
    z-index: 3;
		&-inner{
			position: relative;
			z-index: 2;
		}
  }
  &__member{
    margin-top: 32em;
    &:first-of-type{
      margin-top:0;
    }
  }
	&__fonts {
		.__w600 & {
			line-height: 1.35 !important;
		}
	}
	&__guests {
		.__w600 & {
			margin-top: 0;
		}
	}
	&__guests-c-wrap{
		.__w600 &{
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			margin: 16em 0;
			ul{
				flex-basis: auto;
    		width: 50%;
			}
		}
	}
  .happy-svg {
    width: 236em;
    margin: 0 auto;
  }
	.bg-item{
		z-index:1;
		&__target{
			position:absolute;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	.t-block{
		text-indent: 0 !important;
	}
}
.credits--open{
	.credits__video{
		width: calc(var(--cw9));
		font-size: 1px;
		.w1600__ &{
			width: calc(var(--cw7));
		}
		.__w600 &{
			font-size: inherit;
			width: calc(var(--cw4));
		}
		// padding-top: 51.4vh;
		img{
			width: 100%;
			height: auto;
		}
	}
	.credits__video-pad {
		opacity: 0.85;
	}
}

// .with-modal-popup .i-canvas{
//   height : 100vh;
// }
// .with-modal-popup .c-noise{
// 	&:before {
//     z-index: 1003;
//   }
// }
// .with-modal-popup .page-title{
// 	z-index: -1;
// }
</style>