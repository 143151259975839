<template>
  <div ref="self" class="balloon">
    <div v-if="item.date" class="balloon__date-wrap">
      <span class="t-cb--13"><span class="t--fak balloon__date">0:00 AM</span></span>
    </div>

    <!-- submit-button : input 이후 submit -->
    <template v-if="item.contents.normalType == 'submit-button'">
      <button class="balloon__box balloon__box--btn balloon__box--submit" @click="methods.setInputState">
        <span v-if="!data.pass" class="t-cb--28"><span :class="!data.isLangKr ? 't--fak' : 't--kr'">{{ data.propText }}</span></span>
        <span v-else class="t-cb--28">
          <span :class="!data.isLangKr ? 't--fak' : 't--kr'">
            <span v-if="!data.isLangKr">Submitting</span>
            <span v-else>
              <span v-if="dataName == 'project'">제출 중</span>
              <span v-else-if="dataName == 'joinTeam'">등록 중</span>
              <span v-else>접수 중</span>
            </span>
          </span>
        </span>
        <span class="balloon__dot balloon__dot--1"></span>
        <span class="balloon__dot balloon__dot--2"></span>
        <span class="balloon__dot balloon__dot--3"></span>
      </button>
    </template>

    <!-- confirm-button : 공고 읽음 확인 -->
    <template v-else-if="item.contents.normalType == 'confirm-button'">
      <button class="balloon__box balloon__box--btn balloon__box--confirm" @click="methods.confirmReading" :disabled="data.confirmState">
        <span class="t-cb--16"><span :class="!data.isLangKr ? 't--fak' : 't--kr'">{{ data.propText }}</span></span>
        <span class="b-arrow b-confirm__arrow">
          <span class="b-arrow__img ico-chatbot--content-done"></span>
        </span>
      </button>
    </template>

    <!-- replay-button : 전체 다시 작성 버튼 -->
    <template v-else-if="item.contents.normalType == 'replay-button'">
      <button class="b-confirm" @click="methods.replay(dataName)">
        <div class="balloon__txt">
          <span v-if="!data.isLangKr" class="t-cb--16"><span class="t--fak">Go back</span></span>
          <span v-else class="t-cb--16"><span class="t--kr">다시 작성하기</span></span>
        </div>
        <span class="b-arrow b-confirm__arrow">
          <span class="b-arrow__img ico-chatbot--navi-arrow--forward"></span>
        </span>
      </button>
    </template>

    <!-- 일반 normal text -->
    <div v-else class="balloon__box" :class="{ 'balloon__box--user': !item.fromWho, 'balloon__box--user-submit': item.contents.normalType == 'submit-confirm' }">
      <img class="balloon__react" v-if="item.react == 'thumbsUp'" src="@/assets/messenger/react-thumbsup.svg" alt="">
      <img class="balloon__react" v-if="item.react == 'like'" src="@/assets/messenger/react-like.svg" alt="">
      
      <span v-if="!item.contents.htmlTag" class="t-cb--16"><span :class="!data.isLangKr ? 't--fak' : 't--kr'">{{ item.contents.selectId ?  data.selectedText : data.propText }}</span></span>
      <span v-else class="t-cb--16"><span :class="!data.isLangKr ? 't--fak' : 't--kr'" v-html="data.propText"></span></span>
      
      <span v-if="item.contents.normalType == 'restart'" class="b-restart-wrap">
        <span class="t-cb--16"><span class="t--fak">🎉🎉🎉 </span></span>
        <!-- <button class="b-restart" @click="methods.playConfetti">
          <span class="b-restart__img ico-chatbot--media-replay"></span>
          <span v-if="!data.isLangKr" class="b-restart__txt"> <span class="t-cb--13"><span class="t--fak">Play again</span></span> </span>
          <span v-else class="b-restart__txt"> <span class="t-cb--13"><span class="t--kr">다시 재생</span></span> </span>
        </button> -->
      </span>
    </div>

  </div>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { getCurrentTime } from "@/helper/gadget.js";
import { reactive, ref, computed, onBeforeUpdate, onUpdated, watch, onMounted, useStore } from "@/helper/vue.js";

export default {
  props: [ 'item', 'index', 'checkedItem', 'dataName' ],
  setup(props) {
    const { state, get, commit, dispatch } = useStore();

    const data = reactive({
			isMuted: computed(() => state.isMuted),
      isLangKr: computed(() => state.isLangKr),
      getItems: computed(() => get["chatbot/getItems"](props.dataName)),
      randomNum: Math.floor(Math.random() * 2),
      confirmState: false,
      propText: computed(() => {
        const propText = props.item.contents.text;
        return propText.length > 1 ? propText[data.randomNum] : propText[0];
      }),
      selectedText: computed(() => {
        let selectedText = '';
          _.go(data.getItems,
            _.each(item => {
              if (props.item.contents.selectId == item.id) {
                const itemText = _.map(item => item.hidden == undefined ? item.shown : item.hidden, item.contents.selectedItem)
                
                const itemLength = itemText.length;
                if (item.contents.selectInfo) {
                  // selectedItem이 여러개인 경우
                  if (props.item.contents.selectId && props.item.contents.text) {
                    // 텍스트 안에 selectedText 들어가는 경우
                    const propText = props.item.contents.text;
                    const text = propText.length > 1 ? propText[data.randomNum] : propText[0];
                    const replaceText = `${itemText[0]} ${itemLength > 1 ? `(+${itemLength - 1})` : ''}`;
                    selectedText = text.replace('[:receiveText]', replaceText);
                  } else {
                    // selectedText 나열
                    for (let i=0; i<itemLength-1; i++) {
                      selectedText = selectedText + `${itemText[i]}, `;
                    }
                    selectedText = selectedText + itemText[itemLength-1];
                  }
                } else {
                  // selectedItem이 한개인 경우
                  selectedText = itemText[0];
                }
              }
            })
          )
        return selectedText;
      }),
      pass: false,
    });

    const self = ref();

    console.log()

    const methods = {
      setInputState: () => {
        commit('chatbot/setInputState', {dataName: props.dataName, change: 'checkValidation'});
      },
      playConfetti: () => {
        return
        commit('chatbot/changeApplyCompleted', true);
      },
      confirmReading: () => {
        data.confirmState = true;
        $.addClass("balloon__box--confirm-hide", $.find(".balloon__box--confirm", self.value))
        dispatch('chatbot/addTimer', {delay: 300, callback: () => {
          $.setCss( { display: 'none' }, self.value);
          dispatch('chatbot/executeNextEvent', {nextId: props.item.nextId, dataName: props.dataName});
        }})
      },
      replay: (name) => {
        console.log("replay");
        commit('chatbot/removeHistoryItem', {index: 0, dataName: name});
        commit('chatbot/endState', {dataName: name, end: false});
        dispatch('chatbot/addTimer', {delay: 100, callback: () => {
          commit('chatbot/executeFirstItem', name);
        }});
      }
    }

    const getTime = () => {
      const clock = $.find(".balloon__date", self.value);
      clock.innerText = `${getCurrentTime().hours < 12? `${getCurrentTime().hours == 0 ? 12 : getCurrentTime().hours}`:`${getCurrentTime().hours - 12}`}:${getCurrentTime().minutes < 10 ? `0${getCurrentTime().minutes}` : getCurrentTime().minutes} ${getCurrentTime().hours < 12? 'AM ' : 'PM '}`;
    }
    const addReact = () => {
      dispatch('chatbot/addTimer', {delay: 900, callback: () => {
        $.addClass("balloon__react--show", $.qs(".balloon__react"))
        dispatch('chatbot/addTimer', {delay: 500, callback: () => {
          dispatch('chatbot/executeNextEvent', {nextId: props.item.nextId, dataName: props.dataName});
        }});
      }});
    }

    const playDoneAudio = () => {
      if(data.isMuted) return;
      $.qs(".done-beep").currentTime = 0;
      $.qs(".done-beep").play();
    }

    onMounted(() => {
      if (props.item.date) {
        getTime();
      }
      if (props.item.react) {
        addReact();
      }
      if (props.item.contents.checkQuery) {
        _.go(data.getItems,
          _.each(item => {
            if (props.item.contents.selectId == item.id) {
              dispatch('chatbot/addTimer', {delay: 1000, callback: () => {
                if (item.contents.selectedItem[0].query) {
                  dispatch('chatbot/executeNextEvent', {id: props.item.id, nextId: props.item.nextId, dataName: props.dataName});
                } else {
                  dispatch('chatbot/executeNextEvent', {id: props.item.id, nextId: props.item.noQueryNextId, dataName: props.dataName});
                }
              }})
            }
          })
        )
      }

      if (props.item.contents.normalType == 'submit-button') {
        watch(
          () => state.chatbot.inputState[props.dataName].passValidation,
          (curr, prev) => {
            if (state.chatbot.inputState[props.dataName].passValidation) {
              const targetSubmit = $.find(".balloon__box--submit", self.value);
              $.addClass("balloon__box--sub-complete", targetSubmit);
              $.removeClass("balloon__box--submit", targetSubmit);
              data.pass = true;
            }
          }
        );
        watch(
          () => state.chatbot.mailSent[props.dataName], 
          () => {
          if (state.chatbot.mailSent[props.dataName]) {
            $.addClass("balloon__box--sub-complete-hide", $.qs(".balloon__box--sub-complete", self.value))
            dispatch('chatbot/addTimer', {delay: 300, callback: () => {
              $.setCss( { display: 'none' }, self.value);
              playDoneAudio();
              // commit('chatbot/changeApplyCompleted', true);
              dispatch('chatbot/executeNextEvent', {nextId: props.item.nextId, dataName: props.dataName});
              commit('set', { sel: `chatbot.mailSent.${props.dataName}`, to: false }, { root: true });
            }});
          }
        })
      }
    });

    return {
      data,
      methods,
      self
    };
  },


}

</script>

<style>

</style>