<template>
	<div ref="self" class="mqt" :class="{
		'mqt--reverse': reverse == true
	}">
		<div class="mqt__style"></div>
		<div class="mqt__scroller">
			<div class="mqt__item-wrap">
				<div
					class="mqt__item"
					v-for="(item, index) in data.itemCount" :key="index"
				>
					<slot></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { gsap } from "gsap/all";
import { stPos } from "@/helper/gadget.js";
import { ref, reactive, onMounted, onBeforeUnmount, useStore } from "@/helper/vue.js";
export default {
	props: [ "triggerTarget", "reverse", "firstDelay", "timeScale"],
	setup(props) {
		const { state, commit } = useStore();
		const self = ref();
		const data = reactive({
			itemCount: 2
		});

		// 기준: 1초 기준 30px
		let timeScale = props.timeScale ? props.timeScale : 1;
		let selfSlideAmount = 50;
		let calcedDuration = 0;
		let scrollMoveAmount = 300;

		let tl = null;

		// 키프레임 애니메이션 별도 적용을 위한 고유 ID 생성
		let scopeId = state.marqueeId;
		commit("set", { sel: "marqueeId", to: scopeId + 1 });

		let itemWidth = 0;
		let containerWidth = 0;
		let itemWrap = null;
		let scroller = null;

		const setScrollTrigger = () => {
			if(state.isMobileAgent) return;
			let trigger, start, end;
			// 초기 로딩에서 화면에 보이는 경우, 스크롤트리거가 등록되면서 튕기는 경우를 방지하기 위해서 현재 위치의 프로그레스를 0으로 맞춰준다.
			if(state.vh > self.value.getBoundingClientRect().top) {
				trigger = ".i-container";
				start = e => stPos(e, 0, 0);
				end = () => state.vh + self.value.getBoundingClientRect().height;
			}
			// 로딩완료 시점에 화면에 보이지 않으면 대상이 화면에 보이는 동안만
			else {
				trigger = props.triggerTarget ? props.triggerTarget : self.value;
				start = e => stPos(e, 0, 1);
				end = e => stPos(e, 1, 0);
			}

			tl = gsap.timeline({
				scrollTrigger: {
					trigger,
					start,
					end,
					scrub: true,
				}
			});
			tl.to(scroller, { x: () => props.reverse == true ? scrollMoveAmount : -scrollMoveAmount });
		}

		const calcItemCount = () => {
			setTimeout(() => {
				// 아이템 갯수 계산
				containerWidth = state.cw + scrollMoveAmount;
				if(!$.qs(".mqt__item", self.value)) return;
				itemWidth = $.qs(".mqt__item", self.value).clientWidth;
				data.itemCount = Math.floor(containerWidth / itemWidth) + 2;

				// 시작 클래스 추가, css 변수 설정
				scroller = $.qs(".mqt__scroller", self.value);
				itemWrap = $.qs(".mqt__item-wrap", self.value);
				$.removeClass("mqt--play mqt--play-reverse", self.value);
				itemWrap.style.setProperty('--itemWidth', itemWidth);

				// dynamic 키프레임 애니메이션
				const keyFrames = `
					<style ="text/css">
						@keyframes self-to-left-${scopeId} {
							0% { transform: translate3d(0, 0, 0) }
							100% { transform: translate3d(-${itemWidth}px, 0, 0) }
						}
						@keyframes self-to-left-r-${scopeId} {
							0% { transform: translate3d(-${itemWidth}px, 0, 0) }
							100% { transform: translate3d(0, 0, 0) }
						}

						@keyframes self-to-right-${scopeId} {
							0% { transform: translate3d(0, 0, 0) }
							100% { transform: translate3d(${itemWidth}px, 0, 0) }
						}
						@keyframes self-to-right-r-${scopeId} {
							0% { transform: translate3d(${itemWidth}px, 0, 0) }
							100% { transform: translate3d(0, 0, 0) }
						}
					</style>
				`;
				$.qs(".mqt__style", self.value).innerHTML = keyFrames;

				// 마키 아이템 자체의 길이와 상관없이 동일한 속도로 움직일 수 있도록 계산
				calcedDuration = (itemWidth / selfSlideAmount) * timeScale;
				itemWrap.style.setProperty("animation-duration", `${calcedDuration}s`);

				// base 방향에 따른 초기 애니메이션 설정
				if(props.reverse == true) {
					itemWrap.style.setProperty("animation-name", `self-to-right-${scopeId}`);
				}
				else {
					itemWrap.style.setProperty("animation-name", `self-to-left-${scopeId}`);
				}

				props.firstDelay && itemWrap.style.setProperty("animation-delay", `${props.firstDelay}s`);

				// 스크롤트리거 등록
				if(tl == null) {
					// 페이지 로딩 없이 컴포넌트가 동적으로 변할 경우 스크롤트리거를 바로 적용한다.
					if(state.loadingState == "complete") {
						setScrollTrigger();
					}
					// 페이지로딩중일 때는 로딩완료 이벤트에 스크롤트리거를 예약한다.
					else {
						window.addEventListener("loader.complete", setScrollTrigger);
					}
				}
			});
		};

		const CB_CHANGED_SCROLL_DIR = ({ detail }) => {
			const currentProgress = Math.abs(itemWrap.getBoundingClientRect().x - scroller.getBoundingClientRect().x) / itemWidth;
			if(detail.dir == "down") {
				const calcedDelay = -(currentProgress * calcedDuration);
				itemWrap.style.setProperty("animation-delay", `${calcedDelay}s`);
				if(props.reverse == true) {
					itemWrap.style.setProperty("animation-name", `self-to-right-${scopeId}`);
				}
				else {
					itemWrap.style.setProperty("animation-name", `self-to-left-${scopeId}`);
				}
			}
			else {
				const calcedDelay = (currentProgress * calcedDuration) - calcedDuration;
				itemWrap.style.setProperty("animation-delay", `${calcedDelay}s`);
				if(props.reverse == true) {
					itemWrap.style.setProperty("animation-name", `self-to-right-r-${scopeId}`);
				}
				else {
					itemWrap.style.setProperty("animation-name", `self-to-left-r-${scopeId}`);
				}
			}
		}

		onMounted(() => {
			// 스플래시 마키는 폰트를 가져올 때 까지 기다림
			if(document.readyState != "complete") {
				window.addEventListener("load", () => {
					calcItemCount();
				}, { once: true });
			}
			else {
				calcItemCount();
			}

			window.addEventListener("changed.scroll.direction", CB_CHANGED_SCROLL_DIR);
			window.addEventListener("resize-x", calcItemCount);
			// window.addEventListener("landed", calcItemCount);
		});

		onBeforeUnmount(() => {
			if(tl) {
				gsap.killTweensOf(tl);
				tl.scrollTrigger && tl.scrollTrigger.kill();
				tl.kill();
			}
			window.removeEventListener("loader.complete", setScrollTrigger);
			window.removeEventListener("changed.scroll.direction", CB_CHANGED_SCROLL_DIR);
			window.removeEventListener("resize-x", calcItemCount);
			// window.removeEventListener("landed", calcItemCount);
		})

		return {
			self,
			data
		};
	}
}
</script>

<style scoped lang="scss">
.mqt {
	position: relative;
	overflow: hidden;
	z-index: 30;
	&__item-wrap {
		position: relative;
		display: flex;
		white-space: nowrap;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		z-index: 30;
	}
	&__item {
		display: inline-flex;
		will-change: transform;
	}
	&--reverse {
		.mqt__item-wrap {
			flex-direction: row-reverse;
		}
	}
	&--play {
		.mqt__item-wrap {
		}
	}
}

// temp
// .mqt {
// 	padding: 20px 0;
// 	&__item {
// 		// border: solid 1px;
// 	}
// }
</style>