<template>
  <div class="sub-video">
    <video ref="self" autoplay loop class="sub-video__bg" src="@/assets/messenger/sub-video.mp4" type="video/mp4">
    </video>
    <div class="sub-video__head">
      <button class="b-back b-hover--black sub-video__btn" @click="methods.setSubSubpageState('video', null)">
        <span class="head__icon b-icon__img b-hover__icon ico-chatbot--navi-navigate--back"></span>
      </button>
      <div class="sub-video__location">
        <span class="t-cb--28"><span class="t--fak">{{ bgData.info }}</span></span>
      </div>
      <img class="sub-video__face" :src="bgData.facePath" alt="">
    </div>
    <div class="sub-video__info">
      <div class="sub-video__state">
        <div class="sub-video__state-light"></div>
        <div class="sub-video__state-txt"><span class="t-cb--11"><span class="t--fak">REC</span></span></div>
      </div>
      <div class="sub-video__time">
        <span class="t-cb--13"><span class="t--fak">{{ data.recTime }}</span></span>
      </div>
    </div>
    <div class="sub-video__heart">
      <Infinite :type="'video'" :likeCount="'19'"></Infinite>
    </div>
  </div>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { reactive } from '@vue/reactivity'
import { ref, onMounted, onActivated, onDeactivated, useStore, computed, watchEffect } from '@/helper/vue.js';
import Infinite from '../type/_InfiniteClick.vue';
import { watch } from '@vue/runtime-core';

export default {
  components: { Infinite },
  props: [ 'videoState', 'bgData' ],
  emits: [ 'setVideoState' ],
  setup(props) {
    const { state, get, commit, dispatch } = useStore();
    const data = reactive({
      videoState: computed(() => state.chatbot.videoState),
      recTime: '0:00:00',
    })

    const methods = {
      setSubSubpageState: (type, name) => {
        // type: video, map
        // name: sinsa, paju, seongsu
        // dispatch('chatbot/closeSubSubpage', {type: type, name: name})
        $.removeClass("sub-subpage-wrap--open", $.qs(".sub-subpage-wrap"));
        clearInterval(interval)
      },
    }

    const checkVideoWH = () => {
      // H/W
      const videoRatio = 1072 / 650;
      const viewWidth = window.innerWidth;
      const viewHeight = window.innerHeight * 93.36492;
      const viewRatio = viewHeight / viewWidth;
      if (viewWidth < 600) {
        if (videoRatio > viewRatio) {
          $.setCss(
            {
              width: '100%',
              height: 'auto'
            },
            self.value
          )
        } else {
          $.setCss(
            {
              width: 'auto',
              height: '100%'
            },
            self.value
          )
        }
      }
    }
    let interval;
    const increaseRecTime = () => {
      // return
      let s = 0;
      const show = (val) => `${val > 9 ? val : `0${val}`}`;
      interval = setInterval( function(){
        s++
        data.recTime = `${parseInt(s/3600)}:${show(parseInt((s/60)%60))}:${show(s%60)}`
        $.toggleClass("sub-video__state-light--red", $.qs(".sub-video__state-light"))
      }, 1000);
    }

    const self = ref();

    onMounted(() => {
      window.addEventListener("resize", checkVideoWH);
    })

    watchEffect(() => {
      if (data.videoState.state && data.videoState.name !== null) {
        checkVideoWH();
        increaseRecTime();
      }
		},{ flush: 'post' });

    return {
      data,
      methods,
      self
    };
  }

}
</script>

<style>

</style>