import axios from 'axios';
import * as _ from 'fxjs';
import { allArr, getWork, categoryArr, themeArr, fetchData} from '@/store/data-workList.js';

export default {
	namespaced: true,
	state: {
		currentCategory: -1,
		currentTag: -1,
		isAll: 'all',
		sortedNum:0,
		clientName: '',
		tag: -1,
		category: -1,
		selectedCategory: -1,
		selectedTag: -1,
		selectedFilterName: "ALL PROJECTS",
		// workUnitSorted:_.go(
		// 	allWork,
		// 	_.values,
		// 	_.reverse,
		// 	_.filter(item =>{
		// 		let stringToNum = String(item.date)
		// 		if(stringToNum.slice(0,4) >= 2020){
		// 			return item
		// 		}
		// 	})
		// ),
		isFilterOpen:false,
		workSortedTheme:'ALL PROJECTS',
		categoryIndex:null,
		workViewAllUnit:[],
		latestUnit:[],
		cateThumb0: '',
		cateThumb1: '',
		cateThumb2: '',
		cateThumb3: '',
		interThumb0: '',
		interThumb1: '',
		interThumb2: '',
		interThumb3: '',
		interThumb4: '',
		interThumb5: '',
		interThumb6: '',
		interThumb7: '',

	},
	getters: {

	},
	mutations: {
		["setFilter"](state, { type, id }){
			if(type == "category") {
				state.tag = -1;
			}
			else if(type == "tag") {
				state.category = -1;
			}
			state[type] = id;
		},
		['sorting'](state,{routeName,routeNum}){
			state.workViewAllUnit = _.go(
				state.workUnitSet,
				_.filter(item => {
					let cateArr = routeName=='interest' ? item.tag : item.category
					if(cateArr !== undefined && cateArr.indexOf(routeNum)!== -1){
						return item
					}
				})
			)
		},
		['filterControl'](state,payload) {
			if(payload !== undefined) {
				state.isFilterOpen = payload	
			}else {
				state.isFilterOpen = !state.isFilterOpen
			}
		}
	}
}
