import * as _ from "fxjs";
import * as $ from "fxdom";
import { gsap, SlowMo } from "gsap/all";
import { stParallaxStart, stPos, stParallaxEnd } from "@/helper/gadget.js";
import { store } from '../store/index.js';

const Parallax = (function () {
	// function Parallax(el, speed, direction, ease) {
	function Parallax(el) {
		// _.go(
		// 	$.qsa("[data-parallax]", el),
		// 	_.each(item => {

		// 	})
		// )

		this.tl = gsap.timeline({
			scrollTrigger: {
				trigger: el,
				start: e => stPos(e, 0, 1),
				end: e => stPos(e, 1, 0),
				scrub: true
			}
		});

		_.go(
			$.qsa("[data-parallax]", el),
			_.each(item => {
				const deltaX = parseInt($.attr("data-parallax", item)) * 20;
				const dir = ($.attr("data-direction", item) == "right") ? "+=" : "-=";
				this.tl.to(item, { x: `${dir}${deltaX}vw` }, "<");
			})
		);
	}

	Parallax.prototype.init = function () {
		// console.log("Parallax-ready", this.el, this.settings);
	};

	Parallax.prototype.destroy = function () {
	};

	return Parallax;
}());

window.addEventListener("landed", () => {

	if(store.state.currPage == "edit") return;

	_.go(
		$.qsa("[data-parallax-wrap]"),
		_.each(item => {
			new Parallax(item);
		})
	)
});

// 해제 작업 추가
window.addEventListener("ready.curtain", () => {
	// console.log("gooooooooooooooooooooooood!!!!");
});