<template>
  <div class="chat-google-map">
    <div
      ref="mapDivRef"
      class="map-for-api"
    >GOOGLE MAP</div>
  </div>
</template>

<script>
import { ref, onMounted } from "@/helper/vue.js";
import { onUnmounted } from '@vue/runtime-core';

export default {
  name: 'Chat Google Map',
  props: {

    /**
     * 지도 정보 브랜치 네임
    */
    location: {
      type: String,
      Default: 'DFY® Sinsa'
    },

    /**
     * 위 경도
    */
   latLng: {
     type: Object,
     Default: { lat: 37.51395881977477, lng: 127.02216673005664 }
   }
  },
  setup(props) {
    const map = ref()
    const mapDivRef = ref()
    const data = {
      GOOGLE_MAP_API_KEY: process.env.VUE_APP_GOOGLE_MAP_API,
    }

    const methods = {
      initializeMap: () => {
        const key = data.GOOGLE_MAP_API_KEY
        const googleMapSript = document.createElement('script')

        googleMapSript.setAttribute('src', `https://maps.googleapis.com/maps/api/js?libraries=geometry&key=${key}&callback=initMap&region=kr`)
        googleMapSript.setAttribute('id', 'goolemapScript')
        googleMapSript.setAttribute('defer', '')
        googleMapSript.setAttribute('async', '')
        document.head.appendChild(googleMapSript)
      }
    }

    // 로드맵 마커
    const roadMapMarkers = () => {
      const mapMarker = new window.google.maps.Marker({
        position: props.latLng,
        map: map.value,
        title: `${props.location}`,
        icon: {
          url: require(`@/assets/map_${props.location}.png`),
          scaledSize: new google.maps.Size(80, 100),
        }

      })
    }

    onMounted (() => {
       methods.initializeMap()
    })
    onUnmounted (() => {
      // 구글 api 초기화
      const fetchedGoogleMapApi = Array.from(document.querySelectorAll('script[src^="https://maps.googleapis.com"]'))
      fetchedGoogleMapApi.forEach( item => item.parentNode.removeChild(item))
      window.google = undefined
    })

    // api 호출
    window.initMap = () => {
      map.value = new window.google.maps.Map(mapDivRef.value, {
        zoom: 17,
        disableDefaultUI: true,
        center: props.latLng
      })
      roadMapMarkers()
    }


    return {
      data,
      methods,
      map,
      mapDivRef
    }

  }
}
</script>
<style lang="scss" scoped>
  .chat-google-map {
    width: 100%;
    height: 700px;
    .__w600 & {
      height: 100vh;
    }
    // height: 100%;
    // min-height: 700px;
    .map-for-api {
      width: 100%;
      height: 100%;
    }
  }
</style>