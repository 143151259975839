<template>
	<header ref="self" class="header" :class="{'header--hide': data.getHideState }">
		<div class="header__logo-wrap">
			<a
				class="header__logo"
				href="/"
				router-to="/"
			>
				<svg width="61" height="16" viewBox="0 0 61 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="logo-svg">
					<path d="M39.0981 0L44.2895 7.78372L49.5692 0H53.3333L45.902 10.5868V16H42.6773V10.6222L35.2279 0H39.0981ZM8.40718 0C13.7315 0 16.8974 2.31549 16.8974 7.99989C16.8974 13.6274 13.7945 15.9531 8.56625 15.9991L8.40718 15.9998H0V0H8.40718ZM33.7061 0V2.73766H22.5333V6.85705H33.0801V9.47236H22.5333V15.9998H19.3096V0H33.7061ZM8.27348 2.68904H3.22971V13.3107H8.27348C11.7732 13.3107 13.8814 11.7692 13.8814 7.99989C13.8814 4.23053 11.7732 2.68904 8.27348 2.68904Z" fill="#171010"/>
					<path d="M55.808 0.876712C55.2204 1.46575 54.9333 2.19178 54.9333 3.0274C54.9333 3.86301 55.2204 4.57534 55.808 5.13699C56.3957 5.71233 57.1065 6 57.9402 6C58.7739 6 59.4846 5.71233 60.0723 5.13699C60.6463 4.57534 60.9333 3.86301 60.9333 3.0137C60.9333 2.17808 60.6463 1.46575 60.0723 0.876712C59.4846 0.287671 58.7739 0 57.9402 0C57.1065 0 56.3957 0.287671 55.808 0.876712ZM56.1497 4.83562C55.7124 4.38356 55.4937 3.78082 55.4937 3.0274C55.4937 1.47945 56.4094 0.493151 57.9402 0.493151C59.4709 0.493151 60.3866 1.47945 60.3866 3.0137C60.3866 3.76712 60.168 4.38356 59.7306 4.83562C59.2932 5.28767 58.6919 5.52055 57.9402 5.52055C57.1885 5.52055 56.5871 5.28767 56.1497 4.83562ZM58.5142 3.34247C59.0199 3.23288 59.3342 2.84931 59.3342 2.34247C59.3342 1.75342 58.8559 1.38356 58.1452 1.38356H56.8058V4.46575H57.4345V3.38356H58.0222L58.6235 4.46575H59.4299L58.8559 3.65753L58.5142 3.34247ZM58.2545 1.94521C58.5415 1.94521 58.6919 2.08219 58.6919 2.34247C58.6919 2.64384 58.5552 2.79452 58.2819 2.79452H57.4345V1.94521H58.2545Z" fill="#171010"/>
				</svg>
			</a>
		</div>
		<div class="header__right">
			<nav class="header__nav">
				<ul>
					<li class="header__nav-item" :class="{ 'header__nav-item--active' : data.currPage == 'work' || data.currPage == 'project' || data.currPage == 'work-sublanding-tag' }">
						<a class="header__nav-lnk" href="/work" router-to="/work">WORK</a>
					</li>
					<li class="header__nav-item" :class="{ 'header__nav-item--active' : data.currPage == 'services' || data.currPage == 'work-sublanding-service' }">
						<a class="header__nav-lnk" href="/services" router-to="/services">SERVICES</a>
					</li>
					<li class="header__nav-item" :class="{ 'header__nav-item--active' : data.currPage == 'about' }">
						<a class="header__nav-lnk" href="/about" router-to="/about">ABOUT</a>
					</li>
					<li class="header__nav-item" :class="{ 'header__nav-item--active' : data.currPage == 'contact' }">
						<a class="header__nav-lnk" href="/contact" router-to="/contact">CONTACT</a>
					</li>
					<li v-if="data.useTestMenu" class="header__nav-item">
						<a class="header__nav-lnk" href="/test-wow" router-to="/test-wow">test-wow</a>
					</li>
					<li v-if="data.useTestMenu" class="header__nav-item">
						<a class="header__nav-lnk" href="/blank" router-to="/blank">blank</a>
					</li>
				</ul>
			</nav>
		</div>
	</header>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { gsap, ScrollTrigger } from "gsap/all";
import { ref, reactive, onMounted, onBeforeUnmount, onUnmounted, computed, useStore, watch } from "@/helper/vue.js";
import { isLoaderOn, testMenu } from "@/store/appSettings.js";
import { getScroll } from "@/helper/gadget.js";
export default {
  setup(props) {
		const { state, get } = useStore();
		const self = ref();
		const data = reactive({
			currPage: computed(() => {
				// $.qs(".header__nav-item--active") && $.removeClass("header__nav-item--active", $.qs(".header__nav-item--active"));
				return state.currPage;
			}),
			getHideState: computed(() => {

				if(get["hiddenOnLoading"]) return true;

				// 스크롤 값이 있으면서 스크롤 방향이 아래일 경우
				if(state.scrollDir == "down" && state.currScroll != 0) {
					return true;
				}
				else {
					return false;
				}
			}),
			useTestMenu: testMenu
		});

		onMounted(() => {
			window.addEventListener("landed", () => {
				// PC
				if(!state.isMobileAgent) {
					// 로고 클릭
					$.qs(".header__logo", self.value).addEventListener("click", () => {
						$.qs(".header__nav-item--active") && $.removeClass("header__nav-item--active", $.qs(".header__nav-item--active"));
					});

					// 각 메뉴 아이템 클릭
					_.go(
						$.qsa(".header__nav-lnk", self.value),
						_.each(item => {
							item.addEventListener("click", () => {
								$.qs(".header__nav-item--active") && $.removeClass("header__nav-item--active", $.qs(".header__nav-item--active"));
								$.addClass("header__nav-item--active", $.closest(".header__nav-item", item));
							});

							item.addEventListener("mouseover", () => {
								$.addClass("header__nav--over", $.qs(".header__nav", self.value));
							});

							item.addEventListener("mouseleave", () => {
								$.removeClass("header__nav--over", $.qs(".header__nav", self.value));
							});
						})
					)
				}
			}, { once: true });
		});

    return {
			self,
			data
    }
  }
}
</script>
<style scoped lang="scss">
.header {
	// opacity: 0.2;
	// z-index: -1 !important;
	position: fixed;
	left: var(--outerGutter);
	right: var(--outerGutter);
	top: 16px;
	height: 0;
	z-index: 1300;
	transition: transform 0.5s ease;
	// &__audio-control {
	// 	position: absolute;
	// 	right: 100%;
	// 	top: 0;
	// }
	&__logo-wrap {
		position: absolute;
		top: 0;
		left: 0;
	}
	&__logo {
		display: inline-block;
		path {
			fill: var(--foreground);
			transition: fill 0.3s;
		}
	}
	&__right {
		position: absolute;
		display: inline-block;
		right: 0;
		top: -2px;
	}
	&__nav-item {
		display: inline-block;
		&:first-child {
			.header__nav-lnk {
				margin-left: 0;
			}
		}
		&--active {
			.header__nav-lnk {
				color: var(--background);
				background-color: var(--foreground);
			}
		}
	}
	&__nav-lnk {
		display: inline-block;
		padding: 2px 5px;
		margin-left: 16px;
		border-radius: 4px;
		font-family: "Faktum";
		text-transform: uppercase;
		color: var(--foreground);
		font-weight: 400;
		font-size: 18px;
		letter-spacing: -0.04em;
		line-height: 1;
		vertical-align: middle;
		transition: color 0.3s, background-color 0.3s;
	}
	&__nav {
		display: flex;
		position: relative;
		&--over {
			.header__nav-lnk {
				color: var(--foreground);
				background-color: transparent;
				&:hover {
					color: var(--background);
					background-color: var(--foreground);
				}
			}
		}
	}
	&--hide {
		transform: translate(0, -50px);
		transition: transform 0.3s ease;
	}
}
</style>