const category = {
	0: {
		title: "DIGITAL |^PRODUCTS & ^PLATFORMS",             
		overviewEn: `
			<span class="t-block t--fak">We design for brands, but we also design for the end customers — that’s people just like us. </span>\n
			<span class="t-block t--fak">Our digital products and platforms are built as a place where brands and their target audience can meet. Kind of like your favorite coffee shop.</span>`,
		overviewKr: `<span class="t-block t--kr">우리는 브랜드와 소비자를 위해 일합니다. 브랜드와 소비자의 입장을 모두 생각하는 우리는 궁극적으로 모두에게 더 나은 솔루션을 생각합니다.</span><span class="t-block t--kr">이를 통해 우리의 디지털 프로덕트와 플랫폼이 모두를 끈끈하게 이어주는 역할을 하기를 바랍니다.</span>`,
		overviewList: [
			{
				caption: "UX/UI DESIGN",
				items: ["UX / UI STRATEGY", "WEBSITES", "APPS", "E-COMMERCE PRODUCTS"]
			},
			{
				caption: "CREATIVE CONTENT",
				items: ["GRAPHIC DESIGN", "ENGLISH & KOREAN COPYWRITING", "2D MOTION GRAPHICS", "3D MODELING & VIDEOS", "VIDEO PRODUCTION", "PHOTOGRAPHY", "SOCIAL MEDIA AR FILTERS", "VIRTUAL CONFERENCES", "AR/VR"]
			},
			{
				caption: "MARKETING",
				items: ["SOCIAL MEDIA", "DIGITAL CAMPAIGNS", "GLOBAL & LOCAL MARKETING", "PERFORMANCE MARKETING", "INFLUENCER MARKETING", "SEO", "ANALYTICS"]
			},
			{
				caption: "DEVELOPMENT",
				items: ["FRONT-END DEVELOPMENT", "WEB PUBLISHING", "INTERACTIVE DEVELOPMENT", "APP DEVELOPMENT", "WEB ACCESSIBILITY"]
			}
		]
	},
	1: {
		title: "BRANDED |^E-COMMERCE",
		overviewEn: `
		<span class="t-block t--fak">The way people shop has changed. But then again, not really. Whether online or offline, people still want the shopping experience to be enjoyable in addition to easy and efficient.</span>\n
		<span class="t-block t--fak">We design to align with how customers shop and build solutions into the experience itself.</span>`,
		overviewKr: `<span class="t-block t--kr">쇼핑하는 방식은 계속 변하지만 변하지 않는 부분도 있습니다. 온라인에서도 오프라인에서도 소비자는 변함없이 편리함과 효율성을 추구하죠.</span><span class="t-block t--kr">사는 재미를 새롭게. 소비자의 디지털 라이프가 더 쉽고 즐거울 수 있도록 우리는 디지털 경험에 새로운 솔루션과 서비스를 담습니다.</span>`,
		overviewList: [
			{
				caption: "UX/UI DESIGN",
				items: ["E-COMMERCE STRATEGY", "E-COMMERCE PRODUCTS", "E-COMMERCE CONSULTING", "WEBSITES", "APPS"]
			},
			{
				caption: "BRAND STRATEGY",
				items: ["BRAND PLANNING", "BRAND IDENTITY DESIGN", "BRAND GUIDES", "REBRANDING", "PRODUCT BRANDING", "DIGITAL BRANDING"]
			},
			{
				caption: "DEVELOPMENT",
				items: ["FRONT-END DEVELOPMENT", "WEB PUBLISHING", "INTERACTIVE DEVELOPMENT", "APP DEVELOPMENT", "WEB ACCESSIBILITY"]
			},
			{
				caption: "CREATIVE CONTENT",
				items: ["GRAPHIC DESIGN", "ENGLISH & KOREAN COPYWRITING", "2D MOTION GRAPHICS", "3D MODELING & VIDEOS", "VIDEO PRODUCTION", "PHOTOGRAPHY", "SOCIAL MEDIA AR FILTERS", "AR / VR"]
			}
		]
	},
	2: {
		title: "BRANDING ^|STRATEGY ^& DESIGN",
		overviewEn: `<span class="t-block t--fak">Just like human beings, every brand has a distinct personality. And while some have a clear sense of identity, some have yet to come out of their shells.</span><span class="t-block t--fak">Working with the brand, we aim to develop the right mix of solutions and strategies to make sure the brand’s voice is heard in the right places, in the right way.</span>`,
		overviewKr: `<span class="t-block t--kr">브랜드도 우리처럼 고유한 성격이 있습니다. 시시콜콜 이야기하는 걸 좋아하는 브랜드도 있고, 말수가 적은 브랜드도 있죠. 또 어떤 브랜드는 낯을 가려서 마음껏 자신을 드러내지 못하는데요.</span><span class="t-block t--kr">우리는 브랜드가 브랜드다울 수 있게 돕습니다. 탁월한 전략으로 브랜드의 목소리가 가장 잘 퍼질 곳과 브랜드의 이야기에 귀 기울일 친구들을 찾아주죠.</span>`,
		overviewList: [
			{
				caption: "BRAND STRATEGY",
				items: ["BRAND COMMUNICATION STRATEGY", "BRAND IDENTITY DESIGN", "BRAND GUIDES", "REBRANDING", "PRODUCT BRANDING", "DIGITAL BRANDING"]
			},
			{
				caption: "CONSULTING",
				items: ["UX STRATEGY", "MARKET RESEARCH", "BRAND EXPERIENCE", "SOCIAL MEDIA STRATEGY", "DIGITAL STRATEGY", "CRM STRATEGY"]
			},
			{
				caption: "MARKETING",
				items: ["SOCIAL MEDIA", "DIGITAL CAMPAIGNS", "IMC", "GLOBAL & LOCAL MARKETING", "INFLUENCER MARKETING", "PERFORMANCE MARKETING", "SOCIAL MEDIA", "ANALYTICS"]
			},
			{
				caption: "CREATIVE CONTENT",
				items: ["GRAPHIC DESIGN", "ENGLISH & KOREAN COPYWRITING", "2D MOTION GRAPHICS", "3D MODELING & VIDEOS", "VIDEO PRODUCTION", "PHOTOGRAPHY", "SOCIAL MEDIA AR FILTERS", "Virtual conferences", "AR / VR"]
			}
		]
	},
	3: {
		title: "BRANDED ^|EXPERIENCE",
		overviewEn: `<span class="t-block t--fak">It all begins with the big picture, but it’s the details that make the difference to the customer. </span><span class="t-block t--fak">Each experience that we plan and design is fine-tuned to cater to the target audience and aimed at building and reinforcing a positive relationship between the brand and its audience.</span>`,
		overviewKr: `<span class="t-block t--kr">우리는 큰 그림을 그립니다. 그리고 디테일에 집착합니다. 디테일이 브랜드와 소비자를 친한 친구로 만들기 때문입니다.</span><span class="t-block t--kr">섬세하게 설계된 디지털 경험이 브랜드 타깃에 닿으면 타깃은 소비자가 되고, 소비자는 금세 브랜드와 친해집니다.</span>`,
		overviewList: [
			{
				caption: "UX / UI DESIGN",
				items: ["UX / UI STRATEGY", "UX / UI CONSULTING", "WEBSITES", "APPS"]
			},
			{
				caption: "DEVELOPMENT",
				items: ["FRONT-END DEVELOPMENT", "WEB PUBLISHING", "INTERACTIVE DEVELOPMENT", "APP DEVELOPMENT", "WEB ACCESSIBILITY"]
			},
			{
				caption: "CREATIVE CONTENT",
				items: ["GRAPHIC DESIGN", "ENGLISH & KOREAN COPYWRITING", "2D MOTION GRAPHICS", "3D MODELING & VIDEOS", "VIDEO PRODUCTION", "PHOTOGRAPHY", "SOCIAL MEDIA AR FILTERS", "VIRTUAL CONFERENCES", "AR / VR"]
			},
			{
				caption: "MARKETING",
				items: ["EXPERIENTIAL MARKETING", "DIGITAL CAMPAIGNS", "IMC", "GLOBAL & LOCAL MARKETING", "INFLUENCER MARKETING", "PERFORMANCE MARKETING", "SOCIAL MEDIA", "ANALYTICS"]
			}
		]
	}
};

const tag = {
	0: {
		title: "HUMAN |^TECHNOLOGY"
	},
	1: {
		title: "NEW |WAY ^TO RETAIL"
	},
	2: {
		title: "READY TO ^|LAUNCH"
	},
	3: {
		title: "BEING |SOCIAL"
	},
	4: {
		title: "PURPOSEFUL ^|SPACES"
	},
	5: {
		title: "TOMORROW ^|TODAY"
	},
	6: {
		title: "STORIES |^THAT INSPIRE"
	},
	7: {
		title: "SAMSUNG ^|GALAXY"
	},
}

const getData = (catId, tagId) => {
	if(catId != -1) {
		return category[catId];
	}
	else if(tagId <= -1) {

	}
	else if(tagId != -1) {
		return tag[tagId];
	}
	return {};
};

export { getData }