<template>
  <div v-if="data.embedType == 'youtube'" class="m-vid m-vid--in-popup">
    <iframe class="m-vid__source" :src="`https://www.youtube.com/embed/${data.src}?autoplay=1&loop=0&rel=0&modestbranding=1`" title="Youtube video" frameborder="0" allowfullscreen></iframe>
  </div>

  <div v-else class="m-vid m-vid--in-popup">
    <iframe class="m-vid__source" :src="`https://player.vimeo.com/video/${data.src}?autoplay=1&loop=0`" title="Vimeo video" frameborder="0" allow="fullscreen;autoplay"></iframe>
  </div>
</template>

<script>
import * as $ from 'fxdom';
import * as _ from 'fxjs';
import { reactive, computed, onBeforeUpdate, onMounted, onUnmounted, onUpdated, useStore, } from '@/helper/vue.js';
export default {
	setup(props) {
    const { state, get, commit, dispatch } = useStore();
		const data = reactive({
      compName: computed(() => state.layerPopup.name),
      embedType: computed(() => state.layerPopup.source[0]),
      src: computed(() => state.layerPopup.source[1]),
		});

		// DOM 접근 가능 시점
		onMounted(() => {
			// nextTick(() => {})
		});

		// window, document 등 Unmount되지 않는 이벤트 해제를 위해 사용
		onUnmounted(() => {
      
		});

		return {
			// self,
			data,
		};
	}





}
</script>

<style>

</style>