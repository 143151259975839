<template>
  <div ref="self">
    <button class="card" :class="{'card--with-pad': pad}" v-for="(item, index) in cardList" :key="index" @click="$emit('click', item)" :style="{color: item.cont.colorWhite ? '#F9F5EF' : '#171010'}">
      <div class="card__pad">
        <div class="card__bg-wrap">
          <div class="card__bg-inr">
            <img class="card__bg" :src="item.cont.bgPath" alt="">
          </div>
        </div>
        <!-- <div class="card__shape-wrap"> -->
        <div class="card__shape-wrap" :class="item.cont.float ? `card__shape-wrap--float${index+1}` : `card__shape-wrap--float-none`">
          <div v-if="item.cont.type == 'card'" class="card__shape-inr">
            <img class="card__shape card__shape--1" :src="item.cont.shapePath1" alt="">
            <img class="card__shape card__shape--2" :src="item.cont.shapePath2" alt="">
            <img class="card__shape card__shape--3" :src="item.cont.shapePath3" alt="">
          </div>
          <!-- <div class="card__shape-inr"> -->
            <!-- <img v-else class="card__shape" :src="item.cont.shapePath" alt=""> -->
          <!-- </div> -->
        </div>
        <div class="card__tit-wrap">
          <div class="card__label-wrap">
            <div class="card__label">
              <span class="t-cb--13"><span :class="!data.isLangKr ? 't--fak' : 't--kr'" v-html="item.cont.leftLabel"></span></span>
            </div>
            <div class="card__label">
              <span class="t-cb--13"><span :class="!data.isLangKr ? 't--fak' : 't--kr'" v-html="item.cont.rightLabel"></span></span>
            </div>
          </div>
          <div class="card__tit" :class="item.cont.widthClass ? item.cont.widthClass : null">
            <span class="t-cb--28"><span :class="!data.isLangKr ? 't--fak' : 't--kr'" v-html="item.cont.title"></span></span>
          </div>
          <div class="card__location" v-if="item.cont.location">
            <span class="t-cb--28"><span :class="!data.isLangKr ? 't--fak' : 't--kr'" v-html="item.cont.location"></span></span>
          </div>
        </div>
        <div class="card__desc-wrap">
          <div class="card__desc card__desc--top" v-if="item.cont.descTop">
            <span class="t-cb--16"><span :class="!data.isLangKr ? 't--fak' : 't--kr'" v-html="item.cont.descTop"></span></span>
          </div>
          <div class="card__desc-inr">
            <div class="card__face-wrap">
              <Avatar :avatarName="item.cont.faceName" :type="'big'"/>
              <div class="card__state open-state-dfy__ball" :style="{ borderColor: item.cont.borderColor}"></div>
            </div>
            <div class="card__desc card__desc--bot" :class="[item.cont.type == 'hiring' ? 'card__desc--line-limit' : null]">
              <span class="t-cb--16"><span :class="!data.isLangKr ? 't--fak' : 't--kr'" v-html="item.cont.desc"></span></span>
            </div>
          </div>
        </div>
        <div class="overlay"></div>
      </div>
    </button>
  </div>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { reactive, ref, computed, onBeforeUpdate, onUpdated, onMounted, onActivated, onUnmounted, useStore } from "@/helper/vue.js";
import Avatar from "./_Avatar.vue";

export default {
  props: [ 'cardList', 'pad' ],
  emits: [ 'click' ],
  components: {
    Avatar
  },
  setup(props, { emit }) {
    const { state, get, commit, dispatch } = useStore();
    const data = reactive({
      isLangKr: computed(() => state.isLangKr),
    });

    const methods = {}
    const self = ref();

    onMounted(() => {
      // console.log("onMounted");

      // _.go(props.cardList,
      //   _.each(item => {
      //     if (item.cont.type == 'card') {
      //       _.go($.findAll(".card__bg-wrap", self.value),
      //         _.each(wrap => {
      //           $.setCss({
      //             'padding-top': '100%',
      //           }, wrap)
      //         })
      //       )
      //     } else if (item.cont.type == 'hiring') {
      //       _.go($.findAll(".card__bg-wrap", self.value),
      //         _.each(wrap => {
      //           $.setCss({
      //             'padding-top': '125.1396648%'
      //           }, wrap)
      //         })
      //       )
      //     }
      //   })
      // )
    })
    onUnmounted(() => {
    })

    return {
      data,
      methods,
      self
    };
  }
}

</script>

<style></style>