import * as _ from "fxjs";
// import * as L from "fxjs/Lazy";
import * as C from "fxjs/Concurrency";
// import { store } from '../store/index.js';

export default {
  namespaced: true,
  state: {
		state: "complete",
    queue: [],
  },
  getters: {
  },
	mutations: {
		["readyQueue"](state) {
      state.state = "pending";
			state.queue = [];
			console.log("------------------ LM.readyQueue ------------------");
    },
		["addQueue"](state, item) {
      state.queue.push(item);
    },
	},
  actions: {
		waitAll({ state, commit }, cb) {
			const destCount = state.queue.length;
			let currentCount = 0;
			_.go(
				state.queue,
				// _.tap(queue => {
				// 	console.log(queue);
				//  }),
				// C.takeAll,
				C.map(item => {
					currentCount++;
					commit("set", { sel: "loadingProgress", to: 0.25 + (currentCount / destCount * 0.75) }, { root: true });
					// console.log(item);
				}),
				// _.tap(resolved => {
				// 	console.log(resolved);
				// 	console.log(state.queue);
				// 	console.log(state.queue.length);
				//  }),
				_.tap(() => {
					commit("set", { sel: "loadManager.state", to: "complete" }, { root: true });
					commit("set", { sel: "loadManager.queue", to: [] }, { root: true });
					commit("set", { sel: "loadingProgress", to: 1 }, { root: true });
					// state.state = "complete";
					// state.queue = [];
					console.log("------------------ LM.resolve ------------------");
					cb();
				})
			);
		}
  },
}