import * as _ from "fxjs";

import { mqList } from "./appSettings.js";

export default {
	namespaced: true,
  state: {
		mqList,
		cbs: {
			load: [],
			resize: [],
			scroll: [],
			RAF: [],
			wheel: []
		},
	},
  getters: {
    getItems: state => {
    },
  },
  mutations: {
		["onLoad"](state) {
			console.log("store-eventHandler init2");
    },
		["add"](state, events) {
			Object.entries(events).forEach(([name, callback]) => {
				state.cbs[name].push(callback);
			});
    },
  },
  actions: {
		onLoad({ getters, commit, state }) {
			window.addEventListener("DOMContentLoaded", e => {
				state.cbs["load"].forEach(cb => {
					cb();
				});
			});

			window.addEventListener("resize", e => {
				state.cbs["resize"].forEach(cb => {
					cb();
					console.log("resieze");
				});
			});
    },
    // addEvent({ getters, commit, state }, eventName, cb) {
		// 	state.cbs["load"]
    // },
  },
};