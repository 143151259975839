<template>
  <Layout :bgData="currentCard.cont" :name="currentCard.name" @click="$emit('click')"></Layout>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { ref, reactive, computed, onMounted, onUpdated,watchEffect, onBeforeUnmount, onUnmounted, useStore } from "@/helper/vue.js";
import { addEvent, removeEvent } from "@/helper/event.js";

import Layout from "../_ChatPageLayout.vue";

export default {
  props: [ 'currentCard' ],
  emits: [ 'click' ],
  components: {
    Layout,
  },
  setup({emit}) {

    const data = reactive({
    });

    const methods = {
    };

    return {
      data,
      methods
    }
  }
}
</script>

<style lang="scss" src="@/css/chatbot.scss"></style>
