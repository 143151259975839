import * as _ from "fxjs";

export default {
	namespaced: true,
  state: {
    items: [
      {
        id: 'thanks',
        contents: {
          query: 'thanks',
        }
      }
    ]
  },
  getters: {
    getItems: (state) => {
      return state.items;
    },
  }
};