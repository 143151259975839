<template>
  <div ref="self" class="news">
    <div class="news__person">
      <Person :item="data.person"></Person>
    </div>
    
    <div class="news__txt">
      <span class="t-cb--16"><span :class="!data.isLangKr ? 't--fak' : 't--kr'" v-html="item.cont.txt"></span></span>
    </div>
    <div class="news__img-wrap">
      <img class="news__img" v-for="(path, index) in item.cont.imgPath" :key="index" :src="path" alt="">
      <button v-if="item.cont.imgPath.length > 1" class="news__arrow news__arrow--prev">
        <span class="news__arrow-inr">
          <span class="news__icon ico-chatbot--navi-navigate--back"></span>
        </span>
      </button>
      <button v-if="item.cont.imgPath.length > 1" class="news__arrow news__arrow--next">
        <span class="news__arrow-inr">
          <span class="news__icon ico-chatbot--navi-navigate--next"></span>
        </span>
      </button>
      <button v-if="item.cont.sound" class="news__sound" @click="methods.soundOn">
        <span class="b-sound">
          <span class="b-sound__img b-sound__img--off ico-chatbot--media-volume--mute"></span>
          <span class="b-sound__img b-sound__img--on ico-chatbot--media-volume--up"></span>
        </span>
      </button>
    </div>
    <a v-if="item.cont.href" class="news__more-wrap" :href="item.cont.href" target="_blank">
      <span class="news__more">
        <span class="t-cb--16"><span class="t--fak person__date">Learn more</span></span>
      </span>
      <span class="news__more-arrow ico-chatbot--navi-navigate--next"></span>
    </a>
    <div class="news__infinite">
      <Infinite :likeCount="item.cont.likeCount"></Infinite>
    </div>
  </div>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { ref, reactive, computed, onMounted, onUnmounted, useStore } from "@/helper/vue.js";
import Person from './_Person.vue';
import Infinite from './_InfiniteClick.vue';

export default {
  props: [ 'item', 'person' ],
  components: {
    Person,
    Infinite
  },
  setup(props) {
    const { state, get, commit, dispatch } = useStore();
    const data = reactive({
      isLangKr: computed(() => state.isLangKr),
      person: {
        contents: {
          name: computed(() => props.person.headTitle),
          facePath: computed(() => props.person.facePath),
          location: computed(() => props.item.cont.location)
        }
      },
    });
    
    const methods = {
      soundOn: () => {
        _.go(
          $.find(".b-sound", self.value),
          $.toggleClass("b-sound--on")
        )
      },
    };
    const self = ref();

    onMounted(() => {
		});

    return {
      data,
      methods,
      self
    }
  }



}
</script>

<style>

</style>