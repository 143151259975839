<template>
	<figure ref="self" class="m-pic" :class="{
		'm-pic--embed': data.embedType,
		'm-pic--video': data.getSrcExtension === '.mp4',
		'm-pic--no-parallax': parallax == false || (type != 'bg' && data.isMobileAgent),
		'm-pic--lazy': lazy == true,
		's--no-src': !!src == false,
		'm-pic--has-hover': hover == true,
		'video' : coverSrc
	}">
		<div class="m-pic__inner">
			<div class="m-pic__deeper">
				<!-- 임베드 -->
				<template v-if="data.srcType === 'embed'">
					<!-- 팝업사용 -->
					<template v-if="coverSrc">
						<!-- 커버영상 -->
						<video class="m-pic__source m-pic__source--cover" :src="(from == 'asset') ? require(`@/assets${coverSrc}`) : coverSrc" loop muted playsinline preload="auto" oncontextmenu="return false"></video>
						<!-- 모바일 플레이어 -->
						<!-- <iframe class="m-pic__source m-pic__source--mobile-embed" :src="data.getIframeSrc" :title="`${data.embedType} video`" frameborder="0" allowfullscreen="1"></iframe> -->
					</template>
					<!-- 인라인(only vimeo) -->
					<template v-else>
						<iframe class="m-pic__source m-pic__source--iframe" :src="data.getIframeSrc" :title="`${data.embedType} video`" frameborder="0" allowfullscreen></iframe>
					</template>
				</template>
				<!-- not embed -->
				<template v-else>
					<template v-if="data.getSrcExtension === '.mp4'">
						<!-- <video class="m-pic__source" :src="(from == 'asset') ? require(`@/assets${data.getSrc}`) : (data.curr + `#t=0.1`)" loop muted playsinline preload="auto" oncontextmenu="return false"></video> -->
						<video v-if="!(lazy && !data.isLazyLoadStarted)" class="m-pic__source" :src="(from == 'asset') ? require(`@/assets${data.getSrc}`) : (data.curr)" loop muted playsinline preload="auto" oncontextmenu="return false"></video>
					</template>
					<template v-else>
						<img v-if="!(lazy && !data.isLazyLoadStarted)" class="m-pic__source" :src="(from == 'asset') ? require(`@/assets${data.getSrc}`) : data.getSrc">
					</template>
				</template>
			</div>
		</div>
	</figure>
</template>

<script>
import * as $ from "fxdom";
import { gsap } from "gsap/all";

import { ref, reactive, computed, onMounted, onBeforeUnmount, watch, useStore } from "@/helper/vue.js";
import { stPos } from "@/helper/gadget.js";
import { srcLoadProc, bgm } from "@/helper/gadget.js";
import Player from '@vimeo/player';
import { nextTick } from '@vue/runtime-core';

export default {
	// ratio: 현재는 임베드에만 쓰임
	props: [ "type", "ratio", "lazy", "onLoad", "thumbnailRatio", "placeholderColor", "src", "srcMo", "scroll", "from", "hover", "parallax", "embedType", "coverSrc", "popup", "triggerProxy", "autoPlay" ],
	setup(props, context) {
		let lazyLoadProc = null;
		const data = reactive({
			isLazyLoadStarted: false,
			srcType: props.embedType
					? "embed"
					: props.from
						? props.from
						: props.src
							? "remote"
							: "asset",
			name: "",
			ext: "",
			qs: "",
			isMobileAgent: computed(() => state.isMobileAgent),
			getSrc: computed(() => {
				// 레이지 로딩일 경우
				if(props.lazy) {
					// 아직 소스를 못받아 왔으면 blank.gif
					if(!data.isLazyLoadStarted) {
						return "/common/blank.gif";
					}
					else {
						return data.curr + data.qs;
					}
				}
				return data.curr + data.qs;
			}),
			getSrcExtension: computed(() => {
				if(props.embedType) return null;
				const match = props.src.match(/\S*(?<extension>\.\w+)(\?\S*)?$/i);
				// const match = data.getSrc.match(/\S*(?<extension>\.\w+)(\?\S*)?$/i);
				return match ? match.groups.extension : null;
			}),
			curr: "/common/blank.gif",
			isLoaded: {},
			isLazyLoadStarted: false,
			embedType: computed(() => props.embedType ? props.embedType : null),
			getIframeSrc: computed(() => {
				if(props.coverSrc) {
					if(data.embedType == 'vimeo') return `https://player.vimeo.com/video/${props.src}`+'?autoplay=0&loop=0';
				}
				else {
					if(data.embedType == 'youtube') return `https://www.youtube.com/embed/${props.src}`+'?autoplay=0&loop=0&rel=0&modestbranding=1';
					if(data.embedType == 'vimeo') return `https://player.vimeo.com/video/${props.src}`+'?autoplay=0&loop=1&muted=1&background=1';
				}
				return null;
			}),
		});

		const fileName = props.src.split("/").pop().split(".");
		data.name = fileName[0];
		data.ext = fileName[1];

		const { state, commit } = useStore();

		let tl = null;
		let lazyTl = null;
		const self = ref();
		let isInView = false;
		let landingSize = 0;

		const qsTest = () => {
			// data.qs = ``;
			// return;
			// if(!state.isMobileAgent) {
			// 	data.qs = ``;
			// 	return;
			// }

			if(!self.value) return;

			// srcType "asset" or "embed"
			if(data.srcType === "asset" || data.srcType === "embed") {
				data.qs = "";
				return;
			}

			if(data.getSrcExtension == ".gif" || data.getSrcExtension === ".mp4") {
				data.qs = "";
				return;
			}

			// 쿼리 설정
			if(landingSize > 0) {
				data.qs = `?q=75&w=${landingSize}&f=webp`;
			}
			else {
				data.qs = "?q=75&f=webp";
			}

			// srcType "remote" not "mp4"
			// const qsArr = [{ key: "f", val: "webp" }];
			// if(state.requestWidth !== 1600) {
			// 	qsArr.push({ key: "w", val: state.requestWidth * state.pixelRatio });
			// 	// qsArr.push({ key: "w", val: 200 });
			// }
			// // qsArr.push({ key: "w", val: 200 });
			// data.qs = `?` + qsArr.map(item => `${item.key}=${item.val}`).join("&");
		};

		const srcTest = () => {
			data.curr = props.src ? props.src : require(`@/assets/common/blank.gif`);
			if(props.src) {
				qsTest();
			}
		};

		let vimeoContext = null;
		const setParallax = (source, type) => {
			// 이미지이고, 패럴렉스 사용하지 않으면 리턴
			if(type == "img" && props.parallax == false) return;

			// 패럴렉스 설정

			// 비디오 재생 / 정지
			let onToggleObj = {};
			if((type == "video" || props.coverSrc) && props.autoPlay != false) {
				onToggleObj = {
					onToggle({ isActive }) {
						// 레이지로드이면서 로드가 시작되지 않았으면 리턴
						if(props.lazy && !data.isLazyLoadStarted) return;
						// 타겟이 없어도 리턴
						const target = $.qs(".m-pic__source", self.value);
						if(!target) return;
						if(isActive) {
							if(target.paused) {
								target.play();
							}
						}
						else {
							if(!target.paused) {
								target.pause();
							}
						}
					}
				};
			}
			else if(type == "embed") {
				// PC / MO 자동재생
				if(data.embedType == "vimeo" && !props.coverSrc) {
					vimeoContext = new Player(source);
					onToggleObj = {
						onToggle({ isActive }) {
							if(isActive) {
								vimeoContext.getPaused().then((isPaused) => {
									isPaused && vimeoContext.play();
								});
							}
							else {
								vimeoContext.getPaused().then((isPaused) => {
									!isPaused && vimeoContext.pause();
								});
							}
						}
					};
				}
			}

			// 스크롤트리거 등록(landed 시점에 요소들이 자리 잡은 후 계산할 수 있게 )
			CB_LANDED = () => {
				tl = gsap.timeline({
					scrollTrigger: {
						trigger: props.triggerProxy ? props.triggerProxy : self.value,
						start: "top bottom",
						end: "bottom top",
						scrub: true,
						...onToggleObj,
					}
				});

				// 패럴렉스 등록
				if(props.parallax != false) {
					// PC safari or 모바일에서 type bg가 아닌 경우 return
					if((props.type != "bg" && state.isPcSafari) || (props.type != "bg" && state.isMobileAgent)) {
					}
					else {
						tl.fromTo($.qs(".m-pic__inner", self.value), { ease: "none", x: "0", y: "-8%" }, { ease: "none", x: "0", y: "8%" });
						// tl.fromTo(source, { ease: "none", x: "-50%", y: "-58%" }, { ease: "none", x: "-50%", y: "-42%" });
					}
				}

				// lazy-load일 경우
				if(props.lazy) {
					// 초기 세팅
					$.setCss({ backgroundColor: "#F2ECE3" }, self.value);
					lazyTl = gsap.timeline({
						scrollTrigger: {
							trigger: props.triggerProxy ? props.triggerProxy : self.value,
							start: e => stPos(e, 0, 1, -200),
							end: e => stPos(e, 1, 0, 200),
							// markers: true
							onToggle({ isActive }) {
								isInView = isActive;
								if(isActive) {
									data.test = true;
									// 이미지를 아직 불러오지 않았다면
									if(!data.isLazyLoadStarted) {
										// 스위치가 바뀌면 blank.gif에서 받아올 src로 교체된다. 그 후에 로드프록을 연결해줘야한다(settimeout)
										data.isLazyLoadStarted = true;
										$.addClass("m-pic--lazy-loading", self.value);
										setTimeout(() => {
											const ext = data.getSrcExtension == ".mp4" ? "video" : "img";
											const target = self.value.querySelector(".m-pic__source");
											lazyLoadProc = srcLoadProc(target, ext, () => {
												// 페이드인
												$.addClass("m-pic--lazy-loaded", self.value);
												// 비디오라면 재생
												if(isInView && ext == "video") {
													target.play();
												}
												setTimeout(() => {
													if(!self.value) return;
													// 로딩이 완료되면 shimmer 이펙트 해제
													$.removeClass("m-pic--lazy-loading", self.value);
												}, 800); // 페이드인 듀레이션과 동일하게
											});
										});
									}
									$.setCss({ display: "block" }, $.qs(".m-pic__inner", self.value));
								}
								else {
									$.setCss({ display: "none" }, $.qs(".m-pic__inner", self.value));
								}
							},
						}
					});
				}
			};

			if(state.pageState != "landed") {
				window.addEventListener("landed", CB_LANDED, { once: true });
			} else {
				CB_LANDED();
			}
		}

		const updatePicture = () => {
			nextTick(() => {
				if(!self.value) return;
				const source = $.qs(".m-pic__source", self.value);
				const type = data.getSrcExtension == ".mp4" ? "video" : "img";
				qsTest();
				if(data.srcType == "embed") {
					// 패더 설정
					if(props.ratio) {
						const [ embedWidth, embedHeight ] = props.ratio.split("*");
						$.setCss({
							"padding-top": `${embedHeight / embedWidth * 100}%`
						}, self.value);
					}
					else {
						$.setCss({
							"padding-top": `100%`
						}, self.value);
					}
					// 패럴렉스 설정
					setParallax(source, "embed");

					props.onLoad && props.onLoad();

					// 팝업이벤트 등록
					if(props.coverSrc) {
						// 오픈팝업
						$.qs(".m-pic__source--cover", self.value).addEventListener("click", () => {
							bgm.pause()
							commit('setLayerPopup', {compName: 'workYt', embedType: props.embedType, src: props.src});
							document.documentElement.classList.add('with-modal-popup');
							document.querySelector('body').style.overflow = 'hidden';
						});
					}
				}
				else {
					if(!props.lazy) {
						commit("loadManager/addQueue", srcLoadProc(source, type, () => {
							if(!self.value) return;
							// 패더 설정
							if(type == "img") {
								$.setCss({ paddingTop: `${source.naturalHeight / source.naturalWidth * 100}%` }, self.value);
							}
							else if(type == "video") {
								$.setCss({ paddingTop: `${ source.videoHeight / source.videoWidth * 100}%` }, self.value);
							}
							// 패럴렉스 설정
							setParallax(source, type);

							props.onLoad && props.onLoad();
						}));
					}
					else {
						$.setCss({ paddingTop: `${props.thumbnailRatio}%` }, self.value);
						// 패럴렉스 설정
						setParallax(source, type);

						props.onLoad && props.onLoad();
					}
				}
			});
		};

		let CB_LANDED = null;

		onMounted(() => {

			// 랜딩 사이즈 결정
			if(state.cw < 1441) {
				const parentWidth = $.parent(self.value).clientWidth;
				const pixelRatio = state.pixelRatio > 1 ? 2 : 1;
				// landingSize = Math.floor(pixelRatio * parentWidth);
				if(state.cw < 421) {
					landingSize = Math.floor(pixelRatio * 380);
				}
				else if(state.cw < 769) {
					landingSize = Math.floor(pixelRatio * 440);
				}
				else if(state.cw < 1025) {
					landingSize = Math.floor(pixelRatio * 580);
				}
				else if(state.cw < 1441) {
					landingSize = Math.floor(pixelRatio * 820);
				}
				// if(props.type == "thumb") {
				// 	if(state.cw < 421) {
				// 		landingSize = Math.floor(pixelRatio * 380);
				// 	}
				// 	else if(state.cw < 769) {
				// 		landingSize = Math.floor(pixelRatio * 440);
				// 	}
				// 	else if(state.cw < 1025) {
				// 		landingSize = Math.floor(pixelRatio * 580);
				// 	}
				// 	else if(state.cw < 1441) {
				// 		landingSize = Math.floor(pixelRatio * 820);
				// 	}
				// }
			}

			// 쿼리 선택
			qsTest();
			watch(
				() => [[ state.requestWidth, state.pixelRatio ]],
				() => {
					qsTest();
				}
			);
			// src 선택
			srcTest();
			updatePicture();
			watch(
				() => ([props.src, props.ratio, props.embedType]),
				(curr, prev) => {
					data.srcType = props.embedType
						? "embed"
						: props.from
							? props.from
							: props.src
								? "remote"
								: "asset";
					srcTest();
					updatePicture();
				}
			);
		});

		onBeforeUnmount(() => {

			window.removeEventListener("landed", CB_LANDED);

			if(tl != null) {
				gsap.killTweensOf(tl);
				tl.scrollTrigger && tl.scrollTrigger.kill();
				tl.kill();
				tl = null;
			}

			if(lazyTl != null) {
				lazyLoadProc = null;
				gsap.killTweensOf(lazyTl);
				lazyTl.scrollTrigger && lazyTl.scrollTrigger.kill();
				lazyTl.kill();
				lazyTl = null;
			}

			if(vimeoContext != null) {
				// api 버그로 destroy 불가
				// vimeoContext.destroy();
			}

		});

		return {
			self,
			data
		};
	}
}
</script>