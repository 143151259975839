<template>
  <div class="c-last__contents">
    <div class="g-row c-last__kv">
      <div class="g-col--15 g-col--5s">

        <!-- key visual image -->
        <div class="c-last__kv-img">
          <div class="c-last__kv-img-inner">
              <Picture
                class="c-last__kv-pic"
                from="asset"
                :src="data.getKeyVisualSrc.url ? data.getKeyVisualSrc.url : data.getKeyVisualSrc"
                type="bg"
              />
          </div>
          <div v-if="data.getKeyVisualSrc.desc" class="c-last__kv-desc">
            <div class="t-h--13 t-h--11s">
              <span class="t--fak">{{ data.getKeyVisualSrc.desc }}</span>
            </div>
          </div>
        </div>

        <!-- key visual text -->
        <div class="c-last__kv-txt">
          <div class="g-row s--h-start">
            <div
              :class="[
                `g-col--15`,
                'g-col--5s g-col--13l'
              ]"
            >

              <div v-if="subVisualUrl"
                class="desc-polygon desc-polygon--type1"
                :class="[{'desc-polygon--wide' : keyVisualDescCol >= 15},{'desc-polygon--mo' : data.isMobileAgent}]"
              >
                <div class="desc-polygon__img">
                  <Picture
                    from="asset"
                    :src="data.getSubVisualSrc"
                    type="bg"
                  />
                </div>
                <div class="l-mdl--4 desc-polygon__txt">
                  <div class="t-b--13 t-h--11s">
                    <div class="t--fak">{{ subVisualDesc }}</div>
                  </div>
                </div>
              </div>
              <div class="t-wrap">
                <div class="t-h--54 t-h--28s">
                <div
                  class="t--fak"
                  v-html="methods.descFilter(keyVisualDesc)">
                </div>
              </div>
              <div class="kv-txt__kr l-mdl--16">
                <div class="kv-txt__kr-inner">
                  <div class="t-h--18 t-h--16s">
                    <div
                      class="t--kr"
                      v-html="methods.descFilter(keyVisualDescKr)">
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "fxjs";
import Picture from "@/parts/picture/PictureView.vue";
import { reactive, onMounted, computed, useStore} from "@/helper/vue.js";
export default {
  name: 'Footer Figure',
  components: {
    Picture,
  },
  props: {
    /**
		 * [figure] 키 비주얼 이미지 경로
		 * */
		keyVisualUrl: {
			type: [String, Array],
			default: null
		},

    /**
		 * [figure] 키 비주얼 하단 디스크립션
		 * */
		keyVisualDesc: {
			type: String,
			default: null
		},
    /**
		 * [figure] 키 비주얼 하단 디스크립션 - 국문 텍스트
 		 * */
		keyVisualDescKr: {
			type: String,
			default: null
		},
    /**
		 * [figure] 키 비주얼 하단 디스크립션 g-col--{} 영역
		 * */
		keyVisualDescCol: {
			type: Number,
			default: 9
		},

    /**
		 * [figure] 서브 비주얼 1 이미지 경로
		 * */
		subVisualUrl: {
			type: [String, Array],
			default: null
		},

    /**
		 * [figure] 서브 비주얼 1 이미지 캡션
		 * */
		subVisualDesc: {
			type: String,
			default: null
		},
  },
  setup(props) {
    const { state } = useStore();

    const getSrcProc = (val) => {
      if(_.isArray(val)) {
        const randomIndex = Math.floor(Math.random() * val.length);
        return val[randomIndex];
      }
      return val;
    };

    const data = reactive({
      isMobileAgent: computed(() => state.isMobileAgent),
      getKeyVisualSrc: computed(getSrcProc.bind(null, props.keyVisualUrl)),
      getSubVisualSrc: computed(getSrcProc.bind(null, props.subVisualUrl)),
    });
    const escapeSequenceDfy = desc => {
      return desc.split('DFY®').join('<span class="t-dfy">DFY</span>')
    };
    const textBreak = desc => {
      return desc.split('\n').join('<br />')
    };

    const methods = {
      descFilter:(desc) =>{
       let paragraph = escapeSequenceDfy(desc)
        return textBreak(paragraph)
      }
    }
    onMounted(() => {

    })
    return {
      data,
      methods
    }
  }
}
</script>

<style lang="scss" scoped>
  .c-last {
    &__kv {
      .__w600 & {
        margin: 0;
      }
      &-img {
        position: relative;
        :deep .m-pic {
          height: 100%;
          &__img {
            height: 110%;
            object-fit: cover;
          }
        }
        .__w600 & {
          position: relative;
          padding-top: 100%
        }
        &-inner {
          .__w600 & {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
      }
      &-pic {
        padding-top: 70.766% !important;
        .__w600 & {
          padding-top: 100% !important;
        }
      }
      &-txt {
        margin-top: 4em;
        position: relative;
        .__w600 & {
          margin-top: 4em;
        }
      }
      .desc-polygon {
        $base:&;
        float: left;
        // margin-right: 16em;
        width: var(--cw6);
        height: 200em;
        &__txt {
          // margin-top: 8em;
          text-transform: uppercase;
        }
        // Type1 스타일
        &--type1 {
          margin-top: 97em;
          width: 400em;
          height: auto;
          shape-outside: polygon(0 0, 100% 0, 100% 100%, 0% 100%) content-box;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
          .desc-polygon__img {
            width: calc(100% - 12em);
          }
          .__w600 & {
            margin-top: 85em;
            width:  146em;
            &.desc-polygon--mo {
              margin-top: 88em;
            }
            .desc-polygon__img {
              width: calc(100% - 8em);
            }
          }
          .w1600__ & {
            // margin-top: 69em;
            width: 633em;
            .desc-polygon__img {
              width: calc(100% - 16em);
            }
          }
        }

        // Type2 스타일
        &--type2 {
          position: absolute;
          top: 0;
          right: 0;
          width: var(--cw6);
          height: 274em;
          margin-top: -120em;
          margin-right: 0;
          .__w600 & {
            margin-top: 0;
            width: 76em;
            height: auto;
            .desc-polygon__txt {
              display: none;
            }
          }
        }

        &--right {
          float: right;
          margin-right: 0;
        }

        // Type2 하이드 타입 (contact) 스타일
        &--hide {
          display: none;
          .w1600__ & {
            display: block;;
          }
        }
      }
      .kv-txt {
        &__kr {
          display: inline-block;
          width: var(--cw5);
          text-indent: calc(var(--cw1) + var(--gutter));
          margin-left: calc(var(--cw3) + var(--gutter));
          .__w600 & {
            display: block;;
            width: var(--cw3);
            margin-left: calc(var(--cw2) + var(--gutter));
          }
          .w1600__ & {
            width: var(--cw4);
          }
        }
      }
    }
    &__kv-desc {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(23, 16, 16, 0) 0%, rgba(23, 16, 16, 0.2) 100%);;
      z-index: 30;
      text-align: right;
      padding: 40em 8em 8em 8em;
    }
  }

  .t-dfy {
    color: inherit;
    &:after {
      color: inherit;
    }
  }

</style>
<style lang="scss">
  .c-last__kv-txt {
    .t-dfy {
      color: inherit;
      &:after {
        color: inherit;
      }
    }
  }
</style>