import * as _ from 'fxjs';
import * as $ from 'fxdom';
import * as C from "fxjs/Concurrency";
import { createRouter, createWebHistory } from 'vue-router';
import { useStore } from '@/helper/vue.js';
import { store } from '../store/index.js';
import axios from 'axios';
import { isLoaderOn, isSmoothScroll } from '@/store/appSettings.js';
import { fetchData, getWorkWithUrl } from '@/store/data-workList.js';
import { getData } from "@/pages/work/data-subLanding.js";
import { locoScroll } from "@/js/common.js";

async function getIpClient() {
  try {
    const res = await axios.get('https://extreme-ip-lookup.com/json');
    return res.status == 200 ? res.data.query : "error";
  } catch (error) {
    console.error(error);
    return false;
  }
}
const routes = [
  {
    path: '/:pathMatch(.*)*',
    redirect: "/404"
  },
  {
    path: '/admin/projectslist',
    name: 'projectslist',
    meta: { pageTitle: 'DFY® | admin', contentColor: ["F9F5EF", "171010", "171010"] },
    components: {
      default: () => import('../pages/edit/ProjectsList.vue'),
    },
    beforeEnter: async (to, from, next) => {
      const myIp = await getIpClient();
      if (myIp == false || !["220.118.108.16", "14.52.62.44"].includes(myIp)) {
        next('/work');
        return;
      }
      next();
    }
  },
  {
    path: '/admin/edit/:id',
    name: 'edit',
    meta: { pageTitle: 'DFY® | admin' },
    components: {
      default: () => import('../pages/edit/Edit.vue'),
    },
    beforeEnter: async (to, from, next) => {
      const myIp = await getIpClient();
      if (myIp == false || !["220.118.108.16", "14.52.62.44"].includes(myIp)) {
        next('/work');
        return;
      }
      next();
    },
  },
  {
    path: '/work',
    name: 'work',
    meta: { pageTitle: 'DFY® | Work', desc: 'WHAT WE MADE', titleComponent: "WorkMainTitle", loadingColor: ["171010", "F9F5EF"] },
    components: {
      default: () => import('../pages/work/WorkView.vue'),
    },
  },
  {
    path: '/work/:id',
    name: 'project',
    meta: { pageTitle: '', titleComponent: "ProjectTitle" },
    components: {
      default: () => import('../pages/project/Project.vue'),
    },
    beforeEnter(to, from, next) {
      let work = null;
      if(to.query["preview"] == 1) {
        work = getWorkWithUrl(to.params.id, "uuid");
      }
      else {
        work = getWorkWithUrl(to.params.id);
      }
      to.meta.pageTitle = "DFY® | " + work.brand;
      next();
    },
    beforeRouteLeave (to, from, next) {
      console.log('BEFORE ROUTE LEAVE', to, from)
    }
  },
  {
    path: '/services/:id',
    name: 'work-sublanding-service',
    meta: { pageTitle: '', titleComponent: "WorkDetailTitle" },
    components: {
      default: () => import('../pages/work/SubLanding.vue'),
    },
    beforeEnter: (to, from, next) => {
      let category = -1;
      let pageTitle = ''
      switch(to.params.id) {
        case "digital-products-platforms"
          : category = 0;
            pageTitle = 'Digital Products Platforms'
          break;

        case "branded-ecommerce"
          : category = 1;
            pageTitle = 'Branded e-Commerce'
          break;

        case "branding-strategy-design"
          : category = 2;
            pageTitle = 'Branding Strategy Design'
          break;

        case "branded-experience"
          : category = 3;
            pageTitle = 'Branded Experience'
          break;
      }

      to.meta.pageTitle = "DFY® | " + pageTitle;

      if(category != -1) {
        store.commit("workList/setFilter", { type: "category", id: category });
        next();
      }
      else {
        router.push({ path: "/404" });
      }
    }
  },
  {
    path: '/interests/:id',
    name: 'work-sublanding-tag',
    meta: { pageTitle: '', titleComponent: "WorkDetailTitle" },
    components: {
      default: () => import('../pages/work/SubLanding.vue'),
    },
    beforeEnter: (to, from, next) => {
      let tag = -1;
      let pageTitle = ''
      switch(to.params.id) {
        case "human-technology":
          tag = 0;
          pageTitle = 'Human Technology'
          break;

        case "new-way-to-retail":
          tag = 1;
          pageTitle = 'New Way To Retail'
          break;

        case "ready-to-launch":
          tag = 2;
          pageTitle = 'Ready To Launch'
          break;

        case "being-social":
          tag = 3;
          pageTitle = 'Being Social'
          break;

        case "purposeful-spaces":
          tag = 4;
          pageTitle = 'Purposeful Spaces'
          break;

        case "tomorrow-today":
          tag = 5;
          pageTitle = 'Tomorrow Today'
          break;

        case "stories-that-inspire":
          tag = 6;
          pageTitle = 'Stories That Inspire'
          break;

        case "samsung-galaxy":
          tag = 7;
          pageTitle = 'Samsung Galaxy'
          break;
      }

      to.meta.pageTitle = "DFY® | " + pageTitle

      if(tag != -1) {
        store.commit("workList/setFilter", { type: "tag", id: tag });
        next();
      }
      else {
        router.push({ path: "/404" });
      }
    }
  },
  {
    path: '/services',
    name: 'services',
    meta: { pageTitle: 'DFY® | Services', titleComponent: 'ServicesTitle', loadingColor: ["171010", "F9F5EF"] },
    components: {
      default: () => import('../pages/services/ServicesView.vue'),
    },
  },
  {
    path: '/about',
    name: 'about',
    meta: { pageTitle: 'DFY® | About', desc: 'Who we are', titleComponent: "AboutTitle", loadingColor: ["F9F5EF", "171010"], contentColor: ["F9F5EF", "171010", "F9F5EF"] },
    components: {
      default: () => import('../pages/about/AboutView.vue'),
    },
  },
  {
    path: '/404',
    name: '404',
    meta: { pageTitle: 'DFY® | 404 Design Not Found' },
    components: {
      default: () => import('../pages/404/404.vue'),
    },
  },
  {
    path: '/contact',
    name: 'contact',
    meta: { pageTitle: 'DFY® | Contact', desc: 'CONNECT WITH US', titleComponent: "ContactTitle", loadingColor: ['171010', 'F9F5EF'] },
    components: {
      default: () => import('../pages/contact/ContactView.vue'),
    },
  },
  {
    path: '/',
    alias: '/seoul',
    name: 'home',
    meta: { pageTitle: 'DFY® | Today’s Creative for Tomorrow™', desc: 'WE LOVE', titleComponent: "HomeTitle", loadingColor: ["F9F5EF", "171010"], contentColor: ["F9F5EF", "171010", "F9F5EF"] },
    components: {
      default: () => {
        return import('../pages/home/mainView.vue');
      },
    },
  },
  {
    path: '/blank',
    name: 'blank',
    meta: { pageTitle: 'Blank', titleComponent: "WorkMainTitle", loadingColor: ["171010", "F9F5EF"] },
    components: {
      default: () => {
        return import('../pages/test/Blank.vue');
      },
    },
  },
  {
    path: '/test-wow',
    name: 'testWow',
    meta: { titleComponent: "BlankTitle" },
    components: {
      default: () => {
        return import('../pages/test/TestWow.vue');
      },
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { let: 0, top: 0, behavior: "auto" }
  }
});

const CB_READY_CURTAIN = async (next, to, from) => {
  store.commit("set", { sel: "pageState", to: "loading" });
  store.commit("loadManager/readyQueue");
  store.commit("set", { sel: "currPage", to: to.name });
  store.commit('set', { sel: "loadingProgress", to: 0.25 });
  // 프로젝트 페이지라면 데이터 받아올 때 까지 기다린다.
  if(to.name == "project") {
    await store.dispatch('project/init', to.params.id);
    next();
  }
  else {
    next();
  }

  if(!isLoaderOn) {
    window.addEventListener("landed", () => {
      locoScroll && locoScroll.scrollTo("top", { duration: 0, disableLerp: true });
      locoScroll && locoScroll.start();
      window.dispatchEvent(new CustomEvent("loader.complete"));
    }, { once: true });
  }
};


let earlyLoadResolve = null;
const earlyLoadPromise = new Promise((resolve) => {
  earlyLoadResolve = resolve;
});

router.beforeEach((to, from, next) => {

  store.commit("set", { sel: "loadingState", to: "pending" });
  store.commit("set", { sel: "earlyLoadPromise", to: earlyLoadPromise });

  // 최초 접속(splash)
  if(store.state.isFirstLanding) {
    // 브라우저 체크
    let browserLang = navigator.language;
    browserLang = browserLang.toLowerCase().substring(0, 2);

    if(browserLang == "ko") {
      store.commit("set", { sel: "lang", to: "ko" });
      store.commit("set", { sel: "isLangKr", to: true });
      // store.commit("set", { sel: "lang", to: browserLang.toLowerCase().substring(0, 2) });
    }
    else {
      store.commit("set", { sel: "lang", to: "en" });
      store.commit("set", { sel: "isLangKr", to: false });
    }

    // device check
    const isMobileAgent = /Mobile|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) ||
    (/SamsungBrowser/i.test(navigator.userAgent) && navigator.maxTouchPoints > 1);

    // window.addEventListener("loader.complete", () => {
    //   $.qs(".d-debugger").innerText = navigator.userAgent;
    // }, { once: true });
    // 모바일 디바이스일 경우 초기 처리
    if(isMobileAgent) {
      $.addClass("s--no-smooth-scroll", $.qs("html"));
    }
    // 모바일 디바이스가 아닌 경우 초기 처리
    else {
      $.addClass("s--desktop", $.qs("html"));
      // PC 사파리 구분
      if(/Safari/i.test(navigator.userAgent) && !/Chrome/i.test(navigator.userAgent)) {
        store.commit("set", { sel: "isPcSafari", to: true });
        $.addClass("s--safari", $.qs("html"));
      }
    }

    store.commit("set", { sel: "isMobileAgent", to: isMobileAgent });
    window.dispatchEvent(new CustomEvent("got.agent"));

    if(!isSmoothScroll) {
      store.commit("set", { sel: "isMobileAgent", to: true });
      $.addClass("s--no-smooth-scroll", $.qs("html"));
    }

    // 최초에 받아두면 편해지는 데이터(ex. work)는 최초에 받아두고 라우터 이동시켜준다.
    if(!store.state.isEarlyLoadComplete) {
      _.go(
        [ fetchData() ],
        C.takeAll,
        _.tap(() => {
          store.commit('set', { sel: "isEarlyLoadComplete", to: true });
          store.commit("set", { sel: "isFirstLanding", to: false });
          store.commit("set", { sel: "currPage", to: to.name });
          earlyLoadResolve();
        })
      );
    }
  }

  if(!isLoaderOn) {
    CB_READY_CURTAIN(next, to, from);
    return;
  }

  const { name, params, meta, query } = to;

  // 이미 등록된 이벤트가 있다면 해제.
  window.removeEventListener("ready.curtain", CB_READY_CURTAIN.bind(null, next, to, from));
  // 커튼 완료 후 목표 페이지 next()
  window.addEventListener("ready.curtain", CB_READY_CURTAIN.bind(null, next, to, from), { once: true });

  window.dispatchEvent(new CustomEvent("prepare.enter", {
    detail: { name, params, meta, query }
  }));

  // if(store.state.isEarlyLoadComplete) {
  //   // 페이지 떠날 준비 시작(위에서 ready.curtain 이벤트를 먼저 등록해주고 디스패치한다)
  //   window.dispatchEvent(new CustomEvent("prepare.enter", {
  //     detail: { name, params, meta }
  //   }));
  // }
});

// 비동기 컴포넌트를 모두 받은 후에 실행(데이터만 받고 setup()은 실행되지 않은 상태이다)
// 활용할 상황이 있을까?
// router.beforeResolve((to, from, next) => {
//   console.log('🚌 beforeResolve');
//   const customEvent = new CustomEvent('landing', {
//     detail: {
//       hazcheeseburger: true,
//     },
//   });
//   document.querySelector('.i-root').dispatchEvent(customEvent);
//   next();
// });

let isWindowLoaded = false;
const waitFontLoad = () => {
  if(isWindowLoaded) return;
  let waitLoadResolve;
  const waitLoad = new Promise(resolve => {
    waitLoadResolve = resolve;
  });
  store.commit("loadManager/addQueue", waitLoad);

  const CB_LOAD = () => {
    console.log("🚀 load");
    // store.commit('set', { sel: "loadingProgress", to: 0.75 });
    waitLoadResolve();
    store.dispatch("onLoad");
    isWindowLoaded = true;
  };

  if(document.readyState == "complete") {
    console.log(`document.readyState == "complete"`);
    CB_LOAD();
  }
  else {
    console.log(`else document.readyState == "complete"`);
    window.addEventListener("load", CB_LOAD, { once: true });
  }
};

const interestPageName = (id) => {
  switch (id) {
    case "human-technology": return 'Human Technology'
    case "new-way-to-retail": return 'New Way To Retail'
    case "ready-to-launch": return 'Ready To Launch'
    case "being-social": return 'Being Social'
    case "purposeful-spaces": return 'Purposeful Spaces'
    case "tomorrow-today": return 'Tomorrow Today'
    case "stories-that-inspire": return 'Stories That Inspire'
    case "samsung-galaxy": return 'Samsung Galaxy'
  }
}

router.afterEach((to, from) => {
  // console.clear();
  console.log('🚌 afterEach');

  if (to.name === 'work-sublanding-tag') {
    to.meta.pageTitle = interestPageName(to.params.id)
  }

  // project to project
  if (to.name && from.name === 'project') {
    !to.meta.pageTitle ? to.meta.pageTitle = getWorkWithUrl(to.params.id).brand : to.meta.pageTitle
  }

  document.title = to.meta.pageTitle

  setTimeout(function(){
    store.dispatch("afterPageEnter");
    // TEMP(옮겨야 함)
    $.qs('.d-dboard') && $.removeClass('d-dboard--active d-dboard--slide', $.qs('.d-dboard'));
  });

  // 웹폰트 준비될 때 까지 기다려주기 위한...
  waitFontLoad();
});

export default router;