import * as _ from "fxjs";

export default {
	namespaced: true,
	state: {
		loadedItems: [],
		pendingQueue: []
	},
	getters: {
  },
	mutations: {
		["test1"](state) {
			console.log("asdffasdfdasf");
      return "gooodddd";
    },
	},
  actions: {
		test2({ state, commit }) {
			return "GOOOOOODDDDD!!!!!!!!!";
    },
	}
}