<template>
	<div ref="self" class="c-noti" :class="{'c-noti--hide': data.hiddenOnLoading }">
		<div v-if="!data.isClosed" class="c-noti__item">
			<div class="c-noti__item-text"></div>
			<button class="c-noti__close-btn">
				<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.99984 0.666748C4.3915 0.666748 0.666504 4.39175 0.666504 9.00008C0.666504 13.6084 4.3915 17.3334 8.99984 17.3334C13.6082 17.3334 17.3332 13.6084 17.3332 9.00008C17.3332 4.39175 13.6082 0.666748 8.99984 0.666748ZM12.5832 12.5834C12.2582 12.9084 11.7332 12.9084 11.4082 12.5834L8.99984 10.1751L6.5915 12.5834C6.2665 12.9084 5.7415 12.9084 5.4165 12.5834C5.0915 12.2584 5.0915 11.7334 5.4165 11.4084L7.82484 9.00008L5.4165 6.59175C5.0915 6.26675 5.0915 5.74175 5.4165 5.41675C5.7415 5.09175 6.2665 5.09175 6.5915 5.41675L8.99984 7.82508L11.4082 5.41675C11.7332 5.09175 12.2582 5.09175 12.5832 5.41675C12.9082 5.74175 12.9082 6.26675 12.5832 6.59175L10.1748 9.00008L12.5832 11.4084C12.8998 11.7251 12.8998 12.2584 12.5832 12.5834Z" fill="#171010" fill-opacity="0.4"/></svg>
			</button>
			<button v-if="data.item.button" class="c-noti__btn">
				<span>{{ data.item.button.text }}</span>
				<svg class="c-noti__btn-ico" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <g filter="url(#filter0_b_11458:19005)"> <circle cx="10" cy="10" r="10" fill="#171010" fill-opacity="0.1"/> </g> <path d="M6.16005 9.41667H12.6759L9.82921 6.57C9.60171 6.3425 9.60171 5.96917 9.82921 5.74167C10.0567 5.51417 10.4242 5.51417 10.6517 5.74167L14.4959 9.58584C14.7234 9.81334 14.7234 10.1808 14.4959 10.4083L10.6517 14.2525C10.4242 14.48 10.0567 14.48 9.82921 14.2525C9.60171 14.025 9.60171 13.6575 9.82921 13.43L12.6759 10.5833H6.16005C5.83921 10.5833 5.57671 10.3208 5.57671 10C5.57671 9.67917 5.83921 9.41667 6.16005 9.41667Z" fill="#F9F5EF" fill-opacity="0.8"/> <defs> <filter id="filter0_b_11458:19005" x="-20" y="-20" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"> <feFlood flood-opacity="0" result="BackgroundImageFix"/> <feGaussianBlur in="BackgroundImage" stdDeviation="10"/> <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_11458:19005"/> <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_11458:19005" result="shape"/> </filter> </defs> </svg>
			</button>
		</div>
	</div>
</template>

<script>
import * as $ from 'fxdom';
import * as _ from 'fxjs';
import { ref, reactive, computed, watch, onMounted, useStore } from "@/helper/vue.js";
import { gsap, TextPlugin } from "gsap/all";
gsap.registerPlugin(TextPlugin);
export default {
	setup() {
		const { state, get, commit, dispatch } = useStore();
		let isInitialized = false;
		let isPlayed = false;
		const startDelay = 2800;
		const self = ref();
		const data = reactive({
			isClosed: false,
			hiddenOnLoading: computed(() => get["hiddenOnLoading"]),
			item: {
				text: `안녕하세요! 🙌 <br>지금 디파이와 함께할 멋진 동료를 찾고 있어요!`,
				button: {
					text: "채용공고",
					onClick() {
						commit("set", { sel: "chatbot.isVisited", to: true });
						$.addClass("chat-body-lock", $.qs("body"));
						$.addClass("scroll-lock", $.qs(".app"));
						dispatch('chatbot/openChatbot', {subpageName: "chatMain"});
						setTimeout(() => {
        			$.qs(".c-chatbot .main-cont .main-wrap").scrollTop = $.qs(".c-chatbot .main-job").offsetTop - 50;
      			}, 620);
					}
				}
			},
		});

		let landedTimer = null;
		onMounted(() => {
			const textEl = $.qs(".c-noti__item-text", self.value);
			const btnEl = $.qs(".c-noti__btn", self.value);
			window.addEventListener("landed", () => {
				return
				if(isPlayed) return;
				if(data.isClosed) return;
				// each landed
				clearTimeout(landedTimer);
				landedTimer = setTimeout(() => {
					self.value.style.display = "block";
					// $.addClass("c-noti--play", self.value);
					gsap.to(textEl, {
						text: { value: data.item.text },
						duration: 1,
						ease: 'none',
						onComplete() {
							setTimeout(() => {
								btnEl.style.display = "inline-block";
							}, 100);
						}
					});
					gsap.fromTo(btnEl, { opacity: 0 }, { opacity: 1, duration: 0.5 });
				}, startDelay);

				// init
				if(!isInitialized) {
					if(btnEl) {
						btnEl.addEventListener("click", data.item.button.onClick);
					}
					isInitialized = true;
					isPlayed = true;
				}
			});
			window.addEventListener("ready.curtain", () => {
				// if(data.isClosed) return;
				// gsap.killTweensOf(textEl);
				// textEl.innerText = "";
				// $.removeClass("c-noti--play", self.value);
				// btnEl.style.display = "none";
			});

			$.qs(".c-noti__close-btn").addEventListener("click", () => {
				commit("set", { sel: "chatbot.isVisited", to: true });
			});

			watch(
				() => state.chatbot.isVisited,
				(curr) => {
					if(curr == true) {
						data.isClosed = true;
					}
				}
			)
		});

		return {
			self,
			data
		}
	}
}
</script>

<style scoped lang="scss">
.c-noti {
	position: fixed;
	display: none;
	right: 43px;
	bottom: 30px;
	width: 175px;
	font-family: "YoonGothicPro750";
	word-break: keep-all;
	text-align: left;
	color: #F9F5EF;
	z-index: 1490;
	transform: translateY(0);
	opacity: 1;
  transition: transform 0.5s ease, opacity 0.5s ease;
	&__item {
		position: relative;
		margin-top: 10px;
		padding: 12px 16px;
		background-color: #0AA86C;
		border-radius: 16px 16px 2px 16px;
	}
	&__close-btn {
		position: absolute;
		right: 8px;
		top: 8px;
		font-size: 0;
		&:hover {
			path {
				fill-opacity: 0.2;
			}
		}
		path {
			transition: fill-opacity 0.15s;
		}
	}
	&__btn {
		position: relative;
		display: none;
		font-size: 13px;
		line-height: 1.48;
		letter-spacing: -0.02em;
		padding: 4px 32px 4px 12px;
		margin-top: 9px;
		border-radius: 28px;
		background-color: rgba(23, 16, 16, 0.1);
		font-family: inherit;
		transition: background-color 0.15s;
		&:hover {
			background-color: rgba(23, 16, 16, 0.2);
		}
	}
	&__btn-ico {
		position: absolute;
		top: 50%;
		right: 4px;
		transform: translateY(-50%);
	}
	&__item-text {
		font-size: 13px;
		line-height: 1.48;
		letter-spacing: -0.02em;
	}
	&--play {
		display: block;
	}
	&--hide {
		transform: translateY(70px);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.23s ease;
	}
	.s--in-admin &, [data-page-name="404"] & {
		display: none !important;
	}
}
</style>