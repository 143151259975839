<template>
  <normalButtons :is-footer="true" text="buttonText" :minime-image="minimeImage" :minime-length="minimeLength"/>
</template>

<script>
import normalButtons from "@/parts/button/button.vue";

export default {
  name: 'Footer CTA Button',
  components: {
    normalButtons
  },
  props: {
    buttonText: {
			type: String,
			default: null
    },
    hoverText: {
			type: String,
			default: null
    },
    minimeImage: {
      type: Number,
      default: 0
    },
    minimeLength: {
      type: Number,
      default: 14
    },
  }
}
</script>