import * as $ from "fxdom";
import * as _ from "fxjs";
import { gsap } from "gsap/all";

let colorChangerTls = [];

window.addEventListener("loader.complete", () => {
	_.go(
		$.qsa("[data-color-anchor]"),
		_.each(item => {
			const [ targetAnchor, screenAnchor, beforeForeground, beforeBackground, beforeCanvas, afterForeground, afterBackground, afterCanvas ] = _.split(",", $.attr("data-color-anchor", item));
			colorChangerTls.push(
				gsap.timeline({
					scrollTrigger: {
						trigger: item,
						start: `${targetAnchor * 100}% ${screenAnchor * 100}%`,
						end: "+=1",
						// markers: true,
						// 위에서 접근
						onEnter() {
							// console.log("onEnter", afterForeground);
							document.documentElement.style.setProperty("--foreground", afterForeground);
							document.documentElement.style.setProperty("--background", afterBackground);
							document.documentElement.style.setProperty("--canvasColor", afterCanvas);
						},
						// 아래에서 접근
						onEnterBack() {
							// console.log("onEnterBack", beforeForeground);
							document.documentElement.style.setProperty("--foreground", beforeForeground);
							document.documentElement.style.setProperty("--background", beforeBackground);
							document.documentElement.style.setProperty("--canvasColor", beforeCanvas);
						}
					}
				})
			);
		})
	);
});

window.addEventListener("ready.curtain", () => {
	_.go(
		colorChangerTls,
		_.each(item => {
			if(item) {
				item.scrollTrigger && item.scrollTrigger.kill();
				item.kill();
				item = null;
			}
		})
	);
	colorChangerTls = [];
});