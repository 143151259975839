<template>
  <div ref="self" class="in-box-wrap">
    <div v-for="(cont, index) in item.contents.items" :key="cont.name" class="in-box" :class="{'in-box--disabled': data.disabled}">
      <div class="in-box__label-wrap">
        <div class="in-box__label" :class="{'in-box__label--required': cont.required}">
          <span class="t-cb--13"><span :class="!data.isLangKr ? 't--fak' : 't--kr'">{{ cont.leftLabel }}</span></span>
        </div>
        <div v-if="cont.rightLabel" class="in-box__label in-box__label--soft">
          <span v-if="cont.type == 'textarea'" class="t-cb--13"><span :class="!data.isLangKr ? 't--fak' : 't--kr'">{{ data.textLength }} / {{ cont.rightLabel }}</span></span>
          <span v-else class="t-cb--13"><span :class="!data.isLangKr ? 't--fak' : 't--kr'">{{ cont.rightLabel }}</span></span>
        </div>
      </div>
      <form class="in-box__box-wrap">
        <textarea v-if="cont.type == 'textarea'" @input="methods.enterInput" :placeholder="cont.placeholder" :name="cont.name" class="in-box__box in-box__box--long" :class="{'in-box__box--required': cont.required}" :disabled="data.disabled"></textarea>

        <div v-else-if="cont.type == 'select'" :class="{'in-box__box--required': cont.required}" class="in-box__box in-box__box--select">
          <select :name="cont.name" id="" v-model="data.selected" @change="methods.slelctOption(cont)" :required="cont.required" class=" in-box__select" :disabled="data.disabled">
            <option v-if="cont.placeholder" disabled selected hidden value="">{{ cont.placeholder }}</option>
            <option v-for="(opt, index) in cont.option" :key="index" :value="opt?.value || opt"><span class="t-cb--13"><span :class="!data.isLangKr ? 't--fak' : 't--kr'">{{ opt?.title || opt }}</span></span></option>
          </select>
          <span class="in-box__select-arrow ico-chatbot--navi-expand--more"></span>
        </div>

        <div v-else-if="cont.type == 'file'" class="in-box__box--file-wrap">
          <label for="file" id="file-label" class="in-box__box" :class="{'in-box__box--required': cont.required}">{{ cont.placeholder }}</label>
          <span class="in-box__state"></span>
          <span v-if="cont.type == 'file'" class="in-box__icon ico-chatbot--content-file"></span>
          <!-- <label for="file" class="in-box__box" :class="{'in-box__box--required': cont.required}">{{ cont.placeholder }}</label> -->
          <input type="file" id="file" :placeholder="cont.placeholder" :name="cont.name" class="in-box__box--file" accept="application/pdf,application/vnd.ms-excel,application/zip" @change="methods.onUpload">
        </div>

        <input v-else-if="cont.type == 'preset'" type="text" tabindex="-1" readonly :value="data.selectedInfo[cont.selectedInfo]" :name="cont.name" class="in-box__box in-box__box--required in-box__box--preset in-box__box--posi" :disabled="data.disabled">
        <input v-else-if="cont.type == 'presetText'" type="text" tabindex="-1" readonly :value="cont.text" :name="cont.name" class="in-box__box in-box__box--required in-box__box--preset in-box__box--posi" :disabled="data.disabled">

        <input v-else type="text" @input="methods.enterInput" :placeholder="cont.placeholder" :name="cont.name" class="in-box__box" :class="{'in-box__box--required': cont.required}" :disabled="data.disabled">

        <span class="in-box__state"></span>
        <!-- <input v-if="cont.type !== 'selected' && cont.type !== 'file'" type="reset" class="in-box__close" @click="methods.resetText(cont.name)"> -->
        <button v-if="cont.type != 'preset' && cont.type != 'presetText'" tabindex="-1" type="reset" class="in-box__close" @click="methods.resetText(cont.name, index)">
          <span class="ico-chatbot--content-cancel"></span>
        </button>

        <!-- <span v-if="cont.type == 'file'" class="in-box__icon ico-chatbot--content-file"></span> -->
      </form>
    </div>

    <div class="in-box" :class="{'in-box--disabled': data.disabled}">
      <div class="policy-check in-box__box--required">
        <input type="checkbox" id="policy" v-model="data.policyCheck" :disabled="data.disabled">
        <label for="policy" class="policy-check__label">
          <span class="policy-check__box">
            <span class="policy-check__check ico-chatbot--input-check"></span>
          </span>
          <span class="policy-check__txt">
            <span v-if="!data.isLangKr" class="t-cb--16"><span class="t--fak">I accept the terms of the <button class="policy-check__page" @click="methods.setPolicyState()">privacy policy</button> and I agree</span></span>
            <span v-else class="t-cb--16"><span class="t--kr"><button class="policy-check__page" @click="methods.setPolicyState()">개인정보보호정책</button>에 동의합니다.</span></span>
          </span>
        </label>
      </div>
    </div>
  </div>
  <!-- <Policy :policyState="data.policyState" @setPolicyState="methods.setPolicyState"></Policy> -->
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { ref, reactive, computed, watch, watchEffect, onBeforeUpdate, onUpdated, onMounted, onActivated, onDeactivated, useStore } from "@/helper/vue.js";
import { gsap } from "gsap/all";
import { isURLNoProtocol } from "@/helper/gadget.js";
// import Policy from "../subpages/_ChatPolicy.vue";
import { onUnmounted } from '@vue/runtime-core';

export default {
  props: ['item', 'index', 'dataName', 'subpage', 'hiringTit'],

  components: {
    // Policy,
  },
  setup(props) {
    const { state, get, commit, dispatch } = useStore();
    
    const data = reactive({
      isLangKr: computed(() => state.isLangKr),
      input: {},
      test: null,
      selected: '',
      // typeSelected: '',
      textLength: 0,
      policyCheck: false,
      policyState: computed(() => state.chatbot.policyState),
      getAllItems: computed(() => get["chatbot/getAllItems"]),
      // history: ,
      excuteNextEvent: false,
      disabled: false,
      inputTest: computed(() => state.chatbot.input),
      selectedInfo: computed(() => state.chatbot.selectedInfo[props.dataName].info),
    });

    const methods = {
      setPolicyState: () => {
        // data.policyState = !data.policyState;
        if (data.policyState) {
          dispatch('chatbot/addTimer', {delay: 400, callback: () => {
            commit('chatbot/setPolicyState');
          }})
        } else {
          commit('chatbot/setPolicyState');
        }
      
        _.go(
          $.qs(".sub-policy-wrap"),
          $.toggleClass("sub-policy-wrap--scroll"),
          $.toggleClass("sub-policy-wrap--open")
        )
      },

      slelctOption: (cont) => {
        const select = $.find('.in-box__box--select', self.value);
        data.input[cont.name] = data.selected;
        if (!cont.required) {
          methods.checkValidationClass(select, data.selected !== cont.option[0]);  
        } else {
          methods.checkValidationClass(select, data.selected !== '');
        }
      },
      enterInput: (event) => { // 실시간 check
        // console.log("event");
        // console.log("event.target", event.target); 
        // console.log("event.target.value", event.target.value);

        data.input[event.target.name] = event.target.value;

        if (event.target.type == 'textarea') {
          _.go(props.item.contents.items,
            _.each(item => {
              if (item.type == 'textarea') {
                if (event.target.value.length > item.rightLabel) {
                  data.textLength = item.rightLabel;
                  event.target.value = event.target.value.substring(0, item.rightLabel -1);
                } else {
                  data.textLength = event.target.value.length;
                }
              }
            })
          )
        }

        if ($.hasClass("in-box__box--required", event.target)) {
          methods.checkValidationClass(event.target, event.target.value !== '');
        }
        methods.checkValidation(event.target);
      },
      resetText: (name, index) => {
        if (data.input[name]) {
          data.input[name] = ''
          if ($.hasClass("in-box__box--required", $.findAll(".in-box__box", self.value)[index])) {
            _.go($.findAll(".in-box__box", self.value)[index],
              $.removeClass("in-box__box--posi"),
              $.addClass("in-box__box--nega")
            )
          } else {
            _.go($.findAll(".in-box__box", self.value)[index],
              $.removeClass("in-box__box--posi"),
              $.removeClass("in-box__box--nega")
            )
          }
        }
      },
      validateSubmit: (cont) => {
        // console.log("validateSubmit ----------------------------");
        methods.enterSelected(cont);
        methods.checkPolicy();

        _.go($.findAll(".in-box__box--required", self.value),
          _.each(item => {
            const hasPosi = $.hasClass("in-box__box--posi", item);

            methods.checkValidation(item);
            if (!hasPosi) {
              $.addClass("in-box__box--nega", item);
            }
          
          })
        )
        if ($.findAll(".in-box__box--nega", self.value).length == 0) {
            _.go(props.item.contents.items,
              _.each(item => {
                if (item.type == 'presetText') {
                  data.input[item.name] = item.text;
                  if (item.subText) {
                    data.input = {...data.input, ...item.subText}
                  }
                }
              })
            )
            commit('chatbot/setInput', {input: data.input, dataName: props.dataName});
            commit('chatbot/setInputState', {dataName: props.dataName, change: 'passValidation'});
            dispatch('chatbot/onMailSubmit', { type: props.dataName });
            // data.input.file = null;
            // 스크롤 위치 도달했을 떄 dispatch되게
            // dispatch('chatbot/executeNextEvent', {nextId: props.item.nextId, dataName: props.dataName});
        } else {
          const subpage = $.qs(".subpage");

          const negaArray = [];
          $.findAll(".in-box__box", self.value).forEach((item, index) => {
            if ($.hasClass("in-box__box--nega", item)) {
              negaArray.push(index);
            }
          })
          const negaPrevHeightSum = _.go(
            $.findAll(".in-box", self.value), 
            _.slice(negaArray[0] - $.findAll(".in-box", self.value).length),
            _.map(boxItem => boxItem.offsetHeight + 14),
            _.reduce((a, b) => a + b)
          )

          const nextHeightSum = _.go(
            $.qsa(".chat-comp"), 
            _.slice(-3),
            _.map(nextItem => nextItem.offsetHeight),
            _.reduce((a, b) => a + b)
          )
          dispatch('chatbot/addTimer', {delay: 300, callback: () => {
            // console.log("innerHeight", window.innerHeight * 6);
            let position
            if (window.innerWidth < 600) {
              position = subpage.scrollHeight - nextHeightSum - (40 + 32 + 32 + 48) + (window.innerHeight * 0.06636) - negaPrevHeightSum;
            } else {
              position = subpage.scrollHeight - nextHeightSum - 40 - 32 - 32 - 48 - negaPrevHeightSum;
            }
            // 전체 스크롤 가능한 양 - 패딩 및 마진탑 - 첫번째 nega 이전 인풋 높이 
            const scrollPosition = { scrollDistance: subpage.scrollTop };
            gsap.to(scrollPosition, 0.3, { scrollDistance: position, ease: "none",
              onUpdate: () => {
                subpage.scrollTop = scrollPosition.scrollDistance;
              }
            });
          }})
        }
      },

      excuteNextEvent: () => {
        const layoutInrH = $.qs(".p-layout__inr").offsetHeight;
        const subpageScrollTop = $.qs(".subpage").scrollTop;

        let staticH
        if (window.innerWidth < 600) {
          staticH = layoutInrH - window.innerHeight;
        } else {
          staticH = layoutInrH - 720;
        }

        if (!data.excuteNextEvent && subpageScrollTop > staticH) {
          dispatch('chatbot/executeNextEvent', {nextId: props.item.nextId, dataName: props.dataName});
          data.excuteNextEvent = !data.excuteNextEvent;
        }
      },


      enterSelected: (cont) => {
        _.go(cont,
          _.each(item => {
            if (item.type == 'selected') {
              data.input[item.name] = item.text;
            }
          })
        )
      },
      checkPolicy: () => {
        if (data.policyCheck) {
          $.removeClass("in-box__box--nega", $.find(".policy-check", self.value));
          $.addClass("in-box__box--posi", $.find(".policy-check", self.value));
        } else {
          $.removeClass("in-box__box--posi", $.find(".policy-check", self.value));
          $.addClass("in-box__box--nega", $.find(".policy-check", self.value));
        }
      },
      checkValidation: (target) => {
        if (target.name == 'email') {
          methods.validateEmail(target);
        } else if (target.name == 'tel') {
          methods.validateTel(target);
        } else if (target.name == 'url') {
          methods.validateUrl(target);
        }
      },
      validateEmail: (target) => {
        const mail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isEmail = mail.test(String(target.value).toLowerCase());
        methods.checkValidationClass(target, isEmail);
      },
      validateTel: (target) => {
         
        const numH = /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/;
        const num = /^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/;
        const isTelH = numH.test(String(target.value));
        const isTel = num.test(String(target.value));
        if (isTel || isTelH) {
          methods.checkValidationClass(target, true);
        } else {
          methods.checkValidationClass(target, false);
        }
      },
      validateUrl: (target) => {
        methods.checkValidationClass(target, isURLNoProtocol(target.value));
      },
      checkValidationClass: (target, isValid) => {
        const hasRequired = $.hasClass("in-box__box--required", target);
        $.removeClass("in-box__box--nega", target);
        $.removeClass("in-box__box--posi", target);

        if (!isValid) {
          $.addClass("in-box__box--nega", target);
          if (!hasRequired && target.value == '') {
            $.removeClass("in-box__box--nega", target);
          }
        } else {
          $.addClass("in-box__box--posi", target);
        }
      },
      checkFileClass: () => {
        const hasRequired = $.hasClass("in-box__box--required", $.qs('label.in-box__box'));
        $.removeClass("in-box__box--nega", $.qs('label.in-box__box'));
        $.removeClass("in-box__box--posi", $.qs('label.in-box__box'));

        if (hasRequired) {
          if (data.input.file !== null && data.input.file !== undefined) {
            $.addClass("in-box__box--posi", $.qs('label.in-box__box'));
          } else {
            $.addClass("in-box__box--nega", $.qs('label.in-box__box'));
          }
        } else {
          if (data.input.file !== null && data.input.file !== undefined) {
            $.addClass("in-box__box--posi", $.qs('label.in-box__box'));
          } else if (data.input.file == undefined) {
            $.removeClass("in-box__box--nega", $.qs('label.in-box__box'));
            $.removeClass("in-box__box--posi", $.qs('label.in-box__box'));
          }
        }
        // console.log("file", $.qs('label.in-box__box'));
      },
      onUpload: () => {
        data.input.file = $.qs('.in-box__box--file').files[0];
        
        if (data.input.file.size > 52428800) {
          alert("파일 첨부는 5MB까지 업로드 가능합니다.")
          $.qs('.in-box__box--file').value = "";
        } else {
          $.qs('#file-label').textContent = $.qs('.in-box__box--file').files[0].name;
          commit('chatbot/setInput', {input: data.input.file, dataName: 'file'});
          methods.checkFileClass();
        }
      }
    }
      
    const self = ref();

    const scrollCallback = () => {
      const allItem = data.getAllItems[props.dataName];
      const allItemLength = allItem.length;
      if (allItem[allItemLength - 1] == 'input-items') {
        methods.excuteNextEvent();
      }
    }

    onMounted(() => {
      _.go($.qsa(".in-box__box-wrap", self.value),
        _.each(boxWrap => {
          boxWrap.addEventListener('submit', (e) => {
            e.preventDefault();
          })
        })
      )
      if (props.dataName !== 'project' && props.dataName !== 'joinTeam') {
        _.go(props.item.contents.items,
          _.each(item => {
            if (item.type == 'selected') {
              data.input[item.name] = item.text; 
            }
          })
        )
      }
      // console.log("onMounted -- input", props.subpage);
      if (props.dataName !== 'project' && props.dataName !== 'joinTeam') {
        _.go(props.item.contents.items,
          _.each(item => {
            if (item.type == 'selected') {
              data.input[item.name] = item.text; 
            }
          })
        )
      }

      commit('chatbot/setInputState', {dataName: props.dataName});
			watch(
        () => state.chatbot.inputState[props.dataName].checkValidation,
        (curr, prev) => {
          if (state.chatbot.inputState[props.dataName].checkValidation) {
            // console.log('data.input', data.input);
            // console.log(props.dataName);
            methods.validateSubmit(props.item.contents);
            commit('chatbot/setInputState', {dataName: props.dataName, change: 'checkValidation'});
            // console.log(data.inputTest)
          }
        }
			);
			watch(
        () => state.chatbot.inputState[props.dataName].passValidation,
        (curr, prev) => {
          if (state.chatbot.inputState[props.dataName].passValidation) {
            data.disabled = !data.disabled;
          }
        }
			);
			watch(
        () => props.subpage,
        (curr, prev) => {
          if (props.subpage) {
            const subpage = $.qs(".subpage");
            subpage.removeEventListener('scroll', scrollCallback);
          } else {
            const subpage = $.qs(".subpage");
            subpage.addEventListener('scroll', scrollCallback);
          }
        }
			);

      const subpage = $.qs(".subpage");
      subpage.addEventListener('scroll', scrollCallback);
    });

    onUnmounted(() => {
    })
    onActivated(() => {
    })
    
    onDeactivated(() => {
    })


    return {
      data,
      methods,
      self
    }
  }
}
</script>

<style>

</style>