<template>
  <div ref="selfHead" class="p-head head">
    <div class="head__inr head__inr--default">
      <!-- <button class="head__btn head__btn--back b-icon" @click="$emit('click'); methods.resetSubpage();"> -->
      <button class="head__btn head__btn--back b-icon" @click="$emit('click'); methods.setScrollTop(); methods.resetSubpage();">
        <span class="head__icon b-icon__img b-hover__icon ico-chatbot--navi-navigate--back"></span>
      </button>
      <div class="head__btn-wrap">
        <!-- <button v-if="bgData.type == 'channel'" class="head__btn b-icon" @click="methods.setSubSubpageState('video', bgData.location)">
          <span class="head__icon b-icon__img b-hover__icon ico-chatbot--media-videocam"></span>
        </button> -->
        <button v-if="bgData.type == 'channel' && bgData.location" class="head__btn b-icon" @click="methods.setSubSubpageState('map', bgData.location)">
          <span class="head__icon b-icon__img b-hover__icon ico-chatbot--content-place"></span>
        </button>
        <button class="head__btn b-lang" @click="methods.changeLang" :style="{color: bgData.colorWhite ? '#F9F5EF' : '#171010'}">
          <div class="t-cb--13"><span class="t--fak">{{!data.isLangKr ? 'KR' : 'EN'}}</span></div>
        </button>
      </div>
    </div>

    <div class="head__inr head__inr--scroll">
      <!-- <button class="head__btn head__btn--back b-icon" @click="$emit('click'); methods.resetSubpage()"> -->
      <button class="head__btn head__btn--back b-icon" @click="$emit('click'); methods.setScrollTop(); methods.resetSubpage()">
        <span class="head__icon b-icon__img b-hover__icon ico-chatbot--navi-navigate--back"></span>
      </button>
      <div class="head__tit-wrap">
        <div class="head__img">
          <Avatar v-if="bgData.headFaceName" :avatarName="bgData.headFaceName" :type="'small'"/>
          <img v-else :src="bgData.headFacePath" alt="">
        </div>
        <div class="head__tit">
          <span class="t-cb--13"><span :class="!data.isLangKr ? 't--fak' : 't--kr'" v-html="bgData.headTitle"></span></span>
        </div>
      </div>
      <div class="head__btn-wrap">
        <!-- <button v-if="bgData.type == 'channel'" class="head__btn b-icon" @click="methods.setSubSubpageState('video', bgData.location)">
          <span class="head__icon b-icon__img b-hover__icon ico-chatbot--media-videocam"></span>
        </button> -->
        <button v-if="bgData.type == 'channel' && bgData.location" class="head__btn b-icon" @click="methods.setSubSubpageState('map', bgData.location)">
          <span class="head__icon b-icon__img b-hover__icon ico-chatbot--content-place"></span>
        </button>
        <button class="head__btn b-lang" @click="methods.changeLang" :style="{color: bgData.colorWhite ? '#F9F5EF' : '#171010'}">
          <div class="t-cb--13"><span class="t--fak">{{!data.isLangKr ? 'KR' : 'EN'}}</span></div>
        </button>
      </div>
    </div>
  </div>

  <div ref="selfLayout" class="p-layout">
    <div v-if="bgData.type == 'channel'" class="p-layout__inr">
      <div class="p-layout__fill"></div>
      <div class="p-layout__main">
        <div class="p-layout__bg-wrap">
          <div class="p-layout__bg">
            <img :src="bgData.bgPath" class="p-layout__bg-img" alt="">
          </div>
        </div>
        <!-- <button class="p-layout__arrow p-layout__arrow--prev">
          <span class="p-layout__arrow-inr">
            <span class="p-layout__icon ico-chatbot--navi-navigate--back"></span>
          </span>
        </button>
        <button class="p-layout__arrow p-layout__arrow--next">
          <span class="p-layout__arrow-inr">
            <span class="p-layout__icon ico-chatbot--navi-navigate--next"></span>
          </span>
        </button> -->
        <div class="p-layout__dim"></div>
        <div class="p-layout__info-wrap">
          <img class="p-layout__face" :src="bgData.facePath" alt="">
          <div class="p-layout__info">
            <span class="t-cb--28"><span class="'t--fak'" v-html="bgData.info"></span></span>
            <!-- <span class="t-cb--28"><span :class="!data.isLangKr ? 't--fak' : 't--kr'">{{ bgData.info }}</span></span> -->
          </div>
          <span class="open-state">
            <template v-if="bgData.openTime == 'coming'">
              <span class="open-state__coming">
                <span class="p-layout__state p-layout__state--coming">
                  <span class="t-cb--11"><span class="t--fak">COMING SOON</span></span>
                </span>
              </span>
            </template>

            <template v-else>
              <span :class="`open-state__${bgData.isOpenNow}`">
                <span :class="`p-layout__state p-layout__state--${bgData.isOpenNow}`">
                  <span class="t-cb--11"><span class="t--fak">{{ bgData.isOpenNow ==='open' ? 'OPEN' : 'CLOSED'}}</span></span>
                </span>
                <span class="p-layout__open-time">
                  <span class="t-cb--13"><span :class="!data.isLangKr ? 't--fak' : 't--kr'" v-html="(data.isLangKr && bgData.openTime === 'Close down') ? '영업종료' : bgData.openTime"></span></span>
                </span>
              </span>

              <!-- <span class="open-state__close">
                <span class="p-layout__state p-layout__state--close">
                  <span class="t-cb--11"><span class="t--fak">CLOSED</span></span>
                </span>
                <span class="p-layout__open-time">
                  <span class="t-cb--13"><span class="t--fak" v-html="bgData.openTime"></span></span>
                </span>
              </span> -->
              <!-- <span class="open-state__open">
                <span class="p-layout__state p-layout__state--open">
                  <span class="t-cb--11"><span class="t--fak">OPEN</span></span>
                </span>
                <span class="p-layout__open-time">
                  <span class="t-cb--13"><span class="t--fak" v-html="bgData.openTime"></span></span>
                </span>
              </span>
              <span class="open-state__close">
                <span class="p-layout__state p-layout__state--close">
                  <span class="t-cb--11"><span class="t--fak">CLOSED</span></span>
                </span>
                <span class="p-layout__open-time">
                  <span class="t-cb--13"><span class="t--fak" v-html="bgData.openTime"></span></span>
                </span>
              </span> -->
            </template>
          </span>
        </div>
      </div>
      <div class="p-layout__cont">
        <slot name="fixedInfo"></slot>
      </div>
    </div>

    <div v-else class="p-layout__inr">
      <div class="p-layout__fill"></div>
      <div class="p-layout__main" :style="{color: bgData.colorWhite ? '#F9F5EF' : '#171010'}">
        <div class="p-layout__bg-wrap">
          <div class="p-layout__bg">
            <img :src="bgData.bgPath" class="p-layout__bg-img" alt="">
          </div>

          <!-- <div class="p-layout__shape">
            <img :src="bgData.shapePath" alt="">
          </div> -->

          <div v-if="bgData.type == 'card'" class="card__shape-wrap" :class="bgData.name == 'project' ? `card__shape-wrap--float1` : `card__shape-wrap--float2`">
            <div class="card__shape-inr">
              <img class="card__shape card__shape--1" :src="bgData.shapePath1" alt="">
              <img class="card__shape card__shape--2" :src="bgData.shapePath2" alt="">
              <img class="card__shape card__shape--3" :src="bgData.shapePath3" alt="">
            </div>
          </div>
          <!-- <div v-else class="card__shape-wrap">
            <img class="card__shape" :src="bgData.shapePath" alt="">
          </div> -->

        </div>
        <div v-if="bgData.type == 'hiring'" class="p-layout__dim" :class="{'p-layout__dim--hiring': bgData.type == 'hiring'}"></div>

        <div class="p-layout__txt-wrap">
          <div class="p-layout__label">
            <span class="t-cb--16"><span :class="!data.isLangKr ? 't--fak' : 't--kr'" v-html="bgData.leftLabel"></span></span>
          </div>
          <div class="p-layout__tit">
            <span class="t-cb--28"><span :class="!data.isLangKr ? 't--fak' : 't--kr'" v-html="bgData.title"></span></span>
          </div>
          <div v-if="bgData.location" class="p-layout__location">
            <span class="t-cb--28"><span :class="!data.isLangKr ? 't--fak' : 't--kr'" v-html="bgData.location"></span></span>
          </div>
          <div v-if="bgData.desc && bgData.type == 'card'" class="p-layout__desc">
            <span class="t-cb--16"><span :class="!data.isLangKr ? 't--fak' : 't--kr'" v-html="`${bgData.descTop ? bgData.descTop + bgData.desc : bgData.desc}`"></span></span>
          </div>
          <!-- <div v-if="bgData.desc && bgData.type == 'card'" class="p-layout__desc">
            <span class="t-cb--16"><span :class="!data.isLangKr ? 't--fak' : 't--kr'" v-html="bgData.desc"></span></span>
          </div> -->
        </div>
      </div>
      <div class="p-layout__cont">
        <slot name="fixedInfo"></slot>
        <div class="p-layout__date-wrap">
          <div class="t-cb--13"><div class="t--fak p-layout__date"></div></div>
          <div class="t-cb--13"><div class="t--fak p-layout__time"></div></div>
        </div>

        <ChatHoc v-for="(item, index) in data.getItems" :key="index" :item="item" :index="index" :dataName="name" :subpage="data.subpage" :hiringTit="bgData.title" :class="`${item.type}`"></ChatHoc>
        <!-- <ChatLoading></ChatLoading> -->
        <div v-if="data.endState[name]" class="power">
          <span class="t-cb--11 power__txt"><span class="t--fak">Powered by </span></span>
          <span class="t-cb--11"><span class="t--fak">DFY® 3F</span></span>
        </div>
      </div>
    </div>
  </div>

  <!-- <Video :videoState="data.videoState" :bgData="bgData" @setVideoState="methods.setVideoState"></Video>
  <Map
    :mapState="data.mapState"
    :bgData="bgData"
    @setMapState="methods.setMapState"
  /> -->
  <!-- <Confetti></Confetti> -->
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { gsap, ScrollTrigger } from "gsap/all";
import { ref, reactive, computed, onMounted, onUpdated, onActivated, onDeactivated, watchEffect, watch, onBeforeUnmount, onUnmounted, useStore } from "@/helper/vue.js";
import { addEvent, removeEvent } from "@/helper/event.js";
import { getCurrentTime } from "@/helper/gadget.js";

import ChatHoc from "./_ChatHoc.vue";
// import ChatLoading from "./type/_ChatLoading.vue";
// import Video from "./subpages/_ChatVideo.vue";
// import Map from "./subpages/_ChatMap.vue";
// import Confetti from "./type/_Confetti.vue";
import Avatar from "./type/_Avatar.vue";

export default {
  props: [ 'bgData', 'name', 'subpage' ],
  emits: [ 'click' ],
  components: {
    ChatHoc,
    // ChatLoading,
    // Video,
    // Map,
    // Confetti,
    Avatar
  },
  setup(props) {
    const { state, get, commit, dispatch } = useStore();

    const data = reactive({
      isLangKr: computed(() => state.isLangKr),
      getAllItems: computed(() => get["chatbot/getAllItems"]),
      getItems: computed(() => get["chatbot/getItems"](props.name)),
      getScrollTop: computed(() => get["chatbot/getScrollTop"](props.name)),
      endState: computed(() => state.chatbot.endState),
      scrollTop: '',
      subpage: props.subpage,
      videoState: false,
      mapState: false,
    });
    
    const methods = {
      setSubSubpageState: (type, name) => {
        // type: video, map
        // name: sinsa, paju, seongsu
        dispatch('chatbot/openSubSubpage', {type: type, name: name})
      },
      resetSubpage: () => {
        dispatch('chatbot/addTimer', {delay: 800, callback: () => {
          commit('chatbot/setSubpageFromContact', {subpageName: null, type: null});
          commit('chatbot/setCurrentSubpage', null);
          if (props.bgData.type == 'channel') {
            commit('chatbot/closeSubSubpage', {type: 'map', name: null});
          }
        }})
      },
      setScrollTop: () => {
        // commit('chatbot/setScrollTop', {scrollTop: data.scrollTop, dataName: props.name});
        commit('chatbot/setScrollLock', false);
        commit('chatbot/clearTimer');
        data.subpage = !data.subpage
      },
      changeLang: () => {
        dispatch("chatbot/changeLang");
      },
    }

    const checkHeadScrollClass = () => {
      const layoutMainH = $.qs(".p-layout__main").offsetHeight;
      const subpageScrollTop = $.qs(".subpage").scrollTop;

      if (subpageScrollTop > layoutMainH - 48 - 24 - 13) {
        $.addClass("head--scroll", selfHead.value);
      } else {
        $.removeClass("head--scroll", selfHead.value);
      }
    }

    const importHiringData = () => {
      if (props.bgData.type == 'hiring') {
        const layoutMainH = $.qs(".p-layout__main").offsetHeight;
        const layoutHiringH = $.qs(".p-hiring").offsetHeight;
        const layoutDateH = $.qs(".p-layout__date-wrap").offsetHeight;
        const subpageScrollTop = $.qs(".subpage").scrollTop;
        
        let staticH
        if (window.innerWidth < 600) {
          staticH = layoutMainH + layoutHiringH + layoutDateH - window.innerHeight;
        } else {
          staticH = layoutMainH + layoutHiringH + layoutDateH - 620;
        }
        
        if (!data.getAllItems[props.name] && subpageScrollTop > staticH) {
          dispatch("chatbot/importData", props.name);
        }
      }
    }

    let parallaxTl = null;
    const parallax = () => {
      $.removeClass("head--scroll", selfHead.value);
      const target = props.bgData.type == 'card' ? 
        $.find(".card__shape-wrap", selfLayout.value) :
        $.find(".p-layout__bg", selfLayout.value)

      gsap.set(target, { y: '0%'})

      parallaxTl = gsap.timeline({
        scrollTrigger: {
          trigger: ".p-layout__fill",
          scroller: ".subpage",
          start: "top top", 
          end: "bottom top", 
          scrub: true,
          onUpdate({ progress }) {
            gsap.set(target, { y: `${25 * progress}%`})
          }
        }
      });
    }

    const getTime = () => {
      const dateTarget = $.qs(".p-layout__date");
      const timeTarget = $.qs(".p-layout__time");

      dateTarget.innerText = `(${getCurrentTime().getWeek}) ${getCurrentTime().getMonth} ${getCurrentTime().date}, ${getCurrentTime().year}`;
      timeTarget.innerText = `${getCurrentTime().hours < 12? `${getCurrentTime().hours == 0 ? 12 : getCurrentTime().hours}`:`${getCurrentTime().hours - 12}`}:${getCurrentTime().minutes < 10 ? `0${getCurrentTime().minutes}` : getCurrentTime().minutes} ${getCurrentTime().hours < 12? 'AM' : 'PM'}`;
    }

    const CB_SCROLL = () => {
      checkHeadScrollClass();
      importHiringData();

      const subpage = $.qs(".subpage");
      commit('chatbot/setScrollTop', {scrollTop: subpage.scrollTop, dataName: props.name});
      // data.scrollTop = subpage.scrollTop;
    }
    const checkHistory = () => {
      _.go($.findAll(".chat-comp--uncomplete", selfLayout.value),
        _.each(item => {
          $.removeClass("chat-comp--uncomplete", item)
        })
      )
      const history = data.getAllItems[props.name];
      if (history) {
        _.go(data.getItems,
          _.each(item => {
            if (item.id == history[history.length - 1]) {
              if (!item.waitAnswer || item.react) {
                dispatch('chatbot/executeNextEvent', {id: item.id, nextId: item.noQueryNextId ? item.noQueryNextId : item.nextId, dataName: props.name});
              }
            }
          })
        ) 
      }
    }
    const selfHead = ref();
    const selfLayout = ref();
    
    onMounted(() => {
      // parallax();
      if (props.bgData.type !== 'channel') {
        getTime();
        if (props.bgData.type !== 'hiring') {
          commit('chatbot/setScrollLock', true);
        }
      }
      if ($.qsa(".p-layout__none", selfLayout.value).length !== 0) {
        _.go($.qsa(".p-layout__none", selfLayout.value),
          _.each(item => {
            $.setCss({ display: 'none' }, item);
          })
        )
      }
      if (!data.getAllItems[props.name]) {
        $.qs(".subpage").scrollTop = 0
        if (props.bgData.type !== 'channel') {
          if (props.bgData.type !== 'hiring') {
            dispatch("chatbot/importData", props.name);
          }
        }
      }
    });

    onActivated(() => {
      parallax();
      _.go(
        $.findAll(".head__btn", selfHead.value),
        _.each(item => {
          if (props.bgData.colorWhite) {
            $.addClass("b-hover--black", item);
          } else {
            $.addClass("b-hover--white", item);
          }
        })
      )
      const subpage = $.qs(".subpage");
      if (data.getScrollTop !== undefined) {
        if (props.bgData.type == 'channel') {
          subpage.scrollTop = 0
        } else {
          subpage.scrollTop = data.getScrollTop; 
        }
      }
      subpage.addEventListener('scroll', CB_SCROLL);
    })

    onDeactivated(() => {
      const subpage = $.qs(".subpage");
      subpage.removeEventListener('scroll', CB_SCROLL);
      parallaxTl.kill();
    });

    watchEffect(() => {
      if (data.subpage && props.subpage) {
        checkHistory();
        // parallax();
        data.subpage = false;
      }
		},{ flush: 'post' });
    return {
      data,
      methods,
      selfHead,
      selfLayout,
    }
  }
}
</script>

<style lang="scss" src="@/css/chatbot.scss"></style>
