<template>
  <div ref="self" class="minime-item">
    <div
      ref="minimieWrap"
      class="minime"
      :class="[
        isBlockContent ? 'l-row' : 'minime--floated',
        { 'l-row--t256': desc },
        {'minime--gutters' : data.gutters }
      ]"
      :style="[
        { top: position ? methods.stylePosition(data.pos)[0] : null },
        { right: position ? methods.stylePosition(data.pos)[1] : null },
        { bottom: position ? methods.stylePosition(data.pos)[2] : null },
        { left: position ? methods.stylePosition(data.pos)[3] : null },
        { transform: position ? methods.styleStand(position) : null}
      ]"
    >
      <div
        :class="[
          { 'g-row': isBlockContent },
          { 's--h-start' : descSide && descSide !== 'right'},
          { 's--h-end' : descSide === 'right' }
        ]"
      >
        <div
          :class="[
            { 'g-col--6 g-col--3s wrap__minime__desc': desc },
            desc ? `g-ml--${methods.descSideFilter(descSide)}` : 'minime__cover',
            { 'g-ml--1s' : descSide === 'center'},
            {'g-ml--init' : descSide === 'centerLeft' }
          ]"
        >

          <!-- 미니미 이너 / 타입 -->
          <div class="minime__wrap">
            <div
              class="minime__inner"
            >
              <figure
                class="minime__inner__img"
                :style="[
                  {
                    backgroundImage: `url('${require('@/assets/minime/' + data.backgroundImage +'.png')}`,
                    animationDelay: `-${data.backgroundChange}s`
                  }
                ]"
              />

              <!-- 미니미 말풍선 -->
              <div
                v-if="balloon !== null"
                ref="balloon"
                :class="[
                  `minime__balloon`,
                  {'minime__balloon--right' : isBalloonRight},
                  {'minime__balloon--with-desc' : desc }
                ]
              ">
                <span
                  class="minime__balloon__text"
                  :class="balloonColor ? `minime__balloon__text--col-${balloonColor}` : ''"
                >
                  <span class="t-b--13 textbox">
                    <span class="t--kr balloon-text">
                      <span
                        ref="refs"
                        class="balloon-text__genuine"
                      ></span>
                    </span>
                  </span>
                  <i class="tail">
                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.516602C0 9.06769 3.26836 15.9997 13.7618 15.9997L14 16.0005C13.0623 15.2567 12.2689 14.3944 11.6314 13.4229C10.4112 11.5638 10 9.73924 10 5.406V0.516602H0Z" 
                      fill="#A6814C"/>
                    </svg>
                  </i>
                </span>
              </div>

            </div>
          </div>

          <!-- 미니미 디스크립션 -->
          <div
            v-if="desc"
            class="t-h--13 t-h--18l desc-text"
          >
            <div class="t--fak">
              {{ desc }}
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as _ from "fxjs";
import * as $ from "fxdom";
import { ref, reactive, computed, onMounted, onUnmounted, onBeforeUnmount, useStore } from "@/helper/vue.js";
import { gsap, ScrollTrigger, TextPlugin } from "gsap/all";
import { MinimeType, MinimeTotal, TextType, TextTotal } from "@/parts/minime/data-minime-type.js"

gsap.registerPlugin(TextPlugin);

export default {
	props:{
    /**
		 * 미니미 프레임 타입 번호
		 * */
		frameNumber: {
			type: Number,
			default: undefined
		},
    /**
		 * 미니미 말풍선 타입 번호
		 * */
    balloonNumber: {
			type: Number,
			default: null
		},
    /**
		 * 텍스트 디스크립션 여부
		 * */
		desc: {
			type: String,
			default: null
		},

    /**
		 * 블록 컨텐트 여부 (Relative / Absolute)
		 * */
		isBlockContent: {
			type: Boolean,
			default: (props) => {
        return props.desc ? true : false
      }
		},

    /**
		 * 컨텐트 포지션 (absolute only)
		 * */
		position: {
			type: Array,
			default: null
		},

    /**
		 * 컨텐트 포지션 모바일 (absolute only)
		 * */
		positionMo: {
			type: Array,
			default: null
		},

    /**
		 * 컨텐트 포지션 PC1600 이상 (absolute only)
		 * */
		positionPc: {
			type: Array,
			default: null
		},

    /**
		 * 디스크립션 미니미 위치
		 * */
		descSide: {
			type: String,
      default: (props) => {
        return !props.desc ? null : 'centerLeft' // ['left', 'center', 'centerLeft', 'right']
      }
		},

    /**
		 * 말풍선 유무
		 * */
		balloon: {
			type: String,
			default: null
		},

    /**
		 * 말풍선 위치
		 * */
		isBalloonRight: {
			type: Boolean,
			default: false
		},

    /**
		 * 말풍선 컬러
		 * */
		balloonColor: {
			type: String,
			default: 'brown'
		},

    /**
		 * 항상 빠른 frame loop (ex, 로딩 미니미)
		 * */
    isAlwaysFast: {
      type: Boolean,
      default: false
    },

    /**
		 * 스크롤과 상관없이 항상 동작
		 * */
    isAlwaysActive: {
      type: Boolean,
      default: false
    }
	},
  setup(props) {
    const self = ref();
    const refs = ref();
    const minimieWrap = ref();
    const balloon = ref();
    const tl = gsap.timeline({});
    let tl2 = null;
    const { state , commit } = useStore();

    const data = reactive({
      backgroundChange: 0, // 미니미 백그라운드 이미지 체인지 초기 값
      backgroundImage: 0, // 미니미 백그라운드 이미지 초기 값
      backgroundLength: 0, // 미니미 백그라운드 갯수 초기 값
      gutters: false, // absolute 스타일 미니미 거터 초기값
      minimeNone: true,
      balloonText: '',
      pos: props.positionMo ? props.positionMo : props.position, // 포지션 리사이즈 초기 값
      // 모바일(|| PC) 포지션 스와핑 초기 값
      positionSizeSwap: [],
      frameLoopDuration: 1, // frame loop 기본 배속 (1초)
      // frameLoopDuration: 1.66, // frame loop 기본 배속 (1.66초)
      frameDurationFast: 6, // frame mouse over 배속 (* 6배속)
      frameDurationNormal: 1 // frame 기본 배속
    })
    const methods = {

      // 디스크립션 위치 필터
      descSideFilter: side => {
        switch (side) {
          case 'left' :
            return 0

          case 'centerLeft' :
            return 3

          case 'center' :
            return 6

          case 'right' :
            return 'right'
        }
      },

      // 스타일 센터 필터
      styleStand: pos => {
        const axisXY = [pos[0], pos[3]] // both top and left
        const axisX = [pos[1], pos[3]] // right, left axis x
        const axisY = [pos[0], pos[2]] // top, bottom axis y
        let transformStyle = null

        if (axisXY.every( itm => itm === 'center')) {
          transformStyle = `translate(-50%, -50%)`

        } else if (axisY.includes('center')) {
          transformStyle = `translate(0, -50%)`

        } else if (axisX.includes('center')) {
          transformStyle = `translate(-50%, 0)`

        } else {
          transformStyle = null
        }
        return transformStyle
      },

      // 텍스트 브레이크 필터
      textBreak: desc => {
        return desc.split('\n').join('<br />')
      },

      // absolute 스타일 인셋 필터
      stylePosition: ins => {
        let newIns = ins.map(itm => {
          const dividedString = 'g-col--'
          const centerString = 'center'
          const calculated = 'calc'

          if (typeof itm !== 'number') {
            if (itm.indexOf(dividedString) !== -1) {
              data.gutters = true
              itm = `var(--cw${Number(itm.substr(7, 7))})`
            } else if (itm.indexOf(centerString) !== -1) {
              itm = '50%'
            } else if (itm.indexOf(calculated) !== -1) {
              itm = itm
            } else {
              itm = 'inherit'
            }
          } else if (typeof itm === 'number' && itm !== 0) {
            itm = `${itm}em`
          }

          return itm
        })
        return newIns
      },

      // 미니미 BG Change Duration 메서드
      minimeFrameDuration: (itm, dur, lng) => {
        let counter = { myLength : 0}
        tl.to(counter, {
          myLength: lng,
          onUpdate: () => {
            data.backgroundChange = Math.round(counter.myLength)
          },
          ease: 'none',
          repeat: -1,
          repeatDelay: 0.4,
          duration: dur * lng
        })
      },

      // 미니미 말풍선 타이핑 인터렉션 메서드
      typewriterInteraction: txt => {
        if (!txt) txt = data.balloonText
        const speed = 5

        balloon.value.classList.add('minime__balloon--hello')

        gsap.to(refs.value, {
          text: { value: txt },
          duration: (txt.length/speed) / 10,
          ease: 'none'
        })
      },

      // 미니미 백그라운드 이미지 갯수 체크
      checkFrameLength: () => {
        return data.backgroundLength = MinimeType[data.backgroundImage].length
      },

      // 미니미 랜덤 필터
      getMinimeNumber: () => {
        let value = null

        if (props.frameNumber != undefined) {
          value = props.frameNumber
        } else {
          value = state.minimeRemain[Math.floor(Math.random() * state.minimeRemain.length)]
          commit('checkMinimeRemain', { selected: value, init: false })
        }
        return data.backgroundImage = value
      },

      // 미니미 텍스트 랜덤 필터
      getMinimeText: () => {
        if (props.balloonNumber !== null) {
          return data.balloonText = TextType[props.balloonNumber];
        } else {
          if (props.balloon == '') {
            const value = state.minimeTxtRemain[Math.floor(Math.random() * state.minimeTxtRemain.length)]
            commit('checkMinimeTxtRemain', { selected: value, init: false })
            return data.balloonText = TextType[value];
          } else {
            return data.balloonText = props.balloon
          }
        }
      },
    }

    // 미니미 스크롤 트리거 & 말풍선 스크롤 트리거 메서드 & 스크롤 기반 duration
    const scrollWithTimeline = (fast, normal) => {
      let delayedCall = gsap.delayedCall(0.1, () => tl.timeScale(normal))
      // console.log('비ㅣㅣ교',data.backgroundLength)
      let isMoreThanOneFrame = data.backgroundLength > 1

      const minimeActive = () => {
        if (isMoreThanOneFrame) methods.minimeFrameDuration(props.frameNumber, data.frameLoopDuration, data.backgroundLength)
        if (data.balloonText) methods.typewriterInteraction(methods.textBreak(data.balloonText))
      }

      // 미니미 항상 동작
      if ( props.isAlwaysActive ) {
        minimeActive()
      } else {

        // 미니미 onEnter 동작
        tl2 = gsap.timeline({
          scrollTrigger: {
            trigger: minimieWrap.value,
            start: 'top bottom',
            end: 'bottom top',
            onEnter: () => minimeActive(),
            onEnterBack: () => {
              if (isMoreThanOneFrame) methods.minimeFrameDuration(props.frameNumber, data.frameLoopDuration, data.backgroundLength)
            },
            onLeave: () => tl.clear(),
            onLeaveBack: () => tl.clear(),
            onUpdate: self => {
              const velocity = self.getVelocity()

              if (velocity !== 0) tl.timeScale(fast)
              if (delayedCall) {
                delayedCall.kill()
                delayedCall = gsap.delayedCall(0.1, () => {
                  props.isAlwaysFast
                    ? tl.timeScale(fast)
                    : tl.timeScale(normal)
                })
              }
            }
          }
        });
      }
    }

    // 사파리 em 버그 수정
    let CB_RESIZE = () => {
      data.pos = []
      const targetHtml = document.querySelector('html')

      if ( targetHtml.classList.contains('__w600') ) {
        data.pos = props.positionMo || props.position
      } else if (targetHtml.classList.contains('w1600__')) {
        data.pos = props.positionPc || props.position
      } else {
        data.pos = props.position
      }
      data.positionSizeSwap = data.pos;

      const target = $.qs(".minime__inner__img", self.value);
      $.setCss({ display: "none" }, target);
      target.offsetHeight;
      $.setCss({ display: "" }, target);
    }

    let CB_MOUSE_OVER = () => {
      tl.timeScale(data.frameDurationFast);
    };

    let CB_MOUSE_LEAVE = () => {
      tl.timeScale(data.frameDurationNormal);
    };

    let CB_LANDED = () => scrollWithTimeline(data.frameDurationFast, data.frameDurationNormal);

    onMounted (() => {
      // 마우스 호버 효과
      $.qs(".minime__inner", self.value).addEventListener("mouseover", CB_MOUSE_OVER);
      $.qs(".minime__inner", self.value).addEventListener("mouseleave", CB_MOUSE_LEAVE);

      const { balloon, position, positionMo, positionPc, isBalloonRight } = props
      const { frameDurationFast, frameDurationNormal, positionSizeSwap, pos } = data

      if (state.pageState !== 'landed') {
        window.addEventListener('landed', CB_LANDED, { once: true });
        window.addEventListener('landed', CB_RESIZE, { once: true });
      } else {
        methods.checkFrameLength()
        scrollWithTimeline(frameDurationFast, frameDurationNormal)
      }

      methods.getMinimeNumber()
      methods.checkFrameLength()
      methods.getMinimeText();

      if (balloon) methods.textBreak(balloon)
      if (position) {
        positionMo || positionPc ? methods.stylePosition(positionSizeSwap) : methods.stylePosition(pos)
        methods.styleStand(position)
      }
      // 사파리 em 버그 수정
      // window.addEventListener('resize', CB_RESIZE)
      window.addEventListener('resize.canvas', CB_RESIZE);
      data.minimeNone = false

      if (balloon !== null) {
        // 미니미 타입 기반 말풍선 위치 설정
        const x = MinimeType[data.backgroundImage].x;
        const y = MinimeType[data.backgroundImage].y;
        const w = MinimeType[data.backgroundImage].w;

        const offsetX = 17;
        const offsetY = 8;

        // 왼쪽
        if(!isBalloonRight) {
          $.setCss(
            {
              marginRight: `-${(x/160 * 100) - offsetX}%`,
              marginBottom: `-${(y/200 * 100) + offsetY}%`,
            },
            $.qs(".minime__balloon", self.value)
          );
        } else {
          $.setCss(
            {
              marginLeft: `${((x + w)/160 * 100) - 100 + offsetX}%`,
              marginBottom: `-${(y/200 * 100) + offsetY}%`,
            },
            $.qs(".minime__balloon", self.value)
          );
        }
      }
    })

    onBeforeUnmount(() => {
      // console.log(CB_LANDED);
      // window.removeEventListener('landed', CB_LANDED);
      // CB_LANDED = null;
      // console.log(CB_RESIZE);
      // window.removeEventListener('resize', CB_RESIZE);
      // CB_RESIZE = null;

      if(tl) {
        tl.scrollTrigger && tl.scrollTrigger.kill();
        tl && tl.kill();
      }

      if(tl2) {
        tl2.scrollTrigger && tl2.scrollTrigger.kill();
        tl2 && tl2.kill();
      }

      $.qs(".minime__inner", self.value).removeEventListener("mouseover", CB_MOUSE_OVER);
      $.qs(".minime__inner", self.value).removeEventListener("mouseleave", CB_MOUSE_LEAVE);
      window.removeEventListener('resize.canvas', CB_RESIZE);
      window.removeEventListener('landed', CB_RESIZE);
      window.removeEventListener('landed', CB_LANDED);
    });

    onUnmounted(() => {
    })
    return {
      self,
      data,
      methods,
      refs,
      minimieWrap,
      balloon
    }
  }
}
</script>

<style lang="scss" scoped>

  $total: 24;
  $per: (100% / $total);
  $pc-width: 96em;
  $pc-height: 120em;
  $mo-width: 64em;
  $mo-height: 80em;

  @keyframes minime_animation_pc {
    @for $i from 0 to $total + 1 {
      $current-frame: ($i * $per);
      #{$current-frame} {
        background-position: -#{$pc-width * $i} 0;
        width: $pc-width;
        height: $pc-height;
      }
    }
  }

  @keyframes minime_animation_mo {
    @for $i from 0 to $total + 1 {
      $current-frame: ($i * $per);
      #{$current-frame} {
        background-position: -#{$mo-width * $i} 0;
        width: $mo-width;
        height: $mo-height;
      }
    }
  }

  .minime {
    z-index: 20;
    position: relative;
    &--floated {
      position: absolute;
      inset: 0 0 0 0;
    }
    &--gutters {
      margin-left: calc(var(--gutter));
		  margin-right: calc(var(--gutter));
    }
    &__cover {
      position: relative;
    }
    &__wrap {
      width: $pc-width;
      height: $pc-height;
      .__w600 &{
        width: $mo-width;
        height: $mo-height;
      }
    }
    &__inner {
      position: relative;
      // padding-top: 125%;
      top: 0;
      left: 0;
      width: $pc-width;
      height: $pc-height;
      .__w600 &{
        width: $mo-width;
        height: $mo-height;
      }
      &__img {
        display: block;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0 0;
        animation: minime_animation_pc #{$total + 1s} steps(1) backwards;
        animation-play-state: paused;
        animation-iteration-count: initial;
        animation-duration: #{$total + 1s};
        .__w600 & {
          animation: minime_animation_mo #{$total + 1s} steps(1) backwards;
          animation-play-state: paused;
          animation-iteration-count: initial;
          animation-duration: #{$total + 1s};
        }
        &--none {
          display: none;
        }
      }
    }

    &__balloon {
      position: absolute;
      bottom: 100%;
      right: 100%;
      display: flex;
      flex-wrap: wrap;
      opacity: 0;
      &--hello {
        opacity: 1;
      }
      &--right {
        bottom: 100%;
        left: 100%;
        right: auto;
        .tail {
          transform: scaleX(-1);
          bottom: -2px;
          left: -4px;
        }
      }
      &--with-desc {
        /* left: auto;
        right: 94.6%;
        bottom: calc(100% + 77em);
        .__w600 & {
          bottom: calc(100% + 50em);
        }
        &.minime__balloon--right {
          right: auto;
          left: 67em;
          .__w600 & {
            left: 42em;
          }
        } */
      }
      &__text {
        position: relative;
        padding: 8em 10em;
        border-radius: 8em;
        color: #F9F5EF;
        white-space: nowrap;
        background-color: #A6814C;
        text-align: left;
        path {
          fill: #A6814C;
        }

        // COLOR VARIATIONS
        &--col-red {
          background-color: #D2330F;
          path {
            fill: #D2330F;
          }
        }

        &--col-brown {
          background-color: #A6814C;
          path {
            fill: #A6814C;
          }
        }

        &--col-green {
          background-color: #009E60;
          path {
            fill: #009E60;
          }
        }

        .textbox {
          position: relative;
          z-index: 1;
          display: block;
          .balloon-text {
            display: block;
            position: relative;
          }
        }
        .tail {
          position: absolute;
          width: 14px;
          height: 17px;
          bottom: -2px;
          right: -4px;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &__desc {
      position: relative;
      width: 100%;
    }
  }

  .wrap {
    &__minime__desc {
      position: relative;
      .minime__wrap {
        position: absolute;
        left: 0;
        bottom: calc(100% - 13px);
        .w1600__ & {
          bottom: calc(100% - 17px);
        }
      }
      .desc-text {
        text-transform: uppercase;
        text-indent: calc(var(--cw2) + var(--gutter));
        .__w600 &{
          text-indent: calc(var(--cw1) + var(--gutter));
        }
        .w1600__ &{
          text-indent: calc(var(--cw1) + var(--gutter));
          // text-indent: var(--cw2);
          // text-indent: calc(var(--cw2) - var(--gutter) - var(--gutter));
        }
        .t--fak {
          .w1600__ & {
            font-size: 18px;
            letter-spacing: -0.04px;
          }
        }
      }
    }
  }

  .g-ml--init {
    .__w600 &{
      margin-left: calc(var(--gutter)/2)
    }
  }
</style>