<template>
	<div ref="self" class="mheader">
		<div class="mheader__layer mheader__layer--1"></div>
		<div class="mheader__layer mheader__layer--2"></div>
		<div class="mheader__layer mheader__layer--3">
			<a
				class="mheader__logo"
				href="/"
				router-to="/"
			>
				<svg width="61" height="16" viewBox="0 0 61 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="logo-svg">
					<path d="M39.0981 0L44.2895 7.78372L49.5692 0H53.3333L45.902 10.5868V16H42.6773V10.6222L35.2279 0H39.0981ZM8.40718 0C13.7315 0 16.8974 2.31549 16.8974 7.99989C16.8974 13.6274 13.7945 15.9531 8.56625 15.9991L8.40718 15.9998H0V0H8.40718ZM33.7061 0V2.73766H22.5333V6.85705H33.0801V9.47236H22.5333V15.9998H19.3096V0H33.7061ZM8.27348 2.68904H3.22971V13.3107H8.27348C11.7732 13.3107 13.8814 11.7692 13.8814 7.99989C13.8814 4.23053 11.7732 2.68904 8.27348 2.68904Z" fill="#171010"/>
					<path d="M55.808 0.876712C55.2204 1.46575 54.9333 2.19178 54.9333 3.0274C54.9333 3.86301 55.2204 4.57534 55.808 5.13699C56.3957 5.71233 57.1065 6 57.9402 6C58.7739 6 59.4846 5.71233 60.0723 5.13699C60.6463 4.57534 60.9333 3.86301 60.9333 3.0137C60.9333 2.17808 60.6463 1.46575 60.0723 0.876712C59.4846 0.287671 58.7739 0 57.9402 0C57.1065 0 56.3957 0.287671 55.808 0.876712ZM56.1497 4.83562C55.7124 4.38356 55.4937 3.78082 55.4937 3.0274C55.4937 1.47945 56.4094 0.493151 57.9402 0.493151C59.4709 0.493151 60.3866 1.47945 60.3866 3.0137C60.3866 3.76712 60.168 4.38356 59.7306 4.83562C59.2932 5.28767 58.6919 5.52055 57.9402 5.52055C57.1885 5.52055 56.5871 5.28767 56.1497 4.83562ZM58.5142 3.34247C59.0199 3.23288 59.3342 2.84931 59.3342 2.34247C59.3342 1.75342 58.8559 1.38356 58.1452 1.38356H56.8058V4.46575H57.4345V3.38356H58.0222L58.6235 4.46575H59.4299L58.8559 3.65753L58.5142 3.34247ZM58.2545 1.94521C58.5415 1.94521 58.6919 2.08219 58.6919 2.34247C58.6919 2.64384 58.5552 2.79452 58.2819 2.79452H57.4345V1.94521H58.2545Z" fill="#171010"/>
				</svg>
			</a>
			<div class="mheader__right">
				<button class="mheader__btn">{{ data.isOpen == false ? "MENU" : "CLOSE" }}</button>
			</div>
			<ul class="mheader__nav">
				<li class="mheader__nav-item" :class="{ 'mheader__nav-item--active': data.getActiveState('home') }">
					<a class="mheader__nav-lnk" href="/" router-to="/">
						<span class="t-h--54"><span :class="[ data.getActiveState('home') ? 't--rec' : 't--fak' ]" >HOME</span></span>
					</a>
				</li>
				<li class="mheader__nav-item" :class="{ 'mheader__nav-item--active': data.getActiveState('work') }">
					<a class="mheader__nav-lnk" href="/work" router-to="/work">
						<span class="t-h--54"><span :class="[ data.getActiveState('work') ? 't--rec' : 't--fak' ]" >WORK</span></span>
					</a>
				</li>
				<li class="mheader__nav-item" :class="{ 'mheader__nav-item--active': data.getActiveState('services') }">
					<a class="mheader__nav-lnk" href="/services" router-to="/services">
						<span class="t-h--54"><span :class="[ data.getActiveState('services') ? 't--rec' : 't--fak' ]" >SERVICES</span></span>
					</a>
				</li>
				<li class="mheader__nav-item" :class="{ 'mheader__nav-item--active': data.getActiveState('about') }">
					<a class="mheader__nav-lnk" href="/about" router-to="/about">
						<span class="t-h--54"><span :class="[ data.getActiveState('about') ? 't--rec' : 't--fak' ]" >ABOUT</span></span>
					</a>
				</li>
				<li class="mheader__nav-item" :class="{ 'mheader__nav-item--active': data.getActiveState('contact') }">
					<a class="mheader__nav-lnk" href="/contact" router-to="/contact">
						<span class="t-h--54"><span :class="[ data.getActiveState('contact') ? 't--rec' : 't--fak' ]" >CONTACT</span></span>
					</a>
				</li>
				<li v-if="data.useTestMenu" class="mheader__nav-item">
					<a class="mheader__nav-lnk" href="/test-wow" router-to="/test-wow">
						<span class="t-h--54"><span class="t--fak">test-wow</span></span>
					</a>
				</li>
				<li v-if="data.useTestMenu" class="mheader__nav-item">
					<a class="mheader__nav-lnk" href="/blank" router-to="/blank">
						<span class="t-h--54"><span class="t--fak">blank</span></span>
					</a>
				</li>
			</ul>
			<div class="mheader__bot">
				<span class="t-h--11"><span class="t--fak">Today’s Creative<br>for Tomorrow™<br>DFY® 2K11-{{new Date().getFullYear().toString().substring(2)}}©</span></span>
			</div>
		</div>
	</div>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { ref, reactive, onMounted, computed, useStore } from "@/helper/vue.js";
import { isLoaderOn, testMenu } from "@/store/appSettings.js";
export default {
	components: {
	},
  setup(props) {
		const { state } = useStore();
		const self = ref();
		const data = reactive({
			getActiveState: computed(() => {
				return (menuName) => {
					switch(menuName) {
						case 'home' : { return data.currPage == "home"; }
						case 'work' : { return data.currPage == 'work' || data.currPage == 'project' || data.currPage == 'work-sublanding-tag'; }
						case 'services' : { return data.currPage == 'services' || data.currPage == 'work-sublanding-service'; }
						case 'about' : { return data.currPage == 'about'; }
						case 'contact' : { return data.currPage == 'contact'; }
					}
					return false;
				}
			}),
			currPage: computed(() => {
				// $.qs(".header__nav-item--active") && $.removeClass("header__nav-item--active", $.qs(".header__nav-item--active"));
				return state.currPage;
			}),
			getHideState: computed(() => {
				// 로딩화면 off일 경우는 무조건 보이기
				if(!isLoaderOn) return false;
				// 로딩중에는 때 무조건 숨기기
				if(state.loadingState == "pending") return true;

				// 스크롤 값이 있으면서 스크롤 방향이 아래일 경우
				if(state.scrollDir == "down" && state.isScrollStarted) {
					return true;
				}
				else {
					return false;
				}
			}),
			isOpen: false,
			isLocked: false,
			useTestMenu: testMenu,
		});

		onMounted(() => {
			$.qs(".mheader__btn", self.value).addEventListener("click", () => {

				if(data.isLocked) return;

				data.isOpen = !data.isOpen;

				// 열림 처리
				if(data.isOpen) {
					// 애니메이션 진행하기 전에 필요한 요소들 display 시켜준다.
					$.addClass("mheader--open", self.value);
					// display시켜주고 다음 루프에서 애니메이션 클래스를 추가해준다.
					setTimeout(() => {
						data.isLocked = true;
						$.addClass("mheader--open-ani", self.value);
						// BG 레이어가 올라오는 동안은 동작 입력을 제한한다.
						$.qs(".mheader__layer--2", self.value).addEventListener("transitionend", () => {
							// $.addClass("s--body-lock", $.qs("html"));
							data.isLocked = false;
						}, { once: true });
					});
				}
				// 닫힘 처리
				else {
					data.isLocked = true;
					$.addClass("mheader--close-ani", self.value);
					$.qs(".mheader__layer--2", self.value).addEventListener("transitionend", () => {
							data.isLocked = false;
							$.removeClass("mheader--open mheader--open-ani mheader--close-ani", self.value);
							// $.removeClass("s--body-lock", $.qs("html"));
					}, { once: true });
				}
			});

			window.addEventListener("ready.curtain", () => {
				if(!state.isMobileSize) return;
				$.removeClass("mheader--open mheader--open-ani mheader--close-ani", self.value);
				data.isOpen = false;
				data.isLocked = false;
			});
		});

    return {
			self,
			data
    }
  }
}
</script>
<style scoped lang="scss">
.mheader {
	$firstDelay: 0.28s;
	$stagger: 0.13s;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	&__layer {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		&--1 {
			z-index: 10;
		}
		&--2 {
			display: none;
			width: 100%;
			height: 100vh;
			background-color: #171010;
			transform: translate3d(0, 100%, 0);
			z-index: 20;
		}
		&--3 {
			z-index: 30;
		}
	}
	&__logo {
		position: absolute;
		display: inline-block;
		top: 16px;
		left: var(--outerGutter);
		path {
			fill: var(--foreground);
			transition: fill 0.3s;
		}
	}
	&__nav {
		position: absolute;
		display: none;
		left: var(--outerGutter);
		top: 50vh;
		transform: translateY(-50%);
		color: #EBE3D7;
	}
	&__nav-item {
		margin-top: 8em;
		opacity: 0;
		transform: translate3d(0, 30px, 0);
		&:first-child {
			margin-top: 0;
		}
		&:nth-child(1) {
			transition: transform 1s $firstDelay + $stagger * 0 cubic-bezier(0.15, 0.85, 0.45, 1), opacity 1s $firstDelay + $stagger * 0 cubic-bezier(0.15, 0.85, 0.45, 1);
		}
		&:nth-child(2) {
			transition: transform 1s $firstDelay + $stagger * 1 cubic-bezier(0.15, 0.85, 0.45, 1), opacity 1s $firstDelay + $stagger * 1 cubic-bezier(0.15, 0.85, 0.45, 1);
		}
		&:nth-child(3) {
			transition: transform 1s $firstDelay + $stagger * 2 cubic-bezier(0.15, 0.85, 0.45, 1), opacity 1s $firstDelay + $stagger * 2 cubic-bezier(0.15, 0.85, 0.45, 1);
		}
		&:nth-child(4) {
			transition: transform 1s $firstDelay + $stagger * 3 cubic-bezier(0.15, 0.85, 0.45, 1), opacity 1s $firstDelay + $stagger * 3 cubic-bezier(0.15, 0.85, 0.45, 1);
		}
		&:nth-child(5) {
			transition: transform 1s $firstDelay + $stagger * 3 cubic-bezier(0.15, 0.85, 0.45, 1), opacity 1s $firstDelay + $stagger * 4 cubic-bezier(0.15, 0.85, 0.45, 1);
		}
	}
	&__nav-lnk .t--rec {
		font-size: 54em;
    font-weight: 400;
    letter-spacing: 0;
		text-transform: lowercase;
	}
	&__bot {
		position: absolute;
		display: none;
		left: var(--outerGutter);
		top: 100vh;
    transform: translateY(calc(-100% - var(--outerGutter)));
		color: #EBE3D7;
		opacity: 0;
		.t--fak {
			font-size: 11em;
		}
	}
	&__right {
		position: absolute;
		right: var(--outerGutter);
		top: 16px;
		margin-top: -2px;
	}
	&__btn {
		display: inline-block;
		padding: 3px 5px 2px 5px;
		border-radius: 4px;
		font-family: "Faktum";
		text-transform: uppercase;
		font-weight: 400;
		font-size: 16px;
		letter-spacing: -0.04em;
		line-height: 1;
		vertical-align: middle;
		transition: color 0.3s, background-color 0.3s;
		color: var(--background);
		background-color: var(--foreground);
	}
	&--open {
		.mheader__nav {
			display: block;
		}
		.mheader__bot {
			display: block;
		}
		.mheader__btn {
			color: #171010;
			background-color: #EBE3D7;
		}
		.mheader__logo path {
			fill: #EBE3D7;
		}
		.mheader__layer--2 {
			display: block;
		}
	}
	&--open-ani {
		.mheader__layer--2 {
			transform: translate3d(0, 0, 0);
			transition: transform 0.6s cubic-bezier(0.4, 0, 0,1), opacity 0.3s cubic-bezier(0.4, 0, 0,1);
		}
		.mheader__nav-item {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
		.mheader__bot {
			opacity: 1;
			transition: opacity 0.8s $firstDelay + $stagger * 5 cubic-bezier(0.15, 0.85, 0.45, 1);
		}
	}
	&--close-ani {
		.mheader__layer--2 {
			opacity: 0;
			transition: transform 0.6s cubic-bezier(0.4, 0, 0,1), opacity 0.3s cubic-bezier(0.4, 0, 0,1);
		}
		.mheader__nav {
			opacity: 0;
			transition: opacity 0.3s cubic-bezier(0.4, 0, 0,1);
		}
		.mheader__bot {
			opacity: 0;
			transition: opacity 0.3s cubic-bezier(0.4, 0, 0,1);
		}
	}
}
</style>