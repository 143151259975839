<template>
  <span v-for="(text, index) in data.currentSay.text" :key="index" v-html="text"></span>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";

import { ref, reactive, computed, watch, onMounted, onUnmounted, useStore } from "@/helper/vue.js";
import { addEvent } from "@/helper/event.js";
import { bindKeyEvent } from "@/helper/keyEvent.js";
import {  } from "@/helper/gadget.js";
import { onDeactivated } from '@vue/runtime-core';

export default {
  props: {
		sayText: {
			type: Array,
			default: ['button', 'button']
    },
		type: {
			type: String,
			default: 'autoPlay'
    },
		replaceTime: {
			type: Array,
			default: [1500, 100],
		},
		randomType: {
			type: String,
			default: 'random'
		}
  },

	setup(props) {
		const self = ref();
		const { state, commit, dispatch } = useStore();
		const data = reactive({
      say: [],
			txtLength: [],
			currentSay: {text: [], lang: 'en'},
			nextText: {text: [], lang: 'en'},
			replaceIndex: [],
			playIndex: [],
			stayTime: 0,
      replaceTime: 0,
			totalReplaceTime: 0,
      stopState: false,
      playState: false,
		});
		const methods = {};

		const shuffleArray = (array) => {
			let currentIndex = array.length;
			let randomIndex;
			while (0 !== currentIndex) {
				randomIndex = Math.floor(Math.random() * currentIndex);
				currentIndex--;
				[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
			}
			return array;
		}

		let setTime;
		let sayIndex = 1;  // -> 이거슨 next index
		const playAutoType = () => {
			setTime = setTimeout(() => {
				data.replaceIndex = [];
				data.playIndex = [];
				compareLength(data.say[sayIndex]); // item은 say에서 next item
				sayIndex = sayIndex == data.say.length - 1 ? sayIndex = 0 : sayIndex + 1;
				playAutoType();
			}, data.totalReplaceTime + (data.stayTime));
		}
    const playHoverType = () => {
      data.currentSay.text = _.map(txt => `<span class="${data.say[0].lang == 'kr' ? 't--kr':'t--fak'}">${txt}</span>`, data.say[0].text.split(''))
      data.say[0].text = _.map(txt => `<span class="${data.say[0].lang == 'kr' ? 't--kr':'t--fak'}">${txt}</span>`, data.say[0].text.split(''))
      data.say[1].text = _.map(txt => `<span class="${data.say[1].lang == 'kr' ? 't--kr':'t--fak'}">${txt}</span>`, data.say[1].text.split(''))
      
      if (data.say[0].text.length < data.say[1].text.length) {
        repeatAction(data.say[0].text, data.say[1].text);
      } else {
        repeatAction(data.say[1].text, data.say[0].text);
      }
    }

		const compareLength = (item) => {
      data.nextText.text = item.text.split('');
      if (item.lang) {
        data.nextText.lang = item.lang;
      } else {
        data.nextText.lang = null;
      }
			const nextLength = data.nextText.text.length;
			const currentLength = data.currentSay.text.length;
			
			if (nextLength < currentLength) {
				repeatAction(data.nextText.text, data.currentSay.text);
				data.totalReplaceTime = currentLength * data.replaceTime;
			} else {
				repeatAction(data.currentSay.text, data.nextText.text);
				data.totalReplaceTime = nextLength * data.replaceTime;
			}
		}
    
		const repeatAction = (shortArray, longArray) => {
			getRandomIndex(shortArray.length, longArray.length);
			setShortArray(shortArray, longArray);
      if (props.type == 'autoPlay') {
				replaceText();
      }
		}

		const getRandomIndex = (short, long) => {
      // replace Index
			let check
			let replaceI
			let lastReplaceI = -1;
			let i
			for (i = 0; i < short; i++) {
				check = long - (short - i) - (lastReplaceI + 1)
				replaceI = Math.floor(Math.random() * check);
				lastReplaceI = (lastReplaceI + 1) + replaceI;
				data.replaceIndex.push(lastReplaceI);
			}

      // play Index
			let j
			for (j = 0; j < long; j++) {
				data.playIndex.push(j);
			}
			if (props.randomType == 'random') {
				data.playIndex = shuffleArray(data.playIndex);
			}
		}

		const setShortArray = (shortArray, longArray) => {
			let shortI = 0
			let i
			for (i = 0; i < longArray.length; i++) {
				if (data.replaceIndex[shortI] !== i) {
					shortArray.splice(i, 0, '');
				} else {
					shortI++
				}
			}
		}

		const replaceText = (sayArray) => {
			data.playIndex.forEach((text, index) => {
        setTimeout(() => {
          if (props.type == 'autoPlay') {
            data.currentSay.text[text] = `<span class="${data.nextText.lang == 'kr' ? 't--kr':'t--fak'}">${data.nextText.text[text]}</span>`
            
            if (index == (data.playIndex.length - 1)) {
              data.currentSay.text = 
              _.go(data.currentSay.text,
                _.filter((el) => el !== '<span class="t--fak"></span>'),
                _.filter((el) => el !== '<span class="t--kr"></span>'),
              )
            }
          } else {
            data.currentSay.text[text] = sayArray[text]  
          }
        }, data.replaceTime * (index))
			})
		}

		onMounted(() => {
      if (props.type == 'autoPlay') {
        data.say = shuffleArray(props.sayText);
				data.txtLength = _.map(item => item.text.length, data.say);
				// console.log("data.say", data.say);

				data.say[0].text.split('').forEach(txt => {
					data.currentSay.text.push(`<span class="${data.say[0].lang == 'kr' ? 't--kr':'t--fak'}">${txt}</span>`);
				})
        
				if (props.replaceTime) {
					data.stayTime = props.replaceTime[0];
					data.replaceTime = props.replaceTime[1];
				} else {
					data.stayTime = 2000;
					data.replaceTime = 100;
				}
        playAutoType();
      } else if (props.type == 'hover') {
        data.say = props.sayText;
        data.stayTime = 0;
        data.replaceTime = 20;
        playHoverType();

        const footerBtn = $.qs(".footer-btn")
        footerBtn.addEventListener('mouseenter', e => {
          replaceText(data.say[1].text);
        });
        footerBtn.addEventListener('mouseleave', e => {
          replaceText(data.say[0].text);
        });
      }
		});

		onUnmounted(() => {
			clearTimeout(setTime);
		});

		return {
			self,
			data,
			methods
		};
	}
}
</script>

<style></style>