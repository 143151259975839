<template>
  <div ref="self" class="avatar" :class="`${type == 'big' ? 'avatar--big' : type == 'mid' ? 'avatar--mid' : 'avatar--small' }`">
    <div class="avatar__inr">
      <figure
        class="avatar__img"
        :style="[
          {
            backgroundImage: `url('${require('@/assets/chatbot/animate_avatar_' + avatarName +'.png')}`,
            animationDelay: data.delay,
          }
        ]"
      ></figure>
    </div>
  </div>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { ref, reactive, onMounted, onUnmounted, useStore } from "@/helper/vue.js";
// import SeongsuFlat from './type/_ChatSeongsuFlat.vue';

export default {
  components: {},
  // props: ['avatarName', 'type', 'fixed'],
  props: {
    avatarName: {
      type: String,
      default: 'david'
    },
    type: {
      type: String,
      default: 'mid'
    },
    fixed: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { state } = useStore();
    const data = reactive({
      avatarLength: {
        ann: 95,
        annH: 95,
        dana: 76,
        demi: 74,
        david: 88,
      },
      delay: 0,
      scale: props.type == 'big' ? 0.54 : props.type == 'mid' ? 0.4 : 0.24,
    });
    const self = ref();
    const methods = {};

    let interval;
    const setDelay = (name) => {
      let d = 0;
      interval = setInterval(() => {
        if (d == data.avatarLength[name]) { d = 0 } else { d++ }
        data.delay = `-${d}s`
      }, 30);
    }

    const CB_RESIZE = () => {
      if (state.isMobileSize) {
        const racio = window.innerWidth/390;
        const scale = props.fixed ? data.scale : data.scale * racio;
        $.setCss({ transform: `translate(-50%, -50%) scale(${scale})`}, self.value);
      } else {
        $.setCss({ transform: `translate(-50%, -50%) scale(${data.scale})`}, self.value);
      }
    }

    onMounted(() => {
      CB_RESIZE(); 
      setDelay(props.avatarName);
      window.addEventListener("resize", CB_RESIZE);
    });

    onUnmounted(() => {
      clearInterval(interval);
    })

    return {
      data,
      methods,
      self
    }
  }

}
</script>

<style lang="scss" src="@/css/chatbot.scss"></style>
<style lang="scss" scoped>
  $total-x: 10;
  $total-y: 10;
  $total: $total-x * $total-y;
  $per: (100% / $total);
  $big-width: 100px;
  $big-height: 100px;

  @keyframes avatar_animation {
    @for $i from 0 to $total - 1 {
      $current-frame: ($i * $per);
      #{$current-frame} {
        background-position: -#{$big-width * ($i%10)} -#{$big-height * (($i/10) - ($i/10)%1)};
      }
    }
  }

  .avatar {
    $self: &;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    &__inr {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
    }
    &__img {
      display: block;
      width: 100%;
      height: 100%;
      background-size: 1000%;
      background-repeat: no-repeat;
      background-position: 0 0;

      animation: avatar_animation #{$total + 1s} steps(1) backwards;
      animation-play-state: paused;
      animation-iteration-count: initial;
      animation-duration: #{$total + 1s};
    }
  }
</style>