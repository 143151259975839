import * as _ from "fxjs";
import * as $ from "fxdom";
import { gsap } from "gsap/all";

// let x, y = 0;

// const initCursor = () => {
// 	return;
// 	$.appendTo($.qs("body"), $.el("<div class='test-cursor' style='point-events:none; width: 20px; height: 20px; top: 0; left: 0; border-radius: 50%; background: red; display: inline-block; position: fixed; z-index: 9999; transform: translate(-50%, -50%);'></div>"));
// 	window.addEventListener("mousemove", e => {
// 		// console.log("adsffdsaf - ", e.pageX);
// 		// console.log(e);
// 		x = e.screenX;
// 		y = e.screenY - 100;
// 		// $.setCss({ left: `${e.pageX}`, top: `${e.pageY}` }, $.qs(".test-cursor"));
// 		gsap.to(".test-cursor", 0.3, { x, y, overwrite: "auto" });
// 		console.log(gsap.getTweensOf(".test-cursor"));
// 	});
// }


// export { initCursor };

const Pointer = (_opt => {
	let cursor, previous, current, real, damper, setterX, setterY;
	const update = () => {
		const dt = 1.0 - Math.pow(1.0 - damper, gsap.ticker.deltaRatio());
		// const dt = 1.0 - damper;
		current.deltaX = (real.x - current.x) * dt;
		current.deltaY = (real.y - current.y) * dt;
		current.x = Math.round(current.x + current.deltaX);
		current.y = Math.round(current.y + current.deltaY);
		current.deltaX = previous.x == current.x ? 0 : current.deltaX;
		current.deltaY = previous.y == current.y ? 0 : current.deltaY;
		setterX(current.x);
		setterY(current.y);
		previous.x = current.x;
		previous.y = current.y;
	};
	return {
		init() {
			cursor = document.querySelector(_opt.cursor || ".c-cursor");
			previous = { x: 0, y: 0 };
			current = { x: 0, y: 0, deltaX: 0, deltaY: 0 };
			real = { x: 0, y: 0 };
			damper = _opt.damper || 0.15;
			setterX = gsap.quickSetter(cursor, "x", "px");
			setterY = gsap.quickSetter(cursor, "y", "px");

			document.addEventListener("mouseover", e => {
				current.x = real.x = e.screenX;
				current.y = real.y = e.screenY;
			});

			window.addEventListener("mousemove", e => {
				real.x = e.screenX;
				real.y = e.screenY;
			});
			window.addEventListener("touchstart", e => {
				real.x = e.targetTouches[0].screenX;
				real.y = e.targetTouches[0].screenY;
			});
			window.addEventListener("touchmove", e => {
				real.x = e.targetTouches[0].screenX;
				real.y = e.targetTouches[0].screenY;
			});

			gsap.ticker.add(update);
		},
		removeTicker() { gsap.ticker.remove(update); },
		getCurrentX: () => current.x,
		getCurrentY: () => current.y,
		getRealX: () => real.x,
		getRealY: () => real.y,
		getDeltaX: () => current.deltaX,
		getDeltaY: () => current.deltaY,
	}
})({ cursor: ".c-cursor", damper: 0.15 });

window.addEventListener("load", () => {
	Pointer.init();
});

export { Pointer };