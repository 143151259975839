import * as _ from "fxjs";
import * as $ from "fxdom";
import { createStore } from 'vuex';

import eventHandler from "./store-eventHandler.js";
import mediaLoader from "./store-mediaLoader.js";
import loadManager from "./store-loadManager.js";

import chatbot from "./store-chatbot.js";
import giphy from "./store-giphy.js";
import module from "./store-module.js";
import edit from './store-edit.js';
import project from './store-project.js';
import workList from './store-workList.js';
import { mqList } from "./appSettings.js";
import { MinimeType, MinimeTotal, TextType, TextTotal } from "@/parts/minime/data-minime-type.js";
import { isLoaderOn } from "@/store/appSettings.js";

const allModules = {
  event: eventHandler,
  mediaLoader,
  chatbot,
  giphy,
  module,
  loadManager,
  edit,
  project,
  workList,
  MinimeType,
  TextType
};

export const store = createStore({
  strict: true,
  modules: allModules,
  state: {
    isMuted: true,
    marqueeId: 0,
    vh: window.screen.height,
    currScroll: 0,
    scrollDir: "down",
    isScrollStarted: false,
    isWorkUnit: false,
    kvTypeUnit:'',
    nameUnit:'',
    typeUnit:'',
    colorUnit:[],
    isEarlyLoadComplete: false,
    isMobileAgent: false,
    isMobileSize: false,
    isMobile: false,
    isPcSafari: false,
    isNoSmooth: false,
    pageState: "loading",
    isFirstLanding: true,
    isPageLoading: false,
    loadingProgress: 0,
    loadingState: "pending",
    loadTime: 0,
    scrollPos: 0,
    width: 0,
    height: 0,
    prevMq: -1,
    currMq: -1,
    mx: 0,
    my: 0,
    modules: _.keys(allModules),
    mode: "dev",
    arr1: [1, 2, 3],
    currPage: "",
    isQuickSizerOn: false,
    isWorkTab:false,
    rootDOM: ".i-root",
    canvasDOM: ".i-canvas",
    baseRes: 1024,
    cw: document.documentElement.clientWidth,
    ch: document.documentElement.clientHeight,

    sw: window.screen.width,
    sh: window.screen.height,
    pixelRatio: window.devicePixelRatio,
    requestWidthMax: 1600,
    requestWidth: window.screen.width <= 1600 ? window.screen.width : 1600,

    isPageReady: false,
    lang: 'en',
    isLangKr: false,
    isCreditOpen: false,
    isPolicyOpen: false,
    isIntroYtOpen: false,
    layerPopup: {
      name: null,
      source: [],
      // source: [ embedtype, src],
    },
    minimeRemain: [...Array(MinimeTotal)].map((x, i) => i),
    minimeTotalLength: MinimeTotal,
    minimeTxtRemain: [...Array(TextTotal)].map((x, i) => i),
    minimeTxtTotalLength: TextTotal,
    changeDomCount: 0,
    scrollLockOffsetY: 0
  },
  getters: {
    from: state => sel => _.sel(sel, state),
    arr1: state => state.arr1,
    lang: state => state.lang,
    hiddenOnLoading: state => {
      // 로딩화면 off일 경우는 무조건 보이기
      if(!isLoaderOn) return false;
      // 로딩중에는 때 무조건 숨기기
      if(state.loadingState == "pending") return true;

      return false;
    }
  },
  mutations: {
    ["set"](state, { sel, to }) {
      const selArr = _.split(".", sel);
      const selected = _.reduce((a,b) => a[b], state, selArr);

      if(typeof selected == "object" && !Array.isArray(selected)) {
        Object.assign(selected, to);
      }
      else {
        const k = selArr.pop();
        const preObj = _.reduce((a, b) => a[b], state, selArr);
        preObj[k] = to;
      }
    },
    ["add"](state, { sel, item }) {
      _.sel(sel, state).push(item);
    },
    ["move"](state, { sel, index, to }) {
      if(to < 0) return;
      const newArr = _.sel(sel, state);
      if(to >= newArr.length) return;
      const pop = newArr[index];
      newArr.splice(index, 1);
      newArr.splice(to, 0, pop);
      console.log(pop);
    },
    ["splice"](state, { sel, start, count = 1, addItem }) {
      const newArr = _.sel(sel, state);
      if(addItem) {
        newArr.splice(start, count, addItem);
      }
      else {
        newArr.splice(start, count);
      }
    },
    ["setMutedState"](state) {
      state.isMuted = !state.isMuted
    },
    ["setMouse"](state, pos) {
      state.mx = pos.x;
      state.my = pos.y;
    },
    ["setLoadingPage"](state, pageName) {
      state.currPage = pageName;
    },
    ["setLoadTime"](state, val) {
      state.loadTime = val;
    },
    ["changeLang"](state) {
      if (state.lang == 'ko') {
        state.lang = 'en';
        state.isLangKr = false;
      } else {
        state.lang = 'ko';
        state.isLangKr = true;
      }
    },
    ["setLayerPopup"](state, {compName, embedType, src}) {
      // compName == credit, privacyPolicy, introYt, workYt
      state.layerPopup.name = compName;
      if (compName == 'workYt') {
        state.layerPopup.source = [embedType, src];
      } else if (compName == null) {
        state.layerPopup.source = [];
      }
    },
    ["changeDom"](state, count) {
      state.changeDomCount += count;
    },
    ["checkMinimeRemain"](state, { selected, init }) {
      if (init) {
        state.minimeRemain = [...Array(state.minimeTotalLength)].map((x, i) => i)
      } else {
        if (state.minimeRemain.length > 1) state.minimeRemain = state.minimeRemain.filter( item => item !== selected)
      }
    },
    ["checkMinimeTxtRemain"](state, { selected, init }){
      if (init) {
        state.minimeTxtRemain = [...Array(state.minimeTxtTotalLength)].map((x, i) => i)
      } else {
        if (state.minimeTxtRemain.length > 1) state.minimeTxtRemain = state.minimeTxtRemain.filter( item => item !== selected)
      }
    },
    ["beforeY"](state, val) {
      state.scrollLockOffsetY = val
    }
  },
  actions: {
    onLoad({ commit, dispatch }) {
      commit("set", { sel: "isPageReady", to: true });
      dispatch("appResize");
      dispatch('chatbot/init');
    },
    mobileCheck({ commit, state }) {
      if(state.cw < 600) {
        commit("set", { sel: "isMobileSize", to: true });
        $.addClass("s--mobile-size", $.qs("html"));
      }
      else {
        commit("set", { sel: "isMobileSize", to: false });
        $.removeClass("s--mobile-size", $.qs("html"));
      }
    },
    screenSizeCheck({ commit, state }) {
      commit("set", { sel: "sw", to: window.screen.width });
      commit("set", { sel: "sh", to: window.screen.height });
      // update requestWidth
      if(state.sw > state.requestWidth && state.requestWidth !== state.requestWidthMax) {
        if(state.sw <= state.requestWidthMax) {
          commit("set", { sel: "requestWidth", to: state.sw });
        }else{
          commit("set", { sel: "requestWidth", to: state.requestWidthMax });
        }
      }
      // update devicePixelRatio
      if(state.pixelRatio !== window.devicePixelRatio) {
        commit("set", { sel: "pixelRatio", to: window.devicePixelRatio });
      }
    },
    // 현재 안쓰이고 있음
    startLoading({ commit }, param) {
      console.log("📦 startLoading", param);
			commit("set", { sel: "pageState", to: "loading" });
      commit("setLoadingPage", param)
      console.log("🚀 start.loading");
      window.dispatchEvent(new CustomEvent("start.loading"));
      commit("loadManager/readyQueue");
    },
    afterCurtainReady({ commit }) {
      console.log("📦 afterCurtainReady");
      console.log("🚀 end.curtainReady");
      window.dispatchEvent(new CustomEvent("end.curtainReady"));
    },
    afterPageEnter({ commit, dispatch }) {
      console.log("📦 afterPageEnter");
      dispatch("loadManager/waitAll", () => {
        setTimeout(() => {
          console.log('🚀 landed');
          window.dispatchEvent(new CustomEvent("landed"));
          console.log('🚀 resize');
          window.dispatchEvent(new Event("resize"));
  				commit("set", { sel: "pageState", to: "landed" });
          commit('set', { sel: "loadingProgress", to: 1 });
          commit('checkMinimeRemain', { selected: null, init: true })
          commit('checkMinimeTxtRemain', { selected: null, init: true })
        });
      });
    },
    beforeResize({ state, commit, dispatch }) {
      // measure
      commit("set", { sel: "baseRes", to: state.cw <= 599 ? 390 : state.cw < 1600 ? 1024 : 1600 });

      // removeClass
      _.go(
        mqList.sizes,
        _.map(size => `__w${size} w${size}__`),
        _.join(" "),
        clss => { $.removeClass(clss, $.qs("html")) }
      );

      // for changed.mq event
      const breaks = [0, 600, 1600, 99999];
      _.go(
        breaks,
        _.reduce((pre, next) => {
          if(state.cw >= pre && state.cw < next) {
            commit("set", { sel: "prevMq", to: state.currMq });
            commit("set", { sel: "currMq", to: breaks.indexOf(next) });
          }
          return next;
        })
      );

      _.go(
        mqList.sizes,
        _.map(size => {
          let cls = "";
          if(state.cw < size) {
            cls += ` __w${size}`;
          }
          else {
            cls += ` w${size}__`;
          }
          return cls;
        }),
        _.join(""),
        cls => {$.addClass(cls.trim(), $.qs("html"))}
      );

      dispatch("mobileCheck");

      if(state.prevMq != state.currMq) {
        window.dispatchEvent(new CustomEvent("changed.mq"));
        // 600 < size || 600 >= size, mq -1: onLoad
        if((state.currMq <= 1 && state.prevMq > 1) || (state.currMq >= 2 && state.prevMq < 2) || state.prevMq == -1) {
          window.dispatchEvent(new CustomEvent("toggle.mobileSize"));
        }
      }
    },
    afterResize({ state, commit, dispatch }) {
      dispatch("screenSizeCheck");
    },
    appResize({ state, commit, dispatch }) {

      dispatch("beforeResize");
      commit("set", { sel: "unitScale", to: state.cw / state.baseRes });
      document.documentElement.style.setProperty('--canvasWidth', state.cw);
      document.documentElement.style.setProperty('--unitScale', state.unitScale);

      commit("set", { sel: "vh", to: document.querySelector(".i-viewport").offsetHeight });
      document.documentElement.style.setProperty('--vh', state.vh + "px");

      dispatch("afterResize");
    },
    onChangeDom({commit}){
      commit("changeDom", 1);
    },

  }
});