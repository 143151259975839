<template>
  <ul>
    <li v-for="cont in item.contents" :key="cont.text">
      <a :href="cont.url">
        <div>
          <img :src="cont.path" alt="">
          <p>{{ cont.text }}</p>
        </div>
      </a>
    </li>
  </ul>
</template>

<script>

export default {
  props: {
    item: Object,
  },

}
</script>

<style>

</style>