<template>
	<div class="c-footer" data-color-anchor="0,0.6,#171010,#F9F5EF,#F9F5EF,#EBE3D7,#171010,#171010">
		<div class="c-footer__top">
			<div class="l-full c-last">
				<slot></slot>
				<div class="c-last__inner" :class="{'c-last__inner--wide':marqueeInfo}">
					<FooterDesc
						v-if="normalDescEn"
						:normal-desc-en="normalDescEn"
						:normal-desc-kr="normalDescKr"
					/>
					<!-- 푸터 상단 사진 및 텍스트 모듈 -->
					<FooterFigure
						v-if="keyVisualUrl"
						:key-visual-url="keyVisualUrl"
						:key-visual-desc="keyVisualDesc"
						:key-visual-desc-kr="keyVisualDescKr"
						:key-visual-desc-col="keyVisualDescCol"
						:sub-visual-url="subVisualUrl"
						:sub-visual-desc="subVisualDesc"
					/>
					<FooterBtn :is-footer="true" :footerText="buttonText" :minime-image="minimeImage" :open-chatbot="openChatbot" class="l-mdl--64"/>
				</div>
			</div>
		</div>
		<div class="c-footer__btm">
			<FooterCopyright />
		</div>
	</div>
</template>

<script>
import FooterDesc from "@/parts/footer/FooterDesc.vue"
import FooterFigure from "@/parts/footer/FooterFigure.vue"
import FooterObjectDesc from "@/parts/footer/FooterObjectDesc.vue"
import FooterCtaButton from "@/parts/footer/FooterCtaButton.vue"
import FooterCopyright from "@/parts/footer/FooterCopyright.vue"
import Minime from "@/parts/minime/Minime.vue";
import FooterBtn from "@/parts/button/button.vue";
import { reactive } from '@vue/reactivity';
import { onMounted, onUnmounted } from "@/helper/vue.js";

export default {
	name: 'Footer New',
	components: {
		FooterDesc,
		FooterFigure,
		FooterObjectDesc,
		FooterCtaButton,
		FooterCopyright,
		FooterBtn,
		Minime
	},
	props: {
		/**
		 * 워크 상세 페이지 해당 아이디 (work Only)
		 * */

		pageId: {
			type: String,
			default: null
		},
		/**
		 * 마퀴 
		 * */

		marqueeInfo: {
			type: Array,
			default: null
		},
		/**
		 * 트레이드 마크 유무 (work only)
		 * */
		isTrademark: {
			type: Boolean,
			default: false
		},
		/**
		 * 이미지 없는  텍스트 유무 영문 (work only)
		 * */
		normalDescEn: {
			type: String,
			default: null
		},
		/**
		 * 이미지 없는  텍스트 유무 국문 (work only)
		 * */
		normalDescKr: {
			type: String,
			default: null
		},
		/**
		 * [figure] 키 비주얼 이미지 경로
		 * */
		keyVisualUrl: {
			type: [String, Array],
			default: null
		},

    /**
		 * [figure] 키 비주얼 하단 디스크립션
		 * */
		keyVisualDesc: {
			type: String,
			default: null
		},

    /**
		 * [figure] 키 비주얼 하단 디스크립션 - 국문
		 * */
		keyVisualDescKr: {
			type: String,
			default: null
		},

		/**
		 * [figure] 키 비주얼 하단 디스크립션 g-col--{} 영역
		 * */
		keyVisualDescCol: {
			type: Number,
			default: 9
		},

    /**
		 * [figure] 서브 비주얼 1 이미지 경로
		 * */
		subVisualUrl: {
			type: [String, Array],
			default: null
		},

    /**
		 * [figure] 서브 비주얼 1 이미지 캡션
		 * */
		subVisualDesc: {
			type: String,
			default: null
		},

    /**
		 * [figure] 서브 비주얼 2 이미지 캡션
		 * */
		buttonText: {
			type: Array,
			default: null
    },
    hoverText: {
			type: String,
			default: null
    },
    minimeImage: {
      type: Number,
      default: 0
    },
		minimeLength: {
      type: Number,
      default: 14
    },
		openChatbot: {
      type: Array,
      default: ['chatMain']
		}
	},
	setup(props) {
		const data =  reactive({
		})

		onMounted(() => {
		})

		onUnmounted(() => {
		});

		return {
			data
		}
	}
}
</script>

<style scoped lang="scss">
// @import "@/css/_settings.scss";
.c-footer {
	position: relative;
	left: 0;
	right: 0;
	bottom: 0;
	color: #EBE3D7;
	overflow: hidden;
	z-index: 30;
	// color: #EBE3D7;
	// &::before {
	// 	content: '';
	// 	position: absolute;
	// 	top: 0;
	// 	left:0;
	// 	width: 100%;
	// 	height: 100%;
	// 	// background-color: $f-black;
	// 	// background: url('~@/assets/work/dfy_wrok_contact-banner-texture.png') no-repeat 0 0;
	// 	// background-size: cover;
	// 	z-index: -1;
	// }

	// Footer Top 스타일
	&__top {
		.c-last {
			&__inner {
				padding: 16em 16em 0;
				.w1600__ & {
					padding: 16em 16em 0;
				}
				&--wide {
					padding: 16em 0;
					.w1600__ & {
						padding: 16em 0;
					}
				}

			}
		}
	}

	// Footer Bottom 스타일
	&__btm {
		margin-top: 71em;
		.__w600 & {
			margin-top: 70em;
		}
		.w1600__ & {
			margin-top: 77em;

		}
	}
}

</style>