<template>
  <div ref="infinite" class="infinite" :class="{ 'infinite--video': type == 'video' }">
    <div class="infinite__btn">
      <div class="infinite__particles-wrap infinite__particles-wrap--1">
        <span class="infinite__particles infinite__particles--1 ico-chatbot--content-favorite"></span>
        <span class="infinite__particles infinite__particles--2 ico-chatbot--content-favorite"></span>
        <span class="infinite__particles infinite__particles--3 ico-chatbot--content-favorite"></span>
        <span class="infinite__particles infinite__particles--4 ico-chatbot--content-favorite"></span>
      </div>
      <div class="infinite__particles-wrap infinite__particles-wrap--2">
        <span class="infinite__particles infinite__particles--1 ico-chatbot--content-favorite"></span>
        <span class="infinite__particles infinite__particles--2 ico-chatbot--content-favorite"></span>
        <span class="infinite__particles infinite__particles--3 ico-chatbot--content-favorite"></span>
      </div>
      <div class="infinite__particles-wrap infinite__particles-wrap--3">
        <span class="infinite__particles infinite__particles--1 ico-chatbot--content-favorite"></span>
        <span class="infinite__particles infinite__particles--2 ico-chatbot--content-favorite"></span>
        <span class="infinite__particles infinite__particles--3 ico-chatbot--content-favorite"></span>
      </div>
      <button class="infinite__heart-wrap" @click="methods.increaseHeart">
        <span class="infinite__heart infinite__heart--empty ico-chatbot--content-favorite--border"></span>
        <span class="infinite__heart infinite__heart--full ico-chatbot--content-favorite"></span>
        <!-- <span class="infinite__heart infinite__heart--white ico-chatbot--content-favorite"></span> -->
      </button>
    </div>
    <div class="infinite__counter">
      <span class="t-cb--13"><span class="t--fak">{{ data.likeCount }}K</span></span>
    </div>
  </div>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";

import { ref, reactive, computed, onMounted, onUnmounted, watchEffect, useStore } from "@/helper/vue.js";
import { getRelativePos } from "@/helper/gadget.js";

export default {
  props: ['type', 'likeCount'],
  setup(props) {
    const { state, get, dispatch } = useStore();

    const data = reactive({
      counter: 0,
      likeCount: props.likeCount,
    });

    const methods = {
      increaseHeart: () => {
        $.addClass("clicked", $.find(".infinite__heart-wrap", infinite.value))
        data.counter++;
        if (data.counter == 1000) {
          data.likeCount++;
          data.counter = 0;
        }
        
        const particles1 = $.find(".infinite__particles-wrap--1", infinite.value);
        const particles2 = $.find(".infinite__particles-wrap--2", infinite.value);
        const particles3 = $.find(".infinite__particles-wrap--3", infinite.value);

        const duration = props.type ? 1000 : 600;
        if (!$.hasClass("infinite__particles-wrap-show", particles1)) {
          methods.animateParticles(particles1, duration);
        } else if (!$.hasClass("infinite__particles-wrap-show", particles2)) {
          methods.animateParticles(particles2, duration);
        } else {
          methods.animateParticles(particles3, duration);
        }
      },
      animateParticles: (target, duration) => {
        const particlesClass = ["infinite__particles-wrap-show--1", "infinite__particles-wrap-show--2", "infinite__particles-wrap-show--3"];
        let randomNum = Math.floor(Math.random() * 3);
        $.addClass("infinite__particles-wrap-show", target)
        $.addClass(particlesClass[randomNum], target)
        setTimeout(() => {
          $.removeClass("infinite__particles-wrap-show", target)
          $.removeClass(particlesClass[randomNum], target)
        }, duration);
      }
    }
    const infinite = ref();

    
    onMounted(() => {});

    return {
      data,
      methods,
      infinite
    }
  }
}
</script>

<style>

</style>