import * as _ from "fxjs";
import * as $ from "fxdom";
import { gsap, ScrollTrigger } from "gsap/all";
import LocomotiveScroll from "locomotive-scroll";
import { getScroll } from "@/helper/gadget.js";
import { isSmoothScroll } from "@/store/appSettings.js";
import { store } from '../store/index.js';
gsap.registerPlugin(ScrollTrigger);


gsap.defaults({
	ease: "none",
});

gsap.config({
	force3D: true,
});

// 디바이스 체크가 우선되어야 스크롤러를 선택해줄 수 있다.
window.addEventListener("got.agent", () => {
	ScrollTrigger.config({
		autoRefreshEvents: "visibilitychange"
	});
	ScrollTrigger.defaults({
		// toggleActions: "restart pause resume none",
		scroller: (isSmoothScroll && !store.state.isMobileAgent) ? ".i-canvas" : window,
		group: "normal"
	});
}, { once: true });

// window.addEventListener("load", (e) => {

// 	gsap.set(".c-cursor", {xPercent: -50, yPercent: -50});
// 	const ball = document.querySelector(".c-cursor");
// 	const pos = { x: 0, y: 0 };
// 	const mouse = { x: pos.x, y: pos.y };
// 	const damper = 0.25;
// 	const xSet = gsap.quickSetter(ball, "x", "px");
// 	const ySet = gsap.quickSetter(ball, "y", "px");

// 	document.addEventListener("mouseover", e => {
// 		pos.x = e.screenX;
// 		pos.y = e.screenY - 100;
// 		mouse.x = pos.x;
// 		mouse.y = pos.y;
// 	});

// 	window.addEventListener("mousemove", e => {
// 		mouse.x = e.screenX;
// 		mouse.y = e.screenY - 100;
// 	});

// 	gsap.ticker.add(() => {
// 		pos.x += (mouse.x - pos.x) * damper;
// 		pos.y += (mouse.y - pos.y) * damper;
// 		xSet(pos.x);
// 		ySet(pos.y);
// 	});
// });

let preScroll;
const testScrollDirection = (currScroll) => {

	// 로딩이 완료되기 전에는 실행하지 않는다.
	if(store.state.loadingState != "complete") return;

	// 모바일 사파리에서 상단/하단 바운스 때문에 방향 바뀌는 문제 해결
	if(currScroll < 0) return;
	if(currScroll > store.state.ch - store.state.vh) return;

	// 페이지 불러온 후에 스크롤 시작했는지 여부
	!store.state.isScrollStarted && store.commit("set", { sel: "isScrollStarted", to: true });

	const delta = currScroll - preScroll;
	preScroll = currScroll;

	store.commit("set", { sel: "currScroll", to: Math.floor(currScroll) });

	if(Math.abs(delta) < 1) return;

	const newDir = delta < 0 ? "up" : "down";
	if(store.state.scrollDir != newDir) {
		window.dispatchEvent(new CustomEvent("changed.scroll.direction", {
			detail: {
				dir: newDir
			}
		}));
		store.commit("set", { sel: "scrollDir", to: newDir });
	}
};

let locoScroll = null;

// window.addEventListener("click", () => {
// 	locoScroll.destroy();
// 	ScrollTrigger.update();
// });

window.addEventListener("landed", () => {
	if(!isSmoothScroll || store.state.isMobileAgent) return;
	// if(!isLoaderOn) {
	// 	$.qs(".i-canvas--loading") && $.removeClass("i-canvas--loading", $.qs(".i-canvas--loading"));
	// }
	// return;
	if(locoScroll == null && !store.state.isNoSmooth) {
		locoScroll = new LocomotiveScroll({
			el: document.querySelector(".i-canvas"),
			smooth: true,
			tablet: { breakpoint: 0 },
			smartphone: { breakpoint: 0 },
		});
		locoScroll.scrollTo("top", { duration: 0, disableLerp: true });
		locoScroll.stop();

		locoScroll.on("scroll", (e) => {

			// if(locoScroll.scroll.stop) {
			// 	locoScroll.start();
			// }
			ScrollTrigger.update(e);
			testScrollDirection(e.scroll.y);
			// store.commit('set', { sel: "scrollPos", to: e.scroll.y });
			// store.commit('getScroll', getScroll());
		});

		// tell ScrollTrigger to use these proxy methods for the ".smooth-scroll" element since Locomotive Scroll is hijacking things
		ScrollTrigger.scrollerProxy(".i-canvas", {
			scrollTop(value) {
				return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
			}, // we don't have to define a scrollLeft because we're only scrolling vertically.
			getBoundingClientRect() {
				return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
			},
			// LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
			pinType: document.querySelector(".i-canvas").style.transform ? "transform" : "fixed"
		});

		ScrollTrigger.addEventListener("refresh", () => locoScroll.update());
		// if(!isLoaderOn) {
		// 	setTimeout(() => {
		// 		$.qs(".i-canvas--loading") && $.removeClass("i-canvas--loading", $.qs(".i-canvas--loading"));
		// 		locoScroll.start();
		// 	}, 0);
		// }
	}

	// if(!isLoaderOn) {
	// 	$.qs(".i-canvas--loading") && $.removeClass('i-canvas--loading', $.qs('i-canvas--loading'))
	// }
});

window.addEventListener("resize.canvas.y", () => {
	setTimeout(() => {
		ScrollTrigger.getAll().forEach(st => {
			(st.vars.id === undefined || st.vars.id !== 'marqueeTop') && st.refresh();
		});
	}, 120);
});

window.addEventListener("landed", () => {
	if(store.state.isMobileAgent) {
		window.addEventListener("scroll", (e) => {
			testScrollDirection(window.pageYOffset);
		});
	}
}, { once: true });

window.addEventListener("loader.complete", () => {
	store.commit("set", { sel: "loadingState", to: "complete" });
});

window.addEventListener("ready.curtain", () => {

	// 스크롤 시작 이벤트 초기화
	store.commit("set", { sel: "isScrollStarted", to: false });

	// 스크롤 방향 초기화
	preScroll = 0;
	store.commit("set", { sel: "scrollDir", to: "down" });
	// if(locoScroll) {
	// 	locoScroll.destroy();
	// 	locoScroll = null;
	// }
	_.go(
		ScrollTrigger.getAll(),
		_.each(item => {
			if(item.vars.group == "normal") {
				item.kill();
			}
		})
	);

	$.removeClass("chat-body-lock", $.qs("body"));
	$.removeClass("scroll-lock", $.qs(".app"));
});

export { locoScroll };