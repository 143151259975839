const mqList = {
  sizes: [320, 390, 600, 768, 1024, 1600, 1920, 2560],
  ratios: [42, 56, 60, 75, 80, 100, 166, 200]
};

const isQuickSizerOn = true;

// // 전체 로딩화면 on/off
// const isLoaderOn = true;
// // 스무스스크롤 on/off
// const isSmoothScroll = true;
// // 스플래시(첫로딩) on/off
// const isSplashOn = true;

// 전체 로딩화면 on/off
const isLoaderOn = true;
// 스무스스크롤 on/off
const isSmoothScroll = true;
// 스플래시(첫로딩) on/off
const isSplashOn = true;
// API 서버
// const API_SERVER = "http://localhost:3000";
const API_SERVER = "https://rest.dfy.works";
// 테스트 메뉴 on/off
const testMenu = false;



export {
  mqList,
  isLoaderOn,
  isQuickSizerOn,
  isSmoothScroll,
  isSplashOn,
  testMenu,
  API_SERVER
};