<template>
  <div ref="self" class="chat-comp chat-comp--uncomplete" :class="[item.fromWho === 'bot'? 'chat-left': 'chat-right']">
    <component :is="data.currentCom" :item="item" :index="index" :dataName="dataName" :subpage="subpage" :hiringTit="hiringTit"></component>
    <!-- <component :is="data.currentCom" :item="item" :index="index" :dataName="dataName" @endLoad="methods.setScrollPosition"></component> -->
  </div>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { ref, reactive, computed, onBeforeUpdate, watchEffect, onUpdated, onMounted, useStore } from "@/helper/vue.js";
import NormalText from "./type/_NormalText.vue";
import SelectText from "./type/_SelectText.vue";
import TextInput from "./type/_TextInput.vue";
import SelectImage from "./type/_SelectImage.vue";
import ChatGiphy from "./type/_ChatGiphy.vue";
import Person from "./type/_Person.vue";
import { gsap } from "gsap/all";
import { onUnmounted } from '@vue/runtime-core';

export default {
  props: ['item', 'index', 'dataName', 'subpage', 'hiringTit'],
  components: {
    NormalText,
    SelectText,
    TextInput,
    SelectImage,
    ChatGiphy,
    Person
  },
  setup(props) {
    const { state, get, commit, dispatch } = useStore();
    const data = reactive({
      currentCom: computed(() => {
        if(props.item.type == 'NormalText') {
          return NormalText;
        } else if(props.item.type == 'SelectText') {
          return SelectText;
        } else if(props.item.type == 'TextInput') {
          return TextInput;
        } else if(props.item.type == 'SelectImage') {
          return SelectImage;
        } else if(props.item.type == 'Giphy') {
          return ChatGiphy;
        } else if(props.item.type == 'Person') {
          return Person;
        }
      }),
    });
    
    const methods = {
      setScrollPosition: () => {
        const subpage = $.qs(".subpage");
        const lastComp = $.qsa(".chat-comp")[$.qsa(".chat-comp").length - 1];
        let scrollDistance 
        let scrollTime

        if (props.item.contents.scrollToMiddle && lastComp) {
          const prevHeightSum = _.go(
            $.prevAll(".chat-comp", lastComp),
            _.slice(-props.item.contents.moreChatItemNum),
            _.map(prevItem => prevItem.offsetHeight),
            _.reduce((a, b) => a + b)
          )
          scrollDistance = subpage.scrollHeight - lastComp.offsetHeight - (prevHeightSum + 48 + 40);
          scrollTime = 0.5
          dispatch('chatbot/addTimer', {delay: 410, callback: () => {
            const scrollPosition = { scrollDistance: subpage.scrollTop };
            gsap.to(scrollPosition, scrollTime, { scrollDistance: scrollDistance, ease: "none",
              onUpdate: () => {
                subpage.scrollTop = scrollPosition.scrollDistance;
              }
            });
          }})
        } else {
          scrollDistance = lastComp ? subpage.scrollHeight - lastComp.offsetHeight :  subpage.scrollHeight;
          scrollTime = 1.5
          const scrollPosition = { scrollDistance: subpage.scrollTop };
          gsap.to(scrollPosition, scrollTime, { scrollDistance: scrollDistance, ease: "none",
            onUpdate: () => {
              subpage.scrollTop = scrollPosition.scrollDistance;
            }
          });
        }
      },
    }

    const self = ref();

    onMounted(() => {
      const executeNextEventCondition = props.item.nextId && !props.item.waitAnswer && !props.item.react && !(props.item.contents.normalType == 'submit') && !props.item.contents.checkQuery;
      methods.setScrollPosition();
      dispatch('chatbot/addTimer', {delay: 200, callback: () => {
        $.removeClass("chat-comp--uncomplete", self.value)
        dispatch('chatbot/addTimer', {delay: 400, callback: () => {
          if (executeNextEventCondition) {
            dispatch('chatbot/executeNextEvent', {id: props.item.id, nextId: props.item.nextId, dataName: props.dataName});
          }
          dispatch('chatbot/addTimer', {delay: 400, callback: () => {
            if (props.item.waitAnswer && !props.item.react) {
              commit('chatbot/setScrollLock', false);
            }
          }})
        }});
      }});

      if (props.item.end) {
        dispatch('chatbot/addTimer', {delay: 500, callback: () => {
          commit('chatbot/endState', {dataName: props.dataName, end: true});
          const subpage = $.qs(".subpage");
          const scrollPosition = { scrollDistance: subpage.scrollTop };
          gsap.to(scrollPosition, 1, { scrollDistance: subpage.scrollHeight - 110, ease: "none",
            onUpdate: () => {
              subpage.scrollTop = scrollPosition.scrollDistance;
            }
          });
        }});
      }
    });

    return {
      data,
      methods,
      self
    }
  }
}

</script>

