<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | DFY` : `DFY` }}</template>
  </metainfo>
  <Header v-show="!data.isMobileSize"/>
  <AudioControl class="header__audio-control"/>
  <div class="i-canvas__bg"></div>
  <div
    class="app i-root i-canvas scrollContainer"
  >
    <Loader v-if="data.isLoaderOn"></Loader>
    <!-- <router-view class="i-container" /> -->
    <router-view class="i-container" :key="`${$route.fullPath}`" />
    <!-- <DevTools/> -->
  </div>
  <DrawingLine v-if="data.isValidCustomCursor" />

  <Notifier/>
  <Chatbot></Chatbot>
  <LayerPopup></LayerPopup>
  <div class="i-viewport"></div>
  <!-- <div class="d-debugger" style="position: fixed; bottom: 10px; max-width: 40%; display: inline-block; left: 10px; background: #ffffff; font-size: 13px; border-radius: 4px; padding: 5px; z-index: 9999999;"></div> -->
  <div class="c-noise"></div>
</template>

<script>
import * as $ from 'fxdom';
import * as _ from 'fxjs';

import { ResizeObserver } from '@juggle/resize-observer';
import {
  reactive,
  defineAsyncComponent,
  computed,
  onMounted,
  useStore,
} from '@/helper/vue.js';
// import { useMeta, useActiveMeta } from 'vue-meta'
import { isLoaderOn } from "@/store/appSettings.js";
import router from "@/router/index.js";
import Loader from '@/parts/loader/Loader.vue';
import Header from '@/parts/header/Header.vue';
import Footer from '@/parts/footer/Footer.vue';
import Chatbot from '@/parts/chatbot/Chatbot.vue';
import DrawingLine from '@/parts/matter/DrawingLine.vue';
import LayerPopup from "@/pages/layer/LayerPopup.vue";
import AudioControl from "@/parts/audio/AudioControl.vue";
import Notifier from '@/parts/Notifier.vue';

require("./js/common.js");
require("./js/cursorActions.js");
require("./js/slider.js");
require("./js/parallax.js");
require("./js/colorChanger.js");

export default {
  name: 'App',
  components: {
    // DevTools: defineAsyncComponent(() => import('@/devtools/DevTools.vue')),
    Loader,
    Header,
    Footer,
    Chatbot,
    Notifier,
    AudioControl,
    DrawingLine,
    LayerPopup,
  },
  setup() {
    // store
    const { state, get, commit, dispatch } = useStore();

    // Add meta info
    // The object passed into useMeta is user configurable
    // const { meta } = useMeta({
    //   // title: "My Title",
    //   og: {
    //     type: { property: "og:type", content: "meta.og.type" },
    //     url: { property: "og:url", content: "meta.og.url" },
    //     title: { property: "og:title", content: "meta.og.title" },
    //     image: { property: "og:image", content: "meta.og.image" },
    //     description: { property: "og:description", content: "meta.og.description" },
    //     site_name: { property: "og:site_name", content: "meta.og.site_name" },
    //     locale: { property: "og:locale", content: "meta.og.locale" },
    //   },
    //   twitter: {
    //     card: { name: "twitter:card", content: "meta.twitter.card" },
    //     title: { name: "twitter:title", content: "meta.twitter.title" },
    //     description: { name: "twitter:description", content: "meta.twitter.description" },
    //     image: { name: "twitter:image", content: "meta.twitter.image" },
    //   }
    // });

    // // Get the currently used metainfo
    // const metadata = useActiveMeta()
    // watch(metadata, (newValue) => {
    //   console.log("watch metadata", metadata);
    // })

    // data
    const data = reactive({
      isValidCustomCursor: computed(() => {
        if(state.currPage != 'edit' && !state.isMobileAgent) {
          $.addClass("s--custom-cursor", $.qs("html"));
          return true;
        }
        else {
          $.removeClass("s--custom-cursor", $.qs("html"));
          return false;
        }
      }),
      chatbot: computed(() => state.chatbot),
      loadState: computed(() => state.loadState),
      isCreditOpen: computed(() => state.isCreditOpen),
      isPolicyOpen: computed(() => state.isPolicyOpen),
      isIntroYtOpen: computed(() => state.isIntroYtOpen),
      isLoaderOn,
      isMobileSize: computed(() => state.isMobileSize),
    });
    // methods

    const methods = {
      closePopup: (value) => {
        document.documentElement.classList.remove('with-modal-popup')
        document.querySelector('body').style.overflow = 'auto'
        commit('set', { sel: `${value}`, to: false })
      },
    };

    onMounted(() => {
      dispatch("appResize");
      isLoaderOn && $.addClass("s--before-splash s--hide-content", $.qs("html"));

      console.log("⛰ App.vue");

      window.addEventListener("DOMContentLoaded", function() {
        console.log('🚀 DOMContentLoaded');
        // observe resize
        const RO = new ResizeObserver((targets, observer) => {
          // if (state.pageState != "landed") return;
          // console.clear();
          let isChangedWidth = false;
          let isChangedHeight = false;
          const oldCW = state.cw;
          const oldCH = state.ch;
          const newCW = Math.floor(targets[0].contentBoxSize[0].inlineSize);
          const newCH = Math.floor(targets[0].contentBoxSize[0].blockSize);
          let cwColor, chColor = "inherit";
          if(newCW != oldCW) {
            commit("set", { sel: "cw", to: newCW });
            isChangedWidth = true;
            cwColor = "#9980FF";
          }
          if(newCH != oldCH) {
            commit("set", { sel: "ch", to: newCH });
            isChangedHeight = true;
            chColor = "#9980FF";
          }

          if(state.pageState == "landed" && !state.resizeLock) {
            if(isChangedWidth) {
              console.log("🚀 resize-x");
              window.dispatchEvent(new CustomEvent("resize-x"));
              window.dispatchEvent(new CustomEvent("resize.canvas.x"));
            }
            if(isChangedHeight) {
              console.log("🚀 resize-y");
              window.dispatchEvent(new CustomEvent("resize-y"));
              window.dispatchEvent(new CustomEvent("resize.canvas.y"));
            }
            if(isChangedWidth || isChangedHeight) {
              console.log(state.pageState);
              console.log(`Canvas updated: (%c${oldCW}%c, %c${oldCH}%c) -> (%c${newCW}%c, %c${newCH}%c)`, `color: ${cwColor}`, `color: inherit`, `color: ${chColor}`, `color: inherit`, `color: ${cwColor}`, `color: inherit`, `color: ${chColor}`, `color: inherit`);
              console.log("🚀 resize");
              window.dispatchEvent(new CustomEvent("resize.canvas"));
              window.dispatchEvent(new Event("resize"));
            }
          }
        });
        RO.observe($.qs(".i-canvas"));
      });

      window.addEventListener("resize", e => {
        dispatch("appResize");
      });

      // delegate events
      document.addEventListener("click", e => {
        // router link
        if(e.target.closest("[router-to]")) {
          // 페이지 떠날 준비 시작
          // window.dispatchEvent(new CustomEvent("prepare.leave"));
          // 커튼이 완료되면 라우터를 이동하고 타겟 페이지의 리소스를 받아오기 시작한다.
          router.push({ path: $.attr("router-to", e.target.closest("[router-to]")) });
          // window.addEventListener("ready.curtain", () => {
          //   router.push({ path: $.attr("router-to", e.target.closest("[router-to]")) });
          // }, { once: true });
          e.preventDefault();
        }
      });

      // vue.config.js PrerenderSpaPlugin
      if (process.env.NODE_ENV === 'production') {
        document.dispatchEvent(new Event('render-event'));
      }
    });

    return {
      data,
      methods,
    };
  },
};
</script>
<style lang="scss" src="@/css/common.scss"></style>
<style lang="scss">
  .scrollContainer {
    &--loading {
      height: 100vh;
      overflow: hidden;
    }
  }
</style>