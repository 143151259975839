<template>
  <div class="channel-map-info">
    <div class="indicate__upper">
      <div class="clock">
        <div class="clock__inner">
          <div class="clock__neddles clock__neddles--hour"></div>
          <div class="clock__neddles clock__neddles--min"></div>
          <div class="clock__neddles clock__neddles--sec"></div>
          <div class="clock__center-circle"></div>
        </div>
      </div>
      <div class="date">
        <div class="date__item">
          <span class="t-cb--18">
            <strong class="t--fak">{{ methods.filterCity(data.cityQuery)}}</strong>
          </span>
        </div>
        <div class="date__item date__item--info">
          <span class="t-cb--13">
            <span class="t--fak">{{ methods.filterDate() }}</span>
          </span>
        </div>
      </div>

      <div class="thermometer">
        <div class="degree">
          <span class="t-cb--18">
            <strong class="t--fak">
              {{ methods.filterTemp(data.weatherAPI.main.temp) || '-' }}
            </strong>
          </span>
        </div>
        <div class="weather">
          <span class="t-cb--13">
            <span class="t--fak">
              {{ methods.filterWeatherCondition(data.weatherAPI.weather[0].main) || '-' }}
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="indicate__lower">
      <div class="t-cb--13">
        <div class="t--fak">
          <strong class="branch" v-html="`${branchName} 📍`"></strong>
          <div class="address">
            <address class="address__text" v-html="methods.filterAddress(cityName)">
            </address>
            <a
              :href="googleMapUrl"
              target="_blank"
              class="address__button"
            >GO</a>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted, useStore } from "@/helper/vue.js";
import { onUnmounted } from '@vue/runtime-core';
export default {
  name: 'Chat Map Info',
  props: {

    /**
     * 지도 정보 브랜치 네임
    */
    branchName: {
      type: String,
      Default: 'DFY® Sinsa'
    },

    /**
     * 도시 명
    */
   cityName: {
     type: String,
     Default: 'sinsa'
   },

   /**
     * 구글 맵 검색 url
    */
   googleMapUrl: {
     type: String,
     Default: null
   },
  },
  setup(props) {
    const { state } = useStore();
    const data = reactive({
      cityQuery: computed(() => methods.filterCityName(props.cityName) || 'seoul'),
      WEATHER_API_KEY: process.env.VUE_APP_OPEN_WEATHER_API,
      URL_BASE: 'https://api.openweathermap.org/data/2.5/',
      weatherAPI: {
        name: null,
        main: {
          temp: null
        },
        weather: [
          {
            main: null
          }
        ]
      }
    })

    const methods = {

      // 도시명 필터 메서드
      filterCityName: (city) => {
        switch (city) {
          case 'sinsa' :
            return 'seoul'

          case 'paju' :
            return 'paju'

          case 'seongsu':
            return 'seoul'
        }
      },

      // 데이트 필터
      filterDate: () => {
        const isEnglish = !state.isLangKr;
        const myDate = new Date()
        const MONTH_EN = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const MONTH_KR = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
        const WEEKS_EN = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        const WEEKS_KR = ['일', '월', '화', '수', '목', '금', '토']
        const months = isEnglish ? MONTH_EN : MONTH_KR
        const weeks = isEnglish ? WEEKS_EN : WEEKS_KR

        const date =  myDate.getDate()
        const day = weeks[myDate.getDay()]
        const month = months[myDate.getMonth()]

        return isEnglish ? `${day}, ${date} ${month}` : `${month}월 ${date}일 ${day}요일`
      },

      // 현재 온도 필터
      filterTemp: (temperature) => {
        return `${Math.floor(temperature)}°`
      },

      // 날씨 필터
      filterWeatherCondition: (condition) => {
        const isEng = !state.isLangKr;
        let weather = ''
        switch (condition) {
          case 'Clear' : weather = ['Clear', '맑음']; break;
          case 'Clouds' : weather = ['Clouds', '구름']; break;
          case 'Thunderstorm' : weather = ['Thunderstorm', '뇌우']; break;
          case 'Drizzle' : weather = ['Drizzle',  '안개비']; break;
          case 'Rain' : weather = ['Rain', '비']; break;
          case 'Snow' : weather = ['Snow', '눈']; break;
          case 'Mist' : weather = ['Mist', '미스트']; break;
          case 'Smoke' : weather = ['Smoke', '연기']; break;
          case 'Haze' : weather = ['Haze', '연무']; break;
          case 'Dust' : weather = ['Dust', '먼지']; break;
          case 'Fog' : weather = ['Fog', '안개']; break;
          case 'Sand' : weather = ['Sand', '황사']; break;
          case 'Ash' : weather = ['Ash', '화산재']; break;
          case 'Squall' : weather = ['Squall', '돌풍']; break;
          case 'Tornad' : weather = ['Tornad', '태풍']; break;
        }
        return isEng ? weather[0] : weather[1]
      },

      // 주소 필터
      filterAddress: (branch) => {
        const lang = state.isLangKr ? "kr" : "eng";
        let address = ''
        switch (`${lang}-${branch}`) {
          case 'kr-sinsa' : address = '서울특별시 강남구 강남대로140길 20 \n(06043)'; break;
          case 'kr-paju' : address = '경기도 파주시 회동길 446 1층 \n(10881)'; break;
          case 'kr-seongsu' : address = '서울 성동구 연무장길 90 3층 \n(04785)'; break;
          case 'kr-seongsuFlat' : address = '서울 성동구 연무장길 90 1층 \n(04785)'; break;
          case 'eng-sinsa' : address = '20, Gangnam-daero 140-gil, \nGangnam-gu, Seoul, Korea (06043)'; break;
          case 'eng-paju' : address = '446, Hoedong-gil, Paju-si, \nGyeonggi-do, Korea (10881)'; break;
          case 'eng-seongsu' : address = '90, Yeonmujang-gil, \nSeongdong-gu, Seoul, Korea (04785)'; break;
          case 'eng-seongsuFlat' : address = '90, Yeonmujang-gil, \nSeongdong-gu, Seoul, Korea (04785)'; break;
        }
        return address.split('\n').join('<br />')
      },

      // 도시명 언어 필터
      filterCity: (city) => {
        if(state.isLangKr) {
          if(city == "seoul") {
            return "서울";
          }
          else if(city == "paju") {
            return "파주";
          }
        }
        else {
          if(city == "seoul") {
            return "Seoul, Korea";
          }
          else if(city == "paju") {
            return "Paju, Korea";
          }
        }
      },

      // 아날로그 시계 애니메이션 메서드
      setAnalogClock: () => {
        const fetchAnimationStyle = document.createElement('style')
        fetchAnimationStyle.setAttribute('id', 'clockAnimation')
        const clockTime = new Date()
        let hour = clockTime.getHours()
        let min = clockTime.getMinutes()
        let sec = clockTime.getSeconds()

        fetchAnimationStyle.textContent =
        `
        @keyframes rotateSec {
          0% {
            transform: rotateZ(${90 + sec * 360 / 60}deg);
            transform-origin: ${(5 / 6) * 100}% 50%
          }
          100% {
            transform: rotateZ(${450 + sec * 360 / 60}deg);
            transform-origin: ${(5 / 6) * 100}% 50%
          }
        }

        @keyframes rotateMin {
          0% {
            transform: rotateZ( ${90 + min * 360 / 60 + sec / 360 / 360}deg);
          }
          100% {
            transform: rotateZ( ${450 + min * 360 / 60 + sec / 360 / 360}deg);
          }
        }

        @keyframes rotateHour {
          0% {
            transform: rotateZ( ${90 + hour * 360 / 12 + min * 360 / 60 / 12 + sec / 360 / 360 / 12}deg);
          }
          100% {
            transform: rotateZ( ${450 + hour * 360 / 12 + min * 360 / 60 / 12 + sec / 360 / 360 / 12}deg);
          }
        }`
        return document.head.appendChild(fetchAnimationStyle)
      }
    }

    // 날씨 api fetch
    const fetchWeather = async () => {
      let {cityQuery, URL_BASE, WEATHER_API_KEY } = data
      try {
        const langParam = !state.isLangKr ? '&lang=en' : `&lang=kr`;
        let fetchURL = await fetch(`${URL_BASE}weather?q=${cityQuery}&units=metric${langParam}&APPID=${WEATHER_API_KEY}`)
        let fetchJsonData = await fetchURL.json()

        data.weatherAPI = fetchJsonData
      } catch (err) {
        console.error('에러가 발상했습니다. :', err)
      }
    }

    onMounted (() => {
      fetchWeather()
      methods.setAnalogClock()
    })
    onUnmounted (() => {
      // 아날로그 시계 애니메이션 마운트 해지
      const clockStyle = document.querySelector('#clockAnimation')
      clockStyle.parentNode.removeChild(clockStyle)
    })
    return {
      data,
      methods
    }
  }
}
</script>
<style lang="scss">
@import "@/css/_settings.scss";
  .channel-map-info {
    position: absolute;
    bottom: $gutter;
    left: $gutter;
    width: calc(100% - #{$gutter} - #{$gutter});
    padding: 13em 15em;
    border-radius: 16px;
    background: $f-white;
    .indicate {
      &__upper {
        display: flex;
        .clock {
          $clockDiameter: 39em;
          $innerDiameter: $clockDiameter * 0.8;

          position: relative;
          width: $clockDiameter;
          height: $clockDiameter;
          border: 1px solid $f-black;
          border-radius: 50%;
          &__inner {
            position: absolute;
            top: ($clockDiameter - $innerDiameter)/2;
            right: ($clockDiameter - $innerDiameter)/2;
            width: $innerDiameter;
            height : $innerDiameter;
            border-radius: 50%;
          }
          &__neddles {
            position: absolute;
            left: 0;
            height: 1em;
            animation-timing-function: linear;
            animation-iteration-count: infinite;

            &--hour {
              top: $innerDiameter/2 - ($clockDiameter/50);
              left: 15%;
              width: 35%;
              background: $f-black;
              animation-name: rotateHour;
              animation-duration: 143200s;
              transform-origin: 100% 50%
            }
            &--min {
              top: $innerDiameter/2 - ($clockDiameter/80);
              width: 50%;
              background: $f-black;
              animation-name: rotateMin;
              animation-duration: 3600s;
              transform-origin: 100% 50%
            }
            &--sec {
              top: $innerDiameter/2 - ($clockDiameter/150);
              width: 60%;
              z-index: 4;
              background-color: $f-red;
              animation-name: rotateSec;
              animation-duration: 60s;
              transform-origin: 83.3333% 50%;
            }
          }
          &__center-circle {
            background-color: $f-red;
            position: absolute;
            top: calc(50% - 2em);
            left: calc(50% - 2em);
            width: 4em;
            height: 4em;
            border-radius: 50%;
            z-index: 10;
          }
        }
        .date {
          padding: 0 0 13em 16em;
          &__item {
            &--info {
              opacity: .4;
            }
          }
        }
        .weather {
          opacity: .4;
        }
        .thermometer {
          text-align: right;
          margin-left: auto;
        }
      }
      &__lower {
        position: relative;
        padding-top: 13em;
        &:before {
          content:'';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: $f-black;
          opacity: 0.1;
        }
        .address {
          display: flex;
          align-items: center;
          width: 100%;
          &__text {
            opacity: .4;
          }
          &__button {
            margin-left: auto;
            padding: 0.5em 1.2em;
            border-radius: 15px;
            background-color: #007AFF;
            color: $f-white;
          }
        }
      }
    }
  }
</style>