<template>
  <div class="sub-map">
    <button class="b-back b-hover--white sub-map__btn" @click="methods.setSubSubpageState('map', null)">
      <span class="head__icon b-icon__img b-hover__icon ico-chatbot--navi-navigate--back"></span>
    </button>
    <div
      v-if="data.mapState.name"
      class="channel-map"
    >
      <!-- {{bgData.location}}
      {{data.mapState.name}} -->
      <ChatGoogleMap
        :location="bgData.location"
        :lat-lng="bgData.latLng"
      />
      <ChatMapInfo
        :branch-name="bgData.headTitle"
        :city-name="bgData.location"
        :google-map-url="bgData.googleMapUrl"
      />
    </div>
  </div>
</template>

<script>
import * as $ from "fxdom";
import * as _ from "fxjs";
import { reactive, onMounted, onActivated, onDeactivated, useStore, computed } from "@/helper/vue.js";
// import { reactive, } from '@vue/reactivity'
import ChatGoogleMap from "@/parts/chatbot/subpages/place/ChatGoogleMap.vue"
import ChatMapInfo from "@/parts/chatbot/subpages/place/ChatMapInfo.vue"


export default {
  props: [ 'mapState', 'bgData' ],
  emits: [ 'setMapState' ],
  components: {
    ChatGoogleMap,
    ChatMapInfo
  },
  setup(props) {
    const { state, get, commit, dispatch } = useStore();
    const data = reactive({
      mapState: computed(() => state.chatbot.mapState),
    });

    const methods = {
      setSubSubpageState: (type, name) => {
        // type: video, map
        // name: sinsa, paju, seongsu
        // $.removeClass("sub-subpage-wrap--open", $.qs(".sub-subpage-wrap"));
        dispatch('chatbot/closeSubSubpage', {type: type, name: name})

        // commit('chatbot/setSubpageFromContact', {subpageName: null, type: null});
        // commit('chatbot/setCurrentSubpage', null);
      },
    }
    onMounted(() => {
    })
    return {
      data,
      methods,
    };
  }

}
</script>

<style lang="scss" scoped>
  .sub-map__btn {
    z-index: 10;
  }

</style>