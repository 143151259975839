<template>
	<div class="c-layer" :class="{ 'c-layer--open' : data.compName !== null, 'credit': data.compName == 'credit', 'privacy-policy': data.compName == 'privacyPolicy', 'c-layer--youtub': data.compName == 'workYt' || data.compName == 'introYt' }">
		<div class="c-layer__btn-wrap">
			<button
				type="button"
				@click="methods.closePopup()"
				class="c-layer__btn-close t-h--18 t-h--16s"
        :class="{'c-layer__btn-close--w': data.compName !== 'privacyPolicy'}"
			>
				<span class="t--fak">CLOSE</span>
			</button>
		</div>

		<div class="c-layer__cont">
      <keep-alive>
        <component v-bind:is="data.compName"></component>
      </keep-alive>
		</div>
    <div v-if="data.compName == 'credit'" class="c-layer__dim"></div>
	</div>
</template>

<script>
import * as $ from 'fxdom';
import * as _ from 'fxjs';
import { reactive, watch, watchEffect, defineAsyncComponent, computed, onBeforeUpdate, onMounted, onUnmounted, onUpdated, useStore, } from '@/helper/vue.js';
import { nextTick } from '@vue/runtime-core';
import { bgm } from "@/helper/gadget.js";

import Credit from "@/pages/layer/Credit.vue";
import PrivacyPolicy from "@/pages/layer/PrivacyPolicy.vue";
import IntroYt from "@/pages/layer/IntroYoutube.vue";
import WorkYt from "@/pages/layer/WorkYoutube.vue";

export default {
  components: {
    'credit': Credit,
    'privacyPolicy': PrivacyPolicy,
    'introYt': IntroYt,
    'workYt': WorkYt,
  },
	setup(props) {
    const { state, get, commit, dispatch } = useStore();
		// 현재 템플릿 ROOT를 DOM으로 접근
		// 템플릿에서 사용되는 데이터
		const data = reactive({
			isOpen: false,
      compName: computed(() => state.layerPopup.name),
      embedType: computed(() => state.layerPopup.source[0]),
      src: computed(() => state.layerPopup.source[1]),
		});
		// 템플릿에서 사용되는 메소드
		const methods = {
      closePopup: () => {
        // transition 끝난 후 deactivate
        setTimeout(() => {
          document.documentElement.classList.remove('with-modal-popup');
          document.querySelector('body').style.overflow = 'auto';
          commit('setLayerPopup', {compName: null});

					// audio play
					bgm.play()
        }, 500);
      }
		};

		// DOM 접근 가능 시점
		onMounted(() => {
			nextTick(() => {})
		});

		// window, document 등 Unmount되지 않는 이벤트 해제를 위해 사용
		onUnmounted(() => {
      
		});

		return {
			data,
			methods
		};
	}


}
</script>

<style lang="scss" src="@/css/layerPop.scss"></style>